import React, { useState, useEffect } from 'react';
import InvoicePaymentTable from './InvoicePaymentTable';
import { Form } from 'react-bootstrap';
import './InvoicePayment.css';

const InvoicePayment = () => {
    const [searchQuery, setSearchQuery] = useState('');
    const [totalCount, setTotalCount] = useState(0);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const clearFilter = () => {
        setSearchQuery('');
    };

    return (
        <div className='main-class-data-table'>
            <div className='d-md-flex justify-content-between'>
                <div className='d-flex mt-3'>
                    <h3 className='table-heading me-2'>Invoices Due For Payment</h3>
                    <div>
                        <span className='count-class'>{totalCount}</span>
                    </div>
                </div>
            </div>
            <div className='grey-border'></div>
            <InvoicePaymentTable searchQuery={searchQuery} setTotalCount={setTotalCount} />
        </div>
    );
};

export default InvoicePayment;
