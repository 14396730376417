import React from "react";
import logo from "../../../src/Assets/Images/logo.png";
import { Col, Form, Row, Table } from "react-bootstrap";

const ConnecticutCorp = () => {
  return (
    <section className="main-class-form-pdf">
      {/* First page */}
      <div>
        <div className="d-flex">
          <div>
            <img src={logo} className="logo-img-pdf me-3" />
          </div>
          <div className="mt-4">
            <h2 className="main-title-pdf">
              Secretary of the State of Connecticut
            </h2>
            <div className="d-flex">
              <span className="blkk-text-big me-2">
                Phone: <span className="blue-big-text">860-509-6003</span>
              </span>
              <span className="blkk-text-big me-2">
                {" "}
                Website: <span className="blue-big-text">
                  business.ct.gov
                </span>{" "}
              </span>
              <span className="blkk-text-big me-2">
                Email: <span className="blue-big-text">bsd@ct.gov</span>
              </span>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <p className="sub-main-title-pdf">
            CERTIFICATE OF INCORPORATION -
            <span className="small-blue-text">
              Accessible Version Available
            </span>
          </p>
          <p className="normal-texttt">
            STOCK CORPORATION -
            <span className="sub-normal-text">
              USE INK. PRINT OR TYPE. ATTACH 8 1/2” X 11” SHEETS IF NECESSARY
            </span>{" "}
          </p>
        </div>
        <div className="blk-border">
          <Row>
            <Col xl={8} lg={8} sm={8} xs={12}>
              <div>
                <p>FILING PARTY (CONFIRMATION WILL BE SENT TO THIS ADDRESS):</p>
                <div className="d-flex">
                  <label className="me-3">NAME:</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">ADDRESS:</label>
                  <div>
                    <input type="text" placeholder="$" />
                    <input type="text" placeholder="$" className="mt-3" />
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">CITY:</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STATE:</label>
                  <input type="text" placeholder="$" />
                  <div className="d-flex">
                    <label className="me-3 ms-3">ZIP CODE:</label>
                    <input type="text" placeholder="$" />
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">EMAIL:</label>
                  <input type="email" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">TELEPHONE NUMBER:</label>
                  <input type="tel" placeholder="$" />
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} sm={4} xs={12}>
              <div className="main-div-filling">
                <div className="text-center">
                  <h2 className="sub-main-title-pdf">Filing Fee: $120</h2>
                  <span className="sub-normal-text">
                    Includes franchise tax up to 20,000 shares
                  </span>
                  <p className="sub-normal-text">
                    Make checks payable to“Secretary of the State”
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <div>
                <p className="sub-main-title-pdf">
                  1.NAME OF CORPORATION (required)
                  <span className="normal-texttt">
                    (Must include business designation, e.g., Inc., Co., Corp.)
                  </span>
                </p>
                <input type="text" placeholder="$" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={8} lg={8} sm={12} xs={12}>
              <div>
                <p className="sub-main-title-pdf">
                  2.TOTAL NUMBER OF AUTHORIZED SHARES:
                </p>
                <input type="text" />
              </div>
            </Col>
            <div className=" blk-border mt-4 mb-4 ">
              <span className="normal-texttt">
                If the corporation has more than one class of shares, it must
                designate each class and the number of shares authorized within
                each class below.
              </span>
            </div>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <h2 className="sub-main-title-pdf">CLASS</h2>
              <input type="text" placeholder="$" className="mt-3" />
              <input type="text" placeholder="$" className="mt-3" />
              <input type="text" placeholder="$" className="mt-3" />
              <input type="text" placeholder="$" className="mt-3" />
            </Col>
            <Col xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className="main-class-left-border ps-4">
                <h2 className="sub-main-title-pdf">
                  NUMBER OF SHARES PER CLASS:
                </h2>
                <input type="text" placeholder="$" className="mt-3" />
                <input type="text" placeholder="$" className="mt-3" />
                <input type="text" placeholder="$" className="mt-3" />
                <input type="text" placeholder="$" className="mt-3" />
              </div>
            </Col>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <div className="main-class-top-border ps-4">
                <h2 className="sub-main-title-pdf mt-4">
                  3. IF MORE THAN ONE CLASS OF SHARES, SET FORTH THE TERMS,
                  LIMITATIONS, RELATIVE RIGHTS AND PREFERENCES OF EACH CLASS OF
                  SHARES AND SERIES THEREOF PURSUANT TO CONN. GEN. STAT. SECTION
                  33-665:
                </h2>
                <input type="text" placeholder="$" className="mt-3" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={8} lg={8} sm={12} xs={12}>
              <div>
                <p className="sub-main-title-pdf">
                  3.MAILING ADDRESS
                  <span className="normal-texttt">
                    (Required - Provide full address. P.O. Box acceptable.): –
                  </span>{" "}
                </p>
                <input type="text" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STREET OR P.O. BOX:</label>
                <div>
                  <input type="text" placeholder="$" />
                  <input type="text" placeholder="$" className="mt-3" />
                </div>
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">CITY:</label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STATE:</label>
                <input type="text" placeholder="$" />
                <div className="d-flex">
                  <label className="me-3 ms-3">ZIP CODE:</label>
                  <input type="text" placeholder="$" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <p className="blkk-bgg">
            NOTE: COMPLETE EITHER 4A OR 4B ON THE FOLLOWING PAGE, NOT BOTH
          </p>
          <Row>
            <Col xl={8} lg={8} sm={12} xs={12}>
              <div>
                <p className="sub-main-title-pdf">
                  4.APPOINTMENT OF REGISTERED AGENT
                  <span className="normal-texttt">(Required):</span>{" "}
                </p>
              </div>
              <div>
                <label>
                  If Agent is an individual, print or type full legal name:
                </label>
                <input type="text" placeholder="$" />
              </div>
              <div className="mt-3">
                <label>Signature accepting appointment</label>
                <input type="text" placeholder="$" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={6} lg={6} sm={12} xs={12}>
              <div className="d-flex justify-content-between">
                <p className="sub-main-title-pdf">
                  BUSINESS ADDRESS
                  <span className="normal-texttt ms-2">
                    (Required - No P.O. Box):
                  </span>
                </p>
                <div>
                  <Form.Check label="CHECK BOX IF NONE:" />
                </div>
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STREET:</label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">CITY:</label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STATE:</label>
                <input type="text" placeholder="$" />
                <div className="d-flex">
                  <label className="me-3 ms-3">ZIP CODE:</label>
                  <input type="text" placeholder="$" />
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} sm={12} xs={12}>
              <div className="main-class-left-border ps-4">
                <div>
                  <p className="sub-main-title-pdf">
                    CONNECTICUT RESIDENCE ADDRESS
                    <span className="normal-texttt ms-2">
                      (Required - No P.O. Box):
                    </span>{" "}
                  </p>
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STREET</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">CITY:</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STATE:</label>
                  <input type="text" placeholder="$" />
                  <div className="d-flex">
                    <label className="me-3 ms-3">ZIP CODE:</label>
                    <input type="text" placeholder="$" />
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <div className="main-class-top-border">
                <div className="mt-3">
                  <p className="sub-main-title-pdf">
                    CONNECTICUT MAILING ADDRESS (Required):
                  </p>
                  <span className="normal-texttt ms-2">
                    (P.O. Box is acceptable):
                  </span>{" "}
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STREET: OR P.O. Box:</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">CITY:</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STATE:</label>
                  <input type="text" placeholder="$" />
                  <div className="d-flex">
                    <label className="me-3 ms-3">ZIP CODE:</label>
                    <input type="text" placeholder="$" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </section>
  );
};

export default ConnecticutCorp;
