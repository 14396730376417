import React from "react";
import "../CommonStyleTour/CommonStyleTour.css";
import CommonTourWhite from "../CommonTourComponent/CommonTourWhite";
import CommonTourBlue from "../CommonTourComponent/CommonTourBlue";
import CompanyTourModal from "../CompanyTourModal";
import EmployeeCompanyModal from "../EmployeeTourComponents/EmployeeCompanyModal";

const TourEighteen = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const handleStartClick = () => {
    setModalShow(true);
  };

  return (
    <section className="main-class-tour">
      <div>
        <h4 className="tour-heading">Welcome to Client Management</h4>
        <p className="tour-text-holder">
        This section allows you to manage all clients within your organization. You can add new client, edit existing client profiles.
        </p>
      </div>
      <div>
        <CommonTourBlue label={"Start"} onclick={handleStartClick} />
        {/* <div className="mt-3">
          <CommonTourWhite label={"Skip"} />
        </div> */}
        <EmployeeCompanyModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </section>
  );
};

export default TourEighteen;