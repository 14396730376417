import React from 'react';

const steps = [
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/39037a34bc3ffa913c20ac921150ecd03c2574e0b71d1595136b3182334f3267?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0",
    title: "Step 1: Tell us your business name",
    description: "Start the process by sharing your legal business name."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/987840d08ebffe29dd2406d4c47e146b4db0a581daa2c77833148d7479698dd3?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0",
    title: "Step 2: Answer a few questions",
    description: "We'll ask you to provide some details, such as your contact info."
  },
  {
    icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/2247de3139823c1f7066243977dc8a31da253a0115866b7e69dc5bf414541288?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0",
    title: "Step 3: We'll complete & file your paperwork",
    description: "We'll let your state know that we're your new registered agent. And if you're switching registered agents, we'll cover those costs."
  }
];

function Steps() {
  return (
    <section className="flex flex-col justify-center items-center py-20 mt-7 w-full text-center bg-white">
      <div className="flex flex-col justify-center px-4 max-w-[1140px] w-full">
        <h2 className="text-4xl font-semibold text-gray-800 mb-10 md:text-3xl sm:text-2xl">
          How to get a registered agent with{" "}
          <span className="text-blue-700">vState Filings</span>{" "}
          in <span className="text-blue-700">3 easy steps</span>
        </h2>
        <div className="flex flex-wrap justify-center gap-6 w-full">
          {steps.map((step, index) => (
            <div
              key={index}
              className="flex flex-col items-center p-4 bg-white rounded shadow-lg min-w-[280px] max-w-sm sm:w-full"
            >
              <img
                loading="lazy"
                src={step.icon}
                alt={`Step ${index + 1} icon`}
                className="object-contain aspect-square w-16 mb-4"
              />
              <h3 className="text-xl font-bold text-indigo-600 mb-2">
                <span className="text-blue-700">{step.title.split(":")[0]}: </span>
                <span className="font-semibold text-gray-800">
                  {step.title.split(":")[1]}
                </span>
              </h3>
              <p className="text-gray-700 text-base leading-6">
                {step.description}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default Steps;
