import React from "react";
import "../CommonStyleTour/CommonStyleTour.css";
import CommonTourWhite from "../CommonTourComponent/CommonTourWhite";
import CommonTourBlue from "../CommonTourComponent/CommonTourBlue";
import CommonTourModal from "../CommonTourModal";

const TourOne = () => {
  const [modalShow, setModalShow] = React.useState(false);
  
    const handleStartClick = () => {
      setModalShow(true);
    };

  return (
    <section className="main-class-tour">
      <div>
        <h4 className="tour-heading">Welcome to Your Dashboard</h4>
        <p className="tour-text-holder">
          This dashboard component serves as a central hub for users to initiate
          and manage various company-related activities, including formation,
          compliance, and order tracking.
        </p>
      </div>
      <div>
        <CommonTourBlue label={"Start"}  onclick={handleStartClick} />
        {/* <div className="mt-3">
          <CommonTourWhite label={"Skip"} />
        </div> */}
        <CommonTourModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </section>
  );
};

export default TourOne;
