// import React, { useState, useEffect, useContext } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import OrderServices from "../../../../services/orderServices";
// import "../InvoicePayment/InvoicePayment.css";
// import authContext from "../../../../common/authContext";
// import { MultiSelect } from "primereact/multiselect";

// export default function SubscriptionTable({ searchQuery, setTotalCount }) {
//   const {
//     setFeedbackData,
//     permissions,
//     globalCompany,
//     setGlobalCompany,
//     isLoading,
//     setIsLoading,
//     isTabRefresh,
//     setIsTabRefresh,
//     redirectedData,
//     setRedirectedData,
//   } = useContext(authContext);
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [filters, setFilters] = useState({});
//   const [globalFilterValue, setGlobalFilterValue] = useState("");
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       setError(null);

//       try {
//         const response = await OrderServices.getClientSubscription(
//           globalCompany?.companyDetails?.id,
//           0
//         );
//         const formattedData =
//           response.data?.map((item) => ({
//             id: item?.lineItemId || 0,
//             subscriptionName: item?.subscriptionName || "N/A",
//             entityName: item?.entityName || "N/A",
//             subscriptionDate: item?.orderDate ? new Date(item.orderDate) : null,
//             subscriptionExpiryDate: item?.subscriptionExpiryDate
//               ? new Date(item.subscriptionExpiryDate)
//               : null,
//             orderNumber: item?.orderNumber || "N/A",
//           })) || [];

//         setData(formattedData);
//         setTotalCount(formattedData.length);
//       } catch (err) {
//         console.error("Error fetching data:", err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//     initFilters();
//   }, [globalCompany]);

//   const initFilters = () => {
//     setFilters({
//       global: { value: null, matchMode: "contains" },
//       subscriptionName: { value: null, matchMode: "contains" },
//       entityName: { value: null, matchMode: "contains" },
//       orderNumber: { value: null, matchMode: "contains" },
//       subscriptionDate: { value: null, matchMode: "dateIs" },
//       subscriptionExpiryDate: { value: null, matchMode: "dateIs" },
//     });
//   };

//   const onGlobalFilterChange = (e) => {
//     const value = e.target.value;
//     setFilters({ ...filters, global: { value, matchMode: "contains" } });
//     setGlobalFilterValue(value);
//   };

//   const renderHeader = () => (
//     <div className="flex justify-content-end p-2">
//       <div>
//         <Button
//           type="button"
//           icon="pi pi-filter-slash"
//           label="Clear"
//           outlined
//           onClick={initFilters}
//           className="me-2"
//         />
//       </div>
//       <div>
//         <InputText
//           value={globalFilterValue}
//           onChange={onGlobalFilterChange}
//           placeholder="Global Search"
//         />
//       </div>
//     </div>
//   );

//   const multiSelectFilter = (options, field) => {
//     const uniqueValues = Array.from(new Set(data.map((item) => item[field])));
//     return (
//       <MultiSelect
//         value={options.value}
//         options={uniqueValues}
//         onChange={(e) => options.filterApplyCallback(e.value)}
//         placeholder={`Select ${field}`}
//         display="chip"
//       />
//     );
//   };

//   const formatDate = (date) =>
//     date ? date.toLocaleDateString("en-US") : "N/A";

//   return (
//     <div className="main-table-class-datatable">
//       <div className="card">
//         {error && <div className="alert alert-danger">{error}</div>}
//         <DataTable
//           value={data}
//           paginator
//           rows={5}
//           loading={loading}
//           filters={filters}
//           globalFilterFields={["subscriptionName", "entityName", "orderNumber"]}
//           header={renderHeader()}
//           emptyMessage="No records found."
//           scrollable
//           scrollHeight="flex"
//           scrollWidth="100%"
//         >
//           <Column
//             field="subscriptionName"
//             header="Subscription Name"
//             filter
//             filterElement={(options) =>
//               multiSelectFilter(options, "subscriptionName")
//             }
//             filterMatchMode="contains"
//           />
//           <Column
//             field="entityName"
//             header="Entity Name"
//             filter
//             filterElement={(options) =>
//               multiSelectFilter(options, "entityName")
//             }
//             filterMatchMode="contains"
//           />
//           <Column
//             field="subscriptionDate"
//             header="Subscription Date"
//             body={(rowData) => formatDate(rowData.subscriptionDate)}
//             filter
//           />
//           <Column
//             field="subscriptionExpiryDate"
//             header="Subscription Expiry Date"
//             body={(rowData) => formatDate(rowData.subscriptionExpiryDate)}
//             filter
//           />
//           <Column
//             field="orderNumber"
//             header="Order Number"
//             filter
//             filterElement={(options) =>
//               multiSelectFilter(options, "orderNumber")
//             }
//             filterMatchMode="contains"
//           />
//         </DataTable>
//       </div>
//     </div>
//   );
// }

import React, { useState, useEffect, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import OrderServices from "../../../../services/orderServices";
import "../InvoicePayment/InvoicePayment.css";
import authContext from "../../../../common/authContext";
import { MultiSelect } from "primereact/multiselect";
import { Col, Row } from "react-bootstrap";

export default function SubscriptionTable({ searchQuery, setTotalCount }) {
  const { globalCompany } = useContext(authContext);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [error, setError] = useState(null);

  const columns = [
    { field: "subscriptionName", header: "Subscription Name" },
    { field: "entityName", header: "Entity Name" },
    { field: "subscriptionDate", header: "Subscription Date", format: true },
    {
      field: "subscriptionExpiryDate",
      header: "Subscription Expiry Date",
      format: true,
    },
    { field: "orderNumber", header: "Order Number" },
  ];

  const [visibleColumns, setVisibleColumns] = useState(columns);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await OrderServices.getClientSubscription(
          globalCompany?.companyDetails?.id,
          0
        );
        const formattedData =
          response.data?.map((item) => ({
            id: item?.lineItemId || 0,
            subscriptionName: item?.subscriptionName || "N/A",
            entityName: item?.entityName || "N/A",
            subscriptionDate: item?.orderDate ? new Date(item.orderDate) : null,
            subscriptionExpiryDate: item?.subscriptionExpiryDate
              ? new Date(item.subscriptionExpiryDate)
              : null,
            orderNumber: item?.orderNumber || "N/A",
          })) || [];

        setData(formattedData);
        setTotalCount(formattedData.length);
      } catch (err) {
        console.error("Error fetching data:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    initFilters();
  }, [globalCompany]);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: "contains" },
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters({ ...filters, global: { value, matchMode: "contains" } });
    setGlobalFilterValue(value);
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );

    setVisibleColumns(orderedSelectedColumns);
  };

  const renderHeader = () => (
    <Row>
      <Col xl={6} md={6} sm={12} xs={12}>
        <div>
          <MultiSelect
            value={visibleColumns}
            options={columns}
            optionLabel="header"
            onChange={onColumnToggle}
            className="w-full sm:w-20rem ms-2"
            display="chip"
          />
        </div>
      </Col>
      <Col xl={6} md={6} sm={12} xs={12}>
        <div className="flex justify-content-end p-2">
          <div>
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Clear"
              outlined
              onClick={initFilters}
              className="me-2"
            />
          </div>
          <div>
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Global Search"
            />
          </div>
        </div>
      </Col>
    </Row>
  );

  const formatDate = (date) =>
    date ? date.toLocaleDateString("en-US") : "N/A";

  return (
    <div className="main-table-class-datatable">
      <div className="card">
        {error && <div className="alert alert-danger">{error}</div>}
        <DataTable
          value={data}
          paginator
          rows={5}
          loading={loading}
          filters={filters}
          globalFilterFields={["subscriptionName", "entityName", "orderNumber"]}
          header={renderHeader()}
          emptyMessage="No records found."
          scrollable
          scrollHeight="flex"
          scrollWidth="100%"
        >
          {visibleColumns.map((col) => (
            <Column
              sortable
              key={col.field}
              field={col.field}
              header={col.header}
              body={
                col.format ? (rowData) => formatDate(rowData[col.field]) : null
              }
              // filter
              // filterMatchMode="contains"
            />
          ))}
        </DataTable>
      </div>
    </div>
  );
}
