const propertiesApp={
    strapiUrl:"https://strapi.redberyltest.in",
    strapi:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwiaWF0IjoxNzM3MzYxMzM3LCJleHAiOjE3Mzk5NTMzMzd9.vkiZ5GPD53mSiYfhsIrn0dxvQVPNqSEOLer7KK-A7rw",
    react_host:"http://localhost:8765",
    strapi_java_host:"http://localhost:8765",
    stripe_host:"http://localhost:8000",
    chatbot_url:"https://chatbot.redberyltest.in",
    automation_host:"http://localhost:3003",
    quickbooks_host:"http://localhost:3003",
    python_url:"https://chatbot.redberyltest.in",
    automation_host:"http://localhost:3003",
    quickbooks_host:"http://localhost:3003",
    python_url:"https://chatbot.redberyltest.in",
    tour_url:'https://www.redberyltest.in',

}


export default propertiesApp;