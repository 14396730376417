import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import { Button } from "primereact/button";
import { useRef, useState } from "react";

function ArizonaCorp({ orderData }) {
  const componentRef = useRef();

  if (!orderData) {
    return <div>No order data available.</div>;
  }

  const {
    name = "",
    service = "",
    agentName = "",
    address1 = "",
  
    address1_2 = "",
    city = "",
    sign = "",
    date = "",
  
    printed_date = "",
  
    zip = "",
    address2 = "",
    business_name = "",
    business_name_2 = "",
    char_of_business = "",
    clas = "",
    series = "",
    total = "",
  
    clas2 = "",
    series2 = "",
    total2 = "",
  
    known_attention = "",
    known_address = "",
    known_attention_optional = "",
  
    know_city = "",
    known_state = "",
  
    address2_1 = "",
    business_zip = "",
    business_city2 = "",
    business_state_2 = "",
    business_zip_2 = "",
  
    business_name_3 = "",
    business_name_4 = "",
  
    business_address_1 = "",
    business_address_2 = "",
  
    business_address_3 = "",
    business_address_4 = "",
  
    business_city3 = "",
  
    business_state_3 = "",
    business_state_4 = "",
    business_city4 = "",
    business_state_5 = "",
  
    business_zip_3 = "",
    business_name_5 = "",
    business_name_6 = "",
  
    business_address_5 = "",
    business_address_6 = "",
    business_address_7 = "",
  
    business_address_8 = "",
    business_city5 = "",
    business_state_6 = "",
    business_zip_4 = "",
  
    business_city7 = "",
    business_state_7 = "",
  
    business_state_8 = "",
  
    business_address_9 = "",
  
    agentNamePO = "",
    business_address_10 = "",
    business_address_11 = "",
  
    business_address_12 = "",
  
    business_city8 = "",
  
    business_state_9 = "",
    business_zip_5 = "",
  
    business_city9 = "",
    business_state_10 = "",
  
    business_zip_6 = "",
    incorp_name = "",
    incorp_name_2 = "",
    incorp_address = "",
    incorp_address_1_2 = "",
    incorp_address_2 = "",
    incorp_address_2_1 = "",
    incorp_address_3 = "",
    incorp_state = "",
    incorp_zip = "",
    incorp_city = "",
  
    incorp_state_2 = "",
    incorp_zip_2 = "",
  
    // name="rushi"
  } = orderData;
  

  const downloadPDF = async () => {
    const input = document.getElementById("content-to-pdf"); // Main content container
    const sections = Array.from(input.querySelectorAll(".section")); // Get all sections with class 'section'
    const pageWidth = 595.28; // A4 width in points
    const pageHeight = 841.89; // A4 height in points
    const pdfDoc = await PDFDocument.create();
    const scale = 2; // Scale for better resolution
    const pageMargin = 20; // Page margin

    // Function to render content of a section to a page
    const renderSection = async (section, startY) => {
      // Capture the section's content using html2canvas
      const canvas = await html2canvas(section, {
        scale: scale,
        y: startY, // Start rendering from the current vertical position
        height: pageHeight * scale + 20, // Limit height to the size of one page
      });

      const imgData = canvas.toDataURL("image/png");
      const img = await pdfDoc.embedPng(imgData);

      const imgWidth = img.width * (pageWidth / canvas.width) - 40; // Scale image to fit the page width
      const imgHeight = img.height * (pageHeight / canvas.height) - 100; // Scale image to fit the page height

      // Add a new page to the PDF
      const page = pdfDoc.addPage([pageWidth, pageHeight]);

      // Draw the image onto the page
      page.drawImage(img, {
        x: pageMargin,
        y: pageHeight - imgHeight - pageMargin, // Y position from the top of the page
        width: imgWidth,
        height: imgHeight,
      });
    };

    // Render each section on a new page
    for (let i = 0; i < sections.length; i++) {
      await renderSection(sections[i], 0);
    }

    // Serialize the document to bytes
    const pdfBytes = await pdfDoc.save();

    // Trigger the download of the PDF
    const link = document.createElement("a");
    link.href = URL.createObjectURL(
      new Blob([pdfBytes], { type: "application/pdf" })
    );
    link.download = "generated-document.pdf";
    link.click();
  };
  return (
    <div className="p-12" ref={componentRef} id="content-to-pdf">
      {/* page one */}
      <div className="mt-8 section">
        <div className="">
          <div
            className="mt-4  mt-32 p-2 border-t-8 border-gray-300"
            style={{ borderColor: "#101828" }}
          >
            <h5 className="text-gray-900 text-center font-inter text-xs font-medium leading-normal">
              DO NOT WRITE ABOVE THIS LINE; RESERVED FOR ACC USE ONLY.
            </h5>

            <p className="text-gray-900 mt-1  text-center font-inter text-2xl font-semibold leading-7">
              ARTICLES OF ORGANIZATION
              <br />
              FOR-PROFIT or PROFESSIONAL CORPORATION
            </p>

            <p className="text-gray-900 mt-1 text-center font-inter text-xs font-medium leading-normal">
              Read the Instructions{" "}
              <span className="mt-1  font-inter text-xs italic font-medium leading-normal text-blue-500 underline font-inter font-normal leading-7">
                <a
                  target="_blank"
                  href="https://azcc.gov/docs/default-source/corps-files/instructions/l010i-instructions-articles-of-organization.pdf?sfvrsn=fb0a9180_4"
                >
                  L010i
                </a>
              </span>
            </p>

            <p className="text-gray-900  mt-16 font-inter text-lg font-normal ">
              <span className="font-bold">1. ENTITY TYPE - check only one</span>{" "}
              to indicate the type of entity being formed.
            </p>
          </div>
          <div className="flex flex-wrap items-center px-10 justify-center max-w-[960px] max-md:px-5">
            <div
              key="LLC"
              className="flex flex-1 shrink gap-2 items-center self-stretch my-auto basis-0 min-w-[240px] max-md:max-w-full"
              role="radio"
              tabIndex={0}
            >
              <div className="flex justify-center items-center self-stretch my-auto w-4">
                <div
                  className={`flex self-stretch my-auto w-4 h-4 rounded border border-solid min-h-[16px]`}
                />
              </div>
              <div className="self-stretch my-auto text-xs font-medium leading-loose text-gray-900">
                FOR-PROFIT (BUSINESS) CORPORATION
              </div>
            </div>

            <div
              key="Another Option"
              className="flex flex-1 shrink gap-1.5 items-center self-stretch my-auto basis-0 min-w-[240px] max-md:max-w-full"
              role="radio"
              tabIndex={0}
              style={{ cursor: "pointer" }}
            >
              <div className="flex justify-center items-center self-stretch my-auto w-4">
                <div
                  className={`flex self-stretch my-auto w-4 h-4 rounded border border-solid min-h-[16px]`}
                />
              </div>
              <div className="self-stretch my-auto text-xs font-medium leading-loose text-gray-900">
                PROFESSIONAL CORPORATION
              </div>
            </div>
          </div>

          <p className="text-gray-900 font-inter mt-4 text-lg font-normal leading-7">
            <span className="font-bold">2. ENTITY NAME</span>{" "}
            <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
              <a
                target="_blank"
                href="https://azcc.gov/docs/default-source/corps-files/instructions/l010i-instructions-articles-of-organization.pdf?sfvrsn=fb0a9180_4"
              >
                see Instructions L010i
              </a>
            </span>{" "}
            for full naming requirements – give the exact name of the
            corporation :
          </p>

          <div className="flex w-full justify-center">
            {/* <input
            className="text-blue-500 mt-2
            w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
            // placeholder="$name"
            id="name"
          ></input> */}

            <div
              className="font-bold mt-2 w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
              id="name"
            >
              {" "}
              {name}
            </div>
          </div>

          <div className="mt-4">
            <p className="text-gray-900 font-inter text-lg font-normal leading-7">
              <span className="font-bold">
                3. PROFESSIONAL CORPORATION SERVICES –
              </span>
              if professional corporation is checked in number 1, briefly
              describe the professional service or services that the
              professional corporation will provide (examples: law firm,
              accounting, medical):
            </p>
            <div className="flex w-full justify-center">
              {/* <input
              id="service"
              className="text-blue-500 mt-2 
            w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
              // placeholder="$service"
            ></input> */}
              <div
                id="service"
                className=" mt-2 
            w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
              >
                {service}
              </div>
            </div>
          </div>

          <div className="mt-4">
            <p className="text-gray-900 font-inter text-lg font-normal leading-7">
              <span className="font-bold">4. CHARACTER OF BUSINESS –</span>
              briefly describe the character of business the corporation
              initially intends to conduct in Arizona. NOTE that the character
              of business that the corporation ultimately conducts is not
              limited by the description provided.
            </p>
            <div className="flex w-full justify-center">
              {/* <input
              id="charofbusiness"
              className="text-blue-500 mt-2 
            w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
              // placeholder="$service"
            ></input> */}
              <div
                className=" mt-2 
            w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
              >
                {char_of_business}
              </div>
            </div>
          </div>

          <div className="bg-white w-full mt-5">
            <div className=" flex flex-col  flex items-center space-x-2">
              <ol>
                <li>
                  <span className="font-bold text-lg text-gray-900 ">
                    5. SHARES –{" "}
                  </span>
                  <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
                    <a
                      target="_blank"
                      href="https://azcc.gov/docs/default-source/corps-files/instructions/l010i-instructions-articles-of-organization.pdf?sfvrsn=fb0a9180_4"
                    >
                      see Instructions L010i
                    </a>
                  </span>
                  -list the class (common, preferred, etc.) and total number of
                  shares of each class that the corporation is AUTHORIZED to
                  issue – the total must be greater than zero. If more space is
                  needed, check this box{" "}
                  <input type="checkbox" className="mx-2" />
                  and complete and attach the
                  <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
                    {" "}
                    <a
                      target="_blank"
                      href="https://www.azcc.gov/Divisions/Corporations/forms/starpas/formsSTPS/C087-Shares-Authorized-Attachment.pdf"
                    >
                      Shares Authorized Attachment{" "}
                    </a>
                  </span>
                  form C087.
                </li>
              </ol>

              <div className="w-full gap-5 flex justify-evenly">
                <div className="flex gap-2 justify-center items-center text-center">
                  <p>Class:</p>
                  {/* <input
                  type="text"
                  id="class"
                  className="text-blue-500 mt-2 w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
                /> */}
                  <div
                    // type="text"
                    id="class"
                    className="mt-2 w-64 h-8 border-b p-2 w-[120px] border-b-2 border-gray-900 bg-blue-50"
                  >
                    {" "}
                    {clas}
                  </div>
                </div>
                <div className="flex gap-2 justify-center items-center text-center">
                  <p>Series:</p>
                  {/* <input
                  type="text"
                  id="series"
                  className="text-blue-500 mt-2 w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
                /> */}
                  <div
                    // type="text"
                    id="class"
                    className="mt-2 w-64 h-8 border-b p-2 w-[120px] border-b-2 border-gray-900 bg-blue-50"
                  >
                    {" "}
                    {series}
                  </div>
                </div>

                <div className="flex gap-2 justify-center items-center text-center">
                  <p>Total:</p>
                  {/* <input
                  type="text"
                  id="total"
                  className="text-blue-500 mt-2 w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
                /> */}
                  <div
                    // type="text"
                    // id="class"
                    className="mt-2 w-64 h-8 border-b p-2 w-[120px] border-b-2 border-gray-900 bg-blue-50"
                  >
                    {" "}
                    {total}
                  </div>
                </div>
              </div>

              {/* share row 2 */}
              <div className="w-full gap-5 flex justify-evenly">
                <div className="flex gap-2 justify-center items-center text-center">
                  <p>Class:</p>
                  {/* <input
                  type="text"
                  id="class"
                  className="text-blue-500 mt-2 w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
                /> */}
                  <div
                    // type="text"
                    // id="class"
                    className="mt-2 w-64 h-8 border-b p-2 w-[120px] border-b-2 border-gray-900 bg-blue-50"
                  >
                    {" "}
                    {clas2}
                  </div>
                </div>
                <div className="flex gap-2 justify-center items-center text-center">
                  <p>Series:</p>
                  {/* <input
                  type="text"
                  id="series"
                  className="text-blue-500 mt-2 w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
                /> */}
                  <div
                    // type="text"
                    // id="class"
                    className="mt-2 w-64 h-8 border-b p-2 w-[120px] border-b-2 border-gray-900 bg-blue-50"
                  >
                    {" "}
                    {series2}
                  </div>
                </div>

                <div className="flex gap-2 justify-center items-center text-center">
                  <p>Total:</p>
                  {/* <input
                  type="text"
                  id="total"
                  className="text-blue-500 mt-2 w-full border-b p-2 border-b-2 border-gray-900 bg-blue-50"
                /> */}
                  <div
                    // type="text"
                    id="class"
                    className="mt-2 w-64 h-8 border-b p-2 w-[120px] border-b-2 border-gray-900 bg-blue-50"
                  >
                    {" "}
                    {total2}
                  </div>
                </div>
              </div>
            </div>

            <div className="border-none my-4">
              <p className="text-gray-900 font-inter text-lg font-bold leading-7">
                6. ARIZONA KNOWN PLACE OF BUSINESS ADDRESS:
              </p>
              <p className="text-gray-900 ml-4  font-inter text-base font-normal leading-7 mb-2">
                <span className="text-gray-900 font-inter text-lg font-bold leading-7">
                  6.1{" "}
                </span>
                Is the Arizona known place of business address the same as the
                street address of the statutory agent?{" "}
                <span className="text-gray-900 font-inter text-base font-bold leading-6">
                  {"  "}street address
                </span>{" "}
                of the statutory agent?
              </p>

              <div className="flex justify-center w-[80%] items-center flex-col">
                <div className="grid gap-2">
                  <div>
                    <input type="checkbox" id="saidyes" />
                    <label className="text-gray-900 font-inter text-base font-bold leading-6">
                      {" "}
                      Yes -
                    </label>
                    go to number 7 and continue
                  </div>

                  <div>
                    <input type="checkbox" id="saidno" />{" "}
                    <span className="text-gray-900 font-inter text-base font-bold leading-6">
                      No -
                    </span>{" "}
                    go to number 6.2 and continue
                  </div>
                </div>
              </div>
            </div>

            <p className="text-gray-900 ml-4  font-inter text-base font-normal leading-7 mb-2">
              <span className="text-gray-900 font-inter text-lg font-bold leading-7">
                6.2{" "}
              </span>
              If you answered “No” to number 6.1, give the physical or street
              address (not a P.O. Box) of the known place of business of the
              corporation in Arizona:{" "}
            </p>
            <div className="w-full px-8 my-2">
              <table className="w-full table-auto border-collapse">
                <tbody>
                  {/* <tr>
                  <td className="p-4 border border-gray-900 w-1/2" colSpan={2}>
                    
                  </td>
                </tr> */}

                  {/* Address Section: Attention (Optional) */}
                  <tr>
                    <td
                      className="p-4 border border-gray-900 w-1/2 "
                      colSpan={2}
                    >
                      {/* <input
                      type="text"
                      id="attention1"
                      className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                      <div
                        type="text"
                        id="attention1"
                        className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                        placeholder="$"
                      >
                        {known_attention}
                      </div>

                      <p className="text-sm">Attention (optional)</p>
                    </td>
                  </tr>

                  {/* Address Row 2 */}
                  <tr>
                    <td
                      className="p-4 border border-gray-900 w-1/2 "
                      colSpan={2}
                    >
                      {/* <input
                      type="text"
                      id="attention1"
                      className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                      <div
                        type="text"
                        id="attention1"
                        className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                      >
                        {" "}
                        {known_address}{" "}
                      </div>

                      <label className="text-sm">Address 1</label>
                    </td>
                  </tr>

                  <tr>
                    <td
                      className="p-4 border border-gray-900 w-1/2 "
                      colSpan={2}
                    >
                      {/* <input
                      type="text"
                      id="attention2optional"
                      className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                      <div
                        id="attention2optional"
                        className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                      >
                        {known_attention_optional}
                      </div>

                      <label className="text-sm">Address 2 (optional)</label>
                    </td>
                  </tr>

                  <tr className="w-full">
                    <td className=" border border-gray-900 w-1/2 ">
                      <td className="w-64 p-4   ">
                        {/* <input
                        type="text"
                        id="city2-2"
                        className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                        // placeholder="Address 2 (optional)"
                      /> */}

                        <div
                          type="text"
                          id="city2-2"
                          className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                          // placeholder="Address 2 (optional)"
                        >
                          {know_city}
                        </div>

                        <label htmlFor="city2-2" className="text-sm">
                          City{" "}
                        </label>

                        <td className="p-2 flex items-center">
                          <label htmlFor="city2" className="text-sm mr-2" />{" "}
                          <div className="flex gap-2 w-full items-center text-center">
                            <label htmlFor="country" className="flex">
                              Country
                            </label>
                            <select
                              id="country"
                              className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                              required
                            >
                              <option value="" disabled selected></option>
                              <option value="usa">United States</option>

                              {/* Add more countries as needed */}
                            </select>
                          </div>
                        </td>
                      </td>
                      <td className="w-64 p-4  border-l border-r border-black">
                        {/* <input
                        type="text"
                        id="city2"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        // placeholder="state"
                        required
                      /> */}

                        <div
                          id="city2"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        >
                          {known_state}
                        </div>
                        <label htmlFor="city2" className="text-sm text-left">
                          State or Province
                        </label>
                      </td>

                      <td className="p-4 w-64 items-center ">
                        <div className="flex flex-col  justify-center">
                          {/* <input
                          type="text"
                          id="zip1"
                          className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                          required
                          // pattern="[0-9]{5}"
                        /> */}

                          <div
                            type="text"
                            id="zip1"
                            className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                            // pattern="[0-9]{5}"
                          >
                            {zip}
                          </div>
                          <br />
                          <label htmlFor="zip1" className="text-sm">
                            ZIP
                          </label>
                        </div>
                      </td>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="flex px-8 my-4  w-full justify-between">
              <div>
                <p className="text-gray-900 font-inter text-sm font-normal leading-6">
                  L010.005{" "}
                </p>
                <p className="text-gray-900 font-inter text-sm font-normal leading-6">
                  Rev: 6/2020{" "}
                </p>
              </div>

              <div className="text-right pb-4">
                <p className="text-gray-900 font-inter text-sm font-normal leading-6">
                  Arizona Corporation Commission – Corporations Division{" "}
                </p>
                <p className="text-gray-900 font-inter text-sm font-normal leading-6">
                  Page 1 of 3{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* page two */}
      <div className="section">
        <form className="p-2">
          <table className="w-full table-auto border-collapse">
            <tbody>
              {/* Statutory Agent Name and Mailing Address Section */}
              <tr>
                <td className="p-4 border border-gray-900 w-1/2" colSpan={2}>
                  <p className="text-lg text-gray-900 mb-2">
                    <span className="font-bold text-lg text-gray-900">
                      7. DIRECTORS -{" "}
                    </span>
                    list the
                    <span className="font-bold text-lg text-gray-900">
                      {" "}
                      name and business address{" "}
                    </span>
                    of each and every Director of the corporation. If more space
                    is needed, check this box
                    <input
                      type="checkbox"
                      id="director"
                      className="h-4 w-4 items-center m-2"
                    />{" "}
                    and complete and attach the
                    <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
                      {" "}
                      <a
                        target="_blank"
                        href="https://www.azcc.gov/Divisions/Corporations/forms/starpas/formsSTPS/C082-Director-Attachment.pdf"
                      >
                        Director Attachment{" "}
                      </a>
                    </span>
                    form C082.
                  </p>
                </td>
              </tr>

              {/* Address Section: Attention (Optional) */}
              <tr>
                <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                      type="text"
                      id="dirname"
                      className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                  <div
                    type="text"
                    id="dirname"
                    className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                    placeholder="$"
                  >
                    {business_name}
                  </div>

                  <label htmlFor="dirname" className="text-sm">
                    {" "}
                    name
                  </label>
                </td>
                <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                      type="text"
                      id="dirname2"
                      className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                  <div
                    id="dirname2"
                    className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                  >
                    {business_name_2}
                  </div>

                  <label htmlFor="dirname2" className="text-sm">
                    {" "}
                    name
                  </label>
                </td>
              </tr>

              {/* Address Row 1 */}
              <tr>
                <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                      type="text"
                      id="attention71"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                  <div
                    type="text"
                    id="address1"
                    className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                    placeholder="$"
                  >
                    {address1}
                  </div>

                  <label htmlFor="address72" className="text-sm">
                    Address 1
                  </label>
                </td>
                <td className="p-2 border border-gray-900 w-1/2">
                  {/* <input
                      type="text"
                      id="attention72"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      // placeholder="Attention (Optional)"
                    /> */}

                  <div
                    type="text"
                    id="address2"
                    className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                    // placeholder="Attention (Optional)"
                  >
                    {address1_2}
                  </div>

                  <label htmlFor="address72" className="text-sm">
                    Address 1{" "}
                  </label>
                </td>
              </tr>

              {/* Address Row 2 */}
              <tr>
                <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                      type="text"
                      id="address1"
                      className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                  <div
                    type="text"
                    id="address1"
                    className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                    placeholder="$"
                  >
                    {address2}
                  </div>

                  <label htmlFor="address73" className="text-sm ">
                    {" "}
                    Address 2 (Optional)
                  </label>
                </td>
                <td className="p-2 border border-gray-900 w-1/2">
                  {/* <input
                      type="text"
                      id="address2"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      // placeholder="Attention (Optional)"
                    /> */}

                  <div
                    id="address2"
                    className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                    // placeholder="Attention (Optional)"
                  >
                    {" "}
                    {address2_1}
                  </div>
                  <label htmlFor="address74" className="text-sm ">
                    Address 2 (Optional){" "}
                  </label>
                </td>
              </tr>

              {/* country in table */}
              <tr>
                <td className="p-2 border border-gray-900 w-1/2">
                  <td className=" w-1/2 ">
                    <td className="w-1/2 p-4   ">
                      {/* <input
                          type="text"
                          id="city-71"
                          className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                          // placeholder="Address 2 (optional)"
                        /> */}

                      <div
                        id="city-71"
                        className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                      >
                        {business_name}
                      </div>
                      <label htmlFor="city-71" className="text-sm">
                        City{" "}
                      </label>

                      <td className="p-2 flex items-center">
                        <label htmlFor="city2" className="text-sm mr-2" />{" "}
                        <div className="flex gap-2 w-full items-center text-center">
                          <label htmlFor="country72" className="flex">
                            Country
                          </label>
                          <select
                            id="country72"
                            className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                          >
                            <option value="" disabled selected></option>
                            <option value="usa">United States</option>

                            {/* Add more countries as needed */}
                          </select>
                        </div>
                      </td>
                    </td>
                    <td className="w-1/4 p-4  border-l border-r border-black">
                      {/* <input
                          type="text"
                          id="city72"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                          // placeholder="state"
                          required
                        /> */}

                      <div
                        id="city72"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      >
                        {city}
                      </div>

                      <label htmlFor="city72" className="text-sm text-left">
                        State or Province
                      </label>
                    </td>

                    <td className="p-4 items-center ">
                      <div className="flex flex-col  justify-center">
                        {/* <input
                            type="text"
                            id="zip72"
                            className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                            // pattern="[0-9]{5}"
                          /> */}

                        <div
                          // type="text"
                          id="zip72"
                          className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                          // required
                          // pattern="[0-9]{5}"
                        >
                          {business_zip}
                        </div>

                        <br />

                        <label htmlFor="zip72" className="text-sm">
                          ZIP
                        </label>
                      </div>
                    </td>
                  </td>

                  {/*  */}
                </td>

                <td className="p-2 border border-gray-900 w-1/2">
                  <td className=" w-1/2 ">
                    <td className="w-1/2 p-4   ">
                      {/* <input
                          type="text"
                          id="city-71"
                          className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                          // placeholder="Address 2 (optional)"
                        /> */}

                      <div
                        // type="text"
                        id="city-71"
                        className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                        // placeholder="Address 2 (optional)"
                      >
                        {business_city2}
                      </div>

                      <label htmlFor="city-73" className="text-sm">
                        City{" "}
                      </label>

                      <td className="p-2 flex items-center">
                        <label htmlFor="city73" className="text-sm mr-2" />{" "}
                        <div className="flex gap-2 w-full items-center text-center">
                          <label htmlFor="country73" className="flex">
                            Country
                          </label>
                          <select
                            id="country73"
                            className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                          >
                            <option value="" disabled selected></option>
                            <option value="usa">United States</option>

                            {/* Add more countries as needed */}
                          </select>
                        </div>
                      </td>
                    </td>
                    <td className="w-1/4 p-4  border-l border-r border-black">
                      {/* <input
                          type="text"
                          id="city73"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                          // placeholder="state"
                          required
                        /> */}

                      <div
                        id="city73"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      >
                        {business_state_2}
                      </div>

                      <label htmlFor="city73" className="text-sm text-left">
                        State or Province
                      </label>
                    </td>

                    <td className="p-4 items-center ">
                      <div className="flex flex-col  justify-center">
                        {/* <input
                            type="text"
                            id="zip74"
                            className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                            // pattern="[0-9]{5}"
                          /> */}

                        <div
                          id="zip74"
                          className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                        >
                          {" "}
                          {business_zip_2}
                        </div>

                        <br />
                        <label htmlFor="zip74" className="text-sm">
                          ZIP
                        </label>
                      </div>
                    </td>
                  </td>

                  {/*  */}
                </td>
              </tr>

              {/* Address Section: Attention (Optional) */}
              <tr>
                <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                      type="text"
                      id="dirname-r2"
                      className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                  <div
                    type="text"
                    id="dirname-r2"
                    className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                    placeholder="$"
                  >
                    {business_name_3}
                  </div>

                  <label htmlFor="dirname-r3" className="text-sm">
                    {" "}
                    name
                  </label>
                </td>
                <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                      type="text"
                      id="dirname-r3"
                      className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                  <div
                    id="dirname-r3"
                    className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                  >
                    {business_name_4}
                  </div>

                  <label htmlFor="dirname-r3" className="text-sm">
                    {" "}
                    name
                  </label>
                </td>
              </tr>

              {/* Address Row 1 */}
              <tr>
                <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                      type="text"
                      id="address-r72"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                  <div
                    id="address-r72"
                    className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                  >
                    {business_address_1}
                  </div>

                  <label htmlFor="address-r72" className="text-sm">
                    Address 1
                  </label>
                </td>
                <td className="p-2 border border-gray-900 w-1/2">
                  {/* <input
                      type="text"
                      id="address-r73"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      // placeholder="Attention (Optional)"
                    /> */}

                  <div
                    id="address-r73"
                    className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                  >
                    {business_address_2}
                  </div>

                  <label htmlFor="address-r73" className="text-sm">
                    Address 1{" "}
                  </label>
                </td>
              </tr>

              {/* Address Row 2 */}
              <tr>
                <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                      type="text"
                      id="address-r74"
                      className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                  <div
                    id="address-r74"
                    className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                  >
                    {business_address_3}
                  </div>

                  <label htmlFor="address-r74" className="text-sm ">
                    {" "}
                    Address 2 (Optional)
                  </label>
                </td>
                <td className="p-2 border border-gray-900 w-1/2">
                  {/* <input
                      type="text"
                      id="address-r75"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      // placeholder="Attention (Optional)"
                    /> */}

                  <div
                    id="address-r75"
                    className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                  >
                    {business_address_4}{" "}
                  </div>

                  <label htmlFor="address-r75" className="text-sm">
                    Address 2 (Optional){" "}
                  </label>
                </td>
              </tr>

              {/* country in table */}
              <tr>
                <td className="p-2 border border-gray-900 w-1/2">
                  <td className=" w-1/2 ">
                    <td className="w-1/2 p-4   ">
                      {/* <input
                          type="text"
                          id="city-r71"
                          className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                          // placeholder="Address 2 (optional)"
                        /> */}

                      <div
                        type="text"
                        id="city-r71"
                        className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                        // placeholder="Address 2 (optional)"
                      >
                        {business_city3}
                      </div>
                      <label htmlFor="city-r71" className="text-sm">
                        City{" "}
                      </label>

                      <td className="p-2 flex items-center">
                        <label htmlFor="city2" className="text-sm mr-2" />{" "}
                        <div className="flex gap-2 w-full items-center text-center">
                          <label htmlFor="country-r72" className="flex">
                            Country
                          </label>
                          <select
                            id="country-r72"
                            className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                          >
                            <option value="" disabled selected></option>
                            <option value="usa">United States</option>

                            {/* Add more countries as needed */}
                          </select>
                        </div>
                      </td>
                    </td>
                    <td className="w-1/4 p-4  border-l border-r border-black">
                      {/* <input
                          type="text"
                          id="city-r72"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                          // placeholder="state"
                          required
                        /> */}

                      <div
                        type="text"
                        id="city-r72"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      >
                        {business_state_3}
                      </div>

                      <label htmlFor="city-r72" className="text-sm text-left">
                        State or Province
                      </label>
                    </td>

                    <td className="p-4 items-center ">
                      <div className="flex flex-col  justify-center">
                        {/* <input
                            type="text"
                            id="zip-r72"
                            className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                            // pattern="[0-9]{5}"
                          /> */}

                        <div
                          id="zip-r72"
                          className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                        >
                          {business_state_4}
                        </div>
                        <br />
                        <label htmlFor="zip-r72" className="text-sm">
                          ZIP
                        </label>
                      </div>
                    </td>
                  </td>

                  {/*  */}
                </td>

                <td className="p-2 border border-gray-900 w-1/2">
                  <td className=" w-1/2 ">
                    <td className="w-1/2 p-4   ">
                      {/* <input
                          type="text"
                          id="city-73"
                          className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                          // placeholder="Address 2 (optional)"
                        /> */}

                      <div
                        id="city-73"
                        className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                      >
                        {business_city4}
                      </div>

                      <label htmlFor="city-r73" className="text-sm">
                        City{" "}
                      </label>

                      <td className="p-2 flex items-center">
                        <label htmlFor="city73" className="text-sm mr-2" />{" "}
                        <div className="flex gap-2 w-full items-center text-center">
                          <label htmlFor="country-r73" className="flex">
                            Country
                          </label>
                          <select
                            id="country-r73"
                            className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                          >
                            <option value="" disabled selected></option>
                            <option value="usa">United States</option>

                            {/* Add more countries as needed */}
                          </select>
                        </div>
                      </td>
                    </td>
                    <td className="w-1/4 p-4  border-l border-r border-black">
                      {/* <input
                          type="text"
                          id="state-r73"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                          // placeholder="state"
                          required
                        /> */}

                      <div
                        id="state-r73"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      >
                        {business_state_5}
                      </div>

                      <label htmlFor="state-r73" className="text-sm text-left">
                        State or Province
                      </label>
                    </td>

                    <td className="p-4 items-center ">
                      <div className="flex flex-col  justify-center">
                        {/* <input
                            type="text"
                            id="zip-r74"
                            className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                            // pattern="[0-9]{5}"
                          /> */}

                        <div
                          id="zip-r74"
                          className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                        >
                          {business_zip_3}
                        </div>

                        <br />
                        <label htmlFor="zip-r74" className="text-sm">
                          ZIP
                        </label>
                      </div>
                    </td>
                  </td>

                  {/*  */}
                </td>
              </tr>

              {/*  */}

              {/* Address Section: Attention (Optional) */}
              <tr>
                <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                      type="text"
                      id="dirname-rr2"
                      className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                  <div
                    id="dirname-rr2"
                    className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                  >
                    {business_name_5}
                  </div>

                  <label htmlFor="dirname-rr3" className="text-sm">
                    {" "}
                    name
                  </label>
                </td>
                <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                      type="text"
                      id="dirname-rr3"
                      className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}
                  <div
                    id="dirname-rr3"
                    className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                  >
                    {business_name_6}
                  </div>
                  <label htmlFor="dirname-rr3" className="text-sm">
                    {" "}
                    name
                  </label>
                </td>
              </tr>

              {/* Address Row 1 */}
              <tr>
                <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                  {
                    /* <input
                      type="text"
                      id="address-rr72"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */

                    <div
                      id="address-rr72"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                    >
                      {business_address_5}
                    </div>
                  }
                  <label htmlFor="address-rr72" className="text-sm">
                    Address 1
                  </label>
                </td>
                <td className="p-2 border border-gray-900 w-1/2">
                  {/* <input
                      type="text"
                      id="address-rr73"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      // placeholder="Attention (Optional)"
                    /> */}

                  <div
                    id="address-rr73"
                    className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                  >
                    {business_address_6}
                  </div>

                  <label htmlFor="address-rr73" className="text-sm">
                    Address 1{" "}
                  </label>
                </td>
              </tr>

              {/* Address Row 2 */}
              <tr>
                <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                      type="text"
                      id="address-rr74"
                      className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    /> */}

                  <div
                    id="address-rr74"
                    className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                    placeholder="$"
                  >
                    {" "}
                    {business_address_7}{" "}
                  </div>

                  <label htmlFor="address-rr74" className="text-sm ">
                    {" "}
                    Address 2 (Optional)
                  </label>
                </td>
                <td className="p-2 border border-gray-900 w-1/2">
                  {/* <input
                      type="text"
                      id="address-rr75"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      // placeholder="Attention (Optional)"
                    /> */}

                  <div
                    id="address-rr75"
                    className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                  >
                    {business_address_8}
                  </div>

                  <label htmlFor="address-rr75" className="text-sm">
                    Address 2 (Optional){" "}
                  </label>
                </td>
              </tr>

              {/* country in table */}
              <tr>
                <td className="p-2 border border-gray-900 w-1/2">
                  <td className=" w-1/2 ">
                    <td className="w-1/2 p-4   ">
                      {/* <input
                          type="text"
                          id="city-rr71"
                          className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                          // placeholder="Address 2 (optional)"
                        /> */}

                      <div
                        id="city-rr71"
                        className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                      >
                        {business_city5}
                      </div>

                      <label htmlFor="city-rr71" className="text-sm">
                        City{" "}
                      </label>

                      <td className="p-2 flex items-center">
                        <label htmlFor="city2" className="text-sm mr-2" />{" "}
                        <div className="flex gap-2 w-full items-center text-center">
                          <label htmlFor="country-rr72" className="flex">
                            Country
                          </label>
                          <select
                            id="country-rr72"
                            className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                          >
                            <option value="" disabled selected></option>
                            <option value="usa">United States</option>

                            {/* Add more countries as needed */}
                          </select>
                        </div>
                      </td>
                    </td>
                    <td className="w-1/4 p-4  border-l border-r border-black">
                      {/* <input
                          type="text"
                          id="city-rr72"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                          // placeholder="state"
                          required
                        /> */}

                      <div
                        id="state-rr2"
                        className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                      >
                        {business_state_6}
                      </div>

                      <label htmlFor="city-rr72" className="text-sm text-left">
                        State or Province
                      </label>
                    </td>

                    <td className="p-4 items-center ">
                      <div className="flex flex-col  justify-center">
                        {/* <input
                            type="text"
                            id="zip-rr72"
                            className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                            // pattern="[0-9]{5}"
                          /> */}

                        <div
                          id="zip-rr72"
                          className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                        >
                          {business_zip_4}
                        </div>

                        <br />
                        <label htmlFor="zip-rr72" className="text-sm">
                          ZIP
                        </label>
                      </div>
                    </td>
                  </td>

                  {/*  */}
                </td>

                <td className="p-2 border border-gray-900 w-1/2">
                  <td className=" w-1/2 ">
                    <td className="w-1/2 p-4   ">
                      {/* <input
                          type="text"
                          id="city-rr73"
                          className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                          // placeholder="Address 2 (optional)"
                        /> */}

                      <div
                        id="city-rr71"
                        className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                      >
                        {business_city7}
                      </div>

                      <label htmlFor="city-rr73" className="text-sm">
                        City{" "}
                      </label>

                      <td className="p-2 flex items-center">
                        <label htmlFor="city73" className="text-sm mr-2" />{" "}
                        <div className="flex gap-2 w-full items-center text-center">
                          <label htmlFor="country-rr73" className="flex">
                            Country
                          </label>
                          <select
                            id="country-rr73"
                            className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                          >
                            <option value="" disabled selected></option>
                            <option value="usa">United States</option>

                            {/* Add more countries as needed */}
                          </select>
                        </div>
                      </td>
                    </td>
                    <td className="w-1/4 p-4  border-l border-r border-black">
                      {/* <input
                          type="text"
                          id="state-rr73"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                          // placeholder="state"
                          required
                        /> */}

                      <div
                        id="state-rr73"
                        className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                      >
                        {business_state_7}
                      </div>
                      <label htmlFor="state-rr73" className="text-sm text-left">
                        State or Province
                      </label>
                    </td>

                    <td className="p-4 items-center ">
                      <div className="flex flex-col  justify-center">
                        {/* <input
                            type="text"
                            id="zip-rr74"
                            className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                            // pattern="[0-9]{5}"
                          /> */}

                        <div
                          id="zip-rr74"
                          className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                        >
                          {business_state_8}
                        </div>
                        <br />
                        <label htmlFor="zip-rr74" className="text-sm">
                          ZIP
                        </label>
                      </div>
                    </td>
                  </td>

                  {/*  */}
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>

          {/* 8 */}
          {/*  */}
          {/* <div className="bg-white w-full mt-5"> */}
        <div className="section">
          <div className="border border-gray-900 p-4 mt-4 flex items-center space-x-2">
            <ol>
              <li>
                <span className="font-bold text-lg text-gray-900">
                  8. STATUTORY AGENT -{" "}
                </span>
                <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
                  <a
                    target="_blank"
                    href="https://azcc.gov/docs/default-source/corps-files/instructions/l010i-instructions-articles-of-organization.pdf?sfvrsn=fb0a9180_4"
                  >
                    see Instructions C010i: {"  "}{" "}
                  </a>
                </span>
              </li>
            </ol>
          </div>

          <form className="p-0 ">
            <table className="w-full table-auto border-collapse">
              <tbody>
                {/* Statutory Agent Name and Mailing Address Section */}
                <tr>
                  <td className="p-4 border border-gray-900 w-1/2">
                    <p className="text-sm text-gray-900 mb-2">
                      <span className="font-bold text-lg text-gray-900">
                        8.1{" "}
                      </span>
                      REQUIRED – give the name (can be an individual or an
                      entity) and physical or street address (not a P.O. Box) in
                      Arizona of the statutory agent{" "}
                    </p>
                  </td>
                  <td className="p-4 border border-gray-900 w-1/2">
                    <p className="text-sm text-gray-900 mb-2">
                      <span className="font-bold text-lg text-gray-900">
                        8.2{" "}
                      </span>
                      OPTIONAL – mailing address in Arizona of statutory agent
                      (can be a P.O. Box)
                    </p>
                  </td>
                </tr>

                {/* Address Section: Attention (Optional) */}
                <tr>
                  <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                    {/* <input
                        type="text"
                        id="agent-name"
                        className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                        placeholder="$"
                      /> */}

                    <div
                      id="agent-name"
                      className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                    >
                      {agentName}
                    </div>

                    <label htmlFor="agent-name" className="text-sm">
                      {" "}
                      Statutory Agent Name (required){" "}
                    </label>
                  </td>
                  <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                    {/* <input
                        type="text"
                        id="name-8"
                        className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                        placeholder="$"
                      /> */}

                    <div
                      id="agent-name"
                      className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                    >
                      {agentNamePO}
                    </div>

                    <label htmlFor="name-8" className="text-sm">
                      {" "}
                      name
                    </label>
                  </td>
                </tr>

                {/* Address Row 1 */}
                <tr>
                  <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                    {/* <input
                        type="text"
                        id="address-86"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        placeholder="$"
                      /> */}

                    <div
                      id="address-86"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                    >
                      {business_address_9}
                    </div>

                    <label htmlFor="address-86" className="text-sm">
                      Address 1
                    </label>
                  </td>
                  <td className="p-2 border border-gray-900 w-1/2">
                    {/* <input
                        type="text"
                        id="address-85"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        // placeholder="Attention (Optional)"
                      /> */}

                    <div
                      type="text"
                      id="address-85"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      // placeholder="Attention (Optional)"
                    >
                      {business_address_10}
                    </div>
                    <label htmlFor="address-85" className="text-sm">
                      Address 1{" "}
                    </label>
                  </td>
                </tr>

                {/* Address Row 2 */}
                <tr>
                  <td className="p-4 border border-gray-900 w-1/2 " colSpan={1}>
                    {/* <input
                        type="text"
                        id="address-83"
                        className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                        placeholder="$"
                      /> */}

                    <div
                      className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                      placeholder="$"
                    >
                      {business_address_11}
                    </div>

                    <label htmlFor="address-83" className="text-sm ">
                      {" "}
                      Address 2 (Optional)
                    </label>
                  </td>
                  <td className="p-2 border border-gray-900 w-1/2">
                    {/* <input
                        type="text"
                        id="address-82"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        // placeholder="Attention (Optional)"
                      /> */}

                    <div
                      id="address-82"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                    >
                      {business_address_12}
                    </div>

                    <label htmlFor="address-82" className="text-sm">
                      Address 2 (Optional){" "}
                    </label>
                  </td>
                </tr>

                {/* country in table */}
                <tr>
                  <td className="p-2 border border-gray-900 w-1/2">
                    <td className=" w-1/2 ">
                      <td className="w-1/2 p-4   ">
                        {/* <input
                            type="text"
                            id="city-82"
                            className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                            // placeholder="Address 2 (optional)"
                          /> */}

                        <div
                          id="city-82"
                          className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                        >
                          {business_city8}
                        </div>

                        <label htmlFor="city-82" className="text-sm">
                          City{" "}
                        </label>

                        <td className="p-2 flex items-center">
                          <label htmlFor="city2" className="text-sm mr-2" />{" "}
                          <div className="flex gap-2 w-full items-center text-center">
                            <label htmlFor="country-82" className="flex">
                              Country
                            </label>
                            <select
                              id="country-82"
                              className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                              required
                            >
                              <option value="" disabled selected></option>
                              <option value="usa">United States</option>

                              {/* Add more countries as needed */}
                            </select>
                          </div>
                        </td>
                      </td>
                      <td className="w-1/4 p-4  border-l border-r border-black">
                        {/* <input
                            type="text"
                            id="city-81"
                            className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                            // placeholder="state"
                            required
                          /> */}

                        <div
                          type="text"
                          id="city-81"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        >
                          {business_state_9}{" "}
                        </div>

                        <label htmlFor="city-81" className="text-sm text-left">
                          State or Province
                        </label>
                      </td>

                      <td className="p-4 items-center ">
                        <div className="flex flex-col  justify-center">
                          {/* <input
                              type="text"
                              id="zip-81"
                              className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                              required
                              // pattern="[0-9]{5}"
                            /> */}

                          <div
                            type="text"
                            id="city-81"
                            className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                          >
                            {business_zip_5}{" "}
                          </div>
                          <br />
                          <label htmlFor="zip-81" className="text-sm">
                            ZIP
                          </label>
                        </div>
                      </td>
                    </td>

                    {/*  */}
                  </td>

                  <td className="p-2 border border-gray-900 w-1/2">
                    <td className=" w-1/2 ">
                      <td className="w-1/2 p-4   ">
                        {/* <input
                            type="text"
                            id="city-8"
                            className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                            // placeholder="Address 2 (optional)"
                          /> */}
                        <div
                          type="text"
                          id="city-8"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        >
                          {business_city9}{" "}
                        </div>

                        <label htmlFor="city-8" className="text-sm">
                          City{" "}
                        </label>

                        <td className="p-2 flex items-center">
                          <label htmlFor="city73" className="text-sm mr-2" />{" "}
                          <div className="flex gap-2 w-full items-center text-center">
                            <label htmlFor="country-8" className="flex">
                              Country
                            </label>
                            <select
                              id="country-8"
                              className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                              required
                            >
                              <option value="" disabled selected></option>
                              <option value="usa">United States</option>

                              {/* Add more countries as needed */}
                            </select>
                          </div>
                        </td>
                      </td>
                      <td className="w-1/4 p-4  border-l border-r border-black">
                        {/* <input
                            type="text"
                            id="state-8"
                            className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                            // placeholder="state"
                            required
                          /> */}

                        <div
                          type="text"
                          id="state-8"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        >
                          {business_state_10}{" "}
                        </div>

                        <label htmlFor="state-8" className="text-sm text-left">
                          State or Province
                        </label>
                      </td>

                      <td className="p-4 items-center ">
                        <div className="flex flex-col  justify-center">
                          {/* <input
                              type="text"
                              id="zip-8"
                              className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                              required
                              // pattern="[0-9]{5}"
                            /> */}

                          <div
                            type="text"
                            id="zip-8"
                            className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                          >
                            {business_zip_6}{" "}
                          </div>
                          <br />
                          <label htmlFor="zip-8" className="text-sm">
                            ZIP
                          </label>
                        </div>
                      </td>
                    </td>

                    {/*  */}
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          {/*  */}

          <div className="flex px-8 my-4 border-b border-black w-full justify-between">
            <div>
              <p className="text-gray-900 font-inter text-sm font-normal leading-6">
                L010.005{" "}
              </p>
              <p className="text-gray-900 font-inter text-sm font-normal leading-6">
                Rev: 6/2020{" "}
              </p>
            </div>

            <div className="text-right pb-4">
              <p className="text-gray-900 font-inter text-sm font-normal leading-6">
                Arizona Corporation Commission – Corporations Division{" "}
              </p>
              <p className="text-gray-900 font-inter text-sm font-normal leading-6">
                Page 2 of 3{" "}
              </p>
            </div>
          </div>
        </div>

      {/* page3 */}
      {/* 9 */}
      <div className="section">
        <div className="p-4 mt-4 flex items-center space-x-2">
          <ol>
            <li>
              <span className="font-bold text-lg text-gray-900">
                9. Required -{" "}
              </span>
              you must complete and submit with the Articles a
              <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
                {" "}
                <a
                  target="_blank"
                  href="https://www.azcc.gov/Divisions/Corporations/forms/starpas/formsSTPS/C003-Certificate-of-disclosure.pdf"
                >
                  Certificate of Disclosure {"  "}{" "}
                </a>
              </span>
              . The Articles will be rejected if the Certificate of Disclosure
              is not simultaneously submitted.
            </li>
          </ol>
        </div>
        {/* 10 */}
        <div className="p-4 mt-4 flex items-center space-x-2">
          <ol>
            <li>
              <span className="font-bold text-lg text-gray-900">
                10. INCORPORATORS -{" "}
              </span>
              {"  "} list the
              <span className="text-gray-900 font-inter text-lg font-bold leading-8">
                {"  "}
                name and address,
              </span>
              {"  "}
              and provide the
              <span className="text-gray-900 font-inter text-lg font-bold leading-8">
                {"  "}
                signature,
              </span>
              of each and every incorporator -{/* <br /> */}
              minimum of one is required. If more space is needed, check this
              box
              <input type="checkbox" className="h-4 w-4 m-2" /> and complete and
              attach the
              <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
                {" "}
                <a
                  target="_blank"
                  href="https://www.azcc.gov/Divisions/Corporations/forms/starpas/formsSTPS/C084-Incorporator-Attachment.pdf"
                >
                  Incorporator Attachment {"  "}{" "}
                </a>
              </span>
              form C084.{" "}
            </li>
          </ol>
        </div>
        {/*  */}
        <form className="p-2">
          <table className="w-full  table-auto border-b border-black  border-collapse">
            <tbody>
              {/* Address Section: Attention (Optional) */}
              <tr>
                <td className="p-4 border-r border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                        type="text"
                        id="incorp-name"
                        className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                        placeholder="$"
                      /> */}

                  <div
                    id="incorp-name"
                    className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                    placeholder="$"
                  >
                    {incorp_name}{" "}
                  </div>

                  <label htmlFor="incorp-name" className="text-sm">
                    {" "}
                    name{" "}
                  </label>
                </td>
                <td className="p-4 border-l border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                        type="text"
                        id="incorp-name-2"
                        className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                        placeholder="$"
                      /> */}

                  <div
                    id="incorp-name-2"
                    className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                  >
                    {incorp_name_2}{" "}
                  </div>
                  <label htmlFor="incorp-name-2" className="text-sm">
                    {" "}
                    name
                  </label>
                </td>
              </tr>

              {/* Address Row 1 */}
              <tr>
                <td className="p-4 border-r border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                        type="text"
                        id="incorp-address-86"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        placeholder="$"
                      /> */}

                  <div
                    id="incorp-address-86"
                    className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                  >
                    {incorp_address}{" "}
                  </div>
                  <label htmlFor="incorp-address-86" className="text-sm">
                    Address 1
                  </label>
                </td>
                <td className="p-2 border-l border-gray-900 w-1/2">
                  {/* <input
                        type="text"
                        id="incorp-address-85"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        // placeholder="Attention (Optional)"
                      /> */}

                  <div
                    id="incorp-address-86"
                    className="w-full  mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                  >
                    {incorp_address_1_2}{" "}
                  </div>

                  <label htmlFor="incorp-address-85" className="text-sm">
                    Address 1{" "}
                  </label>
                </td>
              </tr>

              {/* Address Row 2 */}
              <tr>
                <td className="p-4 border-r border-gray-900 w-1/2 " colSpan={1}>
                  {/* <input
                        type="text"
                        id="incorp-address-83"
                        className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                        placeholder="$"
                      /> */}

                  <div
                    id="incorp-address-83"
                    className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                  >
                    {incorp_address_2}
                  </div>

                  <label htmlFor="incorp-address-83" className="text-sm ">
                    {" "}
                    Address 2 (Optional)
                  </label>
                </td>
                <td className="p-2 border-l border-gray-900 w-1/2">
                  {/* <input
                        type="text"
                        id="incorp-address-82"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        // placeholder="Attention (Optional)"
                      /> */}

                  <div
                    id="incorp-address-82"
                    className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                  >
                    {incorp_address_2_1}
                  </div>

                  <label htmlFor="incorp-address-82" className="text-sm">
                    Address 2 (Optional){" "}
                  </label>
                </td>
              </tr>

              {/* country in table */}
              <tr>
                <td className="p-2 border-t border-b border-gray-900 w-1/2">
                  <td className=" w-1/2 ">
                    <td className="w-1/2 p-4   ">
                      {/* <input
                            type="text"
                            id="incorp-city-82"
                            className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                            // placeholder="Address 2 (optional)"
                          /> */}

                      <div
                        id="incorp-address-82"
                        className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                      >
                        {incorp_address_3}
                      </div>

                      <label htmlFor="incorp-city-82" className="text-sm">
                        City{" "}
                      </label>

                      <td className="p-2 flex items-center">
                        <label
                          htmlFor="incorp-city2"
                          className="text-sm mr-2"
                        />{" "}
                        <div className="flex gap-2 w-full items-center text-center">
                          <label htmlFor="incorp-country-82" className="flex">
                            Country
                          </label>
                          <select
                            id="incorp-country-82"
                            className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                          >
                            <option value="" disabled selected></option>
                            <option value="usa">United States</option>

                            {/* Add more countries as needed */}
                          </select>
                        </div>
                      </td>
                    </td>
                    <td className="w-1/4 p-4  border-l border-r border-black">
                      {/* <input
                            type="text"
                            id="incorp-city-81"
                            className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                            // placeholder="state"
                            required
                          /> */}

                      <div
                        id="incorp-address-82"
                        className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                      >
                        {incorp_state}
                      </div>

                      <label
                        htmlFor="incorp-city-81"
                        className="text-sm text-left"
                      >
                        State or Province
                      </label>
                    </td>

                    <td className="p-4 items-center ">
                      <div className="flex flex-col  justify-center">
                        {/* <input
                              type="text"
                              id="incorp-zip-81"
                              className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                              required
                              // pattern="[0-9]{5}"
                            /> */}

                        <div
                          id="incorp-address-81"
                          className="w-full p-2 mb-2 border-b-2 border-gray-900 bg-blue-50"
                        >
                          {incorp_zip}
                        </div>

                        <br />
                        <label htmlFor="incorp-zip-81" className="text-sm">
                          ZIP
                        </label>
                      </div>
                    </td>
                  </td>

                  {/*  */}
                </td>

                <td className="p-2 border-t border-b border-l border-gray-900 w-1/2">
                  <td className=" w-1/2 ">
                    <td className="w-1/2 p-4   ">
                      {/* <input
                            type="text"
                            id="incorp-city-8"
                            className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                            // placeholder="Address 2 (optional)"
                          /> */}

                      <div
                        id="incorp-city-8"
                        className=" p-2 w-full  mb-2 border-b-2 border-gray-900 bg-blue-50"
                        // placeholder="Address 2 (optional)"
                      >
                        {incorp_city}
                      </div>

                      <label htmlFor="city-8" className="text-sm">
                        City{" "}
                      </label>

                      <td className="p-2 flex items-center">
                        <label htmlFor="city73" className="text-sm mr-2" />{" "}
                        <div className="flex gap-2 w-full items-center text-center">
                          <label htmlFor="incorp-country-8" className="flex">
                            Country
                          </label>
                          <select
                            id="country-8"
                            className="w-full mb-2 p-2 border-b-2 border-gray-900 bg-blue-50"
                            required
                          >
                            <option value="" disabled selected></option>
                            <option value="usa">United States</option>

                            {/* Add more countries as needed */}
                          </select>
                        </div>
                      </td>
                    </td>
                    <td className="w-1/4 p-4  border-l border-r border-black">
                      {/* <input
                            type="text"
                            id="incorp-state-8"
                            className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                            // placeholder="state"
                            required
                          /> */}

                      <div
                        id="incorp-state-8"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      >
                        {incorp_state_2}
                      </div>

                      <label
                        htmlFor="incorp-state-8"
                        className="text-sm text-left"
                      >
                        State or Province
                      </label>
                    </td>

                    <td className="p-4 items-center ">
                      <div className="flex flex-col  justify-center">
                        {/* <input
                              type="text"
                              id="incorp-zip-8"
                              className="w-[80%]  p-2 border-b-2 border-gray-900 bg-blue-50"
                              required
                              // pattern="[0-9]{5}"
                            /> */}

                        <div
                          id="incorp-state-8"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        >
                          {incorp_zip_2}
                        </div>

                        <br />
                        <label htmlFor="incorp-zip-8" className="text-sm">
                          ZIP
                        </label>
                      </div>
                    </td>
                  </td>
                </td>
              </tr>
              {/*  */}

              <tr>
                <td className="flex p-4 flex-col gap-2 border-r border-black">
                  <span className="font-bold text-lg  text-gray-900">
                    Signature -{" "}
                    <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
                      {" "}
                      <a
                        target="_blank"
                        // className="none"
                        href="https://www.azcc.gov/Divisions/Corporations/forms/starpas/instructionsSTPS/C010i-Instructions-Articles-of-Inc-For-profit-STPS.pdf"
                      >
                        see Instructions C010i:{"  "}{" "}
                      </a>
                    </span>
                  </span>

                  <p className="text-grey-900 font-inter text-xs font-medium leading-6">
                    By checking the box marked &quot;I accept&quot; below, I
                    acknowledge under penalty of law that this document together
                    with any attachments is submitted in compliance with Arizona
                    lawical or street address (not a P.O. Box) in Arizona of the
                    statutory agent
                  </p>

                  <div className="flex  justify-center ">
                    <input type="checkbox" className="m-2 h-4 w-4 " />
                    <span className="text-grey-900 text-center font-inter text-lg font-bold leading-normal">
                      I Accept
                    </span>
                  </div>

                  {/* <input
                        type="text"
                        id="sign-10"
                        className="w-1/25 mt-4 p-2 mb-2 border-b-2 border-gray-900 "
                        // placeholder="$"
                      /> */}

                  <div
                    id="sign-10"
                    className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                  >
                    {sign}
                  </div>

                  <label htmlFor="sign-10" className="text-sm ">
                    {" "}
                    Signature{" "}
                  </label>

                  <div className="flex justify-between mt-4  gap-2">
                    <div className="flex flex-col w-full ">
                      {/* <input
                            id="printed-date"
                            type="text"
                            className="w-1/50   p-2 border-b-2 border-gray-900 bg-blue-50"
                          /> */}

                      <div
                        id="printed-date"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      >
                        {printed_date}
                      </div>

                      <label
                        htmlFor="printed-date"
                        className="text-gray-900 mb-4 font-inter text-xs font-normal leading-6"
                      >
                        {" "}
                        Printed Date{" "}
                      </label>
                    </div>

                    <div className="flex flex-col">
                      {/* <input
                            id="date"
                            type="text"
                            className="w-1/50 p-2 border-b-2 border-gray-900 bg-blue-50"
                          /> */}

                      <div
                        id="date"
                        className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                      >
                        {date}
                      </div>
                      <label
                        htmlFor="date"
                        className="text-gray-900 font-inter text-xs font-normal leading-6"
                      >
                        {" "}
                        Date
                      </label>
                    </div>
                  </div>
                </td>

                {/*  */}

                <td>
                  <td className="flex p-4 flex-col gap-2">
                    <span className="font-bold text-lg text-gray-900">
                      Signature -{" "}
                      <span className="text-blue-500 underline font-inter text-lg font-normal leading-7">
                        {" "}
                        <a
                          target="_blank"
                          // className="none"
                          href="https://www.azcc.gov/Divisions/Corporations/forms/starpas/instructionsSTPS/C010i-Instructions-Articles-of-Inc-For-profit-STPS.pdf"
                        >
                          see Instructions C010i:{"  "}{" "}
                        </a>
                      </span>
                    </span>

                    <p className="text-grey-900 font-inter text-xs font-medium leading-6">
                      By checking the box marked &quot;I accept&quot; below, I
                      acknowledge under penalty of law that this document
                      together with any attachments is submitted in compliance
                      with Arizona law.
                    </p>

                    <div className="flex  justify-center ">
                      <input type="checkbox" className="m-2 h-4 w-4 " />
                      <span className="text-grey-900 text-center font-inter text-lg font-bold leading-normal">
                        I Accept
                      </span>
                    </div>

                    {/* <input
                          type="text"
                          id="sign-10"
                          className="w-1/25 p-2 mt-8  mb-2 border-b-2 border-gray-900 "
                          // placeholder="$"
                        /> */}

                    <div
                      id="sign-10"
                      className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                    >
                      {sign}
                    </div>

                    <label htmlFor="sign-10" className="text-sm ">
                      {" "}
                      Signature{" "}
                    </label>

                    <div className="flex justify-between mt-4  gap-2">
                      <div className="flex flex-col w-full ">
                        {/* <input
                              id="printed-date"
                              type="text"
                              className="w-1/50  p-2 border-b-2 border-gray-900 bg-blue-50"
                            /> */}

                        <div
                          id="printed-date"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        >
                          {printed_date}
                        </div>

                        <label
                          htmlFor="printed-date"
                          className="text-gray-900 mb-4 font-inter text-xs font-normal leading-6"
                        >
                          {" "}
                          Printed Date{" "}
                        </label>
                      </div>

                      <div className="flex flex-col">
                        {/* <input
                              id="date"
                              type="text"
                              className="w-1/50 p-2 border-b-2 border-gray-900 bg-blue-50"
                            /> */}

                        <div
                          id="date"
                          className="w-full p-2 border-b-2 mb-2 border-gray-900 bg-blue-50"
                        >
                          {date}
                        </div>

                        <label
                          htmlFor="date"
                          className="text-gray-900 font-inter text-xs font-normal leading-6"
                        >
                          {" "}
                          Date
                        </label>
                      </div>
                    </div>
                  </td>
                </td>
              </tr>
            </tbody>
          </table>
        </form>

        <p className="text-gray-900 font-inter text-lg font-bold leading-7">
          Expedited or Same Day/Next Day services are available for an
          additional fee – see Instructions or Cover sheet for prices.
        </p>
        <table className="w-full table-auto my-4 border-collapse">
          <tbody>
            {/* Statutory Agent Name and Mailing Address Section */}
            <tr>
              <td className="p-4 border border-gray-900 w-1/2">
                <div className="flex gap-2  flex-col justify-center">
                  <p className="text-gray-900 font-inter text-xs font-normal leading-6">
                    Filing Fee: $60.00 (regular processing){" "}
                  </p>

                  <p className="text-gray-900 font-inter text-xs font-normal leading-6">
                    All fees are nonrefundable - see Instructions.{" "}
                  </p>
                </div>
              </td>
              <td className="p-4 border border-gray-900 w-1/2">
                <div className="flex flex-col gap-2 justify-center">
                  <p className="text-gray-900 font-inter text-xs font-normal leading-6">
                    Mail: Arizona Corporation Commission - Examination Section
                    1300 W. Washington St., Phoenix, Arizona 85007
                  </p>

                  <p className="text-gray-900 font-inter text-xs font-normal leading-6">
                    Fax (for Regular or Expedite Service ONLY): 602-542-4100{" "}
                  </p>

                  <p className="text-gray-900 font-inter text-xs font-normal leading-6">
                    Fax (for Same Day/Next Day Service ONLY): 602-542-0900{" "}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text-sm my-2">
          Please be advised that A.C.C. forms reflect only the
          <span className="text-gray-900 font-inter text-xs font-bold leading-6">
            {" "}
            minimum{" "}
          </span>
          provisions required by statute. You should seek private legal counsel
          for those matters that may pertain to the individual needs of your
          business. All documents filed with the Arizona Corporation Commission
          are
          <span className="text-gray-900 font-inter text-xs font-bold leading-6">
            {" "}
            public record
          </span>{" "}
          and are open for public inspection. If you have questions after
          reading the Instructions, please call 602-542-3026 or (within Arizona
          only) 800-345-5819.
        </p>
        <div className="flex px-8 w-full mt-4 justify-between">
          <div>
            <p className="text-gray-900 font-inter text-sm font-normal leading-6">
              L010.005{" "}
            </p>
            <p className="text-gray-900 font-inter text-sm font-normal leading-6">
              Rev: 6/2020{" "}
            </p>
          </div>

          <div className="text-right">
            <p className="text-gray-900 font-inter text-sm font-normal leading-6">
              Arizona Corporation Commission – Corporations Division{" "}
            </p>
            <p className="text-gray-900 font-inter text-sm font-normal leading-6">
              Page 3 of 3{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-content-center p-2">
        <Button
          className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
          label="Download PDF"
          icon="pi pi-download"
          onClick={downloadPDF}
        ></Button>
      </div>
    </div>
  );
}

export default ArizonaCorp;
