import React from "react";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import { Col, Container, Row } from "react-bootstrap";



const services = [
    {
      title: "Compliance and legal requirements",
      description:
        "Registered agents ensures your business meets state requirements, avoiding legal issues and penalties for non-compliance.",
    },
    {
      title: "Privacy and security",
      description:
        "Registered agents protects your personal address by using thier own address for public records, keeping legal documents and sensitive information secure and confidential.",
    },
    {
      title: "Professionalism and organization",
      description:
        "It adds a level of professionalism to your business operations and keeps your business filings organized, allowing you to focus on your core operations.",
    },
  ];

const RegisteredSecond = () => {
  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle heading={"Why should your business have a"} />
          <CommonHeadingTitle blueheading={"registered agent"} />
          <Row className="mt-5">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">
                        {service.title}
                      </h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <img
                loading="lazy"
                className="user-img-buisness"
                src={
                  process.env.PUBLIC_URL +
                  "https://cdn.builder.io/api/v1/image/assets/TEMP/3d25827044e772ced8f120d27b9829699eb72a2cf1f453dd209561109fc47155?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default RegisteredSecond;
