import React from "react";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "./CommonHeadingTitle";
import "../CommonSection/CommonStyle/CommonStyle.css";

const FaqCommonSection = () => {
  const faqItems = [
    {
      key: "0",
      question: "WHO NEEDS A REGISTERED AGENT?",
      answer:
        "Registered agents are required for businesses in most states. But beyond legal requirements, registered agents are useful for busy entrepreneurs. They are available during business hours to receive legal notices and official state mail so you can focus on other aspects of your business.",
    },
    {
      key: "1",
      question: "What are the requirements for being a registered agent service provider?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      key: "2",
      question: "Is vState Filings Registered Agent service available in all states?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      key: "3",
      question: "How much does the Registered Agent service cost?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      key: "4",
      question: "Can I change my registered agent?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      key: "5",
      question: "What could happen to a business if it doesn’t have a registered agent?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
    {
      key: "6",
      question: "Will I be notified if you receive documents on my behalf?",
      answer:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    },
  ];

  // Split FAQs into two columns
  const firstColumnFaqs = faqItems.filter((_, index) => index % 2 === 0);
  const secondColumnFaqs = faqItems.filter((_, index) => index % 2 !== 0);

  return (
    <section className="main-section-class">
      <div className="common-faq">
        <Container>
          <CommonHeadingTitle heading={"FAQ"} />
          <div className="text-center">
            <h4 className="medium-level-heading mt-5 mb-5">
              Most frequent questions and answers
            </h4>
          </div>
          <Accordion defaultActiveKey="0">
            <Row>
              <Col lg={6} md={6} sm={12}>
                {firstColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
              <Col lg={6} md={6} sm={12}>
                {secondColumnFaqs.map((item) => (
                  <Accordion.Item key={item.key} eventKey={item.key}>
                    <Accordion.Header>{item.question}</Accordion.Header>
                    <Accordion.Body>{item.answer}</Accordion.Body>
                  </Accordion.Item>
                ))}
              </Col>
            </Row>
          </Accordion>
        </Container>
      </div>
    </section>
  );
};

export default FaqCommonSection;
