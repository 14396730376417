import React, { useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import ReCAPTCHA from 'react-google-recaptcha';

const ContactForm = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: '',
    website: '',
    service: null,
    message: '',
    captcha: null,
  });

  const serviceOptions = [
    { label: 'Service 1', value: 'service1' },
    { label: 'Service 2', value: 'service2' },
    { label: 'Service 3', value: 'service3' },
  ];

  const handleRecaptchaChange = (value) => {
    setFormData(prevState => ({
      ...prevState,
      captcha: value
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Basic form validation
    if (!formData.firstName || !formData.lastName || !formData.email || !formData.phone || !formData.captcha) {
      alert('Please fill all required fields and complete the CAPTCHA.');
      return;
    }

    // Prepare form data to send in the API request
    const postData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      email: formData.email,
      phone: formData.phone,
      company: formData.company,
      website: formData.website,
      service: formData.service,
      message: formData.message,
      captcha: formData.captcha,
    };

    try {
      // Replace with your API endpoint
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });

      if (response.ok) {
        // Handle success (show a success message, reset form, etc.)
        alert('Your message has been sent successfully!');
        setFormData({
          firstName: '',
          lastName: '',
          email: '',
          phone: '',
          company: '',
          website: '',
          service: null,
          message: '',
          captcha: null,
        });
      } else {
        // Handle error (show an error message)
        alert('There was an error submitting the form. Please try again.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('There was an error submitting the form. Please try again.');
    }
  };

  return (

    <div className="flex pt-5 flex-1 shrink justify-center items-start self-start basis-0 min-h-[1px] min-w-[240px] max-md:max-w-full">
      <div className="flex flex-col flex-1 shrink justify-center px-2.5 pt-2.5 w-full basis-0 min-w-[240px] max-md:max-w-full">
        <h1 className="flex flex-col justify-center w-full text-4xl font-semibold leading-snug text-center text-gray-800 min-h-[76px] max-md:max-w-full">
          <div className="flex flex-col w-full min-h-[56px] max-md:max-w-full">
            <div className="pl-52 w-full max-md:pl-5 max-md:max-w-full">
              Let's <span className="font-bold text-blue-700">Talk</span>
            </div>
          </div>
        </h1>
        <p className="flex flex-col justify-center w-full text-base font-light leading-7 text-gray-800 min-h-[101px] max-md:max-w-full">
          Please provide your contact information below, and one of our team members will reach out to you within 24 hours.
        </p>
        <div className='main-contact-form'>
          <form onSubmit={handleSubmit} className="flex flex-col w-full max-md:max-w-full">
            <div className="grid w-full">
              {[
                { name: 'firstName', label: '* First Name', type: 'text', required: true },
                { name: 'lastName', label: '* Last Name', type: 'text', required: true },
                { name: 'email', label: '* Email Address', type: 'email', required: true },
                { name: 'phone', label: '* Phone Number', type: 'tel', required: true },
                { name: 'company', label: 'Company Name', type: 'text' },
                { name: 'website', label: 'Website URL', type: 'url' },
              ].map((input) => (
                <div className="col-6 w-full p-1" key={input.name}>
                  <label htmlFor={input.name} className="block font-medium text-gray-700">
                    {input.label}
                  </label>
                  <InputText
                    id={input.name}
                    name={input.name}
                    type={input.type}
                    value={formData[input.name]}
                    onChange={handleChange}
                    required={input.required}
                    className="p-inputtext-sm w-full"
                  />
                </div>
              ))}
            </div>

            <div className="grid w-full">
              <div className="col-12 w-full p-1">
                <label htmlFor="service" className="block font-medium text-gray-700">Choose service</label>
                <Dropdown
                  id="service"
                  name="service"
                  options={serviceOptions}
                  optionLabel="label"
                  optionValue="value"
                  value={formData.service}
                  onChange={handleChange}
                  placeholder="Select a service"
                  className="w-full"
                />
              </div>
            </div>

            <div className="w-full grid">
              <div className="col-12 w-full p-1">
                <label htmlFor="message" className="block font-medium text-gray-700">Message</label>
                <InputTextarea
                  id="message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  rows={5}
                  cols={30}
                  autoResize
                  className="w-full"
                />
              </div>
            </div>

            {/* reCAPTCHA */}
            <div className="w-full p-1">
              <ReCAPTCHA
                sitekey="your-site-key" // Replace with your actual site key from Google reCAPTCHA
                onChange={handleRecaptchaChange}
              />
            </div>

            {/* Submit Button */}
            <div className="w-full p-1">
              <Button label="Submit" icon="pi pi-send" className="vstate-button w-full" />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
