import React from "react";
import "../CommonStyleTour/CommonStyleTour.css";
import CommonTourBlue from "../CommonTourComponent/CommonTourBlue";
import UserCompanyTourModal from "../UserTourModalFiles/UserCompanyTourModal";

const TourNine = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const handleStartClick = () => {
    setModalShow(true);
  };

  return (
    <section className="main-class-tour">
      <div>
      <h4 className="tour-heading">Welcome to User Management</h4>
        <p className="tour-text-holder">
.        </p>
      </div>
      <div>
        <CommonTourBlue label={"Start"} onclick={handleStartClick} />
        {/* <div className="mt-3">
          <CommonTourWhite label={"Skip"} />
        </div> */}
        <UserCompanyTourModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </section>
  );
};

export default TourNine;
