import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";

const services = [
  {
    title: "Avoid Penalties",
    description:
      "By staying compliant, your business avoids fines, penalties, or suspension due to missed deadlines.",
  },
  {
    title: "Legal Protection",
    description:
      "Compliance ensures your business continues to operate within legal requirements, safeguarding it from potential legal action.",
  },
  {
    title: "Increased Credibility",
    description:
      "Maintaining compliance boosts your business's credibility with regulators, clients, and partners to ensure smooth operations.",
  },
  {
    title: "Streamlined Processes",
    description:
      "Professional compliance services simplify required filings, reducing the burden on your team and saving you time.",
  },
];

const ComplienceSec = () => {
  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle heading={"Why should your business have a"} />
          <CommonHeadingTitle blueheading={"Why Choose Subscription Services?"} />
          <Row className="mt-5">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">{service.title}</h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <img
                loading="lazy"
                className="user-img-buisness"
                src={
                  process.env.PUBLIC_URL +
                  "https://cdn.builder.io/api/v1/image/assets/TEMP/534bb250cb442ca035f407b04dc18bd8538e5df48f97ecda01286a617bf90fd4?apiKey=136b58d08a644862944c0e5f22adbbe0&"
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default ComplienceSec;
