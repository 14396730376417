import React from "react";
import { Modal } from "react-bootstrap";
import CommonTourBlue from "./CommonTourComponent/CommonTourBlue";
import CommonTourWhite from "./CommonTourComponent/CommonTourWhite";
import "./CommonStyleTour/CommonStyleTour.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import { Navigation } from "swiper/modules";
import user1 from "../../Assets/Images/tour/u1.png";
import user2 from "../../Assets/Images/tour/u2.png";
import user3 from "../../Assets/Images/tour/u3.png";
import user4 from "../../Assets/Images/tour/u4.png";
import user5 from "../../Assets/Images/tour/u5.png";

const UserTourModal = (props) => {
  return (
    <section>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="common-modal-class"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 1 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Users: User Overview & Management
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to manage all users within your
                    system.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={user1} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> View User List: </span>{" "}
                      View a list of all users within your organization.
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Filtering & Sorting:{" "}
                      </span>{" "}
                      Filter the list of users by access level and use the
                      search bar to quickly find specific users.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Add New Users: </span>{" "}
                      Create new user accounts by clicking "Add New User" or
                      using the bulk upload feature.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  {/* <CommonTourBlue label={"Go To Next Module"} /> */}
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 2 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    {" "}
                   Users: Manage Login
                    Details
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to manage a user's login
                    credentials.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={user2} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> View Email Address: </span>{" "}
                      View the user's associated email address.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Reset Password: </span>{" "}
                      Reset the user's password if necessary.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  {/* <CommonTourBlue label={"Go To Next Module"} /> */}
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 3 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                     Users: Manage Profile
                    Information
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view and edit a user's{" "}
                    <span className="blkkk-textt">profile </span>
                    information.
                  </p>
                </div>
                <div>
                  <img  loading="lazy" src={user3} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        View Profile Details:{" "}
                      </span>{" "}
                      See the user's full name, email address, phone number, and
                      other relevant information.
                    </li>
                    <li>
                      <span className="blkkk-textt">
                        {" "}
                        Edit Profile Information:{" "}
                      </span>{" "}
                      Update the user's name, contact information, and other
                      profile details.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  {/* <CommonTourBlue label={"Go To Next Module"} /> */}
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 4 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                   Users: Manage Address
                    Details
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to view and edit a{" "}
                    <span className="blkkk-textt">
                      user's address information.
                    </span>
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={user4} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt">View Address: </span> See
                      the user's complete address, including street address,
                      city, state, country, and zip code.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Edit Address: </span>{" "}
                      Update the user's address information as needed.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  {/* <CommonTourBlue label={"Go To Next Module"} /> */}
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 5 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    Companies:  Manage Key
                    Personnel
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to manage all key personnel
                    associated with your company, including owners, members,
                    directors, officers, and more.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src={user5} className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Key Actions: </span> Add,
                      edit, and remove personnel from your company's records.
                    </li>
                    <li>
                      <span className="blkkk-textt"> Personnel Types: </span>{" "}
                      Manage various personnel types such as owners, members,
                      directors, officers, registered agents, and beneficial
                      owners.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  {/* <CommonTourBlue label={"Go To Next Module"} /> */}
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Modal.Body>
      </Modal>
    </section>
  );
};

export default UserTourModal;
