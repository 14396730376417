// import React, { useState, useEffect } from 'react';
// import { DataTable } from 'primereact/datatable';
// import { Column } from 'primereact/column';
// import { InputText } from 'primereact/inputtext';
// import { Button } from 'primereact/button';
// import { ToggleButton } from 'primereact/togglebutton';
// import { Calendar } from 'primereact/calendar';
// import { MultiSelect } from 'primereact/multiselect';
// import { TriStateCheckbox } from 'primereact/tristatecheckbox';
// import OrderServices from '../../../../services/orderServices';
// import '../InvoicePayment/InvoicePayment.css'

// export default function ClientSubscriptionTable({ onCountUpdate }) {
//     const [data, setData] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [filters, setFilters] = useState({});
//     const [globalFilterValue, setGlobalFilterValue] = useState('');
//     const [error, setError] = useState(null);

//     useEffect(() => {
//         const fetchData = async () => {
//             setLoading(true);
//             setError(null);

//             try {
//                 const response = await OrderServices.getClientSubscription(0, 0);
//                 const formattedData = response.data?.map((item) => ({
//                     id: item?.lineItemId || 0,
//                     clientCompanyName: item?.companyName || 'N/A',
//                     subscriptionName: item?.subscriptionName || 'N/A',
//                     entityName: item?.entityName || 'N/A',
//                     subscriptionDate: item?.orderDate ? new Date(item.orderDate) : null,
//                     subscriptionExpiryDate: item?.subscriptionExpiryDate
//                         ? new Date(item.subscriptionExpiryDate)
//                         : null,
//                     orderNumber: item?.orderNumber || 'N/A',
//                     notification: false,
//                 })) || [];

//                 setData(formattedData);
//                 if (onCountUpdate) {
//                     onCountUpdate(formattedData.length);
//                 }
//             } catch (err) {
//                 console.error('Error fetching data:', err);
//             } finally {
//                 setLoading(false);
//             }
//         };

//         fetchData();
//         initFilters();
//     }, []);

//     const initFilters = () => {
//         setFilters({
//             global: { value: null, matchMode: 'contains' },
//             clientCompanyName: { value: null, matchMode: 'contains' },
//             subscriptionName: { value: null, matchMode: 'contains' },
//             entityName: { value: null, matchMode: 'contains' },
//             orderNumber: { value: null, matchMode: 'contains' },
//             subscriptionDate: { value: null, matchMode: 'dateIs' },
//             subscriptionExpiryDate: { value: null, matchMode: 'dateIs' },
//             notification: { value: null, matchMode: 'equals' },
//         });
//     };

//     const onGlobalFilterChange = (e) => {
//         const value = e.target.value;
//         const updatedFilters = { ...filters, global: { value, matchMode: 'contains' } };
//         setFilters(updatedFilters);
//         setGlobalFilterValue(value);
//     };

//     const renderHeader = () => (
//         <div className="flex justify-content-end p-2">
//             <div>
//                 <Button
//                     type="button"
//                     icon="pi pi-filter-slash"
//                     label="Clear"
//                     outlined
//                     onClick={initFilters}
//                     className='me-2'

//                 />
//             </div>
//             <div>
//                 <InputText
//                     value={globalFilterValue}
//                     onChange={onGlobalFilterChange}
//                     placeholder="Global Search"
//                 />
//             </div>
//         </div>
//     );

//     const subscriptionDateFilter = (options) => (
//         <Calendar
//             value={options.value}
//             onChange={(e) => options.filterApplyCallback(e.value)}
//             dateFormat="mm/dd/yy"
//             placeholder="mm/dd/yyyy"
//         />
//     );

//     const notificationFilter = (options) => (
//         <TriStateCheckbox
//             value={options.value}
//             onChange={(e) => options.filterApplyCallback(e.value)}
//         />
//     );

//     const multiSelectFilter = (options, field) => {
//         const uniqueValues = Array.from(new Set(data.map((item) => item[field])));
//         return (
//             <MultiSelect
//                 value={options.value}
//                 options={uniqueValues}
//                 onChange={(e) => options.filterApplyCallback(e.value)}
//                 placeholder={`Select ${field}`}
//                 display="chip"
//             />
//         );
//     };

//     const notificationTemplate = (rowData) => (
//         <ToggleButton
//             checked={rowData.notification}
//             onChange={() =>
//                 setData((prevData) =>
//                     prevData.map((item) =>
//                         item.id === rowData.id
//                             ? { ...item, notification: !item.notification }
//                             : item
//                     )
//                 )
//             }
//             onIcon="pi pi-bell"
//             offIcon="pi pi-bell-slash"
//         />
//     );

//     const formatDate = (date) => (date ? date.toLocaleDateString('en-US') : 'N/A');

//     return (
//         <div className='main-table-class-datatable'>
//             <div className="card">
//                 {error && <div className="alert alert-danger">{error}</div>}
//                 <DataTable
//                     value={data}
//                     paginator
//                     rows={5}
//                     loading={loading}
//                     filters={filters}
//                     globalFilterFields={['clientCompanyName', 'subscriptionName', 'entityName', 'orderNumber']}
//                     header={renderHeader()}
//                     emptyMessage="No records found."
//                     scrollable
//                     scrollHeight="flex"
//                     scrollWidth="100%"
//                 >
//                     <Column
//                         field="clientCompanyName"
//                         header="Client Company Name"
//                         filter
//                         filterElement={(options) => multiSelectFilter(options, 'clientCompanyName')}
//                         className='font-fam-for-all datatable-lg-col'
//                     />
//                     <Column
//                         field="subscriptionName"
//                         header="Subscription Name"
//                         filter
//                         filterElement={(options) => multiSelectFilter(options, 'subscriptionName')}
//                         className='font-fam-for-all datatable-lg-col'
//                     />
//                     <Column
//                         field="entityName"
//                         header="Entity Name"
//                         filter
//                         filterElement={(options) => multiSelectFilter(options, 'entityName')}
//                         className='font-fam-for-all datatable-lg-col'
//                     />
//                     <Column
//                         field="subscriptionDate"
//                         header="Subscription Date"
//                         body={(rowData) => formatDate(rowData.subscriptionDate)}
//                         filter
//                         filterElement={subscriptionDateFilter}
//                         className='font-fam-for-all datatable-lg-col'
//                     />
//                     <Column
//                         field="subscriptionExpiryDate"
//                         header="Subscription Expiry Date"
//                         body={(rowData) => formatDate(rowData.subscriptionExpiryDate)}
//                         filter
//                         filterElement={subscriptionDateFilter}
//                         className='font-fam-for-all datatable-lg-col'
//                     />
//                     <Column
//                         header="Mute Notification"
//                         body={notificationTemplate}
//                         filter
//                         filterElement={notificationFilter}
//                         className='font-fam-for-all datatable-lg-col'
//                     />
//                     <Column
//                         field="orderNumber"
//                         header="Order Number"
//                         filter
//                         filterElement={(options) => multiSelectFilter(options, 'orderNumber')}
//                         className='font-fam-for-all datatable-lg-col'
//                     />
//                     <Column
//                         header="Actions"
//                         body={() => <span style={{ color: '#B54708', fontWeight: 'bold' }}>Send Reminder</span>}
//                         className='font-fam-for-all datatable-lg-col'
//                     />
//                 </DataTable>

//             </div>
//         </div>
//     );
// }

import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";
import { ToggleButton } from "primereact/togglebutton";
import OrderServices from "../../../../services/orderServices";
import "../InvoicePayment/InvoicePayment.css";
import { Col, Row } from "react-bootstrap";

export default function ClientSubscriptionTable({ onCountUpdate }) {
  const allColumns = [
    { field: "clientCompanyName", header: "Client Company Name" },
    { field: "subscriptionName", header: "Subscription Name" },
    { field: "entityName", header: "Entity Name" },
    { field: "subscriptionDate", header: "Subscription Date" },
    { field: "subscriptionExpiryDate", header: "Subscription Expiry Date" },
    { field: "orderNumber", header: "Order Number" },
    { field: "notification", header: "Mute Notification" },
    { field: "actions", header: "Actions" },
  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [visibleColumns, setVisibleColumns] = useState(allColumns);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await OrderServices.getClientSubscription(0, 0);
        const formattedData =
          response.data?.map((item) => ({
            id: item?.lineItemId || 0,
            clientCompanyName: item?.companyName || "N/A",
            subscriptionName: item?.subscriptionName || "N/A",
            entityName: item?.entityName || "N/A",
            subscriptionDate: item?.orderDate ? new Date(item.orderDate) : null,
            subscriptionExpiryDate: item?.subscriptionExpiryDate
              ? new Date(item.subscriptionExpiryDate)
              : null,
            orderNumber: item?.orderNumber || "N/A",
            notification: false,
          })) || [];

        setData(formattedData);
        if (onCountUpdate) {
          onCountUpdate(formattedData.length);
        }
      } catch (err) {
        console.error("Error fetching data:", err);
        setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    initFilters();
  }, []);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: "contains" },
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    setFilters({ ...filters, global: { value, matchMode: "contains" } });
    setGlobalFilterValue(value);
  };

  const onColumnToggle = (event) => {
    const selectedColumns = event.value;
    setVisibleColumns(selectedColumns);
  };

  const renderHeader = () => (
    <Row>
      <Col xl={6} md={6} sm={12} xs={12}>
        <MultiSelect
          value={visibleColumns}
          options={allColumns}
          optionLabel="header"
          onChange={onColumnToggle}
          placeholder="Select Columns"
          className="w-full sm:w-20rem ms-2"
          display="chip"
        />
      </Col>
      <Col xl={6} md={6} sm={12} xs={12}>
        <div className="flex justify-content-end p-2">
          <div>
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Clear"
              outlined
              onClick={initFilters}
              className="me-2"
            />
          </div>
          <div>
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Global Search"
            />
          </div>
        </div>
      </Col>
    </Row>
  );

  const formatDate = (date) =>
    date ? date.toLocaleDateString("en-US") : "N/A";

  const notificationTemplate = (rowData) => (
    <ToggleButton
      checked={rowData.notification}
      onChange={() =>
        setData((prevData) =>
          prevData.map((item) =>
            item.id === rowData.id
              ? { ...item, notification: !item.notification }
              : item
          )
        )
      }
      onIcon="pi pi-bell"
      offIcon="pi pi-bell-slash"
    />
  );

  return (
    <div className="main-table-class-datatable">
      <div className="card">
        {error && <div className="alert alert-danger">{error}</div>}
        <DataTable
          value={data}
          paginator
          rows={5}
          loading={loading}
          filters={filters}
          globalFilterFields={allColumns.map((col) => col.field)}
          header={renderHeader()}
          emptyMessage="No records found."
          scrollable
          scrollHeight="flex"
          scrollWidth="100%"
        >
          {visibleColumns.map((col) => {
            if (
              col.field === "subscriptionDate" ||
              col.field === "subscriptionExpiryDate"
            ) {
              return (
                <Column
                  sortable
                  key={col.field}
                  field={col.field}
                  header={col.header}
                  body={(rowData) => formatDate(rowData[col.field])}
                  className="font-fam-for-all datatable-lg-col"
                />
              );
            }
            if (col.field === "notification") {
              return (
                <Column
                  sortable
                  key={col.field}
                  header={col.header}
                  body={notificationTemplate}
                  className="font-fam-for-all datatable-lg-col"
                />
              );
            }
            if (col.field === "actions") {
              return (
                <Column
                  key={col.field}
                  header={col.header}
                  className="font-fam-for-all datatable-lg-col"
                  body={() => (
                    <span style={{ color: "#B54708", fontWeight: "bold" }}>
                      Send Reminder
                    </span>
                  )}
                />
              );
            }
            return (
              <Column
                sortable
                key={col.field}
                field={col.field}
                header={col.header}
              />
            );
          })}
        </DataTable>
      </div>
    </div>
  );
}
