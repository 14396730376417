

import React from 'react'


const CommonTourBlue = ({label, icon, onclick, className }) => {
  return (
    <section className={`main-class-blue-tour ${className}`}>
        <button className='blue-btnnn' onClick={onclick}> {label} {icon} </button>
    </section>
  )
}

export default CommonTourBlue

