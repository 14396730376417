import React from "react";

const Footer = () => {
  return (
    <footer
      className="flex flex-col justify-center items-center px-16 py-16 w-full max-md:px-5 max-md:py-10"
      style={{ background: "#1B2032" }}
    >
      <div className="justify-center px-0.5 w-full max-w-[1140px]">
        <div className="flex gap-5 max-md:flex-col max-md:gap-5">
          {/* Left Section */}
          <div className="flex flex-col w-[36%] max-md:w-full">
            <div className="flex flex-col justify-center px-2.5 py-2 text-sm font-light leading-7 text-white text-opacity-70">
              <img
                loading="lazy"
                src="https://cdn.builder.io/api/v1/image/assets/TEMP/c21363d3d545cfc471d5f48394b421bd6b0aee2e219e17e9417a89bf538c3079?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
                className="max-w-full w-[200px] max-md:w-full"
                alt="vState Filings logo"
              />
              <p className="mt-5">
                We effectively manage your business formation, registered agent,
                provide legal and advisory assistance. vState Filings sets up
                your company in a few steps.
              </p>
              <ContactInfo />
            </div>
          </div>

          {/* Right Section */}
          <div className="flex flex-col w-[64%] max-md:w-full">
            <div className="flex gap-5 max-md:flex-col">
              {/* Services Section */}
              <div className="flex flex-col w-6/12 max-md:w-full">
                <div className="flex flex-col px-2.5 text-sm font-light leading-7 text-white text-opacity-70">
                  <h3 className="text-lg font-medium text-white">Services</h3>
                  <ul className="mt-5 space-y-2">
                    <li>C-Corp</li>
                    <li>PC or PLLC</li>
                    <li>LP</li>
                    <li>LLP</li>
                    <li>Conversions</li>
                    <li>Tax ID - EIN</li>
                    <li>UCC Searches & Filings</li>
                    <li>S-Corp</li>
                  </ul>
                </div>
              </div>

              {/* Disclaimer Section */}
              <div className="flex flex-col w-6/12 max-md:w-full">
                <div className="flex flex-col px-2.5">
                  <h3 className="text-lg font-medium text-white">Disclaimer</h3>
                  <p className="mt-5 text-sm font-light leading-7 text-white text-opacity-70">
                    The information contained on this site is not and should not
                    be construed as legal advice. vState Filings provides
                    business registration, registered agent, Attorney and remote
                    online services based on a variety of legal, economic,
                    geographic and other criteria.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Bottom Section */}
      <div className="flex justify-center items-center self-stretch px-16 py-3 w-full border-t border-white border-opacity-20 max-md:px-5">
        <div className="flex flex-wrap justify-center items-center text-xs font-light leading-4 text-white text-opacity-70 space-x-3">
          <div>© Copyright 2024 |</div>
          <div>vState Filings</div>
          <div>| Terms |</div>
          <div>All Rights Reserved</div>
        </div>
        <div className="flex space-x-2.5 mt-2 max-md:mt-4">
          <SocialIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/f8ec4acb0c40f7048c6b99fa73e442b121c9cbdb1133c01d6f3f8928eec47977?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
            alt="Facebook"
            link="https://www.facebook.com"
          />
          <SocialIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/80071593701117e8d2fe7dab403696d8eddce7d2bc445189ac644df7c73af3fd?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
            alt="Twitter"
            link="https://www.twitter.com"
          />
          <SocialIcon
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/cc86d398facc9332deac33816253932de09a4383ab7f3126267ee894d15058b8?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
            alt="LinkedIn"
            link="https://www.linkedin.com"
          />
        </div>
      </div>
    </footer>
  );
};

const ContactInfo = () => (
  <div>
    <div className="flex gap-5 mt-5">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/36a91ec308c14bd2576e0acf5e5463fc5c137a2182ee41481767782f04fe8f0b?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
        className="aspect-square"
        alt="Email"
      />
      <span>support@vstatefilings.com</span>
    </div>
    <div className="flex gap-5 mt-1.5">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/e5fa235e3e1b808ce197b222fc9164fef9dae4e4eb1c5499b0960273aaeb173b?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
        className="aspect-square"
        alt="Phone"
      />
      <div>
        <a href="tel:866-638-3309" className="underline">
          (866) 638-3309
        </a>{" "}
        |{" "}
        <a href="tel:718-569-2703" className="underline">
          (718) 569-2703
        </a>
      </div>
    </div>
    <div className="flex gap-5 mt-2">
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/61b22b1897a242a6b315abfaf8701f9dd6ba3c556463a94af03cf7630f6c138c?apiKey=02362ed84d214b928bb0b669d3dcf7f8&"
        className="aspect-square"
        alt="Address"
      />
      <address className="not-italic">
        301 Mill Road, Suite U5, Hewlett, NY 11557
      </address>
    </div>
  </div>
);

const SocialIcon = ({ src, alt, link }) => (
  <a
    href={link}
    target="_blank"
    rel="noopener noreferrer"
    className="flex justify-center items-center p-2.5 bg-blue-700 h-[33px] rounded-[50px] w-[33px] ms-2"
  >
    <img
      loading="lazy"
      src={src}
      className="aspect-square w-[15px]"
      alt={alt}
    />
  </a>
);

export default Footer;
