
import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button } from 'primereact/button';
import { DataScroller } from 'primereact/datascroller';
import { Rating } from 'primereact/rating';
import { Tag } from 'primereact/tag';
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';


import { Checkbox } from 'primereact/checkbox';
import { Avatar } from 'primereact/avatar';

import { MultiSelect } from 'primereact/multiselect';
import { Accordion, AccordionTab } from 'primereact/accordion';


import { SplitButton } from 'primereact/splitbutton';


import { Dialog } from 'primereact/dialog';
import { Divider } from 'primereact/divider';

import { Dropdown } from 'primereact/dropdown';

import { DataView } from 'primereact/dataview';

import { Skeleton } from 'primereact/skeleton';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { TabView, TabPanel } from 'primereact/tabview';
import { Sidebar } from 'primereact/sidebar';
import { OverlayPanel } from 'primereact/overlaypanel';
import authContext from '../common/authContext';
import { useNavigate } from 'react-router-dom';
import UserServices from '../services/userService';
import { RadioButton } from 'primereact/radiobutton';
import Sidesbar from '../common/Sidesbar';
import Headers from '../common/Headers';
import ContactService from '../services/ContactService';
import AddCustomUser from './AddComponents/AddCutsomUser';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import AddEmployee from './AddComponents/AddEmployee';
import GroupDataTable from './GroupDataTable';
import CompanyLocation from './CompanyLocation';
import CompanyOwners from './Company/CompanyOwners';
import PrimaryContact from './Company/PrimaryContact';
import ShipingContact from './Company/ShipingContact';
import BilingContact from './Company/BilingContact';
import GeneralPersonal from './Company/GeneralPersonal';
import AddCompany from './AddComponents/AddCompany';
import companyMasterService from '../services/companyMasterService';
import CompanyOrders from './Company/CompanyOrders';
import AddCompanyLocation from './Company/AddCompanyComponets/AddLocation';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddressService from '../services/addressService';
import errorMessages from '../utils/errorMessages';
import rolesMasterService from '../services/rolesMaster';
import AddCompanyMember from './Company/AddCompanyComponets/AddMember';
import OrganizerContact from './Company/OrganizerContact';
import { InputMask } from 'primereact/inputmask';
import AddCompanyContact from './AddComponents/AddCompanyContact';
import { Toast } from 'primereact/toast';
import AddPrimaryContact from './AddComponents/AddPrimaryContact';
import AddAccountManager from './AddComponents/AddAccountManager';
import Documents from './Orders/Documents';
import AddCompanyDocuments from './AddComponents/AddCompanyDocuments';
import Assignee from './Company/Assignee';
import Debtor from './Company/Debtor';
import Director from './Company/Director';
import Incorporator from './Company/InCorporator';
import Officer from './Company/Officer';
import RegistredAgent from './Company/RegistredAgentContact';
import SecuredParty from './Company/SecuredParty';
import CompanyServices from './Company/CompanyServices';
import CompanySubscriptions from './Company/CompanySubscriptions';
import { formatISODate } from '../utils/dateUtils';
import BeneficialOwner from './Company/BeneficialOwner';
import ComapnyApplicant from './Company/CompanyApplicant';
import CompanyDocuments from './Company/CompanyDocuments';
import CompanyFiler from './Company/CompanyFiler';
import President from './Company/President';
import Treasurer from './Company/Treasurer';
import Secretary from './Company/Secretary';
import AddBOIMember from './Company/AddCompanyComponets/AddBOIMember';
import MasterServices from '../services/coreServices';




export default function MyCompanyDashboard() {

    const { setFeedbackData, setFilterStatusId, globalCompany, clearData, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh, redirectedData, setRedirectedData } = useContext(authContext);
    let newObject = window.localStorage.getItem("user");
    let updatedUser = Object.assign({}, JSON.parse(newObject));

    const [formationDateString, setFormationDateString] = useState(dayjs(null))

    const [dissolutionDateString, setDissolutionDateString] = useState(dayjs(null))

    const [filteredData, setFilteredData] = useState([]);
    const [globalFilter, setGlobalFilter] = useState('');

    const [sortName, setSortName] = useState(null)

    const [chipsData, setChipsData] = useState([]);

    const [chipsData1, setChipsData1] = useState([]);

    const [filterChipData, setFilterChipData] = useState([])

    const [selectedData, setSelectedData] = useState([]);

    const [allCities, setAllCities] = useState([])

    const [ownerType, setAllOwnerType] = useState([])


    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        // name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },

        id: { value: null, matchMode: FilterMatchMode.IN },
        companyName: { value: null, matchMode: FilterMatchMode.EQUALS },
        lastName: { value: null, matchMode: FilterMatchMode.EQUALS },
        mobileNo: { value: null, matchMode: FilterMatchMode.EQUALS },
        emailId: { value: null, matchMode: FilterMatchMode.EQUALS },

    });

    // const [city, setCity] = useState([])

    const [exp1, setExp1] = useState(null)

    const [exp2, setExp2] = useState(null)

    const toast = useRef(null)

    const [skills, setSkills] = useState([]);
    const [skillError, setSkillError] = useState("");
    const [skillARR, setSkillARR] = useState([]);
    const [sortField, setSortField] = useState(null);
    const [sortOrder, setSortOrder] = useState('asc');

    const [qualification, setQualification] = useState([])
    const [qualificationArr, setQualificationArr] = useState([])

    const [visible, setVisible] = useState(false)

    const [visible1, setVisible1] = useState(false)

    const [visible2, setVisible2] = useState(false)

    const [visible3, setVisible3] = useState(false)

    const [visible4, setVisible4] = useState(false)

    const [visible5, setVisible5] = useState(false)

    const [visible6, setVisible6] = useState(false)

    const [visible8, setVisible8] = useState(false)

    const [visibleContent, setVisibleContent] = useState(false)

    const [allPosition, setAllPosition] = useState([])

    const [position, setPosition] = useState(null)

    const [positionData, setPositionData] = useState(null)


    const [candidate, setCandidate] = useState(null)

    const [closeProgresbar, setCloseProgresbar] = useState(false)

    const [closeProgresbar1, setCloseProgresbar1] = useState(false)

    const [isFocused, setIsFocused] = useState(false);

    const [globalKeyValue, setGlobalKeyValue] = useState("")

    const [AllCandidate, setAllCandidate] = useState([])

    const [showSkeleton, setShowSkeleton] = useState(false)

    const [selectedProduct, setSelectedProduct] = useState(null)

    const [addressData, setAddressData] = useState(null)


    const [data, setData] = useState(null)

    const op = useRef(null);

    const downloadop = useRef(null)

    const [isRemoveChip, setIsRemoveChip] = useState(false)

    const [fback, setFback] = useState([])

    const [candidateFback, setCandidateFback] = useState(null)

    const [interviewLevel1, setInterviewLevel1] = useState([])

    const [hrPanelList, setHrPanelList] = useState([])

    const [hrpanel, setHrPanel] = useState(null)

    const [selectedPanelData, setSelectedPanelData] = useState([])

    const [selectedPanelList, setSelectedPanelList] = useState([])

    const [showPanel, setShowPanel] = useState(false)

    const [globalSearchValue, setGlobalSearchValue] = useState("")

    const history = useNavigate()

    const [isShowContent, setIsShowContent] = useState(false)

    const [isShowContent1, setIsShowContent1] = useState(false)

    const [isShowContent2, setIsShowContent2] = useState(false)

    const [isShowContent3, setIsShowContent3] = useState(false)

    const [salutation, setSalutation] = useState("")

    const [companyName, setCompanyName] = useState("")

    const [companyErr, setCompanyErr] = useState("")

    const [domicileSate, setdomicileSate] = useState("")

    const [DomicileCity, setDomicileCity] = useState("")


    const [firstName, setFirstName] = useState("")

    const [lastName, setLastName] = useState("")

    const [website, setWebsite] = useState("")

    const [ingredient, setIngredient] = useState('');

    const [emailErr, setEmailErr] = useState("")

    const [passwordErr, setPasswordErr] = useState("")

    const [confirmPasswordErr, setConfirmPasswordErr] = useState("")

    const [firstNameErr, setFirstNameErr] = useState("")

    const [lastNameErr, setLastNameErr] = useState("")

    const [FormationDate, setFormationDate] = useState(dayjs(null))

    const [DissolutionDate, setDissolutionDate] = useState(dayjs(null))

    const [filteredInput, setFilteredInput] = useState("")

    const [serviceState, setServiceState] = useState(null)

    const [serviceStateErr, setServiceStateErr] = useState("")

    const [domesticState, setDomesticState] = useState(null)

    const [domesticStateErr, setDomesticStateErr] = useState("")

    const [industryType, setIndustryType] = useState(null)

    const [industryTypeErr, setIndustryTypeErr] = useState("")

    const [phone, setPhone] = useState("")

    const [phoneErr, setPhoneErr] = useState("")

    const [EINNumber, setEINNumber] = useState("")

    const [entityNumber, setEntityNumber] = useState("")

    const [EINNumberErr, setEINNumberErr] = useState("")

    const [entityNumberErr, setEntityNumberErr] = useState("")

    const [texYearEnd, setTaxYearEnd] = useState("")

    const [taxYearEndErr, setTaxYearEndErr] = useState("")

    const [allIndustryType, setAllIndustryType] = useState([

    ])

    const [faxErr, setFaxErr] = useState("")

    const [businessPurpose, setBusinessPurpose] = useState("")

    const [addressTypeErr, setAddressTypeErr] = useState("")

    const [businessStructure, setBusinessStructure] = useState(null)

    const [businessStructureErr, setBusinessStructureErr] = useState("")

    const [openDateErr, setOpenDateErr] = useState("")

    const [admin, setAdmin] = useState(null)

    const [email, setEmail] = useState("")

    const [altEmail, setAltEmail] = useState("")

    const [fax, setFax] = useState("")

    const [altFax, setAltFax] = useState("")

    const [addr1, setAddr1] = useState("")

    const [addr1Err, setAddr1Err] = useState("")

    const [addr2, setAddr2] = useState("")

    const [addr2Err, setAddr2Err] = useState("")

    const [addr3, setAddr3] = useState("")

    const [region, setRegion] = useState("")

    const [regionErr, setRegionErr] = useState("")


    const [states, setStates] = useState(null)

    const [zipErr, setZipErr] = useState("")

    const [zip, setZip] = useState("")

    const [pageRefresh, setPageRefresh] = useState(false)

    const [dataId, setDataId] = useState(null)


    const [globalFilterValue, setGlobalFilterValue] = useState('');

    const [rolesName, setRolesName] = useState("")

    const [statesErr, setStatesErr] = useState("")

    const [tempProduct, setTempProduct] = useState([])

    const [allAddressType, setAllAddressType] = useState([])

    const [addressType, setAddressType] = useState(null)

    const [cityErr, setcityErr] = useState('')

    const [city, setCity] = useState(null)

    const [allCountries, setAllCountries] = useState([])

    const [countryData, setCountryData] = useState(null)

    const [countryErr, setCountryErr] = useState("")

    const [stateValue, setStateValue] = useState(null)

    const [countryValue, setCountryValue] = useState(null)

    const [allLocation, setAllLocation] = useState([])

    const [serviceStateValue, setServiceStateValue] = useState(null)

    const [domesticStateValue, setDomesticStateValue] = useState(null)

    const [memberType, setMemberType] = useState(null)

    const [dissolutionDateErr, setDissolutionDateErr] = useState("")

    const [allKeyPersonnelType, setAllKeyPersonnelType] = useState([])

    const [addressUpdate, setAddressUpdate] = useState(false)


    const [superAdminMainId, setSuperAdminMainId] = useState(null)

    const [keyPersonalAddressType, setKeyPersonalAddressType] = useState(null)

    const [avatars, setAvatars] = useState({});

    const [products, setProducts] = useState([
    ]);

    const [allContacts, setAllContacts] = useState([])

    const [isClient, setIsClient] = useState(false)

    const [principalAddress, setPrinciPalAddress] = useState(null)
    const [selectedCountry, setSelectedCountry] = useState(null);

    const [activeIndex, setActiveIndex] = useState(0);

    const [closeProgresbar3, setCloseProgresbar3] = useState(false)

    const [selectedAccountManager, setSelectedAccountManager] = useState(null)


    const countries = [
        { name: 'United States', code: 'US', dialCode: "+1" }
    ];


    const [allStates, setAllStates] = useState([

    ]);

    const entityTypes = [
        { label: "LLC", value: "LLC", id: 1 },
        { label: "Corp", value: "Corp", id: 2 }
    ];

    const [entityType, setEntityType] = useState(null)

    const [filteredStateValue, setFilteredStateValue] = useState(null)

    const user = JSON.parse(localStorage.getItem('user'));

    const [superAdminId, setSuperAdminId] = useState(null)

    const [loginDetails, setLoginDetails] = useState([

        { key: "Password", value: 'dhdhdhdhhdhd' },
    ])

    const [editLoginValues, setEditLoginValues] = useState(
        loginDetails.reduce((acc, item) => {
            acc[item.key] = item.value;
            return acc;
        }, {})
    )

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters['global'].value = value;

        setFilters(_filters);
        setFilteredData(value)
        setGlobalFilterValue(value);
    };

    const handleLoginChange = (key, value) => {
        setEditLoginValues({ ...editLoginValues, [key]: value });
    };



    const selectedCountryTemplate = (option, props) => {
        if (option) {
            return (
                <div className="flex align-items-center">
                    <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '28px' }} />
                    <div >{option.dialCode}</div>
                </div>
            );
        }

        return <span>{props.placeholder}</span>;
    };

    const countryOptionTemplate = (option) => {
        return (
            <div className="flex align-items-center">
                <img alt={option.name} src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png" className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: '18px' }} />
                <div className='webapp-sm-size'>{option.code}</div>
            </div>
        );
    };

    const panelFooterTemplate = () => {
        return (
            <div className="py-2 px-3">
                {selectedCountry ? (
                    <span>
                        <b>{selectedCountry.name}</b> selected.
                    </span>
                ) : (
                    'No country selected.'
                )}
            </div>
        );
    };

    useEffect(() => {
        clearData()
        setFilterStatusId(null)
        setRedirectedData(null)
        companyMasterService.getAllKeyPersonnelType()
            .then((res) => {
                console.log(res)
                if (res.data !== "") {
                    setAllKeyPersonnelType(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })

        MasterServices.getAllApplicantType()
            .then((res) => {
                console.log(res)
                if (Array.isArray(res.data)) {
                    setAllOwnerType(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])



    const handleKeyPersonal = (data) => {
        setVisible2(true)
        const temp = allKeyPersonnelType.filter((val) => val.keyPersonalType === data)
        if (temp.length > 0) {
            setMemberType(temp[0])
        }

        const temp1 = allAddressType.filter((val) => val.type === data)
        if (temp1.length > 0) {
            setKeyPersonalAddressType(temp1[0])
        }
    }

    const handleBOIKeyPersonal = (data) => {
        setVisible8(true)
        console.log(ownerType)
        const temp = ownerType.filter((val) => val.name === data)
        console.log(temp)
        if (temp.length > 0) {
            setMemberType(temp[0])
        }

        const temp1 = allAddressType.filter((val) => val.type === data)
        console.log(temp1)
        if (temp1.length > 0) {
            setKeyPersonalAddressType(temp1[0])
        }
    }




    const [isEditing, setIsEditing] = useState(false);
    const [isEditing1, setIsEditing1] = useState(false);
    const [isEditing2, setIsEditing2] = useState(false);
    const [editValues, setEditValues] = useState(

    );


    const item = [
        {
            label: 'Name',
            // icon: 'pi pi-refresh',
            command: () => {
                sortData('fullName')

            }
        },

        {
            label: 'Experience',

            command: () => {
                sortData('experienceInYears')

            }
        },

    ];

    function checkIdsExist(arr, idsToCheck) {
        // Check if any of the idsToCheck are found in arr
        const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
        return foundIds.length > 0 ? foundIds : null;
    }

    useEffect(() => {
        if (user) {
            console.log(globalCompany);
            if (globalCompany !== null) {
                if (globalCompany.roles.length > 0) {
                    // Check for both 1 and 4
                    const ids = checkIdsExist(globalCompany.roles, [1, 4]);
                    const superId = checkIdsExist(globalCompany.roles, [1]);
                    console.log(ids);
                    if (ids) {
                        setSuperAdminId(ids); // Set the array of matched IDs
                    }
                    if (!ids || ids.length === 0) {
                        setIsClient(true);
                        setSuperAdminId(null)
                    }
                    if (superId) {
                        setSuperAdminMainId(superId)
                    }
                    else {
                        setSuperAdminMainId(null)
                    }
                }
            }
        }
    }, [globalCompany]);

    useEffect(() => {
        setSelectedCountry('+1')
        if (globalCompany) {
            companyMasterService.getAllCompanyById(globalCompany?.companyDetails?.id)
                .then((res) => {
                    console.log(res)
                    if (res.data !== "") {
                        let data = res.data

                        setShowSkeleton(true)


                        console.log(data)
                        setDataId(data.id)
                        setCompanyName(data.companyName)
                        setBusinessStructure(data.entityType)
                        setFormationDate(data.formationDate)
                        setDissolutionDate(data.dissolutionDate)
                        setEINNumber(data.einNo)
                        setEntityNumber(data.entityNo)
                        setTaxYearEnd(data.taxYearEnd)
                        if (data.formationDate) {
                            setFormationDateString(dayjs(data.formationDate, "YYYY-MM-DD"))

                        }
                        if (data.dissolutionDate) {
                            setDissolutionDateString(dayjs(data.dissolutionDate, "YYYY-MM-DD"))
                        }
                        const temp = allStates.filter((val) => val.id === data.serviceStateId)
                        const temp1 = allStates.filter((val) => val.id === data.domesticStateId)
                        if (temp.length > 0) {
                            setServiceState(temp[0])
                        }
                        if (temp1.length > 0) {
                            setDomesticState(temp1[0])
                        }
                        setActiveIndex(0)
                        setData(data)

                        if (data.phoneNo) {
                            const temp3 = data.phoneNo.split(" ")
                            if (Array.isArray(temp3)) {
                                if (temp3.length > 0) {
                                    setSelectedCountry(temp3[0])
                                    setPhone(temp3.slice(1).join(" "))
                                }
                            }
                        }
                    }

                })
                .catch((err) => {
                    console.log(err)
                    setShowSkeleton(true)
                })
        }


    }, [pageRefresh, superAdminId, isClient, superAdminMainId, isLoading, globalCompany])


    useEffect(() => {
        if (data) {
            console.log(data)
            setActiveIndex(0)
            setCompanyName(data.firstName)
            setDomicileCity(data.DomicileCity)
            setEmail(data.emailId)

        }
    }, [data])

    const getState = (id) => {
        const temp = allStates.find((val) => val.id === id)
        console.log(temp)
        if (temp !== undefined) {
            return temp.stateFullDesc;
        }
        else {
            return null
        }

    }

    useEffect(() => {
        setCountryData(3)
        companyMasterService.getAllCompanyById(dataId)
            .then((res) => {
                console.log(res)
                if (res.data !== "") {

                    console.log(res.data)
                    setCompanyName(res.data.companyName)
                    setBusinessStructure(res.data.entityType)
                    setFormationDate(res.data.formationDate)
                    setDissolutionDate(res.data.dissolutionDate)
                    if (res.data.formationDate) {
                        setFormationDateString(dayjs(res.data.formationDate, "YYYY-MM-DD"))

                    }
                    if (res.data.dissolutionDate) {
                        setDissolutionDateString(dayjs(res.data.dissolutionDate, "YYYY-MM-DD"))
                    }
                    setIngredient(res.data.companyEmail)
                    setIndustryType(res.data.industryId)
                    // setPhone(res.data.registeredAgentContactNo)
                    setEINNumber(res.data.einNo)
                    setEntityNumber(res.data.entityNo)
                    setTaxYearEnd(res.data.taxYearEnd)
                    setWebsite(res.data.websiteUrl)
                    // setFax(res.data.fax)
                    if (res.data.fax) {
                        const temp2 = res.data.fax.split(" ")
                        if (Array.isArray(temp2)) {
                            if (temp2.length > 0) {
                                setSelectedCountry(temp2[0])
                                setFax(temp2.slice(1).join(" "))
                            }
                        }
                    }

                    if (res.data.phoneNo) {
                        const temp3 = res.data.phoneNo.split(" ")
                        if (Array.isArray(temp3)) {
                            if (temp3.length > 0) {
                                setSelectedCountry(temp3[0])
                                setPhone(temp3.slice(1).join(" "))
                            }
                        }
                    }
                    const temp = allStates.filter((val) => val.id === res.data.serviceStateId)
                    console.log(temp)
                    const temp1 = allStates.filter((val) => val.id === res.data.domesticStateId)
                    if (temp.length > 0) {
                        setServiceState(temp[0].id)
                        setServiceStateValue(temp[0].stateFullDesc)
                    }
                    if (temp1.length > 0) {
                        setDomesticState(temp1[0].id)
                        setDomesticStateValue(temp1[0].stateFullDesc)
                    }

                }
            })
            .catch((err) => {
                console.log(err)
            })
        AddressService.getAllAddressByCompanyId(dataId)
            .then((res) => {
                console.log(res)
                if (res.data !== "") {
                    console.log(res.data)
                    setAllLocation(res.data)
                    if (Array.isArray(res.data)) {
                        const temp = res.data.filter((val) => val.addressType.type === "PRINCIPAL")
                        console.log(temp)
                        if (temp.length > 0) {
                            setAddressData(temp[0])
                            setPrinciPalAddress(temp[0])
                            setAddr1(temp[0].addressLine1)
                            setAddr2(temp[0].addressLine2)
                            setCity(temp[0].city)
                            setZip(temp[0].postalCode)
                            setCountryData(temp[0].countryId)
                            setStates(temp[0].stateId)
                            const temps = allStates.filter((val) => val.id === temp[0].stateId)
                            const temp1 = allCountries.filter((val) => val.id === temp[0].countryId)
                            console.log(temps)
                            if (temps.length > 0) {
                                setStateValue(temps[0].stateFullDesc)

                            }
                            if (temp1.length > 0) {
                                setCountryValue(temp1[0].countryFullDesc)
                            }
                            setAddressType(temp[0].addressType)
                        }
                        else {
                            setPrinciPalAddress(null)
                            setPrinciPalAddress(null)
                            setAllLocation([])
                            setAddressData(null)

                            setAddr1("")
                            setAddr2("")
                            setCity("")
                            setZip("")
                            // setCountryData(null)
                            setStates(null)
                            setStateValue(null)
                            setCountryValue(null)

                        }
                    }

                }
                else {
                    setPrinciPalAddress(null)
                    setAllLocation([])
                    setAddressData(null)

                    setAddr1("")
                    setAddr2("")
                    setCity("")
                    setZip("")
                    // setCountryData(null)
                    setStates(null)
                    setStateValue(null)
                    setCountryValue(null)

                }
            })
            .catch((err) => {
                console.log(err)
            })



    }, [dataId, addressUpdate, isLoading])

    console.log(data)

    // useEffect(() => {
    //     ContactService.getAllContactByCompanyId(dataId)
    //         .then((res) => {
    //             console.log(res)
    //             if (res.data !== "") {
    //                 setAllContacts(res.data)
    //             }

    //         })
    //         .catch((err) => {
    //             console.log(err)
    //         })
    // }, [dataId, data, isTabRefresh])

    console.log(allContacts)

    useEffect(() => {
        if (data) {
            setDataId(data.id)
            ContactService.getAllContactByCompanyId(data.id)
                .then((res) => {
                    console.log(res)
                    if (res.data !== "") {
                        setAllContacts(res.data)
                    }

                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }, [data, isTabRefresh])

    useEffect(() => {
        AddressService.getAllAddressType()
            .then((res) => {
                if (res.data !== undefined) {
                    if (res.data !== "") {

                        if (Array.isArray(res.data)) {
                            setAllAddressType(res.data)
                            console.log(res.data)
                            const temp = res.data.filter((val) => val.type === "PRINCIPAL")
                            if (temp.length > 0) {
                                setAddressType(temp[0])
                            }
                        }
                    }
                }


            })
            .catch((err) => {
                console.log(err)
            })

        rolesMasterService.getAllIndustryType()
            .then((res) => {
                console.log(res)
                if (res.data !== undefined) {
                    if (res.data !== "") {
                        console.log(res)
                        setAllIndustryType(res.data)
                    }
                }

            })
            .catch((err) => {
                console.log(err)
            })

        AddressService.getAllCountry()
            .then((res) => {
                console.log(res)
                if (res.data !== "") {
                    setAllCountries(res.data)
                }

            })
            .catch((err) => {
                console.log(err)
            })



        AddressService.getAllStates()
            .then((res) => {
                console.log(res)
                if (res.data !== "") {
                    setAllStates(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])



    console.log(dataId)








    const handleFilter = () => {
        console.log(products)
        if (products.length > 0 && filteredStateValue && !entityType) {
            console.log(products)
            const filteredContacts = products.filter(contact =>
                contact.serviceStateId === filteredStateValue
            );
            setProducts(filteredContacts)
            if (filteredContacts.length > 0) {
                setSelectedProduct(filteredContacts[0])
                setData(filteredContacts[0])
            }
            else {
                setProducts([])
                setData(null)
            }
        }
        if (products.length > 0 && entityType && !filteredStateValue) {
            const filteredContactsByRole = products.filter(contact => contact.entityType === entityType

            )
            console.log(filteredContactsByRole)

            setProducts(filteredContactsByRole)
            if (filteredContactsByRole.length > 0) {
                setSelectedProduct(filteredContactsByRole[0])
                setData(filteredContactsByRole[0])
            }
            else {
                setProducts([])
                setData(null)
            }
        }
        if (products.length > 0 && entityType && filteredStateValue) {
            const filteredContactsByRole = products.filter(contact =>
                contact.entityType === entityType && contact.serviceStateId === filteredStateValue
            )
            setProducts(filteredContactsByRole)
            if (filteredContactsByRole.length > 0) {
                setSelectedProduct(filteredContactsByRole[0])
                setData(filteredContactsByRole[0])
            }
            else {
                setProducts([])
                setData(null)
            }
        }
        if (filteredInput !== "") {
            const temp = products.filter((val) => {
                const fullName = `${val.companyName}`.toLowerCase();
                return fullName.includes(filteredInput.toLowerCase());
            });
            setProducts(temp);
            if (temp.length > 0) {
                setSelectedProduct(temp[0])
                setData(temp[0])
            }
            else {
                setProducts([])
                setData(null)
            }
        }
    }

    const handleSearch = () => {
        if (filteredInput !== "") {
            const lowerCaseInput = filteredInput.toLowerCase();

            const searchNestedObject = (obj) => {
                for (const key in obj) {
                    if (obj[key] !== null) {

                        if (typeof obj[key] === "object") {
                            if (searchNestedObject(obj[key])) {
                                return true;
                            }
                        }

                        else if (typeof obj[key] === "string" && obj[key].toLowerCase().includes(lowerCaseInput)) {
                            return true;
                        }

                        else if (typeof obj[key] === "number" && obj[key] == lowerCaseInput) {
                            return true;
                        }

                    }
                }
                return false;
            };


            const temp = tempProduct.filter((val) => searchNestedObject(val));
            setProducts(temp);
            if (temp.length > 0) {
                setSelectedProduct(temp[0])
                setData(temp[0])
            }
            else {
                setProducts([])
                setData(null)
            }
        }
        else {
            setProducts(tempProduct)
        }
    }


    const handleClearFilter = () => {
        setProducts(tempProduct)
        if (tempProduct.length > 0) {
            setSelectedProduct(tempProduct[0])
            setData(tempProduct[0])
        }
        setFilteredStateValue(null)
        setEntityType(null)
        setFilteredInput("")
    }


    const handleEmail = (e) => {
        setEmailErr('')
        const value = e.target.value
        if (value === "") {
            setEmailErr(errorMessages.emailRequired)
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
            setEmailErr(errorMessages.emailValidation);
        }
        setIngredient(e.target.value)
    }



    const handleEntityNumber = (e) => {
        setEntityNumberErr("")
        let value = e.target.value
        // if (value === '') {
        //     setEntityNumberErr("Entity number required")

        // }
        if (value && value.trim() !== '') {
            if (!/^\d+$/.test(value)) {
                setEntityNumberErr("Enter valid entity number")
            }

        }
        setEntityNumber(e.target.value)
    }

    const handleEINNumber = (e) => {
        setEINNumberErr("")
        let value = e.target.value
        // if (value === '') {
        //     setEINNumberErr("EIN number required")

        // }
        if (value && value.trim() !== '') {
            if (!/^\d{2}-\d{7}$/.test(value)) {
                setEINNumberErr("Enter valid EIN number ex: 45-1236362")
            }

        }
        setEINNumber(e.target.value)
    }

    const handleTaxYearEnd = (e) => {
        setTaxYearEndErr("")
        let value = e.target.value
        // if (value === '') {
        //     setTaxYearEndErr("Tax year required")

        // }
        if (value && value.trim() !== '') {
            if (!/^\d+$/.test(value)) {
                setTaxYearEndErr("Enter valid tax year end")
            }

        }
        setTaxYearEnd(e.target.value)
    }

    // const handleFax = (e) => {
    //     setFaxErr("")
    //     let value = e.target.value

    //     setFax(e.target.value)
    // }

    const handleFax = (e) => {
        setFaxErr("")
        let value = e.target.value
        
        let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
        if (inputValue.length > 10) {
            inputValue = inputValue.slice(0, 10); // Limit to 10 digits
        }
        const formattedValue = inputValue.replace(
            /(\d{3})(\d{3})(\d{4})/,
            (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
        );
        if (inputValue.length === 10) {
            // Only show formatted value after 10 digits
            e.target.value = formattedValue;
            setFax(formattedValue); // Update state with formatted number
        } else {
            e.target.value = inputValue;
            setFax(inputValue)
        }
       
        if (value === '') {
            setFaxErr(errorMessages.phoneRequired)
        }
        if (value && value.trim() !== "" && inputValue.length <=9) {
            if (!/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(value)) {
                setFaxErr(errorMessages.phoneValidation)
            }
        }
        setFax(e.target.value)
    }

    const handleWebsite = (e) => {

        let value = e.target.value
        setWebsite(e.target.value)
    }

    const handleFirstName = (e) => {
        setFirstNameErr("")
        let value = e.target.value
        if (value === '') {
            setFirstNameErr(errorMessages.firstNameRequired)

        }
        if (value && value.trim() !== '') {
            if (!/[A-Za-z]+$/.test(value)) {
                setFirstNameErr(errorMessages.firstNameValidation)
            }

        }
        setFirstName(e.target.value)
    }

    const handleZip = (e) => {
        setZipErr("")
        let value = e.target.value

        if (value && value.trim() !== '') {
            if (!/^\d+$/.test(value)) {
                setZipErr('Enter valid zip code')
            }

        }
        setZip(e.target.value)
    }


    const handleEdit = (data) => {
        setDataId(data.id)
        setIsEditing1(true);
    };

    const handleEdit1 = (data) => {
        console.log(data.id)

        setDataId(data.id)
        setIsEditing(true);
    };

    const handleEdit2 = (data) => {
        setDataId(data.id)
        setIsEditing2(true);
    };

    const handlePhone = (e) => {
        setPhoneErr("");
        let value = e.target.value;
    
        let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
        if (inputValue.length > 10) {
          inputValue = inputValue.slice(0, 10); // Limit to 10 digits
        }
        const formattedValue = inputValue.replace(
          /(\d{3})(\d{3})(\d{4})/,
          (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
        );
        if (inputValue.length === 10) {
          // Only show formatted value after 10 digits
          e.target.value = formattedValue;
          setPhone(formattedValue); // Update state with formatted number
        } else {
          e.target.value = inputValue;
          setPhone(inputValue);
        }
    
        if (value === "") {
          setPhoneErr(errorMessages.phoneRequired);
        }
        if (value && value.trim() !== "" && inputValue.length <=9) {
          if (
            !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(value)
          ) {
            setPhoneErr(errorMessages.phoneValidation);
          }      
        }
        setPhone(e.target.value);
      };

    const onCountryChange = (e) => {
        setSelectedCountry(e.value);

    };


    const handleOpenDate = (newValue) => {

        setOpenDateErr(''); // Clear previous errors
        const currentDate = dayjs(); // Get the current date
        if (newValue && dayjs(newValue).isValid()) {
            // setFormationDate(newValue);
            if (newValue !== null) {

                if (dayjs(newValue).isAfter(currentDate)) {
                    setOpenDateErr('Formation date cannot be in the future');
                    setFormationDateString(null);
                } else {
                    setFormationDateString(newValue)
                }


            }
            else {
                setFormationDateString(null);
                setOpenDateErr('Formation date required')
            }
        } else {
            setFormationDateString(dayjs(null)); // or handle the invalid case
        }
    }

    const handleDissolutionDate = (newValue) => {
        setDissolutionDateErr('')
        console.log(newValue)
        if (newValue && dayjs(newValue).isValid()) {
            // setFormationDate(newValue);
            if (newValue !== null) {
                setDissolutionDateString(newValue)
                if (newValue !== null) {
                    setDissolutionDate(newValue)
                    if (dayjs(formationDateString).isValid() && newValue) {
                        const isDissolutionDateAfterFormationDate = dayjs(newValue).isAfter(dayjs(formationDateString));

                        if (!isDissolutionDateAfterFormationDate) {
                            setDissolutionDateErr("Dissolution date must be greater than the formation date")
                        }
                    }

                }

            }

        } else {
            setDissolutionDateString(dayjs(null)); // or handle the invalid case
        }


    }

    const handleChange = (key, value) => {
        setEditValues({ ...editValues, [key]: value });
    };
    // const handleSave = () => {
    //     const newData = data.map((item) => ({
    //         ...item,
    //         value: editValues[item.key],
    //     }));
    //     setData(newData);
    //     setIsEditing(false);
    // };

    const handleSave3 = () => {
        console.log(addressData)
        if (addressData) {
            handleAddressEdit()
        }
        else {
            handleAddressSave()
        }
    }



    const handleAddressEdit = () => {
        if (addressData) {
            const actualdata = addressData
            actualdata["addressLine1"] = addr1
            actualdata["addressLine2"] = addr2
            actualdata["city"] = city
            actualdata["stateId"] = states
            actualdata["countryId"] = countryData
            actualdata["postalCode"] = zip
            actualdata["companyDetails"] = data
            actualdata["addressType"] = addressType



            if (addr1 !== "" && city !== "" && zip !== "" && data && addressType) {
                setIsLoading(true)
                AddressService.updateAllAddress(actualdata)
                    .then((res) => {
                        console.log(res.data)
                        setIsEditing1(false)
                        setIsLoading(false)
                        if (toast.current !== undefined) {
                            toast.current.show({
                                severity: 'success', summary: 'Address updated successfully', life: 3000

                            })
                        }

                    })
                    .catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                        if (toast.current !== undefined) {
                            toast.current.show({
                                severity: 'warn', summary: 'Something went wrong', life: 3000

                            })
                        }
                    })
            } else {
                if (addr1 === "") {
                    setAddr1Err(errorMessages.address1Required)
                }
                // if (addr2 === "") {
                //     setAddr2Err(errorMessages.address2Required)
                // }
                if (city === "") {
                    setcityErr(errorMessages.cityRequired)
                }
                if (zip === "") {
                    setZipErr(errorMessages.zipRequired)
                }
                if (!data) {
                    setCompanyErr(errorMessages.companyRequired)
                }

                if (!addressType) {
                    setAddressTypeErr(errorMessages.addressTypeRequired)
                }

                if (!states) {
                    setStatesErr(errorMessages.statesRequired)
                }

                if (!countryData) {
                    setCountryErr(errorMessages.countryRequired)
                }
            }

        }


    }

    const handleAddressSave = () => {
        const actualdata = {
            "addressLine1": addr1,
            "addressLine2": addr2,
            "city": city,
            "stateId": states,
            "postalCode": zip,
            "countryId": countryData,
            "contactDetails": null,
            "companyDetails": data,
            "addressType": addressType
        }

        console.log(actualdata)

        if (addr1 !== "" && city !== "" && zip !== "" && data && addressType && countryData && states) {
            setIsLoading(true)
            AddressService.saveAllAddress(actualdata)
                .then((res) => {
                    console.log(res)
                    setIsEditing1(false)
                    setIsLoading(false)
                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                })
        } else {
            if (addr1 === "") {
                setAddr1Err(errorMessages.address1Required)
            }
            // if (addr2 === "") {
            //     setAddr2Err(errorMessages.address2Required)
            // }
            if (city === "") {
                setcityErr(errorMessages.cityRequired)
            }
            if (zip === "") {
                setZipErr(errorMessages.zipRequired)
            }

            if (!data) {
                setCompanyErr(errorMessages.companyRequired)
            }

            if (!addressType) {
                setAddressTypeErr(errorMessages.addressTypeRequired)
            }

            if (!states) {
                setStatesErr(errorMessages.statesRequired)
            }

            if (!countryData) {
                setCountryErr(errorMessages.countryRequired)
            }
        }

    }

    const handleSave = () => {

        console.log(EINNumber)



        let d = new Date(formationDateString);

        let day = d.getDate();
        let month = d.getMonth() + 1;
        let year = d.getFullYear();


        if (day < 10) {
            day = '0' + day;
        }
        if (month < 10) {
            month = `0${month}`;
        }
        else {
            month = month + 1
        }

        let newDate = year + "-" + month + "-" + day

        let openDate = new Date(dissolutionDateString);
        console.log(openDate)


        let dayOpen = openDate.getDate();
        let monthOpen = openDate.getMonth() + 1;
        let yearOpen = openDate.getFullYear();
        console.log(dayOpen)
        if (dayOpen < 10) {
            dayOpen = '0' + dayOpen;
        }
        if (monthOpen < 10) {
            monthOpen = `0${monthOpen}`;
        }



        let newOpenDate = yearOpen + "-" + monthOpen + "-" + dayOpen


        if (data && dataId) {
            if (companyName !== "" && businessStructure && serviceState && domesticState && industryType && dissolutionDateErr === "" && EINNumberErr === ""
                && dayjs(formationDateString).isValid() && (DissolutionDate ? dayjs(DissolutionDate).isValid() : true)
            ) {
                const actualdata = data
                console.log(data)
                actualdata["companyName"] = companyName
                actualdata["entityType"] = businessStructure ? businessStructure : null

                actualdata["serviceStateId"] = serviceState
                actualdata["domesticStateId"] = domesticState
                actualdata["industryId"] = industryType
                actualdata["address"] = principalAddress
                actualdata["phoneNo"] = phone !== "" ? `${selectedCountry} ${phone}` : null
                actualdata["companyEmail"] = ingredient

                actualdata["fax"] = fax !== "" ? `${selectedCountry} ${fax}` : null
                actualdata["websiteUrl"] = website
                actualdata["formationDate"] = formationDateString.isValid() ? formationDateString.toISOString() : null
                actualdata["dissolutionDate"] = dissolutionDateString.isValid() ? dissolutionDateString.toISOString() : null
                actualdata["einNo"] = EINNumber
                actualdata["entityNo"] = entityNumber
                actualdata["taxYearEnd"] = texYearEnd
                setIsLoading(true)
                companyMasterService.updateCompany(actualdata)
                    .then((res) => {
                        console.log(res)
                        setIsEditing(false)
                        // setPageRefresh(!pageRefresh)
                        setIsLoading(false)
                        if (toast.current !== undefined) {
                            toast.current.show({
                                severity: 'success', summary: 'Company Info updated successfully', life: 3000,
                                content: (props) => (
                                    <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                                        <div className="flex align-items-center gap-2">
                                            {/* <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }}>
                                                <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                                            </Avatar> */}
                                            <span className="p-toast-summary">vState Filings</span>
                                        </div>
                                        <div className="p-toast-detail">Your comapny updated successfully.
                                           </div>
                                        {/* <Button className=" signup-btn vstate-button flex" label="Ok" severity="success" onClick={() => navigate("/signin")}></Button> */}
                                    </div>
                                )
                            });
                        }

                    })
                    .catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                        if (toast.current !== undefined) {
                            toast.current.show({
                                severity: 'warn', summary: 'Something went wrong', life: 3000

                            })
                        }
                    })
            }
            else {
                // Validate all required fields
                if (companyName === "" && (!companyName)) {
                    setCompanyErr("Legal name required");
                }
                if (!businessStructure) {
                    setBusinessStructureErr("Business structure required");
                }
                if (!serviceState) {
                    setServiceStateErr("Service state required");
                }
                if (!domesticState) {
                    setDomesticStateErr("Domestic state required");
                }
                if (!industryType) {
                    setIndustryTypeErr("Industry type required");
                }
                if (ingredient === "" || (!ingredient)) {  // Check if email (ingredient) is empty
                    setEmailErr("Email required");  // Set email error message
                }
                if (phone === "" || (!phone)) {
                    setPhoneErr("Phone required");
                }
                // if (EINNumber === "" || (!EINNumber)) {
                //     setEINNumberErr("EIN number required");
                // }
                // if (entityNumber === "" || (!entityNumber)) {
                //     setEntityNumberErr("Entity number required");
                // }
                // if (texYearEnd === "" || (!texYearEnd)) {
                //     setTaxYearEndErr("Tax year end required");
                // }
                if (!(dayjs(formationDateString).isValid())) {
                    setOpenDateErr("Formation date required")
                }
                if (DissolutionDate && !dayjs(DissolutionDate).isValid()) {
                    setDissolutionDateErr("Invalid dissolution date");
                }
                // if (!principalAddress) {
                //     if (toast.current !== undefined) {
                //         toast.current.show({
                //             severity: 'warn', summary: 'Principal address required', life: 3000
                //         });
                //     }
                // }
            }

        }

    }

    const handleDeleteAccountManager = () => {
        if (data) {
            const actualdata = data
            actualdata["address"] = principalAddress
            actualdata["accountManagerId"] = null
            setIsLoading(true)
            companyMasterService.updateCompany(actualdata)
                .then((res) => {
                    console.log(res)
                    setIsEditing(false)
                    // setPageRefresh(!pageRefresh)
                    setCloseProgresbar3(false)
                    setIsLoading(false)
                    if (toast.current !== undefined) {
                        toast.current.show({
                            severity: 'success', summary: 'Account manager removed successfully', life: 3000,
                            content: (props) => (
                                <div className="flex flex-column align-items-left" style={{ flex: '1' }}>
                                    <div className="flex align-items-center gap-2">
                                        {/* <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }}>
                                    <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                                </Avatar> */}
                                        <span className="p-toast-summary">vState Filings</span>
                                    </div>
                                    <div className="p-toast-detail">{props.message.summary}</div>
                                    {/* <Button className=" signup-btn vstate-button flex" label="Ok" severity="success" onClick={() => navigate("/signin")}></Button> */}
                                </div>
                            )
                        });
                    }

                })
                .catch((err) => {
                    console.log(err)
                    setIsLoading(false)
                    if (toast.current !== undefined) {
                        toast.current.show({
                            severity: 'warn', summary: 'Something went wrong', life: 3000

                        })
                    }
                })
        }
    }

    console.log(data)

    const handleSave2 = () => {
        console.log(ingredient)

        if (data && dataId) {
            const actualdata = data

            actualdata["phoneNo"] = phone !== "" ? `${selectedCountry} ${phone}` : null
            actualdata["companyEmail"] = ingredient

            actualdata["fax"] = fax !== "" ? `${selectedCountry} ${fax}` : null
            actualdata["websiteUrl"] = website
            actualdata["address"] = principalAddress





            if (phone !== "" && ingredient !== "") {
                setIsLoading(true)
                companyMasterService.updateCompany(actualdata)
                    .then((res) => {
                        console.log(res)
                        setIsEditing2(false)
                        setPageRefresh(!pageRefresh)
                        setIsLoading(false)


                    })
                    .catch((err) => {
                        console.log(err)
                        setIsLoading(false)
                    })
            }
            else {
                if (ingredient === "") {
                    console.log(errorMessages.emailRequired)
                    setEmailErr(errorMessages.emailRequired)
                }
                if (phone === "") {
                    setPhoneErr(errorMessages.phoneRequired)
                }
            }

        }

    }

    const handleToggle = () => {
        setIsShowContent(!isShowContent)
    }

    const handleToggle1 = () => {
        setIsShowContent1(!isShowContent1)
    }

    const handleToggle2 = () => {
        setIsShowContent2(!isShowContent2)
    }

    const handleToggle3 = () => {
        setIsShowContent3(!isShowContent3)
    }



    const sortData = (field) => {
        if (field === sortField) {
            // If clicking on the same field, toggle the sort order
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            // If clicking on a different field, set the new field and default to ascending order
            setSortField(field);
            setSortOrder('asc');
        }
        const sortedData = [...products].sort((a, b) => {
            console.log(a)
            if (a.candidateDetails !== undefined && b.candidateDetails !== undefined) {
                const valueA = a.candidateDetails[field];
                const valueB = b.candidateDetails[field];

                if (valueA < valueB) {
                    return sortOrder === 'asc' ? -1 : 1;
                }
                if (valueA > valueB) {
                    return sortOrder === 'asc' ? 1 : -1;
                }
            }

            return 0;

        });
        console.log(sortedData)

        setProducts(sortedData);
    };










    const handleFbackHeader = () => {
        return <>

            <div className='font-fam-for-all form-md-heading form-sm-close-Btn' >Pre-Screening Feedback
                <button className='form-close-Btn' onClick={() => setVisible6(false)}>
                    <i className='pi pi-times'>
                    </i>
                </button>
            </div>

        </>
    }


    const handleCity = (e) => {
        setEmailErr("")
        const value = e.target.value
        if (value === "") {
            setEmailErr(errorMessages.emailRequired)
        }
        if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(value)) {
            setEmailErr(errorMessages.emailValidation);
        }

        setIngredient(e.target.value)
    }

    const handleSkill = (e) => {

        setSkills(e.target.value)
    }

    const handleQualification = (e) => {

        setQualification(e.target.value)
    }

    const PasswordText = (text) => {
        const maskedText = text.replace(/./g, '*');
        return maskedText;
    };

    const handleSave1 = () => {
        const newData = loginDetails.map((item) => ({
            ...item,
            value: editLoginValues[item.key],
        }));
        setLoginDetails(newData);
        setIsEditing(false);
    };

    const handleHr = (e) => {
        setHrPanel(e.target.value)

        const newD = e.target.value

        const tempHr = selectedPanelList.filter((val) => {
            return newD.some((ele) => {
                return ele.fullName === val.employee.fullName
            })
        })
        console.log(tempHr)
        if (tempHr.length > 0) {
            setSelectedPanelData(tempHr)
        }

    }



    const LocationFilter = () => {
        return (
            <>
                <div>

                    <MultiSelect
                        value={city}
                        options={allCities}
                        onChange={handleCity}
                        optionLabel="cityName"
                        placeholder="Select Job Location"
                        display="chip"
                        filter
                        className='filter-dropdown-field' required />
                </div>
            </>
        )

    }

    const SkillFilter = () => {
        return (
            <>
                <div>

                    <MultiSelect
                        value={skills}
                        options={skillARR}
                        onChange={handleSkill}
                        optionLabel="skillName"
                        placeholder="Select Skill"
                        display="chip"
                        filter
                        className='filter-dropdown-field' required />
                </div>
            </>
        )

    }

    const QualificationFilter = () => {
        return (
            <>
                <div>

                    <MultiSelect
                        value={qualification}
                        options={qualificationArr}
                        onChange={handleQualification}
                        optionLabel="name"
                        placeholder="Select Qualification"
                        display="chip"
                        filter
                        className='filter-dropdown-field' required />
                </div>
            </>
        )

    }

    const allSortName = ["name", "location", "experienc"]

    const handleSortName = (e) => {
        setSortName(e.target.value)
        console.log(e.target.value)
        sortData(e.target.value)
    }

    const handlePosition = (e) => {
        const temp = e.target.value
        setPosition(e.target.value)
        if (temp.jobdescription.skill !== null) {
            setSkills(temp.jobdescription.skill)
        }
        if (temp.jobdescription.experience !== null) {
            const tempexp = temp.jobdescription.experience
            const arrexp = tempexp.split("-")
            console.log(arrexp)
            if (arrexp.length === 2) {
                setExp1(arrexp[0])
                setExp2(arrexp[1])
            }
        }


    }

    const handleClearPosition = () => {
        setVisible1(false)
        setVisible2(false)
        setPosition(null)
        setPositionData(null)
    }

    const handlePositionData = (e) => {
        setProducts(AllCandidate)
        const temp = e.target.value
        setPositionData(e.target.value)
    }

    const SortingFilter = () => {
        return (
            <>
                <div>
                    <Dropdown
                        value={sortName}
                        options={allSortName}
                        onChange={handleSortName}
                        placeholder="Sorting By"
                        filter
                        className='filter-dropdown-field' required />
                </div>
            </>
        )
    }

    console.log(position)

    let skill;
    if (position !== null) {
        if (position.jobdescription.skill !== null) {
            skill = position.jobdescription.skill.map((val) => {
                return val.skillName
            })
        }
    }

    const PositionFilter = () => {
        let skill;
        if (position !== null) {
            if (position.jobdescription.skill !== null) {
                skill = position.jobdescription.skill.map((val) => {
                    return val.skillName
                })
            }
        }
        return (
            <>
                <div className='mt-2 ml-3'>
                    <Dropdown
                        value={position}
                        options={allPosition}
                        onChange={handlePosition}
                        optionLabel='name'
                        placeholder="Select Position"
                        filter
                        className='filter-input-field1' required />

                </div>
                {position && <> <div className='grid ml-2 mt-2'>
                    <div className='col-5'>
                        Skills :
                    </div>
                    <div className='col-7'>
                        {skill !== undefined ? skill.toString() : ''}

                    </div>
                </div>

                    <div className='grid ml-2 mt-2'>
                        <div className='col-5'>
                            Experience :
                        </div>
                        <div className='col-7'>
                            {position !== null ? position.jobdescription.experience !== null ? `${position.jobdescription.experience} Years` : '' : ''}

                        </div>
                    </div></>}

            </>
        )
    }


    const handleExp1 = (e) => {
        setProducts(AllCandidate)
        setExp1(e.target.value)
    }

    const handleExp2 = (e) => {
        setProducts(AllCandidate)
        setExp2(e.target.value)
    }

    const listExp = []
    for (let i = 0; i < 50; i++) {
        listExp.push(`${i}`)
    }


    console.log(filters)



    const ExperinceFilter = () => {


        return (
            <>
                <label htmlFor='' className='form-label font-fam-for-all' style={{ marginLeft: '16px' }}>Experience <span className='form-field-mandatory'>*</span></label>
                <div className='grid'>


                    <div className="col-3 ml-3">
                        <div className="field">



                            <span className="p-float-label ">
                                <Dropdown
                                    className='filter-input-field2' filter
                                    value={exp1} onChange={handleExp1} options={listExp} name='name'
                                />

                                {/* <InputText
                                    className='filter-input-field2'
                                    value={exp1} onChange={handleExp1} name='name'
                                /> */}
                            </span>

                        </div>
                    </div>

                    <div className='col-2 p-2'>
                        <p className='mt-2 text-center'>To</p>

                    </div>



                    <div className="col-3 pl-0">
                        <div className="field">

                            {/* <label htmlFor='' className='form-label font-fam-for-all'>Highest Exp. <span className='form-field-mandatory'>*</span></label> */}
                            <span className="p-float-label ">

                                <Dropdown
                                    className='filter-input-field2' filter
                                    value={exp2} onChange={handleExp2} options={listExp} name='name'
                                />

                                {/* <InputText
                                    className='filter-input-field2'
                                    value={exp2} onChange={handleExp2} name='name'
                                /> */}
                            </span>

                        </div>
                    </div>
                    <div className='col-1'>

                    </div>

                </div>
            </>
        )
    }

    const ExpandedComponent = ({ item }) => {
        return (
            <div className='p-2' style={{ borderTop: '1px solid #ddd' }}>
                <p>{item.details}</p>
                {/* Add your expanded component content here */}
                {item.id === 1 ? <LocationFilter /> : ''}
            </div>
        );
    };



    useEffect(() => {
        setProducts(AllCandidate)
        // handleRemoveSearch()
        console.log('first')
        if (filterChipData.length === 0) {
            setProducts(AllCandidate)
        }

    }, [filterChipData])

    console.log(products)



    const filterData = (event) => {
        const filteredItems = products.filter((item) => {
            for (const key in item) {
                if (item.hasOwnProperty(key)) {
                    const element = (item[key]).toString();
                    console.log(element)

                    if (element.toLowerCase().includes(event.target.value.toLowerCase())) {
                        return item;
                    }


                }
            }

        });
        setFilteredData(filteredItems);
    };


    // effect of filter all

    function checkAllExist(a, b) {
        // Convert all elements of array b to lowercase for case-insensitive comparison
        const lowercaseB = b.map(item => item.toLowerCase());

        for (const element of a) {
            // Convert element to lowercase for case-insensitive comparison
            const lowercaseElement = element.toLowerCase();

            if (!lowercaseB.includes(lowercaseElement)) {
                return false; // If any element of a is not found in b, return false
            }
        }
        return true; // All elements of a were found in b
    }


    function convertMonthsToYears(years) {
        return years * 12;
    }







    const getSeverity = (product) => {
        switch (product.inventoryStatus) {
            case 'INSTOCK':
                return 'success';

            case 'LOWSTOCK':
                return 'warning';

            case 'OUTOFSTOCK':
                return 'danger';

            default:
                return null;
        }
    };

    // event on checkbox
    const onCheckboxChange = (event, data) => {
        console.log(event.checked)
        console.log(data)
        if (event.checked) {
            setSelectedData([...selectedData, data]);
        } else {
            setSelectedData(selectedData.filter(item => item !== data));
        }
    };

    const handleChecked = (e) => {
        const checked = e.checked;
        if (checked) {
            setSelectedData(products)
        }
        else {
            setSelectedData([])
        }
    }

    console.log(selectedData)

    // DataTable columns
    const cols = [
        { field: 'id', header: 'id' },
        { field: 'fullName', header: 'Name' },
        { field: 'skill', header: 'Skills' },
        { field: 'experienceInYears', header: 'Experience' },
        { field: 'city', header: 'Location' },


    ];



    const updateData = selectedData.map((elem) => {

    })



    const updateDataDownload = updateData.map((elem) => {

    })

    console.log(updateDataDownload)

    // Export DataTable data
    const exportColumns = cols.map((col) => ({ title: col.header, dataKey: col.field }));
    // const exportCSV = (selectionOnly) => {
    //     dt.current.exportCSV({ selectionOnly });
    // };

    const exportPdf = () => {
        import('jspdf').then((jsPDF) => {
            import('jspdf-autotable').then(() => {
                const doc = new jsPDF.default(0, 0);

                doc.autoTable(exportColumns, updateDataDownload);
                doc.save('Candidate.pdf');
            });
        });
    };

    const exportExcel = () => {
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(updateDataDownload);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Candidate');
        });
    };

    const exportExcelPreScreening = (data) => {
        console.log(data)
        const updatedata = data.map((elem) => {
            const { candidateId, positionId, interviewLevelId, ...rest } = elem

            delete rest["createdBy"]
            delete rest["creationDate"]
            delete rest["lastModifiedDate"]
            delete rest["lastModifiedBy"]
            delete rest["id"]
            rest['panelDetails'] = (elem.panelDetails.map((e) => {
                return e.employee.fullName
            })).toString()
            if (typeof (elem.detailFeedback) === "object") {

                rest['location'] = elem.detailFeedback !== null ? elem.detailFeedback.location : null
                rest['preferLocation'] = elem.detailFeedback !== null ? elem.detailFeedback.preferLocation : null
                rest['domain'] = elem.detailFeedback !== null ? elem.detailFeedback.domain : null
                rest['Gap In Experience'] = elem.detailFeedback !== null ? elem.detailFeedback.gapExp : null
                rest['Communication'] = elem.detailFeedback !== null ? elem.detailFeedback.communication : null
                rest['Remarks'] = elem.detailFeedback !== null ? elem.detailFeedback.remarks : null
                rest['certifiaction'] = elem.detailFeedback !== null ? elem.detailFeedback.certification : null
            }
            if (Array.isArray(elem.technicalFeedback)) {
                elem.technicalFeedback.map((val) => {
                    rest[val.label] = val.value !== null ? val.value : null
                })
            }
            rest['candidateId'] = elem.candidateId !== null ? elem.candidateId.fullName : null
            rest['positionId'] = elem.positionId !== null ? elem.positionId.name : null
            rest['interviewLevelId'] = elem.interviewLevelId !== null ? elem.interviewLevelId.levelName : null
            return {
                candidateId,
                positionId, interviewLevelId,
                ...rest

            }
        })

        console.log(updatedata)
        import('xlsx').then((xlsx) => {
            const worksheet = xlsx.utils.json_to_sheet(updatedata);
            const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
            const excelBuffer = xlsx.write(workbook, {
                bookType: 'xlsx',
                type: 'array'
            });

            saveAsExcelFile(excelBuffer, 'Pre-Screening-details');
        });
    }
    const saveAsExcelFile = (buffer, fileName) => {
        import('file-saver').then((module) => {
            if (module && module.default) {
                let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
                let EXCEL_EXTENSION = '.xlsx';
                const data = new Blob([buffer], {
                    type: EXCEL_TYPE
                });

                module.default.saveAs(data, fileName + EXCEL_EXTENSION);
            }
        });
    };




    console.log(selectedPanelData)



    const CloseProgresbarheader1 = () => {
        setCloseProgresbar1(false)
    }


    const handleClick3 = () => {
        setVisible(false)
    }

    const handleProfile = (data) => {
        setVisible2(true)
        console.log(data)
        setCandidate(data)
    }

    const handleMoreClick = (data) => {
        // const tgl = document.getElementById("Togle-btn")
        // if (tgl.textContent === "More Details") {
        //     setVisibleContent(true)
        // }
        // if (tgl.textContent === "Hide Details") {
        //     setVisibleContent(false)
        // }

        // Get the button and content element
        const toggleButton = document.getElementById(`toggleButton${data.candidateDetails.id}`);
        const content = document.getElementById(`content${data.candidateDetails.id}`);
        // Toggle the 'hidden' class on the content element
        content.classList.toggle('hidden');

        // Change button text based on content visibility
        if (content.classList.contains('hidden')) {
            toggleButton.textContent = '(More Details)';
        } else {
            toggleButton.textContent = '(Hide Details)';
        }


    }

    console.log(data)




    // Function to check if object exists in array
    function objectExists(array, obj) {
        return array.some(item => JSON.stringify(item) === JSON.stringify(obj));
    }


    const getInitials = (name) => {
        const nameArray = name.trim().split(" ");
        if (nameArray.length >= 2) {
            const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
            const lastNameInitial = nameArray[1].charAt(0).toUpperCase();
            return firstNameInitial;
        } else {

            const initial = nameArray[0].charAt(0).toUpperCase();
            return initial + initial;
        }
    };




    const itemTemplate = (data) => {

        console.log(data)
        console.log(selectedData)

        const states = allStates.find((val) => val.id === data.domesticStateId)
        console.log(states)


        const fullName = `${data.companyName}`

        return (
            <div className='flex justify-between'>
                <div className="srch-cand-card font-fam-for-all justify-content-start px-3">

                    <div className='srch-cand-checkbox'>
                        {
                            superAdminMainId &&
                            <Checkbox
                                onChange={(e) => onCheckboxChange(e, data)}
                                checked={selectedData.includes(data)}
                            ></Checkbox>
                        }
                    </div>
                    <Avatar label={data.avatarImgPath ? '' : getInitials(fullName)} image={data.avatarImgPath ? data.avatarImgPath : ''} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />
                    <div className='grid w-[200px]' >
                        <div className='col-12 pb-0' >
                            <p className=' srch-cand-text1 text-wrap' >{`${data.companyName}`}</p>
                        </div>
                        <div className='col-12 pb-0'>
                            <p className=' srch-cand-text' >{(data.entityType == 1) ? "LLC" : "CORP"}</p>
                        </div>
                    </div>
                </div>
                <div className='badge-container'>
                    <div className='badge-main grid' style={states !== undefined ? { background: '#ECFDF3' } : { background: '#ffff' }}>
                        <div className="col-12 p-0">
                            <div className='badge-label ml-1' style={{ color: '#027A48' }}>{states !== undefined ? states.stateFullDesc : ""}</div>
                        </div>

                    </div>
                </div>
            </div>
        );
    };






    const CloseProgresbarheader = () => {
        setCloseProgresbar(false)
    }


    const searchCandHeader = () => {
        return (
            <div className='font-fam-for-all flex flex-column gap-3'>

                <p className='srch-cand-header table-cand mb-0'>Companies  <Tag className='badge-cand' value={filteredData.length > 0 ? filterData.length : products.length}></Tag></p>



                <div className='card-devider'></div>

                <div className="srch-cand-card justify-between font-fam-for-all px-4" >
                    {
                        superAdminMainId && <>
                            <Checkbox className='mb-1'
                                checked={selectedData.length === products.length}
                                onChange={handleChecked}
                            ></Checkbox>

                            <div >
                                <button style={{ backgroundColor: '#ffff' }} disabled={selectedData.length === 0} className='table-cand' onClick={() => setVisible4(true)}> <i className='pi pi-paperclip ml-3'></i> Assign Account Manager</button>
                            </div>
                        </>
                    }


                </div>
            </div>
        )
    }

    const onRowSelect = (rowData) => {
        setData(rowData.data)
        setIsEditing(false)
        setIsEditing1(false)
        setIsEditing2(false)
        console.log(rowData)

    }

    console.log(data)

    const sidebarHeader = () => (
        <>
            <div style={{ backgroundColor: '#002592', width: 'contentFit', height: '40px', position: 'sticky' }} className="sidebar-head">
                <Button className="pi pi-times sidebar-close-btn" onClick={() => setFilters(false)}></Button>
            </div>
        </>
    )

    const items = Array.from({ length: 5 }, (v, i) => i);


    console.log(data)

    useEffect(() => {
        if (fback.length > 0) {
            const temp = fback.filter((val) => val.interviewLevelId.preference === 1)
            if (temp.length > 0) {

                setCandidateFback(temp[0])
                setFeedbackData(temp)

            }
        }
    }, [fback])

    const handleTabChange = (e) => {
        setActiveIndex(e.index)
        setAddressUpdate(!addressUpdate)
        setIsEditing(false)
        setIsEditing1(false)
        setIsEditing2(false)
    }




    const getIndustry = (id) => {
        const temp = allIndustryType.find((val) => val.id === id)
        if (temp !== undefined) {
            return temp.industryName;
        }
        else {
            return null
        }
    }

    function capitalizeFirstLetter(string) {
        if (!string) return ''; // handle empty or undefined strings

        return string
            .toLowerCase()
            .split(' ')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ');
    }

    const handleRedirectData = (data) => {
        setRedirectedData(data)
        history("/employee")
    }



    useEffect(() => {
        const fetchAvatars = async () => {
            const newAvatars = {};
            for (const item of allContacts) {
                if (item.contactDetails?.avatarImgPath) {
                    try {
                        const res = await MasterServices.getFile(item.contactDetails.avatarImgPath);
                        newAvatars[item.contactDetails.id] = res.data;
                    } catch (error) {
                        console.error(`Error fetching avatar for ${item.contactDetails.id}:`, error);
                    }
                }
            }
            setAvatars(newAvatars);
        };

        fetchAvatars();
    }, [allContacts]);






    return (
        <>

            <Toast ref={toast} />

            <div className='grid webapp-content-container'>
                <div className='col-12 '>

                    <div className='card search-cand-card2'>

                        <div className='col-12 grid font-fam-for-all py-3 px-3'>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-7' style={{ paddingLeft: '20px' }}>
                                <p className='search-cand-header mb-0 company1 user_mycompany'>My Company</p>
                                <p className='search-cand-sub-header mb-0'>Manage your company here including updates to key information.</p>
                            </div>
                            <div className='col-12 col-sm-12 col-md-12 col-lg-5'>
                            </div>
                        </div>

                        {/* <div className="col-12 " style={{ padding: '0px', height: '60px' }}>
                    <p className='panel-headers font-fam-for-all datatable-sm d-flex align-items-center justify-content-center'>Search Candidate</p>
                </div> */}

                        <div className='card-devider'>

                        </div>




                        <div style={{ padding: '30px' }}>

                            <div className='grid' >


                                {
                                    data !== null ? <div className='col-12 pt-0' >
                                        <div className='vstate-card mb-4 company-card-content-header-card'>
                                            <div className='company-card-content-header-card-container'>
                                                <div className='company-card-content-header-card-main'>
                                                    <div className='company-card-content-header-card-main-x'>
                                                        <div className='company-card-content-header-card-main-text'>
                                                            <div className='srch-cand-text2'>
                                                                Welcome To Your Company’s Profile
                                                            </div>
                                                            <div className='srch-cand-text'>
                                                                Update contact information, office or branch locations, upload company records, add key personnel and manage services or subscriptions associated to your company.
                                                            </div>

                                                        </div>
                                                        <div className='devider-y'>

                                                        </div>
                                                    </div>
                                                    <div className='company-card-content-header-card-main-x'>
                                                        <div className='company-card-content-header-card-main-text'>
                                                            <div className='srch-cand-text2'>
                                                                Update missing or outdated information.
                                                            </div>
                                                            <div className='srch-cand-text'>
                                                                If we need more details for your filings, we'll notify you promptly.
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='vstate-card col-12'>


                                            <div className='col-12 srch-cand-card'>
                                                {/* <Avatar className='ml-3 custom-avatar' label={data.avatarImgPath ? '' : getInitials(`${data.companyName}`)} image={data.avatarImgPath ? data.avatarImgPath : ''} size="large" shape="circle" /> */}

                                                <div className='col-11 grid pb-0' >
                                                    <div className='col-8'>
                                                        <div className='flex'>
                                                            <p className=' srch-cand-text2 mb-0' >{`${data.companyName}`}</p>
                                                            <div className='badge-container ml-4'>
                                                                <div className='badge-main grid' style={getState(data.domesticStateId) ? { background: '#ECFDF3' } : { background: '#ffff' }}>
                                                                    <div className="col-12 p-0">

                                                                        <span className='badge-label ml-1' style={{ color: '#027A48' }}>{getState(data.domesticStateId)}</span>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p className=' srch-cand-text' > {`${data.entityType ? data.entityType == 1 ? "Limited Liability Company |" : "Corporation |" : ""} Member Managed -`}
                                                            <button className='datatable-highlight-color' disabled={data.accountManagerId ? !superAdminId ? true : false : true} onClick={() => handleRedirectData(data.accountManagerId)}>
                                                                {data.accountManagerId ? `${data.accountManagerId.firstName} ${data.accountManagerId.lastName}` : "vState Filings"}
                                                            </button></p>
                                                    </div>
                                                    <div className='col-4 d-flex justify-content-end align-items-center'>

                                                        {/* <Button className='font-fam-for-all vstate-reverse-button'>Manage Permission</Button> */}
                                                        {/* <i className='pi pi-plus'></i> */}
                                                    </div>
                                                </div>




                                            </div>

                                            <div className='card-devider'>
                                            </div>
                                            <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>


                                                <TabPanel header="Details">
                                                    <div className='col-12 ' style={{ backgroundColor: '#fbfcfa', padding: '15px' }}>


                                                        <div className="font-fam-for-all">

                                                            <div>
                                                                {
                                                                    isEditing ? <div className='card my-3 slide-in'>
                                                                        <div className='p-4'>
                                                                            <h2 className='srch-cand-text2'>Company Details</h2>

                                                                        </div>


                                                                        <div className="grid  py-2 px-4">


                                                                            <div className='col-4' style={{ fontSize: "14px" }}>
                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <InputText
                                                                                            style={{ height: '40px', width: "100%" }}
                                                                                            maxLength={50}
                                                                                            value={companyName}
                                                                                            id="floating_outlined3"
                                                                                            placeholder=''
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                                            onChange={(e) => setCompanyName(e.target.value)}
                                                                                            name="cityName"
                                                                                        />

                                                                                        <label
                                                                                            htmlFor="floating_outlined3"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Entity Name<span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {companyErr !== "" ? <p className="error-msg font-fam-for-all">{companyErr}</p> : ""}

                                                                                </span>
                                                                            </div>
                                                                            <div className='col-4' style={{ fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <Dropdown value={businessStructure} onChange={(e) => {
                                                                                            setBusinessStructure(e.value)
                                                                                            setBusinessStructureErr("")
                                                                                        }

                                                                                        } options={entityTypes} optionValue='id' placeholder="Select Buisness Structure" style={{ height: "40px" }}
                                                                                            className="w-full"
                                                                                            filter

                                                                                        />
                                                                                        <label htmlFor="floating_outlined25"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Business Structure <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {businessStructureErr !== "" ? <p className="error-msg font-fam-for-all">{businessStructureErr}</p> : ""}
                                                                                </span>

                                                                            </div>


                                                                            <div className='col-4' style={{ fontSize: "14px" }}>
                                                                                {/* <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}> */}

                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                                                            <DatePicker
                                                                                                value={formationDateString}
                                                                                                onChange={handleOpenDate}


                                                                                                disableFuture
                                                                                                slotProps={{
                                                                                                    textField: {
                                                                                                        size: 'small',
                                                                                                        error: false,
                                                                                                        fullWidth: true
                                                                                                    },
                                                                                                }}

                                                                                                className='dialog-form-dropdown-field'

                                                                                            />

                                                                                        </LocalizationProvider>
                                                                                        <label htmlFor="floating_outlined10"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Formation Date <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {openDateErr !== "" ? <p className="error-msg font-fam-for-all">{openDateErr}</p> : ""}
                                                                                </span>
                                                                                {/* </div> */}
                                                                            </div>
                                                                            <div className='col-4 ' style={{ fontSize: "14px" }}>
                                                                                {/* <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}> */}

                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>

                                                                                            <DatePicker
                                                                                                value={dissolutionDateString}
                                                                                                onChange={handleDissolutionDate}
                                                                                                required

                                                                                                slotProps={{
                                                                                                    textField: {
                                                                                                        size: 'small',
                                                                                                        error: false,
                                                                                                        fullWidth: true
                                                                                                    },
                                                                                                }}

                                                                                                className='dialog-form-dropdown-field'

                                                                                            />

                                                                                        </LocalizationProvider>
                                                                                        <label htmlFor="floating_outlined11"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Dissolution Date <span className="form-field-mandatory"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {dissolutionDateErr !== "" ? <p className="error-msg font-fam-for-all">{dissolutionDateErr}</p> : ""}

                                                                                </span>
                                                                                {/* </div> */}
                                                                            </div>

                                                                            <div className='col-4' style={{ fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <Dropdown value={serviceState} onChange={(e) => {
                                                                                            setServiceState(e.value)
                                                                                            setServiceStateErr("")
                                                                                        }
                                                                                        } options={allStates} optionValue='id' optionLabel='stateFullDesc' placeholder="Select Service State" style={{ height: "40px" }}
                                                                                            className="w-full" filter

                                                                                        />
                                                                                        <label htmlFor="floating_outlined25"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Service State <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {serviceStateErr !== "" ? <p className="error-msg font-fam-for-all">{serviceStateErr}</p> : ""}
                                                                                </span>

                                                                            </div>

                                                                            <div className='col-4' style={{ fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <Dropdown value={domesticState} onChange={(e) => {
                                                                                            setDomesticState(e.value)
                                                                                            setDomesticStateErr("")
                                                                                        }} options={allStates} optionValue='id' optionLabel='stateFullDesc' placeholder="Select Domestic State" style={{ height: "40px" }}
                                                                                            className="w-full" filter

                                                                                        />
                                                                                        <label htmlFor="floating_outlined25"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Domestic State <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {domesticStateErr !== "" ? <p className="error-msg font-fam-for-all">{domesticStateErr}</p> : ""}
                                                                                </span>

                                                                            </div>

                                                                            <div className='col-4' style={{ fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <Dropdown value={industryType} onChange={(e) => {
                                                                                            setIndustryType(e.value)
                                                                                            setIndustryTypeErr("")
                                                                                        }} options={allIndustryType} optionValue='id' optionLabel="industryName" placeholder="Select Industry Type" style={{ height: "40px" }}
                                                                                            className="w-full" filter

                                                                                        />
                                                                                        <label htmlFor="floating_outlined25"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Industry Type <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {industryTypeErr !== "" ? <p className="error-msg font-fam-for-all">{industryTypeErr}</p> : ""}
                                                                                </span>

                                                                            </div>

                                                                            <div className='col-4' style={{ fontSize: "14px" }}>




                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <InputText
                                                                                            // className='dialog-form-input-field'
                                                                                            id="floating_outlined71"
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                                            style={{ width: '100%', height: '40px' }}
                                                                                            placeholder=''
                                                                                            maxLength={50} value={ingredient} onChange={handleCity} name='cityName'
                                                                                        />


                                                                                        <label
                                                                                            htmlFor="floating_outlined71"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Company Email<span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {emailErr !== "" ? <p className='error-msg font-fam-for-all'>{emailErr}</p> : ""}
                                                                                </span>

                                                                            </div>

                                                                            <div className='col-4' style={{ fontSize: "14px" }}>
                                                                                <div className="justify-content-center">
                                                                                    <div className="field py-0">
                                                                                        <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}>
                                                                                            <Dropdown
                                                                                                value={selectedCountry}
                                                                                                options={countries}
                                                                                                onChange={onCountryChange}
                                                                                                optionLabel="code"
                                                                                                filter
                                                                                                optionValue='dialCode'
                                                                                                valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}

                                                                                                style={{ width: '160px', borderRadius: '6px 0px 0px 6px' }}
                                                                                                className="p-dropdown font-fam-for-all"
                                                                                            />

                                                                                            <span className="" style={{ width: "100%" }}>
                                                                                                <div className="relative custom-input-mask">
                                                                                                <input
                                                                                                    type="text"
                                                                                                    id="floating_outlined219"
                                                                                                    className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                                                                                    style={{
                                                                                                        height: "40px",
                                                                                                        borderRadius: "0px 6px 6px 0px",
                                                                                                        width: "100%",
                                                                                                    }}
                                                                                                    maxLength={50}
                                                                                                    value={phone}
                                                                                                    onChange={handlePhone}
                                                                                                    autoClear={false}
                                                                                                    placeholder=""
                                                                                                    name="cityName"
                                                                                                    />
                                                                                                    <label
                                                                                                        htmlFor="floating_outlined219"
                                                                                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                                    >
                                                                                                        Company Phone<span className="form-field-mandatory">*</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                                {phoneErr !== "" ? <p className='error-msg font-fam-for-all'>{phoneErr}</p> : ""}
                                                                                            </span>

                                                                                        </div>
                                                                                        {/* <p className='error-msg font-fam-for-all'>{phoneErr}</p> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className='col-4' style={{ fontSize: "14px" }}>
                                                                                <div className="justify-content-center">
                                                                                    <div className="field py-0">
                                                                                        <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}>
                                                                                            <Dropdown
                                                                                                value={selectedCountry}
                                                                                                options={countries}
                                                                                                onChange={onCountryChange}
                                                                                                filter
                                                                                                optionLabel="code"
                                                                                                valueTemplate={selectedCountryTemplate} itemTemplate={countryOptionTemplate}
                                                                                                optionValue='dialCode'
                                                                                                style={{ width: '160px', borderRadius: '6px 0px 0px 6px' }}
                                                                                                className="p-dropdown font-fam-for-all"
                                                                                            />

                                                                                            <span className="" style={{ width: "100%" }}>
                                                                                                <div className="relative custom-input-mask">
                                                                                                    {/* <InputMask
                                                                                                        id="floating_outlined74"
                                                                                                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                                                        style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
                                                                                                        maxLength={16}
                                                                                                        value={fax}
                                                                                                        mask={isFocused ? "(999) 999-9999" : "(999) 999-9999"}
                                                                                                        onFocus={() => setIsFocused(true)}
                                                                                                        onBlur={() => setIsFocused(false)}
                                                                                                        onChange={handleFax}
                                                                                                        autoClear={false}
                                                                                                        placeholder=''
                                                                                                        name="cityName"
                                                                                                    /> */}
                                                                                                    <input
                                                                                                        type='text'
                                                                                                        id="floating_outlined255"
                                                                                                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                                                                                        style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
                                                                                                        maxLength={50}
                                                                                                        value={fax}                                                          
                                                                                                        onChange={handleFax}
                                                                                                        autoClear={false}
                                                                                                        placeholder=''
                                                                                                        name="cityName"
                                                                                                    />

                                                                                                    <label
                                                                                                        htmlFor="floating_outlined255"
                                                                                                        className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                                    >
                                                                                                        Fax
                                                                                                    </label>
                                                                                                </div>
                                                                                                {/* {phoneErr !== "" ? <p className='error-msg font-fam-for-all'>{phoneErr}</p> : ""} */}
                                                                                            </span>

                                                                                        </div>
                                                                                        {/* <p className='error-msg font-fam-for-all'>{phoneErr}</p> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <div className='col-4' style={{ fontSize: "14px" }}>
                                                                                <div className=" justify-content-center">
                                                                                    <div className="field col-12 p-0">



                                                                                        <span className=" ">
                                                                                            <div class="relative">
                                                                                                <InputText
                                                                                                    // className='dialog-form-input-field'
                                                                                                    id="floating_outlined81"
                                                                                                    className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                                                    style={{ width: '100%', height: '40px' }}
                                                                                                    placeholder=''
                                                                                                    maxLength={50} value={website} onChange={handleWebsite} name='cityName'
                                                                                                />


                                                                                                <label
                                                                                                    htmlFor="floating_outlined81"
                                                                                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                                >
                                                                                                    Website
                                                                                                </label>
                                                                                            </div>
                                                                                            {/* {emailErr !== "" ? <p className='error-msg font-fam-for-all'>{emailErr}</p> : ""} */}
                                                                                        </span>

                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                            <div className='col-4' style={{ fontSize: "14px" }}>
                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <InputText
                                                                                            style={{ height: '40px', width: "100%" }}
                                                                                            maxLength={50}
                                                                                            value={EINNumber}
                                                                                            id="floating_outlined51"
                                                                                            placeholder=''
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                                            onChange={handleEINNumber}
                                                                                            name="cityName"
                                                                                        />

                                                                                        <label
                                                                                            htmlFor="floating_outlined51"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            EIN Number<span className="form-field-mandatory"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {EINNumberErr !== "" ? <p className="error-msg font-fam-for-all">{EINNumberErr}</p> : ""}

                                                                                </span>
                                                                            </div>

                                                                            <div className='col-4' style={{ fontSize: "14px" }}>
                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <InputText
                                                                                            style={{ height: '40px', width: "100%" }}
                                                                                            maxLength={50}
                                                                                            value={entityNumber}
                                                                                            id="floating_outlined91"
                                                                                            placeholder=''
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                                            onChange={handleEntityNumber}
                                                                                            name="cityName"
                                                                                        />

                                                                                        <label
                                                                                            htmlFor="floating_outlined91"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Entity Number<span className="form-field-mandatory"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {entityNumberErr !== "" ? <p className="error-msg font-fam-for-all">{entityNumberErr}</p> : ""}

                                                                                </span>
                                                                            </div>





                                                                            <div className='col-4' style={{ fontSize: "14px" }}>
                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <InputText
                                                                                            style={{ height: '40px', width: "100%" }}
                                                                                            maxLength={5}
                                                                                            value={texYearEnd}
                                                                                            id="floating_outlined92"
                                                                                            placeholder=''
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                                            onChange={handleTaxYearEnd}
                                                                                            name="cityName"
                                                                                        />

                                                                                        <label
                                                                                            htmlFor="floating_outlined92"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Tax Year End<span className="form-field-mandatory"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {taxYearEndErr !== "" ? <p className="error-msg font-fam-for-all">{taxYearEndErr}</p> : ""}

                                                                                </span>
                                                                            </div>
                                                                        </div>




                                                                        <div className="mb-3">
                                                                            {isEditing || isEditing1 || isEditing2 ? (
                                                                                <div className='flex justify-content-end pl-3 dialog-form-md-group-Btn' >
                                                                                    <Button className="mt-2  font-fam-for-all text-center text-lg vstate-reverse-button mr-2" style={{ height: "40px" }} onClick={() => {
                                                                                        setIsEditing(false)
                                                                                        setDomesticStateErr("")
                                                                                        setEmailErr("")
                                                                                        setPhoneErr("")
                                                                                        setIndustryTypeErr("")
                                                                                        setCompanyErr("")
                                                                                        setOpenDateErr("")
                                                                                        setBusinessStructureErr("")
                                                                                        setServiceStateErr("")
                                                                                        setTaxYearEndErr("")
                                                                                        setEntityNumberErr("")
                                                                                        setEINNumberErr("")
                                                                                        setDissolutionDateErr("")

                                                                                    }}  >Cancel</Button>
                                                                                    <Button className="mt-2 vstate-button  font-fam-for-all text-center text-lg mr-3" style={{ height: "40px" }} onClick={handleSave} >Submit</Button>
                                                                                </div>
                                                                            ) : (
                                                                                <div></div>
                                                                            )}
                                                                        </div>


                                                                    </div> : <>
                                                                        <div>
                                                                            <div className='card my-3 hover-card slide-in'>


                                                                                <div className=' flex px-4 pt-4 pb-0' style={{ justifyContent: 'space-between' }}>
                                                                                    <h2 className='srch-cand-text2'>Company Details</h2>
                                                                                    <div >
                                                                                        {isEditing ? (
                                                                                            <div>

                                                                                            </div>
                                                                                        ) : (
                                                                                            <div className='flex justify-content-end dialog-form-md-group-Btn'>
                                                                                                <Button onClick={() => handleEdit1(data)} className="edit-button1" icon="pi pi-pencil" style={{ border: 'none', height: "40px" }}></Button>

                                                                                            </div>
                                                                                        )}
                                                                                    </div>
                                                                                </div>

                                                                                <div className="grid px-4 py-2">

                                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                        Entity Name
                                                                                    </div>
                                                                                    <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                        Business Structure
                                                                                    </div>
                                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                        Formation Date
                                                                                    </div>
                                                                                    <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                        Dissolution Date
                                                                                    </div>


                                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                        {data.companyName}
                                                                                    </div>
                                                                                    <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                                        {data.entityType ? (data.entityType == 1) ? "LLC" : "CORP" : ""}
                                                                                    </div>

                                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                        {data.formationDate ? formatISODate(data.formationDate) : null}
                                                                                    </div>
                                                                                    <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                                        {data.dissolutionDate ? formatISODate(data.dissolutionDate) : null}
                                                                                    </div>

                                                                                </div>
                                                                                <div className="grid px-4 py-2">

                                                                                    {/* <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                        Formation Date
                                                                                    </div>
                                                                                    <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                        Dissolution Date
                                                                                    </div> */}
                                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                        Service State
                                                                                    </div>
                                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                        Domestic State
                                                                                    </div>

                                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                        Industry Type
                                                                                    </div>
                                                                                    <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>

                                                                                    </div>
                                                                                    {/* <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                        {data.formationDate}
                                                                                    </div>
                                                                                    <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                                        {data.dissolutionDate}
                                                                                    </div> */}
                                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                        {getState(data?.serviceStateId)}
                                                                                    </div>
                                                                                    <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                        {getState(data?.domesticStateId)}

                                                                                    </div>
                                                                                    <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                                        {getIndustry(data.industryId) ? getIndustry(data.industryId) : ""}
                                                                                    </div>

                                                                                </div>
                                                                                <div className='card-devider'></div>
                                                                                <div className='my-3'>


                                                                                    <div className=' flex px-4 pt-4 pb-0' style={{ justifyContent: 'space-between' }}>
                                                                                        <h2 className='srch-cand-text2'>Contact Info</h2>
                                                                                    </div>

                                                                                    <div className="grid px-4 py-2">


                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Company Email
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Company Phone
                                                                                        </div>


                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Fax
                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Website
                                                                                        </div>


                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                            {data.companyEmail}
                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                                            {data.phoneNo}
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                            {data.fax}
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                            {data.websiteUrl}
                                                                                        </div>

                                                                                    </div>
                                                                                </div>

                                                                                <div className='card-devider'></div>
                                                                                <div className='my-3'>


                                                                                    <div className=' flex px-4 pt-4 pb-0' style={{ justifyContent: 'space-between' }}>
                                                                                        <h2 className='srch-cand-text2'>IRS/Accounting</h2>
                                                                                    </div>

                                                                                    <div className="grid px-4 py-2">


                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            EIN Number
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Entity Number
                                                                                        </div>


                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Tax Year End
                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>

                                                                                        </div>


                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                            {data.einNo}
                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                                            {data.entityNo}
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                            {data.taxYearEnd}
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>

                                                                                        </div>

                                                                                    </div>
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                }


                                                                {
                                                                    isEditing1 ? <div className='card my-3 slide-in'>
                                                                        <div className='p-4'>
                                                                            <h2 className='srch-cand-text2'>Principal Address</h2>

                                                                        </div>
                                                                        <div className="grid px-4 py-2">


                                                                            <div className='col-4 ' style={{ fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <InputText
                                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                            maxLength={50}
                                                                                            value={addr1}
                                                                                            onChange={(e) => setAddr1(e.target.value)}
                                                                                            name="cityName"
                                                                                            id="floating_outlined21"
                                                                                            placeholder=''
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                                                        />
                                                                                        <label
                                                                                            htmlFor="floating_outlined21"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Address Line 1 <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {addr1Err !== "" ? <p className="error-msg font-fam-for-all">{addr1Err}</p> : ""}
                                                                                </span>
                                                                            </div>
                                                                            <div className='col-4' style={{ fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <InputText
                                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                            maxLength={50}
                                                                                            value={addr2}
                                                                                            onChange={(e) => setAddr2(e.target.value)}
                                                                                            name="cityName"
                                                                                            id="floating_outlined22"
                                                                                            placeholder=''
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                                                        />
                                                                                        <label htmlFor="floating_outlined22"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Address Line 2 <span className="form-field-mandatory"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {addr2Err !== "" ? <p className="error-msg font-fam-for-all">{addr2Err}</p> : ""}
                                                                                </span>

                                                                            </div>
                                                                            {/* <div className='col-4' style={{ fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <InputText
                                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                            maxLength={50}
                                                                                            value={region}
                                                                                            onChange={(e) => setRegion(e.target.value)}
                                                                                            name="cityName"
                                                                                            id="floating_outlined23"
                                                                                            placeholder=''
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                                                        />
                                                                                        <label htmlFor="floating_outlined23"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            County <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {regionErr !== "" ? <p className="error-msg font-fam-for-all">{regionErr}</p> : ""}
                                                                                </span>

                                                                            </div> */}
                                                                            <div className='col-4 ' style={{ fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <InputText
                                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                            maxLength={50}
                                                                                            value={city}
                                                                                            onChange={(e) => setCity(e.target.value)}
                                                                                            name="cityName"
                                                                                            id="floating_outlined24"
                                                                                            placeholder=''
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                                                        />
                                                                                        <label htmlFor="floating_outlined24"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            City <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {cityErr !== "" ? <p className="error-msg font-fam-for-all">{cityErr}</p> : ""}
                                                                                </span>

                                                                            </div>

                                                                            <div className='col-4' style={{ fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <Dropdown value={states} onChange={(e) => {
                                                                                            setStatesErr("")
                                                                                            setStates(e.value)
                                                                                        }} options={allStates} optionValue='id' optionLabel="stateFullDesc" placeholder="Select a State" style={{ height: "40px" }}
                                                                                            className="w-full" filter

                                                                                        />
                                                                                        <label htmlFor="floating_outlined25"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            State <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {statesErr !== "" ? <p className="error-msg font-fam-for-all">{statesErr}</p> : ""}
                                                                                </span>

                                                                            </div>
                                                                            <div className='col-4' style={{ fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <Dropdown disabled value={countryData} onChange={(e) => {
                                                                                            setCountryErr("")
                                                                                            setCountryData(e.value)
                                                                                        }} options={allCountries} optionValue='id' optionLabel="countryFullDesc" placeholder="Select a Country" style={{ height: "40px" }}
                                                                                            className="w-full" filter

                                                                                        />
                                                                                        <label htmlFor="floating_outlined25"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Country <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {countryErr !== "" ? <p className="error-msg font-fam-for-all">{countryErr}</p> : ""}

                                                                                </span>

                                                                            </div>


                                                                            <div className='col-4' style={{ fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <InputText
                                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                                            maxLength={5}
                                                                                            value={zip}
                                                                                            onChange={handleZip}
                                                                                            name="cityName"
                                                                                            id="floating_outlined26"
                                                                                            placeholder=''
                                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                                                        />
                                                                                        <label htmlFor="floating_outlined26"
                                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                                        >
                                                                                            Zip Code <span className="form-field-mandatory">*</span>
                                                                                        </label>
                                                                                    </div>
                                                                                    {zipErr !== "" ? <p className="error-msg font-fam-for-all">{zipErr}</p> : ""}
                                                                                </span>

                                                                            </div>

                                                                            {/* <div className='col-4' style={{ fontSize: "14px" }}>


                                                                                <span className=" mr-3">
                                                                                    <div class="relative">
                                                                                        <Dropdown value={addressType} onChange={(e) => setAddressType(e.value)} disabled options={allAddressType} optionLabel="type" placeholder="Select Address Type" style={{ height: "40px" }}
                                                                                            className="w-full rounded-lg " filter

                                                                                        />

                                                                                    </div>
                                                                                    {addressTypeErr !== "" ? <p className="error-msg font-fam-for-all">{addressTypeErr}</p> : ""}

                                                                                </span>

                                                                            </div> */}




                                                                        </div>




                                                                        <div className="mb-3">
                                                                            {isEditing || isEditing1 || isEditing2 ? (
                                                                                <div className='flex justify-content-end pl-3 dialog-form-md-group-Btn' >
                                                                                    <Button className="mt-2  font-fam-for-all text-center vstate-reverse-button text-lg  mr-2" style={{ height: "40px" }} onClick={() => {

                                                                                        setIsEditing1(false)

                                                                                        // setAddr1("")
                                                                                        setAddr1Err("")
                                                                                        // setAddr2("")
                                                                                        setAddr2Err("")
                                                                                        // setCity("")
                                                                                        setcityErr("")
                                                                                        // setRegion("")
                                                                                        setRegionErr("")
                                                                                        // setStates("")
                                                                                        setStatesErr("")
                                                                                        // setZip("")
                                                                                        setZipErr("")

                                                                                        setAddressTypeErr("")
                                                                                        setCompanyErr("")
                                                                                        setCountryErr("")

                                                                                    }}  >Cancel</Button>
                                                                                    <Button className="mt-2  font-fam-for-all vstate-button text-center text-lg mr-3" style={{ height: "40px" }} onClick={handleSave3} >Submit</Button>
                                                                                </div>
                                                                            ) : (
                                                                                <div></div>
                                                                            )}
                                                                        </div>


                                                                    </div> : <>
                                                                        <div>
                                                                            <>
                                                                                {principalAddress && <div className='card my-3 hover-card slide-in'>


                                                                                    <div className=' flex px-4 pt-4 pb-0' style={{ justifyContent: 'space-between' }}>
                                                                                        <h2 className='srch-cand-text2'>Principal Address</h2>
                                                                                        <div >
                                                                                            {isEditing1 ? (
                                                                                                <div>

                                                                                                </div>
                                                                                            ) : (
                                                                                                <div className='flex justify-content-end dialog-form-md-group-Btn'>
                                                                                                    <Button onClick={() => handleEdit(data)} className="edit-button1" icon="pi pi-pencil" style={{ border: 'none', height: "40px" }}></Button>

                                                                                                </div>
                                                                                            )}
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="grid px-4 py-2">

                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Address Line1
                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Address Line 2
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            City
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            State
                                                                                        </div>


                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                            {addr1}
                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                                            {addr2}
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                            {city}
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                            {getState(states)}
                                                                                        </div>

                                                                                    </div>
                                                                                    <div className="grid px-4 py-2">

                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Country
                                                                                        </div>

                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                                            Zip Code
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontWeight: "500", fontSize: "14px" }}>

                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontWeight: "500", fontSize: "14px" }}>

                                                                                        </div>


                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>
                                                                                            {principalAddress && "United States"}

                                                                                        </div>
                                                                                        <div className='col-3 ' style={{ fontSize: "14px" }}>
                                                                                            {zip}
                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>

                                                                                        </div>
                                                                                        <div className='col-3' style={{ fontSize: "14px" }}>

                                                                                        </div>

                                                                                    </div>



                                                                                </div>
                                                                                }
                                                                            </>

                                                                        </div>
                                                                    </>
                                                                }


                                                            </div>




                                                        </div>


                                                    </div>
                                                </TabPanel>

                                                <TabPanel header="Contacts">
                                                    <div className='px-4 pt-4 flex justify-content-between'>
                                                        <div>

                                                        </div>

                                                        <div>
                                                            <button className="datatable-highlight-color" onClick={() => setVisible6(true)}>
                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                    <div className='font-medium'>Add Contact</div>
                                                                </div>

                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div className='grid hover-card slide-in  p-4'>
                                                        {
                                                            allContacts.map((contactData) => (
                                                                <div className='col-4'>

                                                                    <div className='order-contact-card-container'>
                                                                        <div className='order-contact-card-main p-4'>
                                                                            <div className='order-contact-card-header-main'>

                                                                                <Avatar label={contactData.contactDetails.avatarImgPath ? '' : getInitials(contactData.contactDetails.displayName)} image={avatars[contactData.contactDetails.id] ? `data:image/jpeg;base64,${avatars[contactData.contactDetails.id]}` : ''} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                <div>
                                                                                    <div className='order-contact-card-header-text1'>
                                                                                        {`${contactData.contactDetails.firstName} ${contactData.contactDetails.lastName}`}
                                                                                    </div>
                                                                                    {/* <div className='order-contact-card-header-text2'>
                                                                                        Primary
                                                                                    </div> */}
                                                                                </div>

                                                                            </div>
                                                                            <div className='card-devider'> </div>
                                                                            <div className='order-contact-card-text-main'>
                                                                                <div>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                        <g clip-path="url(#clip0_966_10329)">
                                                                                            <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </g>
                                                                                        <defs>
                                                                                            <clipPath id="clip0_966_10329">
                                                                                                <rect width="20" height="20" fill="white" />
                                                                                            </clipPath>
                                                                                        </defs>
                                                                                    </svg>
                                                                                </div>
                                                                                <div className='order-contact-card-text1'>
                                                                                    {contactData.contactDetails.mobileNo}
                                                                                </div>
                                                                            </div>

                                                                            <div className='order-contact-card-text-main'>
                                                                                <div>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                        <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    </svg>
                                                                                </div>
                                                                                <div className='order-contact-card-text1'>
                                                                                    {contactData.contactDetails.emailId}
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )
                                                            )
                                                        }

                                                    </div>
                                                </TabPanel>
                                                {
                                                    superAdminId ?

                                                        <TabPanel header="Account Manager">
                                                            <div className='px-4 pt-4 flex justify-content-between'>
                                                                <div></div>
                                                                <div className='flex gap-2'>
                                                                    <button className="datatable-highlight-color" onClick={() => setVisible4(true)}>
                                                                        <div className='flex align-items-center webapp-global-sm-size'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                            </svg>
                                                                            <div className='font-medium'>{data.accountManagerId ? "Change Account Manager" : "Add Account Manager"}</div>
                                                                        </div>

                                                                    </button>


                                                                </div>
                                                            </div>
                                                            {
                                                                data.accountManagerId ? <div className='grid hover-card slide-in  p-4'>

                                                                    <div className='col-4'>

                                                                        <div className='order-contact-card-container'>
                                                                            <div className='order-contact-card-main p-4'>
                                                                                <div className='order-contact-card-header-main justify-between'>
                                                                                    <div className='flex justify-content-start gap-2'>
                                                                                        <Avatar label={getInitials(`${data.accountManagerId.firstName} ${data.accountManagerId.lastName}`)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                        <div>
                                                                                            <div className='order-contact-card-header-text1'>
                                                                                                {`${data.accountManagerId.firstName} ${data.accountManagerId.lastName}`}
                                                                                            </div>
                                                                                            <div className='order-contact-card-header-text2 text-start'>
                                                                                                Employee
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>


                                                                                    <button className="pi pi-trash image-btn-icon-delete" data-toggle="tooltip" data-placement="bottom" title="Delete" onClick={() => {
                                                                                        setCloseProgresbar3(true)
                                                                                        setSelectedAccountManager(data.accountManagerId)
                                                                                    }} ></button>


                                                                                </div>
                                                                                <div className='card-devider'> </div>
                                                                                <div className='order-contact-card-text-main'>
                                                                                    <div>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                            <g clip-path="url(#clip0_966_10329)">
                                                                                                <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_966_10329">
                                                                                                    <rect width="20" height="20" fill="white" />
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg>
                                                                                    </div>
                                                                                    <div className='order-contact-card-text1'>
                                                                                        {data.accountManagerId.phone}
                                                                                    </div>
                                                                                </div>

                                                                                <div className='order-contact-card-text-main'>
                                                                                    <div>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                            <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </svg>
                                                                                    </div>
                                                                                    <div className='order-contact-card-text1'>
                                                                                        {data.accountManagerId.email}
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>


                                                                </div> : <div className='grid hover-card slide-in  p-4'>

                                                                    <div className='col-4'>

                                                                        <div className='order-contact-card-container'>
                                                                            <div className='order-contact-card-main p-4'>
                                                                                <div className='order-contact-card-header-main justify-between'>
                                                                                    <div className='flex justify-content-start gap-2'>
                                                                                        <Avatar label={getInitials(`vState Filings`)} icon="pi pi-user" size="large" shape="circle" className='custom-avatar' />

                                                                                        <div>
                                                                                            <div className='order-contact-card-header-text1'>
                                                                                                {`vState Filings`}
                                                                                            </div>
                                                                                            <div className='order-contact-card-header-text2 text-start'>
                                                                                                Employee
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                </div>
                                                                                <div className='card-devider'> </div>
                                                                                <div className='order-contact-card-text-main'>
                                                                                    <div>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                            <g clip-path="url(#clip0_966_10329)">
                                                                                                <path d="M18.3332 14.1V16.6C18.3341 16.8321 18.2866 17.0618 18.1936 17.2745C18.1006 17.4871 17.9643 17.678 17.7933 17.8349C17.6222 17.9918 17.4203 18.1113 17.2005 18.1856C16.9806 18.26 16.7477 18.2876 16.5165 18.2667C13.9522 17.9881 11.489 17.1118 9.32486 15.7084C7.31139 14.4289 5.60431 12.7219 4.32486 10.7084C2.91651 8.53438 2.04007 6.0592 1.76653 3.48337C1.7457 3.25293 1.77309 3.02067 1.84695 2.80139C1.9208 2.58211 2.03951 2.38061 2.1955 2.20972C2.3515 2.03883 2.54137 1.9023 2.75302 1.80881C2.96468 1.71532 3.19348 1.66692 3.42486 1.66671H5.92486C6.32928 1.66273 6.72136 1.80594 7.028 2.06965C7.33464 2.33336 7.53493 2.69958 7.59153 3.10004C7.69705 3.9001 7.89274 4.68565 8.17486 5.44171C8.28698 5.73998 8.31125 6.06414 8.24478 6.37577C8.17832 6.68741 8.02392 6.97347 7.79986 7.20004L6.74153 8.25837C7.92783 10.3447 9.65524 12.0721 11.7415 13.2584L12.7999 12.2C13.0264 11.976 13.3125 11.8216 13.6241 11.7551C13.9358 11.6887 14.2599 11.7129 14.5582 11.825C15.3143 12.1072 16.0998 12.3029 16.8999 12.4084C17.3047 12.4655 17.6744 12.6694 17.9386 12.9813C18.2029 13.2932 18.3433 13.6914 18.3332 14.1Z" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                            </g>
                                                                                            <defs>
                                                                                                <clipPath id="clip0_966_10329">
                                                                                                    <rect width="20" height="20" fill="white" />
                                                                                                </clipPath>
                                                                                            </defs>
                                                                                        </svg>
                                                                                    </div>
                                                                                    <div className='order-contact-card-text1'>
                                                                                        866-638-3309
                                                                                    </div>
                                                                                </div>

                                                                                <div className='order-contact-card-text-main'>
                                                                                    <div>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                            <path d="M18.3337 5.00004C18.3337 4.08337 17.5837 3.33337 16.667 3.33337H3.33366C2.41699 3.33337 1.66699 4.08337 1.66699 5.00004M18.3337 5.00004V15C18.3337 15.9167 17.5837 16.6667 16.667 16.6667H3.33366C2.41699 16.6667 1.66699 15.9167 1.66699 15V5.00004M18.3337 5.00004L10.0003 10.8334L1.66699 5.00004" stroke="#175CD3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                        </svg>
                                                                                    </div>
                                                                                    <div className='order-contact-card-text1'>
                                                                                        support@vstatefiling.com
                                                                                    </div>
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </TabPanel> : ""
                                                }

                                                <TabPanel header="Locations">
                                                    <div className='col-12 ' style={{ backgroundColor: '#fbfcfa', padding: '15px' }}>


                                                        <div className="font-fam-for-all">

                                                            <div className='card my-3 hover-card slide-in1'>


                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Offices, Branches, and Affiliates</h2>
                                                                        {/* <div style={{ fontSize: "14px" }}>
                                                                            Update your photo and personal details here.
                                                                        </div> */}
                                                                    </div>
                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => setVisible1(true)} >
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add Location</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>

                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <CompanyLocation companyData={data} />

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>


                                                <TabPanel header="Key Personnel">
                                                    <div className='col-12 ' style={{ backgroundColor: '#fbfcfa', padding: '15px' }}>


                                                        <div className="font-fam-for-all">

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Owners/Members</h2>
                                                                        <div className='srch-cand-text'>
                                                                            {`An individual or entity that holds ownership rights in the organization.`}
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("OWNER")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add Member</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>

                                                                        <CompanyOwners data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Organizer</h2>
                                                                        <div className='srch-cand-text'>
                                                                            {`An individual, responsible for organizing and forming the entity.`}
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("ORGANIZER")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>

                                                                        <OrganizerContact data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Primary Contact Person</h2>
                                                                        <div className='srch-cand-text'>
                                                                            This is the person designated as the official contact with the state. Only one individual can be added for this position.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => setVisible5(true)} >
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>

                                                                        <PrimaryContact data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            {/* <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Contact Person For Shipping</h2>
                                                                        <div className='srch-cand-text'>
                                                                            This individual is responsible for managing and overseeing all shipping-related activities for the particular filing order, ensuring timely and accurate delivery of documents and materials.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("SHIPPING")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <ShipingContact data={data} />

                                                                    </div>

                                                                </div>
                                                            </div> */}

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Contact Person For Biling</h2>
                                                                        <div className='srch-cand-text'>
                                                                            Handles all invoicing, payments, and financial transactions for the organization.                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("BILLING")} >
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>

                                                                        <BilingContact data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>General Personnel</h2>
                                                                        <div className='srch-cand-text'>
                                                                            A general role with broad responsibilities across various operations.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("GENERAL")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <GeneralPersonal data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Assignee</h2>
                                                                        <div className='srch-cand-text'>
                                                                            A person appointed to act on behalf of another in specific matters.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("ASSIGNEE")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <Assignee data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Debtor</h2>
                                                                        <div className='srch-cand-text'>
                                                                            An individual or entity that owes money or obligations to another party.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DEBTOR")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <Debtor data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Director</h2>
                                                                        <div className='srch-cand-text'>
                                                                            A person who oversees and guides the strategic direction of the organization.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("DIRECTOR")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <Director data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Incorporator</h2>
                                                                        <div className='srch-cand-text'>
                                                                            The individual responsible for initiating the incorporation process.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("INCORPORATOR")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <Incorporator data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Officer</h2>
                                                                        <div className='srch-cand-text'>
                                                                            A high-ranking individual responsible for executing managerial decisions.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("OFFICER")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <Officer data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Registred Agent</h2>
                                                                        <div className='srch-cand-text'>
                                                                            The designated individual or entity to receive legal and official documents.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("REGISTERED AGENT")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <RegistredAgent data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Secured Party</h2>
                                                                        <div className='srch-cand-text'>
                                                                            A person or entity that holds a secured interest in a financial transaction.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("SECUERED PARTY")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <SecuredParty data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Beneficial Owner</h2>
                                                                        <div className='srch-cand-text'>
                                                                            An individual who ultimately owns or controls the organization.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleBOIKeyPersonal("BENEFICIAL OWNER")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <BeneficialOwner data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Company Applicant</h2>
                                                                        <div className='srch-cand-text'>
                                                                            The person submitting the application on behalf of the company.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleBOIKeyPersonal("COMPANY APPLICANT")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <ComapnyApplicant data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>President</h2>
                                                                        <div className='srch-cand-text'>
                                                                            The executive leader responsible for overall management of the organization.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("PRESIDENT")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <President data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Treasurer</h2>
                                                                        <div className='srch-cand-text'>
                                                                            Oversees the financial management and budgeting of the organization.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("TREASURER")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <Treasurer data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Secretary</h2>
                                                                        <div className='srch-cand-text'>
                                                                            Maintains records, minutes, and documentation for the organization.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("SECRETARY")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <Secretary data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>

                                                                <div className='p-4 flex justify-content-between'>
                                                                    <div>
                                                                        <h2 className='srch-cand-text2'>Filer</h2>
                                                                        <div className='srch-cand-text'>
                                                                            Responsible for submitting forms, filings, or documents to authorities.
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <button className="datatable-highlight-color" onClick={() => handleKeyPersonal("FILER")}>
                                                                            <div className='flex align-items-center webapp-global-sm-size'>
                                                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                    <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                </svg>
                                                                                <div className='font-medium'>Add</div>
                                                                            </div>

                                                                        </button>
                                                                    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <CompanyFiler data={data} />
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </TabPanel>


                                                {/* <TabPanel header="Signers">
                                                    <div className='col-12 ' style={{ backgroundColor: '#fbfcfa', padding: '15px' }}>


                                                        <div className="font-fam-for-all">

                                                            <div className='card my-3 hover-card slide-in1'>


                                                                <div className='p-4'>
                                                                    <h2 className='srch-cand-text2'>Power of Attorney (recommended)</h2>
                                                                    <div className='srch-cand-text'>
                                                                        vState Filings will sign documents on behalf of TL Flex Holdings LLC using a Power of Attorney, eliminating the need for you to handle and return each form requiring a signature. This optional service is offered where allowed by government agencies.
                                                                    </div>
                                                                </div>
                                                                <div className='grid p-4'>
                                                                    <div className='col-12'>


                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className='card my-3 hover-card slide-in1'>


                                                                <div className='p-4'>
                                                                    <h2 className='srch-cand-text2'>Preferred Signers</h2>
                                                                    <div className='srch-cand-text'>
                                                                        Please select up to three preferred signers for your filings. When possible, we will use your preferred signer as the default.                                                                                                                                        </div>
                                                                </div>
                                                                <div className='grid p-4'>
                                                                    <div className='col-12'>


                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel> */}


                                                <TabPanel header="Records">
                                                    <div className='col-12 ' style={{ backgroundColor: '#fbfcfa', padding: '15px' }}>


                                                        <div className="font-fam-for-all">

                                                            <div className='card my-3 hover-card slide-in1'>


                                                                <div className='flex justify-between'>
                                                                    <div className='p-4'>
                                                                        <h2 className='srch-cand-text2'>Documents</h2>
                                                                        <div className='srch-cand-text'>

                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div className='p-4'>
                                                                            <button className="datatable-highlight-color" onClick={() => setVisible3(true)}>
                                                                                <div className='flex align-items-center webapp-global-sm-size'>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                                        <path d="M10.0003 4.16669V15.8334M4.16699 10H15.8337" stroke="#004EEC" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                                                    </svg>
                                                                                    <div className='font-medium'>Add Document</div>
                                                                                </div>

                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='grid p-4'>
                                                                    <div className='col-12'>
                                                                        <CompanyDocuments data={data} />

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </TabPanel>
                                                <TabPanel header="Orders">
                                                    <div className='col-12 ' style={{ backgroundColor: '#fbfcfa', padding: '15px' }}>


                                                        <div className="font-fam-for-all">

                                                            <div className='card my-3 hover-card slide-in1'>


                                                                <div className='p-4'>
                                                                    <h2 className='srch-cand-text2'>Orders</h2>
                                                                    <div className='srch-cand-text'>
                                                                        View all your company's filing orders in one place. Track the status and details of each order to ensure timely and accurate processing    </div>
                                                                </div>
                                                                <div className='grid px-4'>
                                                                    <div className='col-12'>
                                                                        <CompanyOrders data={data} />

                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>

                                                <TabPanel header="Subscriptions">
                                                    <div className='col-12 ' style={{ backgroundColor: '#fbfcfa', padding: '15px' }}>


                                                        <div className="font-fam-for-all">

                                                            <div className='card my-3 hover-card slide-in1'>


                                                                <div className='p-4'>
                                                                    <h2 className='srch-cand-text2'>Subscriptions</h2>
                                                                    <div className='srch-cand-text'>
                                                                        Keep track of your active subscriptions and explore new plans that offer continued support and value for your business.                                                                    </div>
                                                                </div>
                                                                <CompanySubscriptions data={data} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </TabPanel>

                                                <TabPanel header="Services">
                                                    <div className='col-12 ' style={{ backgroundColor: '#fbfcfa', padding: '15px' }}>


                                                        <div className="font-fam-for-all">

                                                            <div className='card my-3 hover-card slide-in1'>


                                                                <div className='p-4'>
                                                                    <h2 className='srch-cand-text2'>Services</h2>
                                                                    <div className='srch-cand-text'>
                                                                        Discover essential services to support your business. Choose and order the solutions that best fit your needs.
                                                                    </div>
                                                                </div>
                                                                <CompanyServices data={data} />
                                                            </div>

                                                        </div>
                                                    </div>
                                                </TabPanel>




                                            </TabView>
                                        </div>



                                    </div> : showSkeleton ? <div className='col-8 pt-0 ' >
                                        <div className='card col-12' style={{ height: '255px' }}>
                                            <div className='grid justify-content-center align-items-center font-fam-for-all' style={{ height: '255px' }}>
                                                No company found
                                            </div>
                                        </div></div> : <div className=" card col-8 pt-0">
                                        <div className="border-round border-1 surface-border p-4 surface-card">
                                            <div className="flex mb-3">
                                                <Skeleton shape="circle" size="4rem" className="mr-2"></Skeleton>
                                                <div>
                                                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                                                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                    <Skeleton height=".5rem"></Skeleton>
                                                </div>
                                            </div>
                                            <Skeleton width="100%" height="150px"></Skeleton>
                                            <div className="flex justify-content-between mt-3">
                                                <Skeleton width="4rem" height="2rem"></Skeleton>
                                                <Skeleton width="4rem" height="2rem"></Skeleton>
                                            </div>
                                        </div>
                                    </div>

                                }
                            </div>
                        </div>
                    </div>
                    <div>
                        {
                            memberType ? <AddCompanyMember visible={visible2} setVisible={setVisible2} pageRefresh={pageRefresh} setPageRefresh={setPageRefresh} memberType={memberType} data={data} allLocation={allLocation} keyPersonalAddressType={keyPersonalAddressType} /> : ""

                        }
                    </div>
                    <div>
                        {
                            memberType ? <AddBOIMember visible={visible8} setVisible={setVisible8} pageRefresh={pageRefresh} setPageRefresh={setPageRefresh} memberType={memberType} data={data} allLocation={allLocation} keyPersonalAddressType={keyPersonalAddressType} /> : ""

                        }
                    </div>

                    <AddCompany visible={visible} setVisible={setVisible} pageRefresh={pageRefresh} setPageRefresh={setPageRefresh} />

                    <AddCompanyLocation companyId={data} visible={visible1} setVisible={setVisible1} />

                    <AddCompanyContact visible={visible6} allContacts={allContacts} setVisible={setVisible6} data={data} />

                    <AddAccountManager visible={visible4} setVisible={setVisible4} selectedData={selectedData} data={data} principalAddress={principalAddress} />

                    <AddPrimaryContact visible={visible5} setVisible={setVisible5} data={allContacts} companyData={data} principalAddress={principalAddress} />

                    <AddCompanyDocuments visible={visible3} setVisible={setVisible3} data={data} principalAddress={principalAddress} />


                    <Dialog visible={closeProgresbar} modal={false} onHide={CloseProgresbarheader} className='font-fam-for-all'>
                        <p className="m-0 p-3">
                            <div className='d-flex align-items-center'>
                                {/* <img src={SuccessImage} width='60px' height='60px' /> */}
                                {/* <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
                                <p className='font-fam-for-all successMessage-font'>Data Added Successfully</p>
                            </div>
                            <div className='flex justify-content-end dialog-form-md-group-Btn'>
                                <Button type='button' className="mt-2 form-Btn form-Btn-Label font-fam-for-all  text-lg  mr-2" onClick={CloseProgresbarheader} >Ok</Button>
                            </div>
                        </p>
                    </Dialog>


                    <Dialog visible={closeProgresbar1} modal={false} onHide={CloseProgresbarheader1} className='font-fam-for-all'>
                        <p className="m-0 p-3">
                            <div className='d-flex align-items-center'>
                                {/* <img src={ErroImage} width='60px' height='60px' /> */}
                                {/* <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="var(--surface-ground)" animationDuration=".5s" /> */}
                                <p className='font-fam-for-all successMessage-font'>Principal address required</p>
                            </div>
                            <div className='flex justify-content-end dialog-form-md-group-Btn'>
                                <Button type='button' className="mt-2 form-Btn form-Btn-Label font-fam-for-all  text-lg  mr-2" onClick={CloseProgresbarheader1} >Ok</Button>
                            </div>
                        </p>
                    </Dialog>

                    {
                        closeProgresbar3 && (
                            <Dialog className="payment-dialog rounded-lg" visible={closeProgresbar3} onHide={() => setCloseProgresbar3(false)}>
                                <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                                    {/* <div className="payment-icon-confirm">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none">
                                            <path d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1" stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </div> */}
                                    <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                                        <header className="flex flex-col w-full max-md:max-w-full">
                                            <p className="text-lg mb-0 font-medium leading-loose text-red-900 max-md:max-w-full">
                                                Warning
                                            </p>
                                            <p className="mt-2 text-sm leading-5 text-gray-600 w-[500px] text-wrap">
                                                Are you sure you want to remove this account manager?</p>
                                        </header>
                                        <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                                            <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                                                <Button className="vstate-reverse-button" label="Cancel" onClick={() => setCloseProgresbar3(false)} />
                                                <Button className="vstate-button" label="Confirm" onClick={() => handleDeleteAccountManager()} />

                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </Dialog>
                        )
                    }

                </div>
            </div >
        </>
    )
}
