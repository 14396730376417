import React from 'react';
import Logo from './Logo';
import Navigation from './Navigation';
import LoginButton from './LoginButton';

const Header = () => {
  return (
    <header className="flex flex-col self-stretch w-full shadow-lg bg-white bg-opacity-0 max-md:max-w-full">
      <div className="flex justify-center items-center px-16 py-1 w-full  bg-web-color max-md:px-5 max-md:max-w-full" style={{  backdropFilter: "blur(10px)",position:"fixed",top:'0',right:"0",left:"0",zIndex:"1000" }}>
        <div className="flex gap-5 justify-center pl-px max-w-full w-[1150px] max-md:flex-wrap">
          <div className="flex flex-wrap gap-5 justify-between content-start px-5 my-auto text-sm font-light leading-7 text-white max-md:pr-5">
            {/* <div className="flex gap-2.5 whitespace-nowrap">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/01d85febd8dfc947d9b65cbd1a09c659684b80a50ebac50762701198a1998e0d?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="shrink-0 my-auto  aspect-square" alt="" />
              <div>support@vstatefiling.com</div>
            </div> */}
            <div className="flex gap-2.5 whitespace-nowrap">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/01d85febd8dfc947d9b65cbd1a09c659684b80a50ebac50762701198a1998e0d?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="shrink-0 my-auto  aspect-square" alt="" />
              <div>support@vstatefiling.com</div>
            </div>
            <div className="flex gap-2.5">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/f746562eeff225c0384360776c96214ea2f9115b19eaf123cec6c5e9d51ca752?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="shrink-0 my-auto aspect-square" alt="" />
              <div>301 Mill Road, Suite U5, Hewlett, NY 11557</div>
            </div>
          </div>
          <div className="flex gap-2.5 px-px">
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9bead07665613a7be40dc561986ef41e2f9db3a8a0440570633e48d6bbbe72a3?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="shrink-0 aspect-square w-[31px]" alt="" />
            <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/7af674211d282c83d39eed2c1007dd87fe256e0c09efd5159ccd952a47c08628?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="shrink-0 my-auto max-w-full aspect-[6.67] w-[119px]" alt="" />
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center px-16 py-4 w-full shadow text-sm capitalize bg-white max-md:px-5 max-md:max-w-full" style={{position:"fixed",top:"40px",zIndex:"1000"}}>
        <div className="flex gap-5 justify-center pr-1 max-w-full w-[1150px] max-md:flex-wrap">          
          <Logo />
          <Navigation />
          <LoginButton />
        </div>
      </div>  
    </header>
  );
};

export default Header;