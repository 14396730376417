import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";

const services = [
  {
    title: "Legal Verification",
    description:
      "Certified documents confirm your business’s legal status and help with transactions like mergers, loans, or partnerships.",
  },
  {
    title: "Timely Access",
    description:
      "Quick retrieval of essential documents helps avoid delays in business processes that require official documentation.",
  },
  {
    title: "Compliance Support",
    description:
      "Access to the right documents ensures you meet regulatory and filing requirements, keeping your business compliant.",
  },
  {
    title: "Secure and Reliable",
    description:
      "Our services guarantee that your documents are safely stored and retrieved without risk of errors or misplacement.",
  },
];

const DocumentRetrivalSec = () => {
  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle
            blueheading={"Why Choose Document Retrieval Services?"}
          />
          <div className="text-center mt-4 mb-4">
                <h4 className="medium-level-heading">Compliance Services are essential for your business.</h4>
          </div>
          <Row className="mt-5">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">{service.title}</h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <img
                loading="lazy"
                className="user-img-buisness"
                src={
                  process.env.PUBLIC_URL +
                  "https://cdn.builder.io/api/v1/image/assets/TEMP/534bb250cb442ca035f407b04dc18bd8538e5df48f97ecda01286a617bf90fd4?apiKey=136b58d08a644862944c0e5f22adbbe0&"
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default DocumentRetrivalSec;
