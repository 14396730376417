import React from 'react';
import pic1 from "../../Assets/Images/Container.png"

const Hero = () => {
  return (
    <section className="justify-center mt-12 max-w-full w-[1240px] max-md:mt-10">
      <div className="flex  max-md:flex-col max-md:gap-0">
        <div className="flex flex-col w-6/12 max-md:ml-0 max-md:w-full">
          <div className="flex flex-col grow justify-center self-stretch px-5 py-px font-semibold text-white leading-[100%] max-md:max-w-full">
            {/* <div className="self-start px-6 pt-2.5 pb-2 text-sm uppercase bg-gray-800 rounded max-md:px-5">
              we make business filing easy
            </div> */}
            <h1 className="mt-5 tracking-tighter text-blue-700 max-md:max-w-full max-md:text-4xl" style={{ fontSize: "48px" }}>
              <span className="font-bold font-web-color leading-[72px]">Incorporate</span>
              <br />
              <span className="font-bold font-web-color1 leading-[72px] text-slate-700">your businesses
                effortlessly!</span>
            </h1>
            <p className="mt-3 text-lg font-light text-justify text-slate-600 max-md:max-w-full">
              We provide comprehensive business formation services to confidently launch your new venture. Our experts assist you through every step, across all 50 states.</p>
            <button className="login1 flex flex-col justify-center px-5 py-3 mt-10 max-w-full text-base font-medium text-center capitalize bg-web-color rounded border-2 border-white border-solid shadow-sm w-[305px] max-md:px-5 max-md:mt-10">
              <div className="flex gap-1.5  justify-center py-px pr-1.5">
                <div>Start your business</div>
                <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/b14f2389ff07bb06dcaf4a6fe0da40d25667d21d90e227d10cd3772fef909b3f?apiKey=02362ed84d214b928bb0b669d3dcf7f8&" className="shrink-0 self-start  aspect-square" alt="" />
              </div>
            </button>
            <div className="flex gap-4 items-center pt-px mt-9 text-sm leading-6 text-neutral-600">
              <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1f19d56aed7317c65f482ebdf9ba616a89f35c5052a4a412d84a078eeeef1d9c?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0" alt="Customer satisfaction rating" className="object-contain shrink-0 self-stretch my-auto aspect-[2.62] w-[131px]" />
              <p className="flex flex-col justify-center self-stretch my-auto w-[163px]">
                32k Satisfied Customers
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col ml-5 w-6/12 max-md:ml-0 max-md:w-full">
          <img loading="lazy" src={pic1} className="grow content-center self-stretch w-full aspect-[1.23] max-md:max-w-full" alt="Business illustration" />
        </div>
      </div>
    </section>
  );
};

export default Hero;