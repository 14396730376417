import React, { useContext, useEffect, useRef, useState } from "react";
import AccountSummary from "./AccountSummary";
import ServiceCard from "./ServiceCard";
// import TabNavigation from "./TabNavigation";
import NotificationBanner from "./NotificationBanner";
import WelcomeSection from "./WelcomeSection.js";
import ServiceSection from "./ServiceSection";
import ContactService from "../../services/ContactService.js";
import UserServices from "../../services/userService.js";
import authContext from "../../common/authContext.js";
import { Toast } from "primereact/toast";
import InvoicePayment from "./DataTable/InvoicePayment/InvoicePayment.js";
import MySubscription from "./DataTable/MySubscription/MySubscription.js";
import TourOneModal from "../TourGuideComponent/TourOneModal.js";
import OrderServices from "../../services/orderServices.js";

function DashboardMain() {
  const {
    setFeedbackData,
    accountManagerId,
    setAccountManagerId,
    clearData,
    setFilterStatusId,
    setRedirectedData,
    showModal,
    setShowModal,
  } = useContext(authContext);

  const user = JSON.parse(localStorage.getItem("user"));
  const toast = useRef(null);

  const [count,setCount]= useState(null)

  useEffect(() => {
    clearData();
    setFilterStatusId(null);
    setRedirectedData(null);
    if (user) {
      UserServices.getEmployeeByUserId(user.id)
        .then((res) => {
          console.log(res);
          if (res.data) {
            setAccountManagerId(res.data.id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // if (toast.current !== undefined) {
    //   if (toast.current !== undefined) {
    //     toast.current.show({ severity: 'warn', summary: 'Failed to upload', detail: "Something went wrong", sticky: true });

    //   }
    // }
  }, []);

  useEffect(() => {
    if (user) {
      OrderServices.getLoginData(user?.username)
        .then((res) => {
          setCount(res.data)
        })
        .catch((error) => {
          console.error("Error fetching login data:", error);
        });
    }
  }, []);


  return (
    <div className="flex overflow-hidden flex-col bg-gray-50 pb-[40px] max-md:pb-24">
      <Toast ref={toast} position="bottom" />
      <div className="flex flex-col w-full max-md:max-w-full">
        <div className="flex flex-col p-4  w-full max-md:px-5 max-md:max-w-full">
          {/* <NotificationBanner /> */}
          <WelcomeSection />
        </div>
        <hr className=" m-0 w-full bg-gray-200 min-h-[1px] max-md:max-w-full" />
      </div>
      <div className="flex flex-wrap items-start mt-4 w-full max-md:max-w-full">
        <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
          <ServiceSection />
          <hr className="mt-6 w-full bg-gray-200 min-h-[1px] max-md:max-w-full" />
          <div className="mt-4">
            <InvoicePayment />
          </div>
          <div className="mt-4">
            <MySubscription />
          </div>
        </div>
        <aside className="flex flex-col min-w-[240px] w-[460px] max-md:max-w-full">
          <div className="flex gap-6 items-start pr-4 w-full max-md:pr-5 max-md:max-w-full">
            <div className="flex flex-1 shrink gap-8 items-start w-full basis-0 min-w-[240px] max-md:max-w-full">
              <AccountSummary />
            </div>
          </div>
        </aside>
      </div>
      {count && typeof(count)==="number" && count <= 2 && <TourOneModal show={showModal} onHide={() => setShowModal(false)} />}
    </div>
  );
}

export default DashboardMain;
