import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const services = [
  {
    title: "Processing time",
    description:
      "We can get your company registered within 2 business days, so your start-up plans can move forward without delay!",
  },
  {
    title: "Price",
    description:
      "vState Filings provides affordable services to help you quickly form your company. Select from our range of packages to find the perfect support for your needs.",
  },
  {
    title: "Registered agent",
    description:
      "In many states, a registered agent is required for forming a company. They receive legal documents on your companys behalf. All our vState Filings packages include this service.",
  },
  {
    title: "Security",
    description:
      "When forming an LLC, personal information is required. With vState Filings, we prioritize security to protect your data.",
  },
];

const BuisnessNeeds = () => {
  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="check-iconn me-4"
                        />
                        {service.title}
                      </h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <img
                src={
                  process.env.PUBLIC_URL +
                  "https://cdn.builder.io/api/v1/image/assets/TEMP/28039c3098271ecf12c441995d6d71b369290f8ea9db2cd51da89a58fb1c7978?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                }
                className="user-img-buisness"
              />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default BuisnessNeeds;
