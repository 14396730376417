import React from "react";


const ServiceCommonCard = ({ title, description }) => {
  return (
    <section className="main-section-class">
      <div className="service-white-bggg">
        <div className="main-class-div">
          <h4 className="medium-level-heading">{title}</h4>
          <p className="normal-common-txt-ser">{description}</p>
        </div>
      </div>
    </section>
  );
};

export default ServiceCommonCard;
