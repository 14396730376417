import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CommonHeadingTitle from '../CommonSection/CommonHeadingTitle'

const Flexibility = () => {
    // Data for the flexibility items
    const flexibilityData = [
        {
            count: "1",
            heading: "A modern, easy-to-use, and intuitive platform all in one place",
            text: "Say goodbye to tracking multiple websites and wasting time switching between them. With a single sign-in, you can handle all your company's administrative tasks in one place.",
        },
        {
            count: "2",
            heading: "Register your company in minutes.",
            text: "Say goodbye to tracking multiple websites and wasting time switching between them. With a single sign-in, you can handle all your company's administrative tasks in one place.",
        },
        {
            count: "3",
            heading: "Comprehensive Expertise",
            text: "Say goodbye to tracking multiple websites and wasting time switching between them. With a single sign-in, you can handle all your company's administrative tasks in one place.",
        },
        {
            count: "4",
            heading: "Personalized Customer Support",
            text: "Say goodbye to tracking multiple websites and wasting time switching between them. With a single sign-in, you can handle all your company's administrative tasks in one place.",
        },
    ];

    return (
        <section className='main-section-class'>
            <div className='flexibility'>
                <Container>
                    <CommonHeadingTitle heading={'Helping businesses to operate with '} blueheading={'flexibility'} />
                    <div className='mt-5'>
                        <Row>
                            {flexibilityData.map((item, index) => (
                                <Col lg={6} md={6} sm={12} xs={12} key={index}>
                                    <div className={index % 2 === 0 ? 'main-div-class' : ''}>
                                        <p className='count-number'>{item.count}</p>
                                        <div>
                                            <h4 className='medium-level-heading'>{item.heading}</h4>
                                            <p className='normal-common-txt'>{item.text}</p>
                                        </div>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </div>
                </Container>
            </div>
        </section>
    )
}

export default Flexibility
