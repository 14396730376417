import React from 'react'
import CommonHeadingTitle from '../CommonSection/CommonHeadingTitle'
import { Col, Container, Form, InputGroup, Row } from 'react-bootstrap'
import '../CommonSection/CommonStyle/CommonStyle.css'


const ServicesBanner = () => {
    return (
        <section className='main-section-class '>
            <div className='services-banner'>
                <Container>
                    <div className='text-center'>
                        <CommonHeadingTitle heading={'One-stop business service'} blueheading={'formation'} />
                        <h3>Register your online business effortlessly in all
                            50 US states!</h3>
                        <div>
                            <Row className='justify-content-center mt-5'>
                                <Col lg={5} md={8} sm={10} xs={10}>
                                    <InputGroup className="mb-3">
                                        <Form.Control
                                            placeholder="Enter name of your business"
                                            aria-label="Enter name of your business"
                                            aria-describedby="basic-addon2"
                                        />
                                        <InputGroup.Text id="basic-addon2" className='register-now-btnnn'>Register Now</InputGroup.Text>
                                    </InputGroup>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    )
}

export default ServicesBanner