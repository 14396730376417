import React from 'react'
import '../CommonSection/CommonStyle/CommonStyle.css'
import { Col, Container, Row } from 'react-bootstrap'
import BlueButton from '../CommonSection/BlueButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons'

const AboutUsBanner = () => {
    return (
        <section className='main-section-class'>
            <Container>
                <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <div className='mt-5'>
                            <h3 className='home-banner-heading'><span className='big-blue-text'>Incorporate</span> < br /> your businesses effortlessly!</h3>
                            <p className='home-banner-sub-tile mt-3'>We provide comprehensive business formation services to confidently launch your new venture. Our experts assist you through every step, across all 50 states.</p>
                            <div className='mt-5'>
                                <BlueButton label="Start Your Business" icon={<FontAwesomeIcon icon={faArrowRightLong} />} />
                            </div>
                            <div className='d-flex mt-5'>
                                <img src={process.env.PUBLIC_URL + '/Images/user.png'} className='user-img mt-2' />
                                <p className='home-banner-sub-tile-subscriber ms-3'>32k Satisfied <br /> Customers</p>
                            </div>
                        </div>
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                        <img src={process.env.PUBLIC_URL + '/Images/homebannerimg.png'} className='banner-img' />
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

export default AboutUsBanner