import React from 'react';
import ContactInfo from './ContactInfo';
import ContactForm from './ContactForm';
import { Col, Container, Row } from 'react-bootstrap';

const ContactSection = () => {
  return (
    <div className='mt-24'>   
      <div className='mt-5'>
        <Container>
          <Row>
            <Col lg={6} md={5} sm={12} xs={12}>
              <ContactInfo />
            </Col>
            <Col lg={6} md={7} sm={12} xs={12}>
              <ContactForm />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default ContactSection;