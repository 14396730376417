import React, { useState } from "react";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="flex flex-col w-full bg-white max-md:max-w-full mb-4">
      <button
        className="flex relative justify-between items-center p-4 w-full bg-violet-50 rounded max-md:max-w-full"
        onClick={() => setIsOpen(!isOpen)}
        aria-expanded={isOpen}
      >
        <span className="flex z-0 flex-col flex-1 shrink justify-center self-stretch my-auto text-base font-semibold tracking-tight leading-9 text-gray-800 uppercase basis-0 min-w-[240px] max-md:max-w-full">
          {question}
        </span>
        <span className="flex z-0 justify-center items-end self-stretch my-auto ">
          <div
            className={`flex justify-center items-center px-1  bg-blue-700 rounded  transition-transform ${
              isOpen ? "rotate-180" : ""
            }`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
            >
              <path
                d="M3.33301 8.62012H12.6663"
                stroke="white"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>{" "}
          </div>
        </span>
      </button>
      {isOpen && (
        <div className="flex items-center px-4 pt-4 pb-4 w-full text-base font-light leading-7 text-neutral-600 max-md:max-w-full">
          <p className="flex flex-col flex-1 shrink justify-center self-stretch my-auto w-full basis-0 min-w-[240px] max-md:max-w-full">
            {answer}
          </p>
        </div>
      )}
    </div>
  );
};

const faqData = [
  {
    question: "WHO NEEDS A REGISTERED AGENT?",
    answer:
      "Registered agents are required for businesses in most states. But beyond legal requirements, registered agents are useful for busy entrepreneurs. They are available during business hours to receive legal notices and official state mail so you can focus on other aspects of your business.",
  },
  {
    question:
      "What are the requirements for being a registered agent service provider?",
    answer: "",
  },
  {
    question:
      "Is vState Filings Registered Agent service available in all states?",
    answer: "",
  },
  {
    question: "How much does the Registered Agent service cost?",
    answer: "",
  },
  {
    question: "Can I change my registered agent?",
    answer: "",
  },
  {
    question:
      "What could happen to a business if it doesn't have a registered agent?",
    answer: "",
  },
  {
    question: "Will I be notified if you receive documents on my behalf?",
    answer: "",
  },
];

const FAQ = () => {
  const faqs = [
    {
      question: "WHO NEEDS A REGISTERED AGENT?",
      answer:
        "Registered agents are required for businesses in most states. But beyond legal requirements, registered agents are useful for busy entrepreneurs. They are available during business hours to receive legal notices and official state mail so you can focus on other aspects of your business.",
    },
    {
      question:
        "What are the requirements for being a registered agent service provider?",
      answer: "",
    },
    {
      question:
        "Is vState Filings Registered Agent service available in all states?",
      answer: "",
    },
    {
      question: "How much does the Registered Agent service cost?",
      answer: "",
    },
    {
      question: "Can I change my registered agent?",
      answer: "",
    },
    {
      question:
        "What could happen to a business if it doesn't have a registered agent?",
      answer: "",
    },
    {
      question: "Will I be notified if you receive documents on my behalf?",
      answer: "",
    },
  ];

  return (
    <section className="flex flex-col items-center  pb-24 px-20 mt-7 w-full max-w-[1903px] max-md:px-5 max-md:max-w-full">
      <div className="flex justify-center w-full">
        <div className="flex flex-col flex-1 shrink justify-center w-full basis-0 min-w-[240px] max-md:max-w-full">
          <CommonHeadingTitle heading={"FAQ"} />
          <div className="text-center mb-5">
            <h2 className=""> Most frequent questions and answers</h2>
          </div>
          <div className="flex flex-col w-full max-md:max-w-full">
            <div className="flex flex-wrap gap-6 items-start w-full max-md:max-w-full">
              <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                {faqs.slice(0, 3).map((faq, index) => (
                  <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                  />
                ))}
              </div>
              <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                {faqs.slice(3).map((faq, index) => (
                  <FAQItem
                    key={index}
                    question={faq.question}
                    answer={faq.answer}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
