import axios from "axios";
import authHeader, { initAuth } from "./authHeader";
import encryption from "./CryptoClass";
import propertiesApp from "../utils/propertiesApp";

const getAllCompany = () => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/getAll`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getAllVendor = () => {
    const dataToEncrypt = {
        endpoint: `/contact/api/vendorDetails/getAll`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getAllKeyPersonnel = () => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyKeyPersonnel/getAll`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getRegisteredAgentByStateId = (stateId) => {
    const dataToEncrypt = {
        endpoint: `/core/api/registeredAgent/state/${stateId}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const getAllKeyPersonnelType = () => {
    const dataToEncrypt = {
        endpoint: `/core/api/keyPersonnelTypeMaster/getAll`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllOwnerInformation = (id) => {
    const dataToEncrypt = {
        endpoint: `/order/api/ownerInformation/getByIdCompanyId/${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllKeyPersonnelByCompanyId = (companyId) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyKeyPersonnel/getByCompanyId/${companyId}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllCompanyById = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/getById/${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllCompanyByAccountManagerId = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/getByAccountManagerId/${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveCompany = (data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/save`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveOwnerInfo = (data) => {
    const dataToEncrypt = {
        endpoint: `/order/api/ownerInformation/save`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveCompanyMerge = (data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyMerge/save`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveKeyPersonnel = (data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyKeyPersonnel/save`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updateKeyPersonnel = (data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyKeyPersonnel/update`,
        payload: data,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updateOwnerInfo = (data) => {
    const dataToEncrypt = {
        endpoint: `/order/api/ownerInformation/update`,
        payload: data,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveVendor = (data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/vendorDetails/save`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveCompanyContact = (data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyContactDetails/save`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveCompanyAccountManager = (data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/assignAccountManager`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updateCompany = (data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/update`,
        payload: data,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllCompanyWithContact = () => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyContactDetails/getAll`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllChildCompanyByParentId = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/getChildCompaniesByParentId/${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllChildCompanyByTransfer = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/getChildCompaniesForTransfer/${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllParentCompany = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/companyDetails/getAllParentCompanies`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllParentCompanyList = () => {
    const dataToEncrypt = {
        endpoint: `/contact/api/parentCompanyListings/getAll`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const saveCompanyTransfer = (data) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/ownershipTransferRequest/save`,
        payload: data,
        type: "post",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllCompanyTransferByUserId = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/ownershipTransferRequest/myOwnershipTransferRequest/${id}`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const getAllCompanyTransfer = () => {
    const dataToEncrypt = {
        endpoint: `/contact/api/ownershipTransferRequest/getAllOwnershipTransferRequests`,
        payload: null,
        type: "get",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}

const updateTranferWithCancel = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/ownershipTransferRequest/cancelTransferRequest/${id}`,
        payload: null,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const updateTranferWithCompleted = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/ownershipTransferRequest/markTransferComplete/${id}`,
        payload: null,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const updateTranferWithApproved = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/ownershipTransferRequest/approveTransferRequest/${id}`,
        payload: null,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const updateTranferWithRejected = (id) => {
    const dataToEncrypt = {
        endpoint: `/contact/api/ownershipTransferRequest/rejectTransferRequest/${id}`,
        payload: null,
        type: "put",
        auth: authHeader()
    };

    const encryptedData = encryption.encryptData(dataToEncrypt)
    console.log(encryptedData)
    try {

        return axios.post(`/decrypt`, dataToEncrypt,


            { headers: initAuth() }
        )
    } catch (error) {
        return []
    }
}


const companyMasterService = {
    getAllCompany,
    getAllCompanyWithContact,
    saveCompany,
    getAllCompanyById,
    updateCompany,
    getAllVendor,
    saveVendor,
    getAllKeyPersonnel,
    getAllKeyPersonnelType,
    saveKeyPersonnel,
    getAllKeyPersonnelByCompanyId,
    updateKeyPersonnel,
    getAllCompanyByAccountManagerId,
    saveCompanyContact,
    saveCompanyAccountManager,
    getRegisteredAgentByStateId,
    getAllOwnerInformation,
    saveOwnerInfo,
    updateOwnerInfo,
    saveCompanyMerge,
    getAllChildCompanyByParentId,
    getAllParentCompany,
    saveCompanyTransfer,
    getAllCompanyTransferByUserId,
    updateTranferWithApproved,
    updateTranferWithCancel,
    updateTranferWithCompleted,
    updateTranferWithRejected,
    getAllParentCompanyList,
    getAllCompanyTransfer,
    getAllChildCompanyByTransfer
}

export default companyMasterService;