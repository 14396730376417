import React from "react";
import "../CommonStyleTour/CommonStyleTour.css";
import CommonTourWhite from "../CommonTourComponent/CommonTourWhite";
import CommonTourBlue from "../CommonTourComponent/CommonTourBlue";
import CompanyTourModal from "../CompanyTourModal";

const TourThird = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const handleStartClick = () => {
    setModalShow(true);
  };

  return (
    <section className="main-class-tour">
      <div>
        <h4 className="tour-heading">Welcome to Your Company Profile</h4>
        <p className="tour-text-holder">
          This is your company's central hub. Here, you can manage all aspects
          of your company's information and operations.
        </p>
      </div>
      <div>
        <CommonTourBlue label={"Start"} onclick={handleStartClick} />
        {/* <div className="mt-3">
          <CommonTourWhite label={"Skip"} />
        </div> */}
        <CompanyTourModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </section>
  );
};

export default TourThird;
