import React from "react";
import Header from "../RegistredAgentService/Header";
import Partners from "../Partners";
import ComplianceServices from "./Complianc Service/ComplianceServices";
import ComplianceSection from "./Compliance/ComplianceSection.js";
import FAQ from "../RegistredAgentService/Fact.js";
import ContactForm from "./ContactForm.js";
import CorporateSupliesServices from "./Complianc Service/CorporateSupliesService.js";
import CorporateSuppliesPic from "../../../Assets/Images/Corporate Suplies.png";
import RegisteredBanner from "../RegistredAgentService/RegisteredBanner.js";
import CorporateSuppliesSec from "../AllServices/CorporateSuppliesPage/CorporateSuppliesSec.js";
import CorporateSuppliesThird from "../AllServices/CorporateSuppliesPage/CorporateSuppliesThird.js";
import FaqCommonSection from "../CommonSection/FaqCommonSection.js";
import OurTrustedSection from "../CommonSection/OurTrustedSection.js";

const complianceData = {
  sectionTitle: "Why Source Corporate Supplies?",
  sectionSubtitle: "Compliance Services are essential for your business.",
  items: [
    {
      title: "Legal Compliance",
      description:
        "Having the proper corporate supplies ensures your business complies with legal requirements for record-keeping and issuance of shares or membership units.",
    },
    {
      title: "Professional Presentation",
      description:
        "Corporate kits and seals enhance the professional image of your business when conducting official transactions or presentations.",
    },
    {
      title: "Simplified Organization",
      description:
        "Corporate kits provide organized storage for essential documents, making it easy to reference them when needed.",
    },
    {
      title: "Customizable Solutions",
      description:
        "Our supplies can be customized to meet the specific needs of your business, from branded stock certificates to personalized corporate seals.",
    },
  ],
  images: {
    backgroundImage: CorporateSuppliesPic,
    iconImage:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/2517a6129f7ab66094c10670002e3c726e87b73dba9f0efd07fceaf970443933?apiKey=136b58d08a644862944c0e5f22adbbe0&",
  },
};

const CorporateSuplies = () => {
  return (
    <div className="mt-24">
      <RegisteredBanner />
      <OurTrustedSection />
      <CorporateSuppliesSec />
      <CorporateSuppliesThird />
      {/* <FaqCommonSection/> */}
      <main className="flex flex-col items-center px-80 pt-16 pb-20 max-md:px-5">
        <div className="flex flex-col px-5 w-full max-w-[1240px] max-md:max-w-full">
          {/* <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <Header />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <Partners />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <ComplianceSection complianceData={complianceData} />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <CorporateSupliesServices />
          </div> */}
          {/* <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <FAQ />
          </div> */}
          <div className="flex flex-col px-8 website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <p className="contact-form-haeding-main-text1">
              Need help to{" "}
              <span className="contact-form-haeding-main-text2">
                stay compliant?
              </span>
            </p>
            <ContactForm />
          </div>
        </div>
      </main>
    </div>
  );
};

export default CorporateSuplies;
