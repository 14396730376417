export function extractTenDigitPhoneNumber(input) {
    if (typeof (input) === "string") {
        const temp3 = input.split(" ")
        if (Array.isArray(temp3)) {
            console.log(temp3)
            if (temp3.length > 0) {
                return (temp3.slice(1).join(" "))
            }
        }
    }

}