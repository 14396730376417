import React from 'react'

const CommonTourWhite = ({label, icon, onclick}) => {
  return (
    <section className='main-class-white-btn'>
        <button className='white-btnnn'  onClick={onclick}>{label} {icon}</button>
    </section>
  )
}

export default CommonTourWhite