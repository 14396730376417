import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";

const services = [
  {
    title: "Seamless Compliance",
    description:
      "Subscription-based services ensure that regular filings and legal responsibilities are handled without needing to manually track deadlines.",
  },
  {
    title: "Ongoing Support",
    description:
      "With services like registered agent representation, you have continuous support in handling legal documents and notices on your behalf.",
  },
  {
    title: "Reduced Risk",
    description:
      "By staying subscribed, you reduce the risk of missing important filings or legal notices that could disrupt business operations.",
  },
  {
    title: "Cost-Effective Solutions",
    description:
      "Subscriptions offer a cost-effective way to maintain compliance and access critical services regularly.",
  },
];

const SubscriptionSec = () => {
  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle
            blueheading={"Why Choose Subscription Services?"}
          />
          <h4 className="medium-level-heading text-center mt-4 mb-4">
            Compliance Services are essential for your business.
          </h4>
          <Row className="mt-5">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">{service.title}</h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <img
                loading="lazy"
                className="user-img-buisness"
                src={
                  process.env.PUBLIC_URL +
                  "https://cdn.builder.io/api/v1/image/assets/TEMP/534bb250cb442ca035f407b04dc18bd8538e5df48f97ecda01286a617bf90fd4?apiKey=136b58d08a644862944c0e5f22adbbe0&"
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default SubscriptionSec;
