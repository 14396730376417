import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";

const services = [
  {
    title: "Protect Business Transactions",
    description:
      "UCC filings secure your interests in commercial transactions, making it clear who holds rights to assets or collateral.",
  },
  {
    title: "Legal Confidence",
    description:
      "Whether filing or searching for liens, UCC filings ensure that your transactions are protected and verified.",
  },
  {
    title: "Risk Mitigation",
    description:
      "By filing UCCs, you avoid disputes over asset ownership and protect your claims in financial agreements.",
  },
  {
    title: "Comprehensive Search Solutions",
    description:
      "Our UCC search services help uncover existing liens and ensure that any potential risks in transactions are identified.",
  },
];

const UcFillingSec = () => {
  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle blueheading={"Why do UCC Filings Matter?"} />
          <div className="text-center">
            <h4>Compliance Services are essential for your business.</h4>
          </div>
          <Row className="mt-5">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">{service.title}</h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <img
                loading="lazy"
                className="user-img-buisness"
                src={
                  process.env.PUBLIC_URL +
                  "https://cdn.builder.io/api/v1/image/assets/TEMP/534bb250cb442ca035f407b04dc18bd8538e5df48f97ecda01286a617bf90fd4?apiKey=136b58d08a644862944c0e5f22adbbe0&"
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default UcFillingSec;
