import React, { useState, useEffect } from "react";

const Ribbon = ({ messages, autoHideDuration = 20000 }) => {
  const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    if (!messages || messages.length === 0) return;

    const timer = setTimeout(() => {
      setVisible(false);
      setTimeout(() => {
        setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length); 
        setVisible(true); 
      }, 500); 
    }, autoHideDuration);

    return () => clearTimeout(timer); 
  }, [currentMessageIndex, messages, autoHideDuration]);

  if (!visible || !messages || messages.length === 0) return null;

  return (
    <div
      className="fixed bottom-0 left-0 right-0 bg-web-color text-white p-4 flex justify-between items-center shadow-lg overflow-hidden"
      style={{ zIndex: 1000 }}
    >
      <div className="relative overflow-hidden flex-grow">
        <div className="marquee">
          <span>{messages[currentMessageIndex]}</span>
        </div>
      </div>
      <button
        className="text-white ml-4"
        onClick={() => setVisible(false)}
        aria-label="Close"
      >
        <i className="pi pi-times"></i>
      </button>
    </div>
  );
};

export default Ribbon;