import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";
import ServiceCommonCard from "../../CommonSection/ServiceCommonCard";
import BlueButton from "../../CommonSection/BlueButton";

const DocumentRetrivalThird = () => {
  const services = [
    {
      title: "Certificate of Good Standing",
      description:
        "A document issued by a state that certifies a business is compliant with state regulations. It may be required for various transactions, including loans and contracts.",
    },
    {
      title: "Formation Document",
      description:
        "The official documents filed to establish a business entity, such as Articles of Incorporation or Organization. These documents are essential for verifying the existence of the entity.",
    },
    {
      title: "Amendment Document",
      description:
        "Documentation related to changes made to an existing business entity, such as changes in structure, ownership, or operational details. This serves as a legal record of amendments.",
    },
  ];

  return (
    <section>
      <Container>
        <CommonHeadingTitle blueheading={"Compliance Services"} />
        <div className="text-center mt-5 mb-5">
          <h4 className="subheading">
            Compliance services ensure that your business adheres to regulatory
            requirements, including filing annual reports, reinstating inactive
            entities, and completing publication obligations. Compliance is
            essential to maintain your business's good standing and avoid legal
            or financial penalties.
          </h4>
        </div>
        <Row className="justify-content-center">
          {services.map((service, index) => (
            <Col key={index} lg={4} md={4} sm={6} xs={12}>
              <ServiceCommonCard
                title={service.title}
                description={service.description}
              />
            </Col>
          ))}
        </Row>
        <div className="text-center mt-5 mb-5">
          <BlueButton label={"Get Quote"} />
        </div>
      </Container>
    </section>
  );
};

export default DocumentRetrivalThird;
