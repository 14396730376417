import React, { useContext, useEffect, useRef, useState } from "react";
import { Card } from "primereact/card";
import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
// import AddGroup from './AddComponents/AddGroup';
import GroupDataTable from "./GroupDataTable";
import UploadImage from "./AddComponents/UploadImage";
// import AddContact from './AddComponents/AddContact';
import DynamicForm from "./AddComponents/DynamicForm";
import { Avatar, IconButton } from "@mui/material";
import { Dropdown } from "primereact/dropdown";
import profileBg from "../Assets/Images/prof-bg.png";
import ProfilePic from "../Assets/Images/Avatar profile photo.png";
import { CameraAlt } from "@mui/icons-material";
import ContactService from "../services/ContactService";
import { Checkbox } from "primereact/checkbox";
import { RadioButton } from "primereact/radiobutton";
import errorMessages, { globalMessages } from "../utils/errorMessages";
import { Toast } from "primereact/toast";
import ForgotPasswordService from "../services/forgotPasswordService";
import AddressService from "../services/addressService";
import authContext from "../common/authContext";
import { InputMask } from "primereact/inputmask";

export default function Profile() {
  const {
    setFeedbackData,
    permissions,
    globalCompany,
    setGlobalCompany,
    isLoading,
    setIsLoading,
    fileBase64,
    setIsTabRefresh,
  } = useContext(authContext);

  const [visible, setVisible] = useState(false);
  const [isImageVisible, setImageVisible] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType1, setPasswordType1] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const [passwordErr, setPasswordErr] = useState("");

  const [oldpasswordErr, setOldPasswordErr] = useState("");

  const [confirmPasswordErr, setConfirmPasswordErr] = useState("");

  const [password, setPassword] = useState("");
  const [confirmedPassword, setConfirmPassword] = useState("");
  const [oldPassword, setOldPassword] = useState("");

  const user = JSON.parse(localStorage.getItem("user"));

  const [addressData, setAddressData] = useState(null);

  const handleDialogHide = () => {
    setVisible(false);
  };

  const handleImageClick = () => {
    setImageVisible(true);
  };

  const hideImageDialog = () => {
    setImageVisible(false);
  };

  const [userName, setUserName] = useState("");

  const [email, setEmail] = useState("");

  const [isShowContent, setIsShowContent] = useState(false);

  const [isShowContent1, setIsShowContent1] = useState(false);

  const [isShowContent2, setIsShowContent2] = useState(false);

  const [isShowContent3, setIsShowContent3] = useState(false);

  const [addr1, setAddr1] = useState("");

  const [addr1Err, setAddr1Err] = useState("");

  const [addr2, setAddr2] = useState("");

  const [addr2Err, setAddr2Err] = useState("");

  const [addr3, setAddr3] = useState("");

  const [region, setRegion] = useState("");

  const [regionErr, setRegionErr] = useState("");

  const [city, setCity] = useState("");

  const [cityErr, setcityErr] = useState("");

  const [states, setStates] = useState(null);

  const [statesErr, setStatesErr] = useState("");

  const [zip, setZip] = useState("");

  const [zipErr, setZipErr] = useState("");

  const [salutation, setSalutation] = useState("");

  const [firstName, setFirstName] = useState(null);

  const [middleName, setMiddleName] = useState("");

  const [lastName, setLastName] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState(null);

  const [altPhoneNumber, setAltPhoneNumber] = useState(null);
  const [phone, setPhone] = useState("");

  const [altEmail, setAltEmail] = useState("");

  const [fax, setFax] = useState(null);

  const [altFax, setAltFax] = useState("");

  const [data, setData] = useState(null);

  const [emailErr, setEmailErr] = useState("");

  const [phoneErr, setPhoneErr] = useState("");

  const [altPhoneErr, setAltPhoneErr] = useState("");

  const [lastNameErr, setLastNameErr] = useState("");

  const [firstNameErr, setFirstNameErr] = useState("");

  const [dataId, setDataId] = useState(null);

  const [contactDetails, setContactDeatils] = useState(null);

  const [companyDetails, setCompanyDetails] = useState(null);

  const [allAddressType, setAllAddressType] = useState([]);

  const [addressType, setAddressType] = useState(null);

  const [allStates, setAllStates] = useState([]);

  const [allCountries, setAllCountries] = useState([]);

  const [countryData, setCountryData] = useState(null);

  const [stateValue, setStateValue] = useState(null);

  const [countryValue, setCountryValue] = useState(null);

  const [allCompanies, setAllCompanies] = useState([]);

  const [company, setCompany] = useState(null);

  const [allRoles, setAllRoles] = useState([]);

  const [role, setRole] = useState(null);

  const [tempProduct, setTempProduct] = useState([]);

  const [isSort, setIsSort] = useState(false);

  const [faxErr, setFaxErr] = useState("");

  const [addressTypeErr, setAddressTypeErr] = useState("");

  const [companyErr, setCompanyErr] = useState("");

  const [countryErr, setCountryErr] = useState("");

  const [selectedCountry, setSelectedCountry] = useState(null);
  const countries = [{ name: "United States", code: "US", dialCode: "+1" }];
  const [superAdminId, setSuperAdminId] = useState(null);

  function checkIdsExist(arr, idsToCheck) {
    // Check if any of the idsToCheck are found in arr
    const foundIds = idsToCheck.filter((idToCheck) =>
      arr.some((item) => item.id === idToCheck)
    );
    return foundIds.length > 0 ? foundIds : null;
  }

  useEffect(() => {
    if (user) {
      if (user.companyRoles !== undefined) {
        if (user.companyRoles.length > 0) {
          if (user.companyRoles[0].roles.length > 0) {
            const id = checkIdsExist(user.companyRoles[0].roles, [1, 4, 2]);
            const empId = checkIdsExist(user.companyRoles[0].roles, [2]);
            console.log(id);
            setSuperAdminId(empId);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    console.log(dataId);
    setSelectedCountry("+1");
    setCountryData(3);
    AddressService.getAllAddressByContactId(dataId)
      .then((res) => {
        console.log(res);
        if (res.data !== "") {
          const tempArr = res.data;
          const temp = tempArr.filter(
            (val) => val.addressType.type === "CONTACT"
          );
          console.log(temp);
          if (temp.length > 0) {
            setAddressData(temp[0]);
            setAddr1(temp[0].addressLine1);
            setAddr2(temp[0].addressLine2);
            setCity(temp[0].city);
            setZip(temp[0].postalCode);
            setStates(temp[0].stateId);
            // setCountryData(temp[0].countryId)
            setAddressType(temp[0].addressType);
            setContactDeatils(temp[0].contactDetails);
            const temps = allStates.filter((val) => val.id === temp[0].stateId);
            const temp1 = allCountries.filter(
              (val) => val.id === temp[0].countryId
            );
            console.log(temps);
            if (temps.length > 0 && temp1.length > 0) {
              setStateValue(temps[0].stateFullDesc);
              setCountryValue(temp1[0].countryFullDesc);
            }
          }
        } else {
          setAddressData(null);
          setAddr1("");
          setAddr2("");
          setCity("");
          setZip("");
          setStates(null);
          // setCountryData(null)
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [dataId]);

  useEffect(() => {
    // companyMasterService.getAllCompany()
    //     .then((res) => {
    //         console.log(res)
    //         setAllCompanies(res.data)
    //     })
    //     .catch((err) => {
    //         console.log(err)
    //     })

    AddressService.getAllAddressType()
      .then((res) => {
        setAllAddressType(res.data);
        console.log(res.data);
        if (Array.isArray(res.data)) {
          const temp = res.data.filter((val) => val.type === "CONTACT");
          if (temp.length > 0) {
            setAddressType(temp[0]);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });

    AddressService.getAllCountry()
      .then((res) => {
        console.log(res);
        setAllCountries(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    AddressService.getAllStates()
      .then((res) => {
        console.log(res);
        setAllStates(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const selectedCountryTemplate = (option, props) => {
    if (option) {
      return (
        <div className="flex align-items-center">
          <img
            alt={option.name}
            src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
            className={`mr-2 flag flag-${option.code.toLowerCase()}`}
            style={{ width: "28px" }}
          />
          <div>{option.dialCode}</div>
        </div>
      );
    }

    return <span>{props.placeholder}</span>;
  };

  const countryOptionTemplate = (option) => {
    return (
      <div className="flex align-items-center">
        <img
          alt={option.name}
          src="https://primefaces.org/cdn/primereact/images/flag/flag_placeholder.png"
          className={`mr-2 flag flag-${option.code.toLowerCase()}`}
          style={{ width: "18px" }}
        />
        <div className="webapp-sm-size">{option.code}</div>
      </div>
    );
  };

  const panelFooterTemplate = () => {
    return (
      <div className="py-2 px-3">
        {selectedCountry ? (
          <span>
            <b>{selectedCountry.name}</b> selected.
          </span>
        ) : (
          "No country selected."
        )}
      </div>
    );
  };

  const [loginDetails, setLoginDetails] = useState([
    { key: "Email Address", value: `${user ? user.username : ""}` },
    { key: "Password", value: "dhdhdhdhhdhd" },
  ]);

  const [products, setProducts] = useState([]);

  useEffect(() => {
    setIsTabRefresh(true);
    if (user?.id) {
      ContactService.getAllContactByUserId(user?.id)
        .then((res) => {
          console.log(res);
          let data = res.data;

          if (res.data) {
            if (res.data?.contactDetails !== undefined) {
              setDataId(res.data?.contactDetails?.id);
              setUserName(
                `${res.data?.contactDetails?.firstName} ${res.data?.contactDetails?.lastName}`
              );
              setEmail(res.data?.contactDetails?.emailId);
              setData(res.data);
              setContactDeatils(res.data?.contactDetails);
            }

            if (res.data?.companyDetails !== undefined) {
              setCompanyDetails(res.data?.companyDetails);
              if (Array.isArray(res.data?.companyDetails)) {
                console.log(globalCompany);
                if (globalCompany) {
                  const temp = res.data?.companyDetails.filter(
                    (val) => val.id === globalCompany.companyDetails.id
                  );
                  console.log(temp);
                  if (temp.length > 0) {
                    setCompany(temp);
                  }
                }
              }
            }
            setTimeout(() => {
              setIsTabRefresh(false);
            }, 800);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsTabRefresh(false);
        });
    }
  }, [globalCompany, isLoading]);

  useEffect(() => {
    if (data) {
      if (data.contactDetails !== undefined) {
        setFirstName(data.contactDetails.firstName);
        setLastName(data.contactDetails.lastName);

        if (data.contactDetails.mobileNo) {
          // const temp3 = data.contactDetails.mobileNo.split(" ")
          // if (Array.isArray(temp3)) {
          //     if (temp3.length > 0) {
          //         setPhone(temp3.slice(1).join(" "))
          //     }
          // }
          setPhone(data.contactDetails.mobileNo);
        }
        if (data.contactDetails.fax) {
          const temp3 = data.contactDetails.fax.split(" ");
          if (Array.isArray(temp3)) {
            if (temp3.length > 0) {
              setFax(temp3.slice(1).join(" "));
            }
          }
        }
        if (data.contactDetails.phone) {
          const temp3 = data.contactDetails.phone.split(" ");
          if (Array.isArray(temp3)) {
            if (temp3.length > 0) {
              setAltPhoneNumber(temp3.slice(1).join(" "));
            }
          }
        }
        setEmail(data.contactDetails.emailId);
        setSalutation(data.contactDetails.salutation);
      }
    }
  }, [data]);

  const getInitials = (name) => {
    const nameArray = name.trim().split(" ");
    if (nameArray.length >= 2) {
      const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
      const lastNameInitial = nameArray[1].charAt(0).toUpperCase();
      return firstNameInitial + lastNameInitial;
    } else {
      const initial = nameArray[0].charAt(0).toUpperCase();
      return initial + initial;
    }
  };

  const [isEditing, setIsEditing] = useState(false);
  const [isEditing1, setIsEditing1] = useState(false);
  const [isEditing2, setIsEditing2] = useState(false);

  const toast = useRef(null);

  const handleEdit1 = () => {
    setIsEditing1(true);
  };

  const handleEdit2 = () => {
    setIsEditing2(true);
  };

  const [editLoginValues, setEditLoginValues] = useState(
    loginDetails.reduce((acc, item) => {
      acc[item.key] = item.value;
      return acc;
    }, {})
  );

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handlePassword = (e) => {
    setPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setPasswordErr(errorMessages.passwordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          value
        )
      ) {
        setPasswordErr(errorMessages.passwordValidation);
      }
    }
    setPassword(e.target.value);
  };

  const handleOldPassword = (e) => {
    setOldPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setOldPasswordErr(errorMessages.oldPasswordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          value
        )
      ) {
        setOldPasswordErr(errorMessages.oldPasswordValidation);
      }
    }
    setOldPassword(e.target.value);
  };

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const togglePassword1 = () => {
    if (passwordType1 === "password") {
      setPasswordType1("text");
      return;
    }
    setPasswordType1("password");
  };

  const togglePassword2 = () => {
    if (passwordType2 === "password") {
      setPasswordType2("text");
      return;
    }
    setPasswordType2("password");
  };

  const handleConfirmPassword = (e) => {
    setConfirmPasswordErr("");
    let value = e.target.value;
    if (value === "") {
      setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
    }
    if (value && value.trim() !== "") {
      if (
        !/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/.test(
          value
        )
      ) {
        setConfirmPasswordErr(errorMessages.confirmPasswordValidation);
      }
    }
    setConfirmPassword(e.target.value);
  };

  const handleEmail = (e) => {
    setEmailErr("");
    let value = e.target.value;
    if (value === "") {
      setEmailErr(errorMessages.emailRequired);
    }
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
      setEmailErr(errorMessages.emailValidation);
    }
    setEmail(e.target.value);
  };

  const handleLastName = (e) => {
    setLastNameErr("");
    let value = e.target.value;
    if (value === "") {
      setLastNameErr(errorMessages.lastNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setLastNameErr(errorMessages.lastNameValidation);
      }
    }
    setLastName(e.target.value);
  };

  const handleFirstName = (e) => {
    setFirstNameErr("");
    let value = e.target.value;
    if (value === "") {
      setFirstNameErr(errorMessages.firstNameRequired);
    }
    if (value && value.trim() !== "") {
      if (!/[A-Za-z]+$/.test(value)) {
        setFirstNameErr(errorMessages.firstNameValidation);
      }
    }
    setFirstName(e.target.value);
  };

  const handlePhone = (e) => {
    setPhoneErr("");
    let value = e.target.value;

    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }
    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
    );
    if (inputValue.length === 10) {
      // Only show formatted value after 10 digits
      e.target.value = formattedValue;
      setPhone(formattedValue); // Update state with formatted number
    } else {
      e.target.value = inputValue;
      setPhone(inputValue);
    }
    if (value === "") {
      setPhoneErr(errorMessages.phoneRequired);
    }
    if (value && value.trim() !== "" && inputValue.length <= 9) {
      if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          value
        )
      ) {
        setPhoneErr(errorMessages.phoneValidation);
      }
    }
    setPhone(e.target.value);
  };

  // const handleAlternatePhone = (e) => {
  //     setPhoneErr("")
  //     let value = e.target.value

  //     if (value && value.trim() !== '') {
  //         if (!/^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value)) {
  //             setPhoneErr(errorMessages.phoneValidation)
  //         }

  //     }
  //     setAltPhoneNumber(e.target.value)
  // }

  const handleAlternatePhone = (e) => {
    setAltPhoneErr("");
    let value = e.target.value;

    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }
    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
    );
    if (inputValue.length === 10) {
      // Only show formatted value after 10 digits
      e.target.value = formattedValue;
      setAltPhoneNumber(formattedValue); // Update state with formatted number
    } else {
      e.target.value = inputValue;
      setAltPhoneNumber(inputValue);
    }

    if (value === "") {
      setAltPhoneErr(errorMessages.phoneRequired);
    }
    if (value && value.trim() !== "" && inputValue.length <= 9) {
      if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          value
        )
      ) {
        setAltPhoneErr(errorMessages.phoneValidation);
      }
    }
    setAltPhoneNumber(e.target.value);
  };

  // const handleFax = (e) => {
  //   setFaxErr("");
  //   let value = e.target.value;

  //   if (value && value.trim() !== "") {
  //     if (
  //       !/^(\+1|1)?[-.\s]?\(?[2-9]\d{2}\)?[-.\s]?\d{3}[-.\s]?\d{4}$/.test(value)
  //     ) {
  //       setFaxErr(errorMessages.faxValidation);
  //     }
  //   }
  //   setFax(e.target.value);
  // };
  const handleFax = (e) => {
    setFaxErr("");
    let value = e.target.value;

    let inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
    if (inputValue.length > 10) {
      inputValue = inputValue.slice(0, 10); // Limit to 10 digits
    }
    const formattedValue = inputValue.replace(
      /(\d{3})(\d{3})(\d{4})/,
      (match, p1, p2, p3) => `(${p1}) ${p2}-${p3}`
    );
    if (inputValue.length === 10) {
      // Only show formatted value after 10 digits
      e.target.value = formattedValue;
      setFax(formattedValue); // Update state with formatted number
    } else {
      e.target.value = inputValue;
      setFax(inputValue);
    }

    if (value === "") {
      setFaxErr(errorMessages.phoneRequired);
    }
    if (value && value.trim() !== "" && inputValue.length <= 9) {
      if (
        !/^\+?\d{1,4}?[-.\s]?\(?\d{1,4}\)?[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/.test(
          value
        )
      ) {
        setFaxErr(errorMessages.phoneValidation);
      }
    }
    setFax(e.target.value);
  };
  const handleZip = (e) => {
    setZipErr("");
    let value = e.target.value;

    if (value && value.trim() !== "") {
      if (!/^\d+$/.test(value)) {
        setZipErr("Enter valid zip code");
      }
    }
    setZip(e.target.value);
  };

  const handleSave = (e) => {
    console.log(e.target.value, "testvalues");

    setIsEditing(false);
    console.log("insideHandleSave");
    if (data) {
      const actualdata = data.contactDetails;
      actualdata["firstName"] = firstName;
      actualdata["lastName"] = lastName;
      actualdata["mobileNo"] = phoneNumber;
      actualdata["emailId"] = email;
      actualdata["fax"] = fax;
      actualdata["phone1"] = altPhoneNumber;
      actualdata["salutation"] = salutation;

      console.log(actualdata, "actualdata");

      const actualdata1 = {
        id: data.contactDetails.id,
        salutation: salutation,
        jobTitle: "Java Developer",
        contactSourceId: null,
        recordTypeId: null,
        avatarImgPath: null,
        phone: `${selectedCountry} ${altPhoneNumber}`,
        fax: `${selectedCountry} ${fax}`,
        statusId: 1,
      };

      if (firstName && lastName && phone && email) {
        setIsLoading(true);
        ContactService.updateContact(actualdata1, "")
          .then((res) => {
            console.log(res);
            setIsEditing1(false);
            setIsLoading(false);
            if (toast.current !== undefined) {
              toast.current.show({
                severity: "success",
                summary: "Profile updated successfully",
                life: 3000,
                content: (props) => (
                  <div
                    className="flex flex-column align-items-left"
                    style={{ flex: "1" }}
                  >
                    <div className="flex align-items-center gap-2">
                      {/* <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }}>
                                                <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                                            </Avatar> */}
                      <span className="p-toast-summary">vState Filings</span>
                    </div>
                    <div className="p-toast-detail">
                      {props.message.summary}
                    </div>
                    {/* <Button className=" signup-btn vstate-button flex" label="Ok" severity="success" onClick={() => navigate("/signin")}></Button> */}
                  </div>
                ),
              });
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            if (toast.current !== undefined) {
              toast.current.show({
                severity: "warn",
                summary: "Something went wrong",
                life: 3000,
              });
            }
          });
      } else {
        if (!firstName) {
          setFirstNameErr(errorMessages.firstNameRequired);
        }
        if (!lastName) {
          setLastNameErr(errorMessages.lastNameRequired);
        }
        if (!phoneNumber) {
          setPhoneErr(errorMessages.phoneRequired);
        }
        if (!email) {
          setEmailErr(errorMessages.emailRequired);
        }
      }
    }
  };

  const handleSave2 = () => {
    console.log(addressData);
    if (addressData) {
      handleAddressEdit();
    } else {
      handleAddressSave();
    }
  };

  const handleAddressEdit = () => {
    if (addressData) {
      const actualdata = addressData;
      actualdata["addressLine1"] = addr1;
      actualdata["addressLine2"] = addr2;
      actualdata["city"] = city;
      actualdata["stateMasterId"] = states;
      actualdata["countryMasterId"] = countryData;
      actualdata["postalCode"] = zip;
      actualdata["stateId"] = states;
      actualdata["countryId"] = countryData;

      if (
        addr1 !== "" &&
        city !== "" &&
        zip !== "" &&
        contactDetails &&
        company &&
        addressType &&
        states &&
        countryData
      ) {
        setIsLoading(true);
        AddressService.updateAllAddress(actualdata)
          .then((res) => {
            console.log(res.data);
            setIsEditing2(false);
            setIsLoading(false);
            if (toast.current !== undefined) {
              toast.current.show({
                severity: "success",
                summary: "Address updated successfully",
                life: 3000,
                content: (props) => (
                  <div
                    className="flex flex-column align-items-left"
                    style={{ flex: "1" }}
                  >
                    <div className="flex align-items-center gap-2">
                      {/* <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }}>
                                                <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                                            </Avatar> */}
                      <span className="p-toast-summary">vState Filings</span>
                    </div>
                    <div className="p-toast-detail">
                      {props.message.summary}
                    </div>
                    {/* <Button className=" signup-btn vstate-button flex" label="Ok" severity="success" onClick={() => navigate("/signin")}></Button> */}
                  </div>
                ),
              });
            }
          })
          .catch((err) => {
            console.log(err);
            setIsLoading(false);
            if (toast.current !== undefined) {
              toast.current.show({
                severity: "warn",
                summary: "Something went wrong",
                life: 3000,
              });
            }
          });
      } else {
        if (addr1 === "") {
          setAddr1Err(errorMessages.address1Required);
        }
        // if (addr2 === "") {
        //     setAddr2Err(errorMessages.address2Required)
        // }
        if (city === "") {
          setcityErr(errorMessages.cityRequired);
        }
        if (zip === "") {
          setZipErr(errorMessages.zipRequired);
        }
        if (!company) {
          setCompanyErr(errorMessages.companyRequired);
        }
        if (!addressType) {
          setAddressTypeErr(errorMessages.addressTypeRequired);
        }
        if (!states) {
          setStatesErr(errorMessages.statesRequired);
        }
        if (!countryData) {
          setCountryErr(errorMessages.countryRequired);
        }
      }
    }
  };

  const handleAddressSave = () => {
    const actualdata = {
      addressLine1: addr1,
      addressLine2: addr2,
      city: city,
      stateId: states,
      postalCode: zip,
      countryId: countryData,
      contactDetails: contactDetails,
      companyDetails: company,
      addressType: addressType,
    };

    console.log(actualdata);

    if (
      addr1 !== "" &&
      city !== "" &&
      zip !== "" &&
      contactDetails &&
      company &&
      addressType &&
      states &&
      countryData
    ) {
      setIsLoading(true);
      AddressService.saveAllAddress(actualdata)
        .then((res) => {
          console.log(res);
          setIsEditing2(false);
          setIsLoading(false);
          if (toast.current !== undefined) {
            toast.current.show({
              severity: "success",
              summary: "Address added successfully",
              life: 3000,
              content: (props) => (
                <div
                  className="flex flex-column align-items-left"
                  style={{ flex: "1" }}
                >
                  <div className="flex align-items-center gap-2">
                    {/* <Avatar shape="circle" style={{ backgroundColor: '#0047FF', color: '#ffffff' }}>
                                            <div dangerouslySetInnerHTML={{ __html: svgContent }} />
                                        </Avatar> */}
                    <span className="p-toast-summary">vState Filings</span>
                  </div>
                  <div className="p-toast-detail">{props.message.summary}</div>
                  {/* <Button className=" signup-btn vstate-button flex" label="Ok" severity="success" onClick={() => navigate("/signin")}></Button> */}
                </div>
              ),
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          if (toast.current !== undefined) {
            toast.current.show({
              severity: "warn",
              summary: "Something went wrong",
              life: 3000,
            });
          }
        });
    } else {
      if (addr1 === "") {
        setAddr1Err(errorMessages.address1Required);
      }
      // if (addr2 === "") {
      //     setAddr2Err(errorMessages.address2Required)
      // }
      if (city === "") {
        setcityErr(errorMessages.cityRequired);
      }
      if (zip === "") {
        setZipErr(errorMessages.zipRequired);
      }
      if (!company) {
        setCompanyErr(errorMessages.companyRequired);
      }
      if (!addressType) {
        setAddressTypeErr(errorMessages.addressTypeRequired);
      }

      if (!states) {
        setStatesErr(errorMessages.statesRequired);
      }
      if (!countryData) {
        setCountryErr(errorMessages.countryRequired);
      }
    }
  };

  const handleSave1 = () => {
    const newData = loginDetails.map((item) => ({
      ...item,
      value: editLoginValues[item.key],
    }));
    setLoginDetails(newData);

    if (
      password !== "" &&
      confirmedPassword !== "" &&
      password === confirmedPassword
    ) {
      setIsLoading(true);
      ForgotPasswordService.resetPassword(confirmedPassword, email)
        .then((res) => {
          console.log(res);
          setIsEditing(false);
          setIsLoading(false);
          if (toast.current !== null) {
            if (res.data === "Password Changed successfully!") {
              toast.current.show({
                severity: "success",
                summary: "Success",
                detail: "Password Updated Successfully",
                life: 2800,
              });
            }
          }
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
          if (err.response !== undefined) {
            if (err.response.status !== undefined) {
              if (err.response.status === 701) {
                if (toast.current !== undefined) {
                  toast.current.show({
                    severity: "warn",
                    summary: "Warning",

                    content: (props) => (
                      <div
                        className="flex flex-column align-items-left"
                        style={{ flex: "1" }}
                      >
                        <div className="flex align-items-center gap-2">
                          <span className="p-toast-summary">
                            vState Filings
                          </span>
                        </div>
                        <div className="p-toast-detail">
                          Password has been used recently
                        </div>
                      </div>
                    ),
                    life: 3000,
                  });
                }
              }
            }
          }
        });
    } else {
      if (confirmedPassword === "") {
        setConfirmPasswordErr(errorMessages.confirmPasswordRequired);
      }
      if (password === "") {
        setPasswordErr(errorMessages.passwordRequired);
      }
      // if (oldPassword === "") {
      //     setOldPasswordErr(errorMessages.oldPasswordRequired)
      // }
      if (password !== confirmedPassword) {
        // setVisible(true)
        if (toast.current !== undefined) {
          toast.current.show({
            severity: "warn",
            summary: "Warning",

            content: (props) => (
              <div
                className="flex flex-column align-items-left"
                style={{ flex: "1" }}
              >
                <div className="flex align-items-center gap-2">
                  <span className="p-toast-summary">vState Filings</span>
                </div>
                <div className="p-toast-detail">
                  {errorMessages.passwordMatched}
                </div>
              </div>
            ),
            life: 3000,
          });
        }
        // setGlobalErr(errorMessages.passwordMatched)
      }
    }
  };

  const handleLoginChange = (key, value) => {
    setEditLoginValues({ ...editLoginValues, [key]: value });
  };

  const header = (
    <div className="header-container">
      <img alt="Card" src={profileBg} className="header-image" />

      <div className="avatar-container profile1 user_myprofile">
        <Avatar
          alt="Remy Sharp"
          src={`data:image/jpeg;base64,${fileBase64}`}
          className="custom-avatar"
          sx={{ width: 124, height: 124 }}
          onClick={handleImageClick}
        >
          {fileBase64 ? "" : getInitials(userName)}
        </Avatar>
        {/* <IconButton className="edit-button" onClick={handleImageClick}>
                    <i className='pi pi-pencil'></i>
                </IconButton> */}
        {/* <i className='pi pi-pencil' style={{zIndex:"1200"}}></i> */}
      </div>
      <img alt="" />
    </div>
  );

  const PasswordText = (text) => {
    const maskedText = text.replace(/./g, "*");
    return maskedText;
  };

  return (
    <div className="card flex justify-content-center font-fam-for-all">
      <Toast ref={toast} />
      <Card
        title=""
        subTitle=""
        header={header}
        className="profile-card"
        style={{ backgroundColor: "#FCFCFD" }}
      >
        <div className="mt-6 p-4 font-fam-for-all">
          <div className="grid">
            <div className="col-12 col-sm-12 col-md-12 col-8 ">
              <h3 style={{ fontWeight: "500", fontSize: "22px" }}>
                {userName}
              </h3>
              <p>{email}</p>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-4"></div>
          </div>
          <div className="" style={{ backgroundColor: "#FCFCFD" }}>
            {data && data.contactDetails !== undefined ? (
              <TabView>
                <TabPanel header="Login Details">
                  {isEditing ? (
                    <>
                      <div className=" grid- font-fam-for-all slide-in1 card my-3">
                        <div className="px-4 pt-4 pb-0">
                          <div className="grid">
                            <div className="col-10 mt-2">
                              <h6
                                style={{ fontWeight: "500", fontSize: "18px" }}
                              >
                                Login Details
                              </h6>
                              {/* <p>Change Password Here</p> */}
                            </div>
                          </div>
                        </div>
                        <div className="grid">
                          <div className="col-5">
                            <div className="justify-content-center dialog-form-field-space grid mt-3">
                              <div className="field col-10 p-0">
                                <span className="relative ">
                                  <InputText
                                    id="floating_outlined9"
                                    className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=""
                                    type={passwordType}
                                    value={password}
                                    onChange={handlePassword}
                                    style={{ width: "100%", height: "40px" }}
                                  />
                                  <i
                                    className="absolute right-3 top-1/2 transform -translate-y-1/2 pi"
                                    style={{
                                      fontSize: "1rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={togglePassword}
                                  >
                                    <i
                                      className={
                                        passwordType === "password"
                                          ? "pi pi-eye"
                                          : "pi pi-eye-slash"
                                      }
                                    ></i>
                                  </i>

                                  <label
                                    htmlFor="floating_outlined9"
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                  >
                                    New Password
                                    <span className="form-field-mandatory">
                                      *
                                    </span>
                                  </label>
                                </span>
                                {passwordErr !== "" ? (
                                  <p className="error-msg font-fam-for-all">
                                    {passwordErr}
                                  </p>
                                ) : (
                                  ""
                                )}
                                <ul>
                                  <li
                                    className=" font-fam-for-all pt-2"
                                    style={{
                                      fontSize: "12px",
                                      color: "#667085",
                                    }}
                                  >
                                    {globalMessages.passwordValidText1}
                                  </li>
                                  <li
                                    className=" font-fam-for-all pt-2"
                                    style={{
                                      fontSize: "12px",
                                      color: "#667085",
                                    }}
                                  >
                                    {globalMessages.passwordValidText2}
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>

                          <div className="col-5">
                            <div className="justify-content-center dialog-form-field-space grid mt-3">
                              <div className="field col-10 p-0">
                                <span className="relative ">
                                  <InputText
                                    id="floating_outlined"
                                    className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    placeholder=""
                                    type={passwordType1}
                                    value={confirmedPassword}
                                    onChange={handleConfirmPassword}
                                    style={{ width: "100%", height: "40px" }}
                                  />
                                  <i
                                    className="absolute right-3 top-1/2 transform -translate-y-1/2 pi"
                                    style={{
                                      fontSize: "1rem",
                                      cursor: "pointer",
                                    }}
                                    onClick={togglePassword1}
                                  >
                                    <i
                                      className={
                                        passwordType1 === "password"
                                          ? "pi pi-eye"
                                          : "pi pi-eye-slash"
                                      }
                                    ></i>
                                  </i>

                                  <label
                                    htmlFor="floating_outlined"
                                    className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                  >
                                    Confirm Password
                                    <span className="form-field-mandatory">
                                      *
                                    </span>
                                  </label>
                                </span>
                                {confirmPasswordErr !== "" ? (
                                  <p className="error-msg font-fam-for-all">
                                    {confirmPasswordErr}
                                  </p>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="mb-2">
                          <div className="flex justify-content-end dialog-form-md-group-Btn">
                            <Button
                              className="mt-2  font-fam-for-all text-center vstate-reverse-button mr-2"
                              style={{ height: "40px" }}
                              onClick={() => {
                                setIsEditing(false);
                                setOldPassword("");
                                setPassword("");
                                setConfirmPassword("");
                                setOldPasswordErr("");
                                setPasswordErr("");
                                setConfirmPasswordErr("");
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="mt-2  font-fam-for-all text-center vstate-button mr-3"
                              style={{ height: "40px" }}
                              onClick={handleSave1}
                            >
                              Submit
                            </Button>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className=" font-fam-for-all slide-in hover-card card my-3">
                        <div className="p-3">
                          <div className="grid">
                            <div className="col-10 mt-2">
                              <h6
                                style={{ fontWeight: "500", fontSize: "18px" }}
                              >
                                Login Details
                              </h6>
                            </div>

                            <div className="col-2 flex justify-content-end">
                              <div className="flex justify-content-end dialog-form-md-group-Btn">
                                <button
                                  className="datatable-highlight-color webapp-global-sm-size"
                                  onClick={handleEdit}
                                >
                                  Reset password?
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                        {loginDetails.map((item, index) => (
                          <div key={index} className="grid-row">
                            <div className="grid">
                              <div
                                className="col-2 p-4"
                                style={{ fontWeight: "bold", fontSize: "14px" }}
                              >
                                {item.key}
                              </div>
                              <div className="col-6 p-4 webapp-global-sm-size">
                                {`${
                                  item.key === "Password"
                                    ? PasswordText(item.value)
                                    : item.value
                                }`}
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </TabPanel>
                <TabPanel header="Profile Info" className="user-tabpanel">
                  {/* <div cd */}

                  {isEditing1 ? (
                    <div className="card my-3 slide-in">
                      <div className="p-4">
                        <h2 style={{ fontWeight: "500", fontSize: "18px" }}>
                          Profile Information
                        </h2>
                        <div style={{ fontSize: "14px" }}>
                          Update your photo and personal details here.
                        </div>
                      </div>

                      <div className="grid px-4 py-2">
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3"
                          style={{ fontWeight: "500", fontSize: "14px" }}
                        >
                          <span className=" mr-3">
                            <div class="relative">
                              <Dropdown
                                value={salutation}
                                options={["Mr", "Ms", "Mrs", "Dr"]}
                                filter
                                onChange={(e) => setSalutation(e.target.value)}
                                // optionLabel="code"
                                className="p-dropdown font-fam-for-all vstate-input-field"
                              />
                              <label
                                htmlFor="floating_outlined25"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                              >
                                Salutation{" "}
                                <span className="form-field-mandatory"></span>
                              </label>
                            </div>
                            {/* {statesErr !== "" ? <p className="error-msg font-fam-for-all">{statesErr}</p> : ""} */}
                          </span>
                        </div>
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3 "
                          style={{ fontWeight: "500", fontSize: "14px" }}
                        >
                          <span className=" mr-3">
                            <div class="relative">
                              <InputText
                                style={{ height: "40px", width: "100%" }}
                                maxLength={50}
                                value={firstName}
                                id="floating_outlined3"
                                placeholder=""
                                className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                onChange={handleFirstName}
                                disabled
                                name="cityName"
                              />

                              <label
                                htmlFor="floating_outlined3"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                              >
                                First Name
                                <span className="form-field-mandatory">*</span>
                              </label>
                            </div>
                            {firstNameErr !== "" ? (
                              <p className="error-msg font-fam-for-all">
                                {firstNameErr}
                              </p>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>

                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3"
                          style={{ fontWeight: "500", fontSize: "14px" }}
                        >
                          <span className=" mr-3">
                            <div class="relative">
                              <InputText
                                style={{ height: "40px", width: "100%" }}
                                maxLength={50}
                                value={lastName}
                                id="floating_outlined5"
                                placeholder=""
                                className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                onChange={handleLastName}
                                disabled
                                name="cityName"
                              />
                              <label
                                htmlFor="floating_outlined5"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                              >
                                Last Name{" "}
                                <span className="form-field-mandatory">*</span>
                              </label>
                            </div>
                            {lastNameErr !== "" ? (
                              <p className="error-msg font-fam-for-all">
                                {lastNameErr}
                              </p>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3"
                          style={{ fontWeight: "500", fontSize: "14px" }}
                        >
                          {/* <div className="p-inputgroup flex-1" style={{ height: '40px', width: '100%' }}> */}

                          <span className=" mr-3">
                            <div class="relative">
                              <InputText
                                style={{
                                  height: "40px",
                                  borderRadius: "0px 6px 6px 0px",
                                }}
                                maxLength={50}
                                value={email}
                                onChange={handleEmail}
                                name="cityName"
                                disabled
                                id="floating_outlined6"
                                placeholder=""
                                className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                              />
                              <label
                                htmlFor="floating_outlined6"
                                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                              >
                                Email Address{" "}
                                <span className="form-field-mandatory">*</span>
                              </label>
                            </div>
                            {emailErr !== "" ? (
                              <p className="error-msg font-fam-for-all">
                                {emailErr}
                              </p>
                            ) : (
                              ""
                            )}
                          </span>
                          {/* </div> */}
                        </div>
                      </div>
                      <div className="grid px-4 py-2">
                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3"
                          style={{ fontSize: "14px" }}
                        >
                          <div
                            className="p-inputgroup flex-1"
                            style={{ height: "40px", width: "100%" }}
                          >
                            <Dropdown
                              value={selectedCountry}
                              options={countries}
                              onChange={(e) => setSelectedCountry(e.value)}
                              optionLabel="code"
                              optionValue="dialCode"
                              disabled
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              style={{
                                width: "80px",
                                borderRadius: "6px 0px 0px 6px",
                              }}
                              className="p-dropdown font-fam-for-all"
                            />
                            <span className="">
                              <div class="relative">
                                {/* <InputMask
                                                                    maxLength={50}
                                                                    value={phoneNumber}
                                                                    id="floating_outlined10"
                                                                    placeholder=''
                                                                    mask={isFocused ? "(999) 999-9999" : "(999) 999-9999"}
                                                                    onFocus={() => setIsFocused(true)}
                                                                    onBlur={() => setIsFocused(false)}
                                                                    className="block vstate-input-field px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                    disabled
                                                                    onChange={handlePhone}
                                                                    name="cityName"
                                                                /> */}

                                {/* <input
                                                                    type='text'
                                                                        id="floating_outlined93"
                                                                        className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                                                        style={{ height: '40px', borderRadius: '0px 6px 6px 0px', width: "100%" }}
                                                                        maxLength={50}
                                                                        value={data.contactDetails.mobileNo}                                                    
                                                                        onChange={handlePhone}
                                                                        autoClear={false}
                                                                        placeholder=''
                                                                        name="cityName"
                                                                    /> */}

                                <input
                                  type="text"
                                  id="floating_outlined93"
                                  className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                  style={{
                                    height: "40px",
                                    borderRadius: "0px 6px 6px 0px",
                                    width: "100%",
                                  }}
                                  maxLength={50}
                                  disabled
                                  value={phone}
                                  // defaultValue={data.contactDetails.mobileNo}
                                  onChange={handlePhone}
                                  placeholder=""
                                  name="cityName"
                                />

                                <label
                                  htmlFor="floating_outlined93"
                                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                >
                                  Phone Number{" "}
                                  <span className="form-field-mandatory">
                                    *
                                  </span>
                                </label>
                              </div>
                              {/* {phoneErr !== "" ? <p className='error-msg font-fam-for-all'>{phoneErr}</p> : ""} */}
                            </span>
                          </div>
                        </div>

                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3 "
                          style={{ fontSize: "14px" }}
                        >
                          <div
                            className="p-inputgroup flex-1"
                            style={{ height: "40px", width: "100%" }}
                          >
                            <Dropdown
                              value={selectedCountry}
                              options={countries}
                              onChange={(e) => setSelectedCountry(e.value)}
                              optionLabel="code"
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              optionValue="dialCode"
                              filter
                              style={{
                                width: "80px",
                                borderRadius: "6px 0px 0px 6px",
                              }}
                              className="p-dropdown font-fam-for-all"
                            />
                            <span className="">
                              <div class="relative">
                                {/* <InputMask

                                                                    maxLength={50}
                                                                    value={altPhoneNumber}
                                                                    id="floating_outlined11"
                                                                    placeholder=''
                                                                    className="block vstate-input-field px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                                                    mask={isFocused ? "(999) 999-9999" : "(999) 999-9999"}
                                                                    onFocus={() => setIsFocused(true)}
                                                                    onBlur={() => setIsFocused(false)}
                                                                    onChange={handleAlternatePhone}
                                                                    name="cityName"
                                                                /> */}
                                <input
                                  type="text"
                                  id="floating_outlined92"
                                  className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                  style={{
                                    height: "40px",
                                    borderRadius: "0px 6px 6px 0px",
                                    width: "100%",
                                  }}
                                  maxLength={50}
                                  value={altPhoneNumber}
                                  onChange={handleAlternatePhone}
                                  autoClear={false}
                                  placeholder=""
                                  name="cityName"
                                />
                                <label
                                  htmlFor="floating_outlined92"
                                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                >
                                  Alt. Phone Number{" "}
                                  <span className="form-field-mandatory"></span>
                                </label>
                              </div>
                            </span>
                          </div>
                        </div>

                        <div
                          className="col-12 col-sm-12 col-md-12 col-lg-3"
                          style={{ fontSize: "14px" }}
                        >
                          <div
                            className="p-inputgroup flex-1"
                            style={{ height: "40px", width: "100%" }}
                          >
                            <Dropdown
                              value={selectedCountry}
                              options={countries}
                              onChange={(e) => setSelectedCountry(e.value)}
                              optionLabel="code"
                              filter
                              valueTemplate={selectedCountryTemplate}
                              itemTemplate={countryOptionTemplate}
                              optionValue="dialCode"
                              style={{
                                width: "40px",
                                borderRadius: "6px 0px 0px 6px",
                              }}
                              className="p-dropdown font-fam-for-all"
                            />
                            <span className=" mr-3">
                              <div class="relative">
                                {/* <InputMask
                                                                    maxLength={16}
                                                                    value={fax}
                                                                    onChange={handleFax}
                                                                    name="cityName"
                                                                    id="floating_outlined8"
                                                                    mask={isFocused ? "(999) 999-9999" : "(999) 999-9999"}
                                                                    onFocus={() => setIsFocused(true)}
                                                                    onBlur={() => setIsFocused(false)}
                                                                    placeholder=''
                                                                    className="block vstate-input-field px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                                /> */}
                                <input
                                  type="text"
                                  id="floating_outlined55"
                                  className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer dialog-form-input-field-wizard p-inputtext"
                                  style={{
                                    height: "40px",
                                    borderRadius: "0px 6px 6px 0px",
                                    width: "100%",
                                  }}
                                  maxLength={50}
                                  value={fax}
                                  onChange={handleFax}
                                  autoClear={false}
                                  placeholder=""
                                  name="cityName"
                                />
                                <label
                                  htmlFor="floating_outlined55"
                                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                >
                                  Fax{" "}
                                  <span className="form-field-mandatory"></span>
                                </label>
                              </div>
                              {faxErr !== "" ? (
                                <p className="error-msg font-fam-for-all">
                                  {faxErr}
                                </p>
                              ) : (
                                ""
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="mb-3">
                        {isEditing || isEditing1 || isEditing2 ? (
                          <div className="flex justify-content-end pl-3 dialog-form-md-group-Btn">
                            <Button
                              className="mt-2  font-fam-for-all text-center vstate-reverse-button mr-2"
                              style={{ height: "40px" }}
                              onClick={() => {
                                setIsEditing(false);
                                setIsEditing1(false);
                                setIsEditing2(false);
                                setFirstNameErr("");
                                setLastNameErr("");
                                setPhoneErr("");
                                setEmailErr("");
                                setFaxErr("");
                              }}
                            >
                              Cancel
                            </Button>
                            <Button
                              className="mt-2  font-fam-for-all text-center vstate-button mr-3"
                              style={{ height: "40px" }}
                              type="submit"
                              onClick={handleSave}
                            >
                              Submit{" "}
                            </Button>
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        <div className="card my-3 hover-card slide-in">
                          <div
                            className=" flex px-4 pt-4 pb-0"
                            style={{ justifyContent: "space-between" }}
                          >
                            <h2
                              className="text-lg "
                              style={{ fontWeight: "500" }}
                            >
                              Profile Information
                            </h2>
                            <div>
                              {isEditing ? (
                                <div></div>
                              ) : (
                                <div className="flex justify-content-end dialog-form-md-group-Btn">
                                  <Button
                                    onClick={handleEdit1}
                                    className="edit-button1"
                                    icon="pi pi-pencil"
                                    style={{ border: "none", height: "40px" }}
                                  ></Button>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="grid px-4 py-2">
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              Salutation
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3 "
                              style={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              First Name
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              Last Name
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              Email Address
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontSize: "14px" }}
                            >
                              {data.contactDetails.salutation
                                ? data.contactDetails.salutation
                                : "-"}
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3 "
                              style={{ fontSize: "14px" }}
                            >
                              {data.contactDetails.firstName}
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontSize: "14px" }}
                            >
                              {data.contactDetails.lastName}
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontSize: "14px" }}
                            >
                              {data.contactDetails.emailId}
                            </div>
                          </div>
                          <div className="grid px-4 py-2">
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              Phone Number
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3 "
                              style={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              Alt. Phone Number
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontWeight: "500", fontSize: "14px" }}
                            >
                              Fax
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontWeight: "500", fontSize: "14px" }}
                            ></div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontSize: "14px" }}
                            >
                              {data.contactDetails.mobileNo}
                            </div>
                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3 "
                              style={{ fontSize: "14px" }}
                            >
                              {data.contactDetails.phone}
                            </div>

                            <div
                              className="col-12 col-sm-12 col-md-12 col-lg-3"
                              style={{ fontSize: "14px" }}
                            >
                              {data.contactDetails.fax}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </TabPanel>

                <TabPanel header="Address Details">
                  <div
                    className="col-12 "
                    style={{ backgroundColor: "#fbfcfa", padding: "15px" }}
                  >
                    <div className="font-fam-for-all">
                      {isEditing2 ? (
                        <>
                          <div className="card my-3 hover-card slide-in">
                            <div className="p-4">
                              <h2
                                style={{ fontWeight: "500", fontSize: "18px" }}
                              >
                                Address Details
                              </h2>
                            </div>

                            <div className="grid px-4 py-2">
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                <span className=" mr-3">
                                  <div class="relative">
                                    <InputText
                                      style={{
                                        height: "40px",
                                        borderRadius: "0px 6px 6px 0px",
                                      }}
                                      maxLength={50}
                                      value={addr1}
                                      onChange={(e) => {
                                        setAddr1Err("");
                                        setAddr1(e.target.value);
                                      }}
                                      name="cityName"
                                      id="floating_outlined21"
                                      placeholder=""
                                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    />
                                    <label
                                      htmlFor="floating_outlined21"
                                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                      Address Line 1{" "}
                                      <span className="form-field-mandatory">
                                        *
                                      </span>
                                    </label>
                                  </div>
                                  {addr1Err !== "" ? (
                                    <p className="error-msg font-fam-for-all">
                                      {addr1Err}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                <span className=" mr-3">
                                  <div class="relative">
                                    <InputText
                                      style={{
                                        height: "40px",
                                        borderRadius: "0px 6px 6px 0px",
                                      }}
                                      maxLength={50}
                                      value={addr2}
                                      onChange={(e) => setAddr2(e.target.value)}
                                      name="cityName"
                                      id="floating_outlined22"
                                      placeholder=""
                                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    />
                                    <label
                                      htmlFor="floating_outlined22"
                                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                      Address Line 2{" "}
                                      <span className="form-field-mandatory"></span>
                                    </label>
                                  </div>
                                  {addr2Err !== "" ? (
                                    <p className="error-msg font-fam-for-all">
                                      {addr2Err}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              {/* <div className='col-12 col-sm-12 col-md-12 col-lg-3' style={{ fontWeight: "500", fontSize: "14px" }}>


                                                                <span className=" mr-3">
                                                                    <div class="relative">
                                                                        <InputText
                                                                            style={{ height: '40px', borderRadius: '0px 6px 6px 0px' }}
                                                                            maxLength={50}
                                                                            value={region}
                                                                            onChange={(e) => setRegion(e.target.value)}
                                                                            name="cityName"
                                                                            id="floating_outlined23"
                                                                            placeholder=''
                                                                            className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"

                                                                        />
                                                                        <label htmlFor="floating_outlined23"
                                                                            className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                                                        >
                                                                            County <span className="form-field-mandatory">*</span>
                                                                        </label>
                                                                    </div>
                                                                    {regionErr !== "" ? <p className="error-msg font-fam-for-all">{regionErr}</p> : ""}
                                                                </span>

                                                            </div> */}
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                <span className=" mr-3">
                                  <div class="relative">
                                    <InputText
                                      style={{
                                        height: "40px",
                                        borderRadius: "0px 6px 6px 0px",
                                      }}
                                      maxLength={50}
                                      value={city}
                                      onChange={(e) => {
                                        setcityErr("");
                                        setCity(e.target.value);
                                      }}
                                      name="cityName"
                                      id="floating_outlined24"
                                      placeholder=""
                                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    />
                                    <label
                                      htmlFor="floating_outlined24"
                                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                      City{" "}
                                      <span className="form-field-mandatory">
                                        *
                                      </span>
                                    </label>
                                  </div>
                                  {cityErr !== "" ? (
                                    <p className="error-msg font-fam-for-all">
                                      {cityErr}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>

                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                <span className=" mr-3">
                                  <div class="relative">
                                    <Dropdown
                                      value={states}
                                      filter
                                      onChange={(e) => {
                                        setStatesErr("");
                                        setStates(e.value);
                                      }}
                                      options={allStates}
                                      optionValue="id"
                                      optionLabel="stateFullDesc"
                                      placeholder="Select a State"
                                      className="w-full "
                                      style={{
                                        height: "40px",
                                        borderRadius: "6px",
                                      }}
                                    />
                                    <label
                                      htmlFor="floating_outlined25"
                                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                      State{" "}
                                      <span className="form-field-mandatory">
                                        *
                                      </span>
                                    </label>
                                  </div>
                                  {statesErr !== "" ? (
                                    <p className="error-msg font-fam-for-all">
                                      {statesErr}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                <span className=" mr-3">
                                  <div class="relative">
                                    <Dropdown
                                      value={countryData}
                                      disabled
                                      filter
                                      onChange={(e) => setCountryData(e.value)}
                                      options={allCountries}
                                      optionValue="id"
                                      optionLabel="countryFullDesc"
                                      placeholder="Select a Country"
                                      className="w-full"
                                      style={{
                                        height: "40px",
                                        borderRadius: "0px 6px 6px 0px",
                                      }}
                                    />
                                    <label
                                      htmlFor="floating_outlined25"
                                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                      Country{" "}
                                      <span className="form-field-mandatory">
                                        *
                                      </span>
                                    </label>
                                  </div>
                                  {countryErr !== "" ? (
                                    <p className="error-msg font-fam-for-all">
                                      {countryErr}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                <span className=" mr-3">
                                  <div class="relative">
                                    <InputText
                                      style={{
                                        height: "40px",
                                        borderRadius: "0px 6px 6px 0px",
                                      }}
                                      maxLength={5}
                                      value={zip}
                                      onChange={handleZip}
                                      name="cityName"
                                      id="floating_outlined26"
                                      placeholder=""
                                      className="block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                                    />
                                    <label
                                      htmlFor="floating_outlined26"
                                      className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                                    >
                                      Zip Code{" "}
                                      <span className="form-field-mandatory">
                                        *
                                      </span>
                                    </label>
                                  </div>
                                  {zipErr !== "" ? (
                                    <p className="error-msg font-fam-for-all">
                                      {zipErr}
                                    </p>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </div>

                            <div className="mb-3">
                              {isEditing || isEditing1 || isEditing2 ? (
                                <div className="flex justify-content-end pl-3 dialog-form-md-group-Btn">
                                  <Button
                                    className="mt-2  font-fam-for-all text-center  vstate-reverse-button mr-2"
                                    style={{ height: "40px" }}
                                    onClick={() => {
                                      setIsEditing(false);
                                      setIsEditing1(false);
                                      setIsEditing2(false);
                                      // setAddr1("")
                                      setAddr1Err("");
                                      // setAddr2("")
                                      setAddr2Err("");
                                      // setCity("")
                                      setcityErr("");
                                      // setRegion("")
                                      setRegionErr("");
                                      // setStates("")
                                      setStatesErr("");
                                      // setZip("")
                                      setZipErr("");
                                      // setAddressType(null)
                                      setCountryErr("");
                                      setAddressTypeErr("");
                                      setCompanyErr("");
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                  <Button
                                    className="mt-2  font-fam-for-all text-center vstate-button mr-3"
                                    style={{ height: "40px" }}
                                    onClick={handleSave2}
                                  >
                                    Submit
                                  </Button>
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="card my-3 hover-card slide-in">
                            <div
                              className=" flex px-4 pt-4 pb-0"
                              style={{ justifyContent: "space-between" }}
                            >
                              <h2
                                className="text-lg "
                                style={{ fontWeight: "500" }}
                              >
                                Address Details
                              </h2>
                              <div>
                                {isEditing ? (
                                  <div></div>
                                ) : (
                                  <div className="flex justify-content-end dialog-form-md-group-Btn">
                                    <Button
                                      onClick={handleEdit2}
                                      className="edit-button1"
                                      icon="pi pi-pencil"
                                      style={{ border: "none", height: "40px" }}
                                    ></Button>
                                  </div>
                                )}
                              </div>
                            </div>
                            <div className="grid px-4 py-2">
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                Address Line 1
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                Address Line 2
                              </div>

                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                City
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontSize: "14px" }}
                              >
                                State
                              </div>

                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontSize: "14px" }}
                              >
                                {addr1}
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                style={{ fontSize: "14px" }}
                              >
                                {addr2}
                              </div>

                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontSize: "14px" }}
                              >
                                {city}
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontSize: "14px" }}
                              >
                                {stateValue}
                              </div>
                            </div>
                            <div className="grid px-4 py-2">
                              {/* <div className='col-12 col-sm-12 col-md-12 col-lg-3' style={{ fontWeight: "500", fontSize: "14px" }}>
                                                                State
                                                            </div> */}
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                Country
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              >
                                Zip Code
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              ></div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontWeight: "500", fontSize: "14px" }}
                              ></div>

                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3 "
                                style={{ fontSize: "14px" }}
                              >
                                {countryValue}
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontSize: "14px" }}
                              >
                                {zip}
                              </div>
                              <div
                                className="col-12 col-sm-12 col-md-12 col-lg-3"
                                style={{ fontSize: "14px" }}
                              ></div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </TabPanel>
                {!superAdminId && (
                  <TabPanel header="My Permission">
                    <div
                      className="col-12 "
                      style={{ backgroundColor: "#fbfcfa" }}
                    >
                      <div className="font-fam-for-all">
                        <div className="card my-3 hover-card slide-in1">
                          <div className="p-4">
                            <h2 style={{ fontWeight: "500", fontSize: "18px" }}>
                              Manage Permission
                            </h2>
                          </div>
                          <div className="grid p-4">
                            <div className="col-12">
                              <GroupDataTable data={data} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                )}
              </TabView>
            ) : (
              ""
            )}
          </div>
        </div>
      </Card>

      <Dialog
        visible={isImageVisible}
        className="dialog-box profile-view-dialog-box"
        onHide={hideImageDialog}
      >
        <UploadImage setImageVisible={setImageVisible} data={data} />
      </Dialog>
    </div>
  );
}
