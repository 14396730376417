import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import BlueButton from "../CommonSection/BlueButton";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

const TestimonialsSec = () => {
  return (
    <section className="main-section-class">
      <div className="testimonials">
        <Container>
          <CommonHeadingTitle blueheading={"Testimonials"} />
          <div className="text-center">
            <h4 className="normal-common-txt">
              Trusted by businesses nationwide for seamless filing & compliance
              solutions.
            </h4>
          </div>
          <div className="mt-5">
            <Row>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="main-class-div-first">
                  <h4 className="medium-level-heading">
                    “ handles everything with professionalism “
                  </h4>
                  <p className="normal-common-txt mt-4">
                    vState Filings has been invaluable to our business,
                    facilitating payroll tax registration for employee hiring.
                    They're a responsive partner, crucial to our business
                    expansion. I highly recommend their services!
                  </p>
                  <div className="d-flex mt-5 justify-content-center ">
                    <img
                      src={process.env.PUBLIC_URL + "/Images/user/u1.png"}
                      className="user-img me-3"
                    />
                    <div>
                      <h3 className="user-name">Neil Sims</h3>
                      <p className="company-name">CEO, Company_Name</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="main-class-div-second">
                  <h4 className="medium-level-heading">
                    “ attention to detail and timely reminders “"
                  </h4>
                  <p className="normal-common-txt mt-4">
                    vState Filings has been invaluable to our business,
                    facilitating payroll tax registration for employee hiring.
                    They're a responsive partner, crucial to our business
                    expansion. I highly recommend their services!
                  </p>
                  <div className="d-flex mt-5 justify-content-center">
                    <img
                      src={process.env.PUBLIC_URL + "/Images/user/u2.png"}
                      className="user-img me-3"
                    />
                    <div>
                      <h3 className="user-name">Micheal Gough</h3>
                      <p className="company-name">CEO, Company_Name</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="main-class-div-third">
                  <h4 className="medium-level-heading">
                    “ customer service is outstanding “
                  </h4>
                  <p className="normal-common-txt mt-4">
                    vState Filings has been invaluable to our business,
                    facilitating payroll tax registration for employee hiring.
                    They're a responsive partner, crucial to our business
                    expansion. I highly recommend their services!
                  </p>
                  <div className="d-flex mt-5 justify-content-center">
                    <img
                      src={process.env.PUBLIC_URL + "/Images/user/u3.png"}
                      className="user-img me-3"
                    />
                    <div>
                      <h3 className="user-name">Heena Engales</h3>
                      <p className="company-name">CEO, Company_Name</p>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={6} sm={12} xs={12}>
                <div className="main-class-div-four">
                  <h4 className="medium-level-heading">
                    “ seamless and stress-free “
                  </h4>
                  <p className="normal-common-txt mt-4">
                    vState Filings has been invaluable to our business,
                    facilitating payroll tax registration for employee hiring.
                    They're a responsive partner, crucial to our business
                    expansion. I highly recommend their services!
                  </p>
                  <div className="d-flex mt-5 justify-content-center">
                    <img
                      src={process.env.PUBLIC_URL + "/Images/user/u4.png"}
                      className="user-img me-3"
                    />
                    <div>
                      <h3 className="user-name">Neil Sims</h3>
                      <p className="company-name">CEO, Company_Name</p>
                    </div>
                  </div>
                </div>
              </Col>
              <div className="main-section-class mt-5">
                <CommonHeadingTitle
                  heading={"Want to"}
                  blueheading={"start a business?"}
                />
                <CommonHeadingTitle
                  heading={"lets get it done,"}
                  blueheading={"together."}
                />
                <div className="text-center">
                  <p className="normal-common-txt mt-5">
                    Streamline your business essentials under one roof. Stay
                    informed with timely reminders and compliance notifications.
                    Extend your business reach with adaptable additional
                    services.
                  </p>
                </div>
                <div className="text-center mt-5">
                  <Link to="/webcontact">
                    <BlueButton
                      label="Contact Us"
                      icon={<FontAwesomeIcon icon={faArrowRightLong} />}
                    />
                  </Link>
                </div>
              </div>
            </Row>
          </div>
        </Container>
      </div>
    </section>
  );
};

export default TestimonialsSec;
