import React from "react";
import Header from "../RegistredAgentService/Header";
import Partners from "../Partners";
import ComplianceServices from "./Complianc Service/ComplianceServices";
import ComplianceSection from "./Compliance/ComplianceSection.js";
import FAQ from "../RegistredAgentService/Fact.js";
import ContactForm from "./ContactForm.js";
import SubscriptionsServices from "./Complianc Service/SubScriptionService.js";
import SubscriptionPic from "../../../Assets/Images/Subscription Service.png";
import RegisteredBanner from "../RegistredAgentService/RegisteredBanner.js";
import SubscriptionSec from "../AllServices/SubscriptionPage/SubscriptionSec.js";
import SubscriptionThird from "../AllServices/SubscriptionPage/SubscriptionThird.js";
import FaqCommonSection from "../CommonSection/FaqCommonSection.js";
import OurTrustedSection from "../CommonSection/OurTrustedSection.js";

const complianceData = {
  sectionTitle: "Why Choose Subscription Services?",
  sectionSubtitle: "Compliance Services are essential for your business.",
  items: [
    {
      title: "Seamless Compliance",
      description:
        "Subscription-based services ensure that regular filings and legal responsibilities are handled without needing to manually track deadlines.",
    },
    {
      title: "Ongoing Support",
      description:
        "With services like registered agent representation, you have continuous support in handling legal documents and notices on your behalf.",
    },
    {
      title: "Reduced Risk",
      description:
        "By staying subscribed, you reduce the risk of missing important filings or legal notices that could disrupt business operations.",
    },
    {
      title: "Cost-Effective Solutions",
      description:
        "Subscriptions offer a cost-effective way to maintain compliance and access critical services regularly.",
    },
  ],
  images: {
    backgroundImage: SubscriptionPic,
    iconImage:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/2517a6129f7ab66094c10670002e3c726e87b73dba9f0efd07fceaf970443933?apiKey=136b58d08a644862944c0e5f22adbbe0&",
  },
};

const SubscriptionServices = () => {
  return (
    <div className="mt-24">
      <RegisteredBanner />
      <OurTrustedSection />
      <SubscriptionSec />
      <SubscriptionThird />
      {/* <FaqCommonSection/> */}
      <main className="flex flex-col items-center px-80 pt-16 pb-20 max-md:px-5">
        <div className="flex flex-col px-5 w-full max-w-[1240px] max-md:max-w-full">
          {/* <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <Header />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <Partners />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <ComplianceSection complianceData={complianceData} />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <SubscriptionsServices />
          </div> */}
          {/* <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <FAQ />
          </div> */}
          <div className="flex flex-col px-8 website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <p className="contact-form-haeding-main-text1">
              Need help to{" "}
              <span className="contact-form-haeding-main-text2">
                stay compliant?
              </span>
            </p>
            <ContactForm />
          </div>
        </div>
      </main>
    </div>
  );
};

export default SubscriptionServices;
