export const stateHtml = [
    {
        id: 1,
        state: "Delaware",
        html: `
<style>
    .certificate-container {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      font-family: Inter, sans-serif;
    }
    
    .certificate-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
    }
    
    .header-section {
      display: flex;
      width: 100%;
      align-items: start;
      color: var(--Gray-900, #101828);
      font-weight: 600;
      text-align: center;
      justify-content: center;
    }
    
    .header-content {
      display: flex;
      width: 100%;
      align-items: center;
      gap: 11px;
      justify-content: center;
      flex: 1;
      padding: 80px 120px;
    }
    
    .title-wrapper {
      align-self: stretch;
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: start;
      flex: 1;
      margin: auto 0;
    }
    
    .main-title {
      font-size: 24px;
      line-height: 16px;
    }
    
    .company-name {
      font-size: 28px;
      line-height: 1;
      margin-top: 24px;
    }
    
    .main-content {
      display: flex;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      padding: 16px 120px 48px;
    }
    
    .content-wrapper {
      display: flex;
      width: 100%;
      flex-direction: column;
    }
    
    .first-section {
      display: flex;
      width: 100%;
      flex-direction: column;
      font-weight: 500;
    }
    
    .section-row {
      display: flex;
      width: 100%;
      gap: 40px 64px;
      justify-content: start;
      flex-wrap: wrap;
    }
    
    .section-number {
      font-size: 18px;
      color: var(--Gray-900, #101828);
      white-space: nowrap;
      line-height: 2;
      width: 80px;
    }
    
    .section-content {
      display: flex;
      min-width: 240px;
      align-items: start;
      gap: 6px;
      font-size: 16px;
      color: var(--Grey-900, #101828);
      justify-content: start;
      flex-wrap: wrap;
      flex: 1;
      margin: auto 0;
    }
    
    .input-field {
      align-items: center;
      border-bottom: 1px solid var(--Gray-300, #d0d5dd);
      background: var(--White, #fff);
      box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
      display: flex;
      min-width: 240px;
      gap: 8px;
      overflow: hidden;
      font-size: 14px;
      color: var(--Grey-500, #667085);
      font-weight: 400;
      line-height: 24px;
      justify-content: start;
      flex: 1;
      padding: 0 14px;
    }
    
    .witness-section {
      display: flex;
      margin-top: 20px;
      width: 100%;
      align-items: center;
      gap: 40px 84px;
      line-height: 24px;
    }
    
    .signature-section {
      display: flex;
      margin-top: 20px;
      min-height: 116px;
      width: 100%;
      flex-direction: column;
      justify-content: end;
      padding: 24px 0;
      max-width: 600px;
    }
    
    .signature-field {
      width: 222px;
      padding: 0 14px;
    }
    
    .divider {
      background: var(--Gray-200, #e4e7ec);
      min-height: 1px;
      width: 100%;
    }
    
    @media (max-width: 991px) {
      .certificate-wrapper,
      .header-section,
      .header-content,
      .title-wrapper,
      .main-title,
      .company-name,
      .main-content,
      .content-wrapper,
      .first-section,
      .section-row,
      .section-content,
      .signature-section,
      .divider {
        max-width: 100%;
      }
      
      .header-content {
        padding: 0 20px;
      }
      
      .main-content {
        padding: 0 20px;
      }
      
      .section-number {
        white-space: initial;
      }
      
      .input-field {
        white-space: initial;
      }
    }
</style>
<div class="certificate-container" style="max-width: 1200px;">
  <div class="certificate-wrapper">
    <div class="header-section">
      <div class="header-content">
        <div class="title-wrapper">
          <div class="main-title" style="line-height: normal;">
            CERTIFICATE OF FORMATION
            <br />
            OF
          </div>
          <div class="company-name">$llc_name</div>
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="content-wrapper">
        <div class="certificate-wrapper">
          <div class="first-section">
            <div class="section-row">
              <div class="section-number">First</div>
              <div class="section-content">
                <div>The name of the Limited Liability Company is</div>
                <div class="input-field">$llc_name</div>
                <div>.</div>
              </div>
            </div>
          </div>
          <div class="section-row">
            <div class="section-number">Second</div>
            <div class="section-content">
              <div>The address of its registered office in the state of Delaware is</div>
              <div class="input-field">$address</div>
              <div>.</div>
            </div>
          </div>
          <div class="witness-section">
            <div class="section-content">
              <div><strong>IN WITNESS WHEREOF</strong>, the undersigned has executed this Certificate of Formation as of the date</div>
              <div class="input-field">$date</div>
            </div>
          </div>
          <div class="signature-section">
            <div class="section-content">
              <div>By: /S/</div>
              <div class="input-field signature-field">$</div>
            </div>
            <div class="section-content">
              <div>Name:</div>
              <div class="input-field signature-field">$</div>
              <div>,Authorized Person</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="divider"></div>
</div>
`,
    },
    {
        id: 2,
        state: "New York",
        html: `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Certificate of Organization - Limited Liability Company</title>
    <meta name="author" content="Anna">
    <style type="text/css">
        * {
            margin: 0;
            padding: 0;
            text-indent: 0;
        }

        h1 {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: bold;
            text-decoration: none;
            font-size: 16pt;
        }

        p {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 11pt;
            margin: 0pt;
        }

        a {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: italic;
            font-weight: normal;
            text-decoration: none;
            font-size: 11pt;
        }

        .s1 {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: underline;
            font-size: 12pt;
        }

        .s2 {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }

        .s3 {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: italic;
            font-weight: normal;
            text-decoration: none;
            font-size: 11pt;
        }

        .s5 {
            color: black;
            font-family: "Times New Roman", serif;
            font-style: normal;
            font-weight: normal;
            text-decoration: none;
            font-size: 12pt;
        }
    </style>
</head>

<body style="padding: 10vh;">
    <h1 style="padding-top: 2pt; padding-left: 74pt; padding-bottom: 10pt; text-indent: 0pt; text-align: center;">
        CERTIFICATE OF ORGANIZATION LIMITED LIABILITY COMPANY
    </h1>
    <p style="padding-left: 74pt; text-indent: 0pt; line-height: 12pt; text-align: center;">
        Robert B. Evnen, Secretary of State
    </p>
    <p style="padding-left: 74pt; text-indent: 0pt; line-height: 12pt; text-align: center;">P.O. Box 94608</p>
    <p style="padding-left: 74pt; text-indent: 0pt; line-height: 12pt; text-align: center;">Lincoln, NE 68509</p>
    <p style="padding-left: 76pt; text-indent: 0pt; line-height: 13pt; text-align: center;">
        <a href="http://www.sos.ne.gov/">www.sos.nebraska.gov</a>
    </p>
    <p style="padding-top: 9pt; text-indent: 0pt; text-align: left;"><br /></p>
    <p style="padding-left: 6pt; text-indent: 0pt; text-align: left;">
        Name of the limited liability company:
        <span
            class="s1">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
            <br />
        <i>The name must contain the words Limited Liability Company or Limited Company or the abbreviation L.L.C., LLC,
            L.C. or LC. Limited may be abbreviated as Ltd., and Company may be abbreviated as Co.</i>
    </p>
    <p style="padding-top: 12pt; padding-left: 6pt; text-indent: 0pt; text-align: left;">
        Street and mailing address of the initial designated office:
    </p>
    <p style="text-indent: 0pt; text-align: left;"><br /></p>
    <p class="s5" style="padding-left: 6pt; text-indent: 0pt; line-height: 14pt; text-align: left;">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
        NE <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
    </p>
    <p style="padding-left: 6pt; text-indent: 0pt; line-height: 13pt; text-align: left;">
        Street Address (Required) City ZIP
    </p>
    <p class="s5" style="padding-top: 12pt; padding-left: 5pt; text-indent: 0pt; line-height: 14pt; text-align: left;">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
        NE <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
    </p>
    <p style="padding-left: 6pt; text-indent: 0pt; line-height: 13pt; text-align: left;">
        Mailing Address (if different from street address) City ZIP
    </p>
    <p style="text-indent: 0pt; text-align: left;"><br /></p>
    <p style="padding-left: 6pt; text-indent: 0pt; text-align: left;">
        Name of the initial agent for service of process:
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
    </p>
    <p style="text-indent: 0pt; text-align: left;"><br /></p>
    <p style="padding-left: 6pt; text-indent: 0pt; text-align: left;">
        Street, mailing address and post office box (if any) of the initial agent for service of process:
    </p>
    <p style="text-indent: 0pt; text-align: left;"><br /></p>
    <p class="s5" style="padding-left: 5pt; text-indent: 0pt; line-height: 14pt; text-align: left;">
        <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
        NE <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>
    </p>
    <p style="padding-left: 6pt; text-indent: 0pt; line-height: 13pt; text-align: left;"> Street Address (Required) City
        ZIP </p>
    <p class="s5" style="padding-top: 12pt; padding-left: 5pt; text-indent: 0pt; line-height: 14pt; text-align: left;">
        <u>                                                                                  </u> NE
        <u>              </u> </p>
    <p style="padding-left: 6pt; text-indent: 0pt; line-height: 13pt; text-align: left;"> Mailing Address (if different
        from street address) City ZIP </p>
    <p style="text-indent: 0pt; text-align: left;"><br /></p>
    <p style="padding-left: 6pt; text-indent: 0pt; text-align: left;"> Name of the individual or entity executing the
        certificate: <u>                                                </u> </p>
    <p style="text-indent: 0pt; text-align: left;"><br /></p>
    <p style="padding-left: 6pt; text-indent: 0pt; text-align: left;"> Capacity in which signing (e.g., organizer,
        member, manager, etc.): <u>                                                   </u> </p>
    <p style="text-indent: 0pt; text-align: left;"><br /></p>
    <p style="padding-left: 6pt; text-indent: 0pt; text-align: left;"> Signature of individual or duly authorized
        representative: <u>                                                     </u> </p>

        <i>Legal notice: The Secretary of State’s office cannot provide legal advice. We highly recommend that you
            seek professional legal, tax and financial advice to assist you in forming your business</i>
            <div style="display: flex; justify-content: space-between; margin-top: 20px;">
                <p>
                    Revised 7/12/2022 
                </p>
                <p>Neb. Rev. Stat. §21-117</p>
            </div>

</body>

</html>`,
    },
];
