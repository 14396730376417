import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import CommonHeadingTitle from '../CommonSection/CommonHeadingTitle'

const SeamlesslySec = () => {
    // Data for the cards
    const seamlesslyData = [
        {
            img: "https://cdn.builder.io/api/v1/image/assets/TEMP/8369dda86ce163ec02fbe68ab9b257660f206d38157a1978bf62f58c93d6dd70?apiKey=02362ed84d214b928bb0b669d3dcf7f8&",
            text: "Your search for the most efficient way to manage your business is over.",
        },
        {
            img: "https://cdn.builder.io/api/v1/image/assets/TEMP/8369dda86ce163ec02fbe68ab9b257660f206d38157a1978bf62f58c93d6dd70?apiKey=02362ed84d214b928bb0b669d3dcf7f8&",
            text: "Spend your time where it'll bring you the most returns. Hint, it isn't in the mailroom.",
        },
        {
            img: "https://cdn.builder.io/api/v1/image/assets/TEMP/8369dda86ce163ec02fbe68ab9b257660f206d38157a1978bf62f58c93d6dd70?apiKey=02362ed84d214b928bb0b669d3dcf7f8&",
            text: "We'll take care of the admin work so that you can finally focus on the fun part of your business.",
        },
    ];

    return (
        <div className='main-section-class'>
            <div className='semalessly-main'>
                <Container>
                    <CommonHeadingTitle heading={'Heres how we do everything '} blueheading={'seamlessly!'} />
                    <div>
                        <img src={process.env.PUBLIC_URL + '/Images/seamlesslyimg.png'} className='img-class' />
                        <h4 className='medium-level-heading mt-5 text-center'>
                            With decades of proven experience, our team is prepared to help you achieve your <br /> goals, and beyond.
                        </h4>
                    </div>
                    <Row className='justify-content-center mt-4'>
                        {seamlesslyData.map((item, index) => (
                            <Col lg={4} md={4} sm={6} xs={12} key={index}>
                                <div className='d-flex'>
                                    <img src={item.img} className='me-3' alt={`Seamlessly item ${index + 1}`} />
                                    <p className='normal-common-txt mt-3'>{item.text}</p>
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </div>
    )
}

export default SeamlesslySec
