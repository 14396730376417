import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";
import ServiceCommonCard from "../../CommonSection/ServiceCommonCard";
import BlueButton from "../../CommonSection/BlueButton";

const CorporateSuppliesThird = () => {
  const services = [
    {
      title: "Corporate Kit",
      description:
        "A collection of essential documents and supplies needed to manage a corporation, including bylaws, minutes, and stock certificates. This kit helps in maintaining corporate compliance.",
    },
    {
      title: "Corporate Seal",
      description:
        "A seal used to authenticate documents issued by a corporation. It provides legal validity to contracts and official paperwork.",
    },
    {
      title: "Stock/Membership Certificates",
      description:
        "Documents that represent ownership in a corporation or LLC. Issuing these certificates is necessary for proper corporate governance.",
    },
  ];

  return (
    <section>
      <Container>
        <CommonHeadingTitle blueheading={"Corporate Supplies Services"} />
        <div className="text-center mt-5 mb-5">
          <h4 className="subheading">
            Corporate supplies and publications include essential items like
            corporate kits, seals, and stock/membership certificates. These
            items are necessary for formalizing business operations, issuing
            shares, and keeping official records.
          </h4>
        </div>
        <Row className="justify-content-center">
          {services.map((service, index) => (
            <Col key={index} lg={4} md={4} sm={6} xs={12}>
              <ServiceCommonCard
                title={service.title}
                description={service.description}
              />
            </Col>
          ))}
        </Row>
        <div className="text-center mt-5 mb-5">
          <BlueButton label={"Get Quote"} />
        </div>
      </Container>
    </section>
  );
};

export default CorporateSuppliesThird;
