import React from 'react'

const CommonHeadingTitle = ({heading,blueheading}) => {
  return (
    <div className='common-heading-main'>
        <h3 className='common-heading-class'>{heading} <span className='common-heading-blue'> {blueheading}</span> </h3>
    </div>
  )
}

export default CommonHeadingTitle