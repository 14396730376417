// import React, { useState, useEffect } from "react";
// import { DataTable } from "primereact/datatable";
// import { Column } from "primereact/column";
// import { InputText } from "primereact/inputtext";
// import { Button } from "primereact/button";
// import { Calendar } from "primereact/calendar";
// import { MultiSelect } from "primereact/multiselect";
// import { TriStateCheckbox } from "primereact/tristatecheckbox";
// import OrderServices from "../../../../services/orderServices";
// import "../InvoicePayment/InvoicePayment.css";

// export default function ClientInvoicePaymentTable({
//   searchQuery,
//   setTotalCount,
// }) {
//   const [data, setData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [filters, setFilters] = useState({});
//   const [globalFilterValue, setGlobalFilterValue] = useState("");
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchData = async () => {
//       setLoading(true);
//       setError(null);

//       try {
//         const response = await OrderServices.getInoviceDuePayment(0, 0);
//         const formattedData =
//           response.data?.map((item) => ({
//             id: item.orderId,
//             clientname: item.displayName || "N/A",
//             ordernumber: item.orderNumber || "N/A",
//             state: item.stateName || "N/A",
//             ordertype: item.orderTypeName || "N/A",
//             entityname: item.displayName || "N/A",
//             orderdate: item.orderDate ? new Date(item.orderDate) : null,
//             orderamount: item.orderAmount || "N/A",
//             invoicenumber: `INV-${item.orderNumber}` || "N/A",
//             status: item.statusName || "N/A",
//           })) || [];

//         setData(formattedData);
//         setTotalCount(formattedData.length);
//       } catch (err) {
//         console.error("Error fetching data:", err);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchData();
//     initFilters();
//   }, []);

//   const initFilters = () => {
//     setFilters({
//       global: { value: null, matchMode: "contains" },
//       clientname: { value: null, matchMode: "contains" },
//       ordernumber: { value: null, matchMode: "contains" },
//       state: { value: null, matchMode: "contains" },
//       ordertype: { value: null, matchMode: "contains" },
//       entityname: { value: null, matchMode: "contains" },
//       orderdate: { value: null, matchMode: "dateIs" },
//       orderamount: { value: null, matchMode: "contains" },
//       invoicenumber: { value: null, matchMode: "contains" },
//       status: { value: null, matchMode: "contains" },
//     });
//   };

//   const onGlobalFilterChange = (e) => {
//     const value = e.target.value;
//     const updatedFilters = {
//       ...filters,
//       global: { value, matchMode: "contains" },
//     };

//     setFilters(updatedFilters);
//     setGlobalFilterValue(value);
//   };

//   const renderHeader = () => (
//     <div className="flex justify-content-end p-2">
//       <div>
//         <Button
//           type="button"
//           icon="pi pi-filter-slash"
//           label="Clear"
//           outlined
//           onClick={initFilters}
//           className="me-2"
//         />
//       </div>
//       <div>
//         <InputText
//           value={globalFilterValue}
//           onChange={onGlobalFilterChange}
//           placeholder="Global Search"
//         />
//       </div>
//     </div>
//   );

//   const dateFilter = (options) => (
//     <Calendar
//       value={options.value}
//       onChange={(e) => options.filterApplyCallback(e.value)}
//       dateFormat="mm/dd/yy"
//       placeholder="mm/dd/yyyy"
//     />
//   );

//   const multiSelectFilter = (options, field) => {
//     const uniqueValues = Array.from(new Set(data.map((item) => item[field])));
//     return (
//       <MultiSelect
//         value={options.value}
//         options={uniqueValues}
//         onChange={(e) => options.filterApplyCallback(e.value)}
//         placeholder={`Select ${field}`}
//         display="chip"
//       />
//     );
//   };

//   const statusTemplate = (rowData) => (
//     <span
//       style={{
//         color:
//           rowData.status === "Need Review"
//             ? "#B54708"
//             : rowData.status === "Pending"
//             ? "#B42318"
//             : "inherit",
//         backgroundColor:
//           rowData.status === "Need Review"
//             ? "#FFFAEB"
//             : rowData.status === "Pending"
//             ? "#FEF3F2"
//             : "transparent",
//         fontWeight: "500",
//         padding: "5px 10px",
//         borderRadius: "25px",
//         display: "inline-block",
//       }}
//     >
//       {rowData.status}
//     </span>
//   );

//   const formatDate = (date) =>
//     date ? date.toLocaleDateString("en-US") : "N/A";

//   const orderAmountBody = (rowData) => {
//     return <div>${rowData.orderamount}</div>;
//   };

//   return (
//     <div className="main-table-class-datatable">
//       <div className="card">
//         {error && <div className="alert alert-danger">{error}</div>}
//         <DataTable
//           value={data}
//           paginator
//           rows={5}
//           loading={loading}
//           filters={filters}
//           globalFilterFields={[
//             "clientname",
//             "ordernumber",
//             "state",
//             "ordertype",
//             "entityname",
//             'invoicenumber',
//             'status'
//           ]}
//           header={renderHeader()}
//           emptyMessage="No records found."
//           // showGridlines
//           scrollable
//           scrollHeight="flex"
//           scrollWidth="100%"
//         >
//           <Column
//             field="clientname"
//             header="Client Name"
//             filter
//             filterElement={(options) =>
//               multiSelectFilter(options, "clientname")
//             }
//             className="font-fam-for-all datatable-lg-col"
//           />
//           <Column
//             field="ordernumber"
//             header="Order Number"
//             filter
//             filterElement={(options) =>
//               multiSelectFilter(options, "ordernumber")
//             }
//             className="font-fam-for-all datatable-lg-col"
//           />
//           <Column
//             field="state"
//             header="State"
//             filter
//             filterElement={(options) => multiSelectFilter(options, "state")}
//             className="font-fam-for-all datatable-lg-col"
//           />
//           <Column
//             field="ordertype"
//             header="Order Type"
//             filter
//             filterElement={(options) => multiSelectFilter(options, "ordertype")}
//             className="font-fam-for-all datatable-lg-col"
//           />
//           <Column
//             field="entityname"
//             header="Entity Name"
//             filter
//             filterElement={(options) =>
//               multiSelectFilter(options, "entityname")
//             }
//             className="font-fam-for-all datatable-lg-col"
//           />
//           <Column
//             field="orderdate"
//             header="Order Date"
//             body={(rowData) => formatDate(rowData.orderdate)}
//             filter
//             filterElement={dateFilter}
//             className="font-fam-for-all datatable-lg-col"
//           />
//           <Column
//             field="orderamount"
//             body={orderAmountBody}
//             header="Order Amount"
//             filter
//             filterElement={(options) =>
//               multiSelectFilter(options, "orderamount")
//             }
//             className="font-fam-for-all datatable-lg-col"
//           />
//           <Column
//             field="invoicenumber"
//             header="Invoive Number"
//             filter
//             filterElement={(options) =>
//               multiSelectFilter(options, "invoicenumber")
//             }
//             className="font-fam-for-all datatable-lg-col"
//           />
//           <Column
//             field="status"
//             header="Status"
//             body={statusTemplate}
//             filter
//             filterElement={(options) =>
//               multiSelectFilter(options, "status")
//             }
//             className="font-fam-for-all datatable-lg-col"
//           />
//           <Column
//             header="Actions"
//             body={() => (
//               <span style={{ color: "#B54708", fontWeight: "bold" }}>
//                 Send Reminder
//               </span>
//             )}
//             className="font-fam-for-all datatable-lg-col"
//           />
//         </DataTable>
//       </div>
//     </div>
//   );
// }


import React, { useState, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { MultiSelect } from "primereact/multiselect";
import { TriStateCheckbox } from "primereact/tristatecheckbox";
import OrderServices from "../../../../services/orderServices";
import "../InvoicePayment/InvoicePayment.css";
import { Col, Row } from "react-bootstrap";

export default function ClientInvoicePaymentTable({
  searchQuery,
  setTotalCount,
}) {
  const allColumns = [
    { field: "clientname", header: "Client Name" },
    { field: "ordernumber", header: "Order Number" },
    { field: "state", header: "State" },
    { field: "ordertype", header: "Order Type" },
    { field: "entityname", header: "Entity Name" },
    { field: "orderdate", header: "Order Date" },
    { field: "orderamount", header: "Order Amount" },
    { field: "invoicenumber", header: "Invoice Number" },
    { field: "status", header: "Status" },
  ];
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [error, setError] = useState(null);
  const [visibleColumns, setVisibleColumns] = useState(allColumns);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await OrderServices.getInoviceDuePayment(0, 0);
        const formattedData =
          response.data?.map((item) => ({
            id: item.orderId,
            clientname: item.displayName || "N/A",
            ordernumber: item.orderNumber || "N/A",
            state: item.stateName || "N/A",
            ordertype: item.orderTypeName || "N/A",
            entityname: item.displayName || "N/A",
            orderdate: item.orderDate ? new Date(item.orderDate) : null,
            orderamount: item.orderAmount || "N/A",
            invoicenumber: `INV-${item.orderNumber}` || "N/A",
            status: item.statusName || "N/A",
          })) || [];

        setData(formattedData);
        setTotalCount(formattedData.length);
      } catch (err) {
        console.error("Error fetching data:", err);
        // setError("Failed to load data. Please try again later.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    initFilters();
  }, []);

  const initFilters = () => {
    setFilters({
      global: { value: null, matchMode: "contains" },
      clientname: { value: null, matchMode: "contains" },
      ordernumber: { value: null, matchMode: "contains" },
      state: { value: null, matchMode: "contains" },
      ordertype: { value: null, matchMode: "contains" },
      entityname: { value: null, matchMode: "contains" },
      orderdate: { value: null, matchMode: "dateIs" },
      orderamount: { value: null, matchMode: "contains" },
      invoicenumber: { value: null, matchMode: "contains" },
      status: { value: null, matchMode: "contains" },
    });
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    const updatedFilters = {
      ...filters,
      global: { value, matchMode: "contains" },
    };

    setFilters(updatedFilters);
    setGlobalFilterValue(value);
  };

  const onColumnToggle = (event) => {
    const selectedColumns = event.value;
    const orderedColumns = allColumns.filter((col) =>
      selectedColumns.some((selCol) => selCol.field === col.field)
    );
    setVisibleColumns(orderedColumns);
  };

  const renderHeader = () => (
    <Row>
      <Col xl={6} md={6} sm={12} xs={12}>
        <MultiSelect
          value={visibleColumns}
          options={allColumns}
          optionLabel="header"
          onChange={onColumnToggle}
          placeholder="Select Columns"
          className="w-full sm:w-20rem ms-2"
          display="chip"
        />
      </Col>
      <Col xl={6} md={6} sm={12} xs={12}>
        <div className="flex justify-content-end p-2">
          <div>
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Clear"
              outlined
              onClick={initFilters}
              className="me-2"
            />
          </div>
          <div>
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Global Search"
            />
          </div>
        </div>
      </Col>
    </Row>
  );

  const dateFilter = (options) => (
    <Calendar
      value={options.value}
      onChange={(e) => options.filterApplyCallback(e.value)}
      dateFormat="mm/dd/yy"
      placeholder="mm/dd/yyyy"
    />
  );

  const multiSelectFilter = (options, field) => {
    const uniqueValues = Array.from(new Set(data.map((item) => item[field])));
    return (
      <MultiSelect
        value={options.value}
        options={uniqueValues}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder={`Select ${field}`}
        display="chip"
      />
    );
  };


  const statusTemplate = (rowData) => (
    <span
      style={{
        color:
          rowData.status === "Need Review"
            ? "#B54708"
            : rowData.status === "Pending"
            ? "#B42318"
            : "inherit",
        backgroundColor:
          rowData.status === "Need Review"
            ? "#FFFAEB"
            : rowData.status === "Pending"
            ? "#FEF3F2"
            : "transparent",
        fontWeight: "500",
        padding: "5px 10px",
        borderRadius: "25px",
        display: "inline-block",
      }}
    >
      {rowData.status}
    </span>
  );

  const formatDate = (date) =>
    date ? date.toLocaleDateString("en-US") : "N/A";

  const orderAmountBody = (rowData) => {
    return <div>${rowData.orderamount}</div>;
  };

  return (
    <div className="main-table-class-datatable">
      <div className="card">
        {error && <div className="alert alert-danger">{error}</div>}
        <DataTable
          value={data}
          paginator
          rows={5}
          loading={loading}
          filters={filters}
          globalFilterFields={[
            "clientname",
            "ordernumber",
            "state",
            "ordertype",
            "entityname",
            "invoicenumber",
            "status",
          ]}
          header={renderHeader()}
          emptyMessage="No records found."
          // showGridlines
          scrollable
          scrollHeight="flex"
          scrollWidth="100%"
        >
        {visibleColumns.map((col) => (
            <Column
              key={col.field}
              field={col.field}
              header={col.header}
              // filter
              sortable 
              filterElement={(options) =>
                col.field === "orderdate"
                  ? dateFilter(options)
                  : multiSelectFilter(options, col.field)
              }
              body={
                col.field === "orderdate"
                  ? (rowData) => formatDate(rowData.orderdate)
                  : col.field === "status"
                  ? statusTemplate
                  : col.field === "orderamount"
                  ? orderAmountBody
                  : null
              }
              className="font-fam-for-all datatable-lg-col"
            />
          ))}
         <Column
            header="Actions"
            body={() => (
              <span style={{ color: "#B54708", fontWeight: "bold" }}>
                Send Reminder
              </span>
            )}
            className="font-fam-for-all datatable-lg-col"
          />
        </DataTable>
      </div>
    </div>
  );
}

