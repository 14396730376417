import React from "react";
import Header from "../RegistredAgentService/Header";
import Partners from "../Partners";
import ComplianceServices from "./Complianc Service/ComplianceServices";
import ComplianceSection from "./Compliance/ComplianceSection.js";
import FAQ from "../RegistredAgentService/Fact.js";
import ContactForm from "./ContactForm.js";
import CorporateFilingsServices from "./Complianc Service/CorporateFilingsService.js";
import CorporateFilingsPic from "../../../Assets/Images/Corprate Filings.png";
import RegisteredBanner from "../RegistredAgentService/RegisteredBanner.js";
import CorporateFillingSec from "../AllServices/CorporateFillingsPage/CorporateFillingSec.js";
import CorporateFillingsThird from "../AllServices/CorporateFillingsPage/CorporateFillingsThird.js";
import FaqCommonSection from "../CommonSection/FaqCommonSection.js";
import OurTrustedSection from "../CommonSection/OurTrustedSection.js";

const complianceData = {
  sectionTitle: "Why Corporate Flilings Matters?",
  sectionSubtitle: "Compliance Services are essential for your business.",
  items: [
    {
      title: "Legal Compliance",
      description:
        "Corporate filings ensure your business remains compliant with regulations in all states or countries where you operate.",
    },
    {
      title: "Operational Flexibility",
      description:
        "Filings such as amendments or conversions allow your business to evolve as it grows, ensuring you meet current and future needs.",
    },
    {
      title: "Avoid Disruptions",
      description:
        "Timely and accurate filings prevent business disruptions due to non-compliance or incorrect documentation.",
    },
    {
      title: "Streamlined Process",
      description:
        "Our services simplify the filing process, reducing paperwork and helping you avoid errors or delays.",
    },
  ],
  images: {
    backgroundImage: CorporateFilingsPic,
    iconImage:
      "https://cdn.builder.io/api/v1/image/assets/TEMP/2517a6129f7ab66094c10670002e3c726e87b73dba9f0efd07fceaf970443933?apiKey=136b58d08a644862944c0e5f22adbbe0&",
  },
};

const CorporateFilingsMain = () => {
  return (
    <div className="mt-24">
      <RegisteredBanner />
      <OurTrustedSection />
      <CorporateFillingSec />
      <CorporateFillingsThird />
      {/* <FaqCommonSection /> */}
      <main className="flex flex-col items-center px-80 pt-16 pb-20 max-md:px-5">
        <div className="flex flex-col px-5 w-full max-w-[1240px] max-md:max-w-full">
          {/* <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <Header />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <Partners />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <ComplianceSection complianceData={complianceData} />
          </div>
          <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <CorporateFilingsServices />
          </div> */}
          {/* <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <FAQ />
          </div> */}
          <div className="flex flex-col px-8 website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
            <p className="contact-form-haeding-main-text1">
              Need help to{" "}
              <span className="contact-form-haeding-main-text2">
                stay compliant?
              </span>
            </p>
            <ContactForm />
          </div>
        </div>
      </main>
    </div>
  );
};

export default CorporateFilingsMain;
