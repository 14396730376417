import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";
import CommonStepCard from "../CommonSection/CommonStepCard";
import {
  faBook,
  faCircleQuestion,
  faRectangleList,
} from "@fortawesome/free-solid-svg-icons";

const RegisteredStep = () => {
  const steps = [
    {
      stepNumber: 1,
      title: " Tell us your business name",
      description:
        "We'll ask you to provide some details, such as your contact info.",
      icon: faBook,
    },
    {
      stepNumber: 2,
      title: " Answer a few questions",
      description:
        "We'll ask you to provide some details, such as your contact info.",
      icon: faRectangleList,
    },
    {
      stepNumber: 3,
      title: "  We'll complete & file your paperwork",
      description:
        "We'll let your state know that we're your new registered agent. And if you're switching registered agents, we'll cover those costs.",
      icon: faCircleQuestion,
    },
  ];

  return (
    <section className="main-section-class">
      <Container>
        <CommonHeadingTitle
          heading={"How to get a registered agent with"}
        />
        <CommonHeadingTitle blueheading={"vState Filings in 3 easy steps"} />
        <Row className="justify-content-center">
          {steps.map((step) => (
            <Col key={step.stepNumber} lg={4} md={6} sm={12} xs={12}>
              <CommonStepCard
                stepNumber={step.stepNumber}
                title={step.title}
                description={step.description}
                icon={step.icon}
              />
            </Col>
          ))}
        </Row>
      </Container>
    </section>
  );
};

export default RegisteredStep;
