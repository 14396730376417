import React, { useEffect } from "react";
import { useState, useRef, useContext } from "react";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { RadioButton } from "primereact/radiobutton";
import { Message } from "primereact/message";
import { useForm, Controller } from "react-hook-form";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import { useNavigate } from "react-router";
import Switch from "@mui/material/Switch";
import SignIn from "../../services/SignInServices";
import { Checkbox } from "primereact/checkbox";
import encryption from "../../services/CryptoClass";
import errorMessages from "../../utils/errorMessages";
import { Avatar } from "primereact/avatar";
import authContext from "../../common/authContext";
import TourOneModal from "../TourGuideComponent/TourOneModal";
import axios from "axios";
import getLoginData  from "../../../src/services/orderServices.js"; 



export const AddSignIn = ({ visible1, setVisible1 }) => {
  const {
    pageRefreshMain,
    setPageRefreshMain,
    isTabRefresh,
    setIsTabRefresh,
    setLoginUserName,
    showModal,
    setShowModal,
    setGlobalCompany
  } = useContext(authContext);

  const [passwordType, setPasswordType] = useState("password");
  const [value, setValue] = useState("");
  const [ingredient, setIngredient] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [cityErr, setcityErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");
  const toast = useRef(null);
  const toastCenter = useRef(null);
  const [upload, setUpload] = useState("");
  const [selectedSkills, setSelectedSkills] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const navigate = useNavigate();
  const [checked, setChecked] = useState(true);
  const [maskedPassword, setMaskedPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [globalErr, setGlobalErr] = useState("");
  // const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal) {
      const timer = setTimeout(() => {
        setShowModal(false); // Hide the modal after 3 seconds
      }, 3000);
      return () => clearTimeout(timer); // Cleanup timeout on component unmount
    }
  }, [showModal]);

  const hideCard = () => {
    setVisible(false);
  };

  let newObject = window.localStorage.getItem("user");
  let updatedUser = Object.assign({}, JSON.parse(newObject));

  const handleCity = (e) => {
    setcityErr("");
    let City = e.target.value;
    if (City === "") {
      setcityErr(errorMessages.emailRequired);
    }
    if (City && City.trim() !== "") {
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(City)) {
        setcityErr(errorMessages.emailValidation);
      }
    }
    setIngredient(e.target.value);
  };

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handlePassword = (e) => {
    setPasswordErr("");
    setMaskedPassword("");
    if (e.target.value === "") {
      setPasswordErr(errorMessages.passwordRequired);
    }
    setPassword(e.target.value);
  };

  const togglePassword = () => {
    // setTimeout(() => {
    //     setPasswordType("password")
    // }, 200);
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const [superAdminId, setSuperAdminId] = useState(null);

  function checkIdsExist(arr, idsToCheck) {
    // Return the found IDs or null if none are found
    return (
      idsToCheck.filter((idToCheck) =>
        arr.some((item) => item.id === idToCheck)
      ) || null
    );
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    const encryptPassword = encryption.encryptData(password);
    setTimeout(() => {
      setMaskedPassword(encryptPassword);
    }, 1000);

    const actualdata = {
      username: ingredient,
      password: password,
      latDetails: "sadadasd",
      longDetails: "asdasd",
      ipAddress: "asadad",
    };

    if (
      ingredient !== "" &&
      password !== "" &&
      cityErr === "" &&
      passwordErr === ""
    ) {
      setIsTabRefresh(true);
      SignIn.signIn(actualdata)
        .then((res) => {
          console.log(res);
          const encryptedResponse = res.data;
          setIsTabRefresh(false);

          // For the login
         
          // const decryptedResponse = encryption.decryptData(encryptedResponse);
          if (res.status !== undefined) {
            if (res.status === 201) {
              localStorage.setItem("user", JSON.stringify(encryptedResponse));
              setPageRefreshMain(!pageRefreshMain);

              if (res.data) {
                if (res.data.companyRoles !== undefined) {
                  if (res.data.companyRoles.length > 0) {
                    if (res.data.companyRoles[0].roles.length > 0) {
                      const superAdminIds = checkIdsExist(
                        res.data.companyRoles[0].roles,
                        [1, 4]
                      );
                      console.log(superAdminIds);
                      const hasSuperId = superAdminIds?.includes(1); // Check if 1 exists in the roles
                      const hasOtherId = superAdminIds?.includes(4);

                      // Check if 4 exists in the roles
                      if (hasOtherId) {
                        setLoginUserName("");
                        setGlobalCompany(null)
                      }

                      if (hasOtherId || hasSuperId) {
                        navigate("/admindash");
                      } else {
                        navigate("/dashboard");
                        setShowModal(true);
                      }
                    }
                  }
                }
              }
            }
          }
          if (res === 401) {
            if (toast.current !== undefined) {
              toast.current.show({
                severity: "warn",
                summary: "Warning",
                detail: `${errorMessages.invalidCredential}`,
                life: 3000,
              });
            }

            const decryptPassword = encryption.decryptData(encryptPassword);
            setTimeout(() => {
              setMaskedPassword(decryptPassword);
            }, 1000);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsTabRefresh(false);
          if (err.response !== undefined) {
            if (err.response.status !== undefined) {
              if (err.response.status === 401) {
                if (err.response.data !== undefined) {
                  if (toast.current !== undefined) {
                    toast.current.show({
                      severity: "warn",
                      summary: "Warning",
                      detail: `${errorMessages.invalidCredential}`,
                      life: 3000,
                    });
                  }

                  const decryptPassword =
                    encryption.decryptData(encryptPassword);
                  setTimeout(() => {
                    setMaskedPassword(decryptPassword);
                  }, 1000);
                }
              }
            }
          }
        });
    } else {
      if (ingredient === "") {
        setcityErr(errorMessages.emailRequired);
      }
      if (password === "") {
        setPasswordErr(errorMessages.passwordRequired);
      }
    }
  };

  return (
    <div>
      <form id="myform" onSubmit={handleSubmit}>
        <Toast ref={toast} />
        <Toast ref={toastCenter} position="center" />

        <div className=" justify-content-center dialog-form-field-space grid  mt-2">
          <div className="field col-12 p-0">
            <span className=" ">
              <div class="relative">
                <InputText
                  id="floating_outlined3"
                  className=" vstate-input-field block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=""
                  maxLength={50}
                  value={ingredient}
                  onChange={handleCity}
                  name="cityName"
                />

                <label
                  htmlFor="floating_outlined3"
                  className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
                >
                  Email<span className="form-field-mandatory">*</span>
                </label>
              </div>
              {cityErr !== "" ? (
                <p className="error-msg font-fam-for-all">{cityErr}</p>
              ) : (
                ""
              )}
            </span>
          </div>
        </div>
        <div className="justify-content-center dialog-form-field-space grid mt-3">
          <div className="field col-12 p-0">
            <span className="relative ">
              <InputText
                id="floating_outlined15"
                className=" vstate-input-field block px-2.5 pb-2.5 pt-2 text-sm text-gray-900 bg-transparent rounded-lg border border-gray-300 appearance-none dark:text-white dark:border-gray-600 dark:focus:border-blue-500 focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                placeholder=""
                type={passwordType}
                value={maskedPassword || password}
                onChange={handlePassword}
              />
              <label
                htmlFor="floating_outlined15"
                className="absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-white dark:bg-gray-900 px-2 peer-focus:px-2 peer-focus:text-red-500 peer-focus:dark:text-red-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto start-1"
              >
                Password<span className="form-field-mandatory">*</span>
              </label>
              <i
                className="absolute right-3 top-1/2 transform -translate-y-1/2 pi"
                style={{ fontSize: "1rem", cursor: "pointer" }}
                onClick={togglePassword}
              >
                <i
                  className={
                    passwordType === "password"
                      ? "pi pi-eye"
                      : "pi pi-eye-slash"
                  }
                ></i>
              </i>
            </span>

            {passwordErr !== "" ? (
              <p className="error-msg font-fam-for-all">{passwordErr}</p>
            ) : (
              ""
            )}
          </div>
        </div>

        <div className="flex justify-content-end dialog-form-md-group-Btn mt-3">
          <span className="font-fam-for-all" style={{ fontSize: "14px" }}>
            <a
              href="/#/forgotpwd"
              className="font-fam-for-all text-blue-500 hover:text-blue-700"
            >
              Forgot Password
            </a>
          </span>
        </div>

        <div className="grid justify-content-center mt-3">
          <div className=" col-12 p-0">
            <div className="flex justify-content-center">
              <Button
                type="submit"
                className="signup-btn vstate-button font-fam-for-all"
                id="signInButton"
                style={{ width: "400px" }}
              >
                Sign In
              </Button>
            </div>
          </div>
        </div>

        <div className="flex justify-content-center dialog-form-md-group-Btn mt-3">
          <span className="font-fam-for-all" style={{ fontSize: "14px" }}>
            Don't have an account?{" "}
            <button
              type="button"
              onClick={() => navigate("/signup")}
              className="font-fam-for-all text-blue-500 hover:text-blue-700"
            >
              Sign Up
            </button>
          </span>
        </div>
      </form>

      {visible && (
        <div className="error-card-container">
          <div className="error-card-header">
            <div className="error-card-header-main1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
              >
                <g clip-path="url(#clip0_627_11094)">
                  <path
                    d="M10.0001 6.66699V10.0003M10.0001 13.3337H10.0084M18.3334 10.0003C18.3334 14.6027 14.6025 18.3337 10.0001 18.3337C5.39771 18.3337 1.66675 14.6027 1.66675 10.0003C1.66675 5.39795 5.39771 1.66699 10.0001 1.66699C14.6025 1.66699 18.3334 5.39795 18.3334 10.0003Z"
                    stroke="#D92D20"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_627_11094">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div className="error-card-header-main2">
              {/* <Button icon="pi pi-times" onClick={hideCard}></Button> */}
              <div className="error-card-close-main">
                <button className="error-card-close-main1" onClick={hideCard}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M15 5L5 15M5 5L15 15"
                      stroke="#B42318"
                      stroke-width="1.67"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <div className="error-card-content-main">
            <p className="error-card-content-text1">Error</p>
            <p className="error-card-content-text2">{globalErr}</p>
          </div>
        </div>
      )}
      {/* <TourOneModal show={showModal} onHide={() => setShowModal(false)} /> */}
    </div>
  );
};
