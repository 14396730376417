import React, { useContext, useEffect, useState } from 'react';
import authContext from '../../../common/authContext';
import { Switch } from '@mui/material';
import MasterServices from '../../../services/coreServices';

function WelcomeHeader() {
  const { loginUserName, setIsTabRefresh, isTabRefresh } = useContext(authContext)
  const [checked1, setChecked1] = useState(true)
  const [allConfig, setAllConfig] = useState([])
  const [flagData, setFlagData] = useState(null)

  useEffect(() => {
    MasterServices.getConfig()
      .then((res) => {
        console.log(res)
        if (Array.isArray(res.data)) {
          setAllConfig(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])


  useEffect(() => {
    if (allConfig.length > 0) {
      const tempFlag = allConfig.find((val) => val.keyName === "flag")?.value === "true";
      const FlagData = allConfig.find((val) => val.keyName === "flag");
      setFlagData(FlagData)
      console.log(tempFlag)
      if (FlagData && FlagData.value === "true") {
        setChecked1(true)
      }
      else if (FlagData && FlagData.value === "false") {
        setChecked1(false)

      }

      // setChecked1(tempFlag);
    }
  }, [allConfig]);


  const handleChange = (e) => {
    setChecked1(e.target.checked)
    if (e.target.checked) {
      if (flagData) {
        const flag = { ...flagData, value: "true" };
        setIsTabRefresh(true)
        MasterServices.updateConfigById(flag)
          .then((res) => {
            console.log(res)
            setIsTabRefresh(false)
          })
          .catch((err) => {
            setIsTabRefresh(false)
            console.error(err)
          });
      }
    }
    else {
      if (flagData) {
        const flag = { ...flagData, value: "false" };
        setIsTabRefresh(true)
        MasterServices.updateConfigById(flag)
          .then((res) => {
            console.log(res)
            setIsTabRefresh(false)
          })
          .catch((err) => {
            setIsTabRefresh(false)
            console.error(err)
          });
      }
    }

  }


  return (
    <header className="flex flex-col mt-6 w-full max-md:max-w-full">
      <div className="flex gap-4 px-4 items-start w-full max-md:max-w-full">
        <div className="flex flex-col flex-1 shrink w-full basis-0 min-w-[240px] max-md:max-w-full">
          <h1 className="text-3xl font-medium leading-none text-gray-900 max-md:max-w-full">
            Welcome, {loginUserName}!
          </h1>
          <p className="mt-1 text-base text-gray-500 max-md:max-w-full">
            View updates on your applications, check the status of ongoing filings, or start a new application.
          </p>
        </div>
        {/* <div className="grid-row">
          <div className='grid px-2'>
            <div className='col-2 flex align-items-center p-4' style={{ fontWeight: "500", fontSize: "14px" }}>Refresh Token</div>
            <div className='col-6 p-4' style={{ fontSize: "14px" }}>
              <Switch
                checked={checked1}
                onChange={handleChange}
                inputProps={{ 'aria-label': 'controlled' }}
              />

            </div>
          </div>
        </div> */}
      </div>
    </header>
  );
}

export default WelcomeHeader;