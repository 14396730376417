import React from "react";
import "../CommonStyleTour/CommonStyleTour.css";
import CommonTourWhite from "../CommonTourComponent/CommonTourWhite";
import CommonTourBlue from "../CommonTourComponent/CommonTourBlue";
import CommonTourModal from "../CommonTourModal";
import MyProfileTourModal from "../MyProfileTourModal";
import EmployeeDashboardModal from "../EmployeeTourComponents/EmployeeDashboardModal";
import EmployeeOrderTourModal from "../EmployeeTourComponents/EmployeeOrderTourModal";

const TourEight = () => {
  const [modalShow, setModalShow] = React.useState(false);

  const handleStartClick = () => {
    setModalShow(true);
  };

  return (
    <section className="main-class-tour">
      <div>
        <h4 className="tour-heading">Welcome to Your Order Center</h4>
        <p className="tour-text-holder">
        This order center is your hub for managing all your client’s orders. Here, you can easily view, track, and manage all your orders in one place.        </p>
      </div>
      <div>
        <CommonTourBlue label={"Start"} onclick={handleStartClick} />
        {/* <div className="mt-3">
          <CommonTourWhite label={"Skip"} />
        </div> */}
        <EmployeeOrderTourModal show={modalShow} onHide={() => setModalShow(false)} />
      </div>
    </section>
  );
};

export default TourEight;
