import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useContext, useEffect, useState } from 'react'
import companyMasterService from '../../services/companyMasterService'
import authContext from '../../common/authContext'
import EditCompanyMember from './EditCompanyComponents/EditKeyPersonnelType'
import EditBOIMember from './EditCompanyComponents/EditBOIMember'
import EditBOIMemberApplication from './EditCompanyComponents/EditBOIMemberApplicant'

const BeneficialOwner = ({data}) => {
    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh } = useContext(authContext);

    const [products, setProducts] = useState([
        
    ])

    
    const [visible,setVisible]=useState(false)

    const [keyPersonalData,setKeyPersonnelData]=useState(null)

    useEffect(() => {
        if (data) {
            companyMasterService.getAllOwnerInformation(data.id)
                .then((res) => {
                    console.log(res)
                    if (res.data !== "") {
                        if (Array.isArray(res.data)) {
                            const temp = res.data.filter((val) => val?.applicantType?.name === "BENEFICIAL OWNER")
                            setProducts(temp)
                        }
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }

    }, [data,isTabRefresh])

    const handleEdit=(rowdata)=>{
        console.log(rowdata)
        setVisible(true)
        setKeyPersonnelData(rowdata)
    }

    const iconBodyTemplate = (rowdata) => {
        return (
            <>
                <button className="pi pi-pencil image-btn-icon" data-toggle="tooltip" data-placement="bottom" title="Edit" onClick={()=>handleEdit(rowdata)}></button>
                {/* <button className="pi pi-trash image-btn-icon-delete" data-toggle="tooltip" data-placement="bottom" title="Delete" ></button> */}
            </>
        )
    }

    const contactBody=(rowdata)=>{
        console.log(rowdata)
        return(
            <>
            <div >
                {`${rowdata.contactNumber}`}
            </div>
            </>
        )
    }

    
    return (
        <div>
            <DataTable value={products} scrollable
                dataKey='name' paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
                className='font-fam-for-all datatable-sm'

                globalFilterFields={["djjddjjd"]}>

                <Column field='firstName' header="Name" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column field='email' header="Email" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column  header="Contact Info" body={contactBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column body={iconBodyTemplate} header="Action" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                


            </DataTable>
            <EditBOIMemberApplication visible={visible} setVisible={setVisible} data={keyPersonalData}/>

        </div>
    )
}

export default BeneficialOwner;