import React from "react";
import logo from "../../../src/Assets/Images/logo.png";
import "./CommonStatePdfStyle/CommonStatePdfStyle.css";
import { Col, Form, Row, Table } from "react-bootstrap";

const ConnecticutOne = () => {
  return (
    <section className="main-class-form-pdf">
      {/* First page */}
      <div>
        <div className="d-flex">
          <div>
            <img src={logo} className="logo-img-pdf me-3" />
          </div>
          <div className="mt-4">
            <h2 className="main-title-pdf">
              Secretary of the State of Connecticut
            </h2>
            <div className="d-flex">
              <span className="blkk-text-big me-2">
                Phone: <span className="blue-big-text">860-509-6003</span>
              </span>
              <span className="blkk-text-big me-2">
                {" "}
                Website: <span className="blue-big-text">
                  business.ct.gov
                </span>{" "}
              </span>
              <span className="blkk-text-big me-2">
                Email: <span className="blue-big-text">bsd@ct.gov</span>
              </span>
            </div>
          </div>
        </div>
        <div className="mt-4">
          <p className="sub-main-title-pdf">
            Certificate of Organization -{" "}
            <span className="small-blue-text">
              Accessible Version Available
            </span>
          </p>
          <p className="normal-texttt">
            Limited Liability Company: Domestic -{" "}
            <span className="sub-normal-text">
              Use Ink. Print or Type. Attach 8 1/2” X 11” sheets if necessary
            </span>{" "}
          </p>
        </div>
        <div className="blk-border">
          <Row>
            <Col xl={8} lg={8} sm={8} xs={12}>
              <div>
                <p>FILING PARTY (CONFIRMATION WILL BE SENT TO THIS ADDRESS):</p>
                <div className="d-flex">
                  <label className="me-3">NAME:</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">ADDRESS:</label>
                  <div>
                    <input type="text" placeholder="$" />
                    <input type="text" placeholder="$" className="mt-3" />
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">CITY:</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STATE:</label>
                  <input type="text" placeholder="$" />
                  <div className="d-flex">
                    <label className="me-3 ms-3">ZIP CODE:</label>
                    <input type="text" placeholder="$" />
                  </div>
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">TELEPHONE NUMBER:</label>
                  <input type="tel" placeholder="$" />
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} sm={4} xs={12}>
              <div className="main-div-filling">
                <div>
                  <h2 className="sub-main-title-pdf">Filing Fee: $120</h2>
                  <span className="sub-normal-text">
                    Make checks payable to{" "}
                  </span>
                  <p className="sub-normal-text">“Secretary of the State”</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <div>
                <p className="sub-main-title-pdf">
                  1.NAME OF LIMITED LIABILITY COMPANY –{" "}
                  <span className="normal-texttt">
                    (Required - Must include business designation (e.g., LLC,
                    L.L.C., etc.)):
                  </span>{" "}
                </p>
                <input type="text" placeholder="$" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={8} lg={8} sm={12} xs={12}>
              <div>
                <p className="sub-main-title-pdf">
                  2.PRINCIPAL OFFICE ADDRESS{" "}
                  <span className="normal-texttt">
                    (Required - Provide full address. P.O. Box unacceptable.): –
                  </span>{" "}
                </p>
                <input type="text" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STREET:</label>
                <div>
                  <input type="text" placeholder="$" />
                  <input type="text" placeholder="$" className="mt-3" />
                </div>
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">CITY:</label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STATE:</label>
                <input type="text" placeholder="$" />
                <div className="d-flex">
                  <label className="me-3 ms-3">ZIP CODE:</label>
                  <input type="text" placeholder="$" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={8} lg={8} sm={12} xs={12}>
              <div>
                <p className="sub-main-title-pdf">
                  3.MAILING ADDRESS
                  <span className="normal-texttt">
                    (Required - Provide full address. P.O. Box acceptable.): –
                  </span>{" "}
                </p>
                <input type="text" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STREET OR P.O. BOX:</label>
                <div>
                  <input type="text" placeholder="$" />
                  <input type="text" placeholder="$" className="mt-3" />
                </div>
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">CITY:</label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STATE:</label>
                <input type="text" placeholder="$" />
                <div className="d-flex">
                  <label className="me-3 ms-3">ZIP CODE:</label>
                  <input type="text" placeholder="$" />
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <p className="blkk-bgg">
            NOTE: COMPLETE EITHER 4A OR 4B ON THE FOLLOWING PAGE, NOT BOTH
          </p>
          <Row>
            <Col xl={8} lg={8} sm={12} xs={12}>
              <div>
                <p className="sub-main-title-pdf">
                  4.APPOINTMENT OF REGISTERED AGENT
                  <span className="normal-texttt">(Required):</span>{" "}
                </p>
              </div>
              <div>
                <label>
                  If Agent is an individual, print or type full legal name:
                </label>
                <input type="text" placeholder="$" />
              </div>
              <div className="mt-3">
                <label>Signature accepting appointment</label>
                <input type="text" placeholder="$" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={6} lg={6} sm={12} xs={12}>
              <div className="d-flex justify-content-between">
                <p className="sub-main-title-pdf">
                  BUSINESS ADDRESS
                  <span className="normal-texttt ms-2">
                    (Required - No P.O. Box):
                  </span>{" "}
                </p>
                <div>
                  <Form.Check label="CHECK BOX IF NONE:" />
                </div>
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STREET:</label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">CITY:</label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STATE:</label>
                <input type="text" placeholder="$" />
                <div className="d-flex">
                  <label className="me-3 ms-3">ZIP CODE:</label>
                  <input type="text" placeholder="$" />
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} sm={12} xs={12}>
              <div className="main-class-left-border ps-4">
                <div>
                  <p className="sub-main-title-pdf">
                    CONNECTICUT RESIDENCE ADDRESS
                    <span className="normal-texttt ms-2">
                      (Required - No P.O. Box):
                    </span>{" "}
                  </p>
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STREET</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">CITY:</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STATE:</label>
                  <input type="text" placeholder="$" />
                  <div className="d-flex">
                    <label className="me-3 ms-3">ZIP CODE:</label>
                    <input type="text" placeholder="$" />
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <div className="main-class-top-border">
                <div className="mt-3">
                  <p className="sub-main-title-pdf">
                    CONNECTICUT MAILING ADDRESS (Required):
                  </p>
                  <span className="normal-texttt ms-2">
                    (P.O. Box is acceptable):
                  </span>{" "}
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STREET: OR P.O. Box:</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">CITY:</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STATE:</label>
                  <input type="text" placeholder="$" />
                  <div className="d-flex">
                    <label className="me-3 ms-3">ZIP CODE:</label>
                    <input type="text" placeholder="$" />
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {/* Second Page */}
      <div className="mt-5 ">
        <div className="d-flex mb-5">
          <div>
            <img src={logo} className="logo-img-pdf me-3" />
          </div>
          <div className="mt-4">
            <h2 className="main-title-pdf">
              Secretary of the State of Connecticut
            </h2>
            <div className="d-flex">
              <span className="blkk-text-big me-2">
                Phone: <span className="blue-big-text">860-509-6003</span>
              </span>
              <span className="blkk-text-big me-2">
                {" "}
                Website: <span className="blue-big-text">
                  business.ct.gov
                </span>{" "}
              </span>
              <span className="blkk-text-big me-2">
                Email: <span className="blue-big-text">bsd@ct.gov</span>
              </span>
            </div>
          </div>
        </div>
        <div className="blk-border mt-3">
          <p className="blkk-bgg">
            NOTE: DO NOT COMPLETE 4B BELOW IF AGENT APPOINTED IN 4A ON THE
            PREVIOUS PAGE.
          </p>
          <Row>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <div className="d-flex">
                <label className="me-3">
                  If Agent is a business, print or type name of business as it
                  appears on our records
                </label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">
                  Signature accepting appointment on behalf of agent
                </label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">
                  Print full name and title of person signing on behalf of
                  agent:
                </label>
                <input type="text" placeholder="$" />
              </div>
            </Col>
          </Row>
        </div>
        <div className="blk-border mt-3">
          <Row>
            <Col xl={6} lg={6} sm={12} xs={12}>
              <p className="sub-main-title-pdf">
                CONNECTICUT BUSINESS ADDRESS
                <span className="normal-texttt">
                  (REQUIRED - No P.O. Box):
                </span>{" "}
              </p>
              <div className="d-flex mt-3">
                <label className="me-3">STREET:</label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">CITY:</label>
                <input type="text" placeholder="$" />
              </div>
              <div className="d-flex mt-3">
                <label className="me-3">STATE:</label>
                <input type="text" placeholder="$" />
                <div className="d-flex">
                  <label className="me-3 ms-3">ZIP CODE:</label>
                  <input type="text" placeholder="$" />
                </div>
              </div>
            </Col>
            <Col xl={6} lg={6} sm={12} xs={12}>
              <div className="main-class-left-border ps-4">
                <div>
                  <p className="sub-main-title-pdf">
                    CONNECTICUT MAILING ADDRESS
                    <span className="normal-texttt ms-2">
                      (REQUIRED - P.O. Box IS acceptable):
                    </span>{" "}
                  </p>
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STREET OR P.O. BOX :</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">CITY:</label>
                  <input type="text" placeholder="$" />
                </div>
                <div className="d-flex mt-3">
                  <label className="me-3">STATE:</label>
                  <input type="text" placeholder="$" />
                  <div className="d-flex">
                    <label className="me-3 ms-3">ZIP CODE:</label>
                    <input type="text" placeholder="$" />
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <div className="main-class-top-border">
                <div className="mt-3">
                  <p className="sub-main-title-pdf">
                    5.MANAGER OR MEMBER INFORMATION (REQUIRED):
                  </p>
                </div>
                <span className="normal-texttt">
                  (Must list at least one Manager or Member of the LLC. Attach 8
                  1/2” x 11” sheets if necessary):
                </span>
              </div>
            </Col>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>FULL NAME</th>
                    <th>TITLE</th>
                    <th>BUSINESS ADDRESS (No P.O. Box)</th>
                    <th>ESIDENCE ADDRESS (No P.O. Box)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <input placeholder="$" />
                    </td>
                    <td>
                      <Form.Check label="Member" />
                      <Form.Check label="Manager" />
                    </td>
                    <td className="p-4">
                      <Form.Check label="Check if none" />
                      <div className="d-flex mt-3">
                        <label className="me-3">ADDRESS:</label>
                        <input type="text" placeholder="$" />
                      </div>
                      <div className="d-flex mt-3">
                        <label className="me-3">CITY:</label>
                        <input type="text" placeholder="$" />
                      </div>
                      <div className="d-flex mt-3">
                        <label className="me-3">STATE:</label>
                        <input type="text" placeholder="$" />
                        <div className="d-flex">
                          <label className="me-3 ms-3">ZIP CODE:</label>
                          <input type="text" placeholder="$" />
                        </div>
                      </div>
                    </td>
                    <td className="p-4">
                      <div className="d-flex mt-3">
                        <label className="me-3">ADDRESS:</label>
                        <input type="text" placeholder="$" />
                      </div>
                      <div className="d-flex mt-3">
                        <label className="me-3">CITY:</label>
                        <input type="text" placeholder="$" />
                      </div>
                      <div className="d-flex mt-3">
                        <label className="me-3">STATE:</label>
                        <input type="text" placeholder="$" />
                        <div className="d-flex">
                          <label className="me-3 ms-3">ZIP CODE:</label>
                          <input type="text" placeholder="$" />
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <input placeholder="$" />
                    </td>
                    <td>
                      <Form.Check label="Member" />
                      <Form.Check label="Manager" />
                    </td>
                    <td className="p-4">
                      <Form.Check label="Check if none" />
                      <div className="d-flex mt-3">
                        <label className="me-3">ADDRESS:</label>
                        <input type="text" placeholder="$" />
                      </div>
                      <div className="d-flex mt-3">
                        <label className="me-3">CITY:</label>
                        <input type="text" placeholder="$" />
                      </div>
                      <div className="d-flex mt-3">
                        <label className="me-3">STATE:</label>
                        <input type="text" placeholder="$" />
                        <div className="d-flex">
                          <label className="me-3 ms-3">ZIP CODE:</label>
                          <input type="text" placeholder="$" />
                        </div>
                      </div>
                    </td>
                    <td className="p-4">
                      <div className="d-flex mt-3">
                        <label className="me-3">ADDRESS:</label>
                        <input type="text" placeholder="$" />
                      </div>
                      <div className="d-flex mt-3">
                        <label className="me-3">CITY:</label>
                        <input type="text" placeholder="$" />
                      </div>
                      <div className="d-flex mt-3">
                        <label className="me-3">STATE:</label>
                        <input type="text" placeholder="$" />
                        <div className="d-flex">
                          <label className="me-3 ms-3">ZIP CODE:</label>
                          <input type="text" placeholder="$" />
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col xl={12} lg={12} sm={12} xs={12}>
              <div className="main-class-top-border">
                <Row>
                  <Col xl={6} lg={6} sm={12} xs={12}>
                    <div className="d-flex justify-content-between">
                      <p className="sub-main-title-pdf">
                        6. ENTITY E-MAIL ADDRESS
                        <span className="normal-texttt">(REQUIRED):</span>{" "}
                      </p>
                      <Form.Check label="NONE" />
                    </div>
                    <div>
                      <label className="me-3">
                        Check box if none. Do not leave blank.
                      </label>
                      <input type="text" placeholder="$" />
                    </div>
                  </Col>
                  <Col xl={6} lg={6} sm={12} xs={12}>
                    <div className="main-class-left-border ps-4">
                      <div>
                        <p className="sub-main-title-pdf">
                          7. NAICS CODE (REQUIRED - six digits):
                        </p>
                      </div>
                      <div className="mt-3">
                        <label className="me-3">
                          Check box if none. Do not leave blank.
                        </label>
                        <div className="number-input d-flex">
                          <input type="text" />
                          <input type="text" />
                          <input type="text" />
                          <input type="text" />
                          <input type="text" />
                          <input type="text" />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
              <div className="main-class-top-border">
                <Row>
                  <Col xl={8} lg={8} sm={12} xs={12}>
                    <p className="sub-main-title-pdf">
                      EXECUTION / SIGNATURE :
                      <span className="normal-texttt">
                        (REQUIRED - Subject to penalties of false statement):
                      </span>{" "}
                    </p>
                    <div>
                      <label className="me-3">DATE (mm/dd/yyyy):</label>
                      <input type="date" />
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {/* Third Page */}

      <div className="blk-border mt-5">
        <div className="text-center">
          <h2 className="main-title-pdf">Certificate of Organization</h2>
          <p className="sub-main-title-pdf">
            Limited Liability Company: Domestic
          </p>
        </div>

        <div>
          <p className="blkk-text-big">
            INSTRUCTIONS (All required sections must be completed): Note: this
            form can be filed online at{" "}
            <span className="blue-big-text">business.ct.gov.</span>
          </p>
        </div>

        <div>
          <p className="normal-texttt">
            <span className="blkk-text-big">
              1. NAME OF LIMITED LIABILITY COMPANY:
            </span>
            The name must include a business designation, such as Limited
            Liability Company, LLC, L.L.C., Limited Liability Co., Ltd.
            Liability Company, or Ltd. Liability Co. Professional LLCs must
            contain P.L.L.C., PLLC, or Professional Limited Liability Company.
            Limited may be abbreviated “Ltd” and Company may be abbreviated “Co”
            and the name must be distinguishable from all other active business
            names on record with this office. MANAGER OR MEMBER INFORMATION
            (REQUIRED):
          </p>

          <p className="normal-texttt">
            <span className="blkk-text-big">2. PRINCIPAL OFFICE: </span> Include
            street number, street name, city, state, and zip code. No P.O. Box.
          </p>

          <p className="normal-texttt">
            <span className="blkk-text-big">3. MAILING ADDRESS: </span> Include
            street number, street name, city, state, and zip code. P.O. Box is
            acceptable.
          </p>

          <p className="normal-texttt">
            <span className="blkk-text-big">
              4. APPOINTMENT OF REGISTERED AGENT:{" "}
            </span>
            The Limited Liability Company may not be its own agent. An
            individual or business entity (other than this LLC) must be
            appointed to accept legal process, notice, or demand served upon the
            Limited Liability Company. The Agent may be either:
          </p>

          <p className="normal-texttt">
            A. Any individual who is a resident of Connecticut, including a
            member or manager of the LLC.
          </p>
          <ul>
            <li>
              • An individual must provide his/her complete business address (or
              state “none”), Connecticut residence address and Connecticut
              mailing address.
            </li>
            <li>• The Agent must sign accepting the appointment.</li>
          </ul>

          <p className="normal-texttt mt-4">
            5. One of the following business types, already on record with this
            office, with a Connecticut address:
          </p>
          <ul>
            <li>
              • A Connecticut corporation, limited liability company, limited
              liability partnership, or statutory trust.
            </li>
            <li>• The Limited Liability Company may not be its own agent.</li>
            <li>
              • A foreign corporation, limited liability company, limited
              liability partnership, or statutory trust, which has obtained a
              Certificate of Authority to transact business in Connecticut and
              has a Connecticut address on file with this office.
            </li>
            <li>
              • Provide the Connecticut principal office address at “Business
              Address” and the Connecticut mailing address at “Mailing Address.”
              The Agent must sign accepting the appointment, and the person
              signing on behalf of a business must print his/her name and title
              next to his/her signature.
            </li>
            <li>• The Agent must sign accepting the appointment.</li>
          </ul>

          <p className="normal-texttt mt-4">
            <span className="blkk-text-big">
              6. MEMBER OR MANAGER INFORMATION:{" "}
            </span>
            The Limited Liability Company must list the name, title, business
            address, and residence address of at least one member or manager of
            the Limited Liability Company (if no business address, must state
            “none”). Include street number, street name, city, state, and zip
            code, and check the appropriate box under “Title.” (Additional
            member(s) and manager(s) information may be included on an attached
            8 ½” x 11” sheet.) Note: LLCs may have as many members/managers as
            they wish. However, only three will be displayed on the business
            registry page. Additional names will be available by requesting
            copies of the original filing.
          </p>

          <p className="normal-texttt">
            <span className="blkk-text-big">7. EMAIL ADDRESS:</span> If none,
            must check the box “none”. The Secretary of the State will notify
            entities via email when their Annual Reports are due.
          </p>

          <p className="normal-texttt">
            <span className="blkk-text-big">8. NAICS CODE: </span> (Go to
            <span className="blue-big-text">
              www.census.gov/naics) 1-888-756-2427.{" "}
            </span>{" "}
            (business / occupation / profession code)
          </p>
          <p className="normal-texttt">
            <span className="blkk-text-big">9.EXECUTION / SIGNATURE:</span> The
            organizer (person forming the LLC) must print or type his/her full
            name and provide a signature. Note that the execution/signature is
            made under the penalties of false statement, certifying that the
            information provided in the document is true. If the organizer is
            another business entity, the person signing on behalf of the
            business entity must provide his/her full name and title for the
            organizing entity. The Limited Liability Company itself may not be
            its own organizer, but a member/manager of the LLC may be the
            organizer.
          </p>
        </div>
      </div>

      {/* Fourth Page */}
      <div className="blk-border mt-5">
        <p className="normal-texttt">
          An annual report will be due yearly, in the following year that the
          entity was formed/registered between January 1st and March 31st, and
          can be easily filed online at{" "}
          <span className="blue-big-text">business.ct.gov.</span>
        </p>
        <p className="normal-texttt">
          Contact your tax advisor or the taxpayer service center at the
          Department of Revenue Services as to any potential tax liability
          relating to your business. Taxpayer Service Center:{" "}
          <span className="blue-big-text">
            (860) 297-5962 or portal.ct.gov/drs
          </span>
        </p>
        <div className="text-center">
          <h3 className="sub-main-title-pdf mt-5 mb-5">
            *YOU ARE REQUIRED TO FILE A CERTIFICATE OF DISSOLUTION IF YOU
            DISSOLVE YOUR BUSINESS.*
          </h3>
        </div>
        <div>
          <h3 className="sub-main-title-pdf mt-5 mb-5">
            OFFICE OF THE SECRETARY OF THE STATE
          </h3>
          <p className="sub-main-title-pdf">MAILING ADDRESS:</p>
          <p className="normal-texttt">
            BUSINESS SERVICES DIVISION <br />
            CONNECTICUT SECRETARY OF THE STATE <br />
            P.O. BOX 150470 <br /> HARTFORD, CT 06115-0470
          </p>
          <p className="sub-main-title-pdf">DELIVERY ADDRESS:</p>
          <p className="normal-texttt">
            BBUSINESS SERVICES DIVISION <br /> CONNECTICUT SECRETARY OF THE
            STATE
            <br />
            P.O. BOX 150470 <br /> HARTFORD, CT 06115-0470{" "}
          </p>
        </div>
        <p className="sub-main-title-pdf">
          PHONE: <span className="blue-big-text">860-509-6003</span>
        </p>
        <p className="sub-main-title-pdf">
          WEBSITE: <span className="blue-big-text">business.ct.gov</span>{" "}
        </p>
      </div>
    </section>
  );
};

export default ConnecticutOne;
