import React from "react";
import { Modal } from "react-bootstrap";
import CommonTourBlue from "./../CommonTourComponent/CommonTourBlue";
// import CommonTourWhite from "./CommonTourComponent/CommonTourWhite";
import "./../CommonStyleTour/CommonStyleTour.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";

import { Navigation } from "swiper/modules";
import MyCompanyModal from "./../MyCompanyModal";

const UserMyProfileTourModal = (props) => {
  const [modalsecondShow, setModalSecondShow] = React.useState(false);

  const handleStartClick = () => {
    setModalSecondShow(true);
    props.onHide();
  };
  return (
    <section>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable
        className="common-modal-class"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            My Profile
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 1 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">View Profile</h4>
                  <p className="tour-text-holder">
                    Access view profile from the drop down menu by clicking on
                    the profile button displayed on the top right corner.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src="https://strapi.redberyltest.in/uploads/myprofile_s1_d_58395a3a39.png" className="tour-img" />
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 2 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading"> View Profile: Login Details</h4>
                  <p className="tour-text-holder">
                    This section allows you to manage your login details within
                    your system.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src="https://strapi.redberyltest.in/uploads/myprofile_s2_d_8668fb7371.png" className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      Quickly update your{" "}
                      <span className="blkkk-textt">email or password</span> to
                      maintain account security.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 3 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">View Profile: Profile Info</h4>
                  <p className="tour-text-holder">
                    This section allows you to manage your profile info within
                    your system.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src="https://strapi.redberyltest.in/uploads/myprofile_s3_d_74490b3401.png" className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Add or modify </span>{" "}
                      Narrow details such as your first name, last name,
                      salutation, email, phone and fax to keep your contact
                      information up to date.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 4 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    {" "}
                    View Profile: Address Details
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to manage your address details
                    within your system.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src="https://strapi.redberyltest.in/uploads/myprofile_s4_d_d311acc527.png" className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Address Management: </span>
                      Update or add your official address, including street
                      details, city, state and ZIP code, to ensure accurate
                      records.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="main-class-tour">
                <div>
                  <span className="step-txtt">Step 5 Of 5</span>
                </div>
                <div>
                  <h4 className="tour-heading">
                    View Profile: Manage Permissions
                  </h4>
                  <p className="tour-text-holder">
                    This section allows you to manage your permissions within
                    your system.
                  </p>
                </div>
                <div>
                  <img loading="lazy" src="https://strapi.redberyltest.in/uploads/myprofile_s5_d_cf169cb64b.png" className="tour-img" />
                </div>
                <div className="mt-4 mb-4">
                  <ul>
                    <li>
                      <span className="blkkk-textt"> Permission Control: </span>
                      View assigned permissions and request additional access
                      based on your role, streamlining your tasks with admin
                      approval.
                    </li>
                  </ul>
                </div>
                <div className="mt-5">
                  <CommonTourBlue
                    label={"Go To Next Module"}
                    onclick={handleStartClick}
                  />
                  {/* <div className="mt-3">
                    <CommonTourWhite label={"Next"} />
                  </div> */}
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </Modal.Body>
      </Modal>
      <MyCompanyModal
        show={modalsecondShow}
        onHide={() => setModalSecondShow(false)}
      />
    </section>
  );
};

export default UserMyProfileTourModal;
