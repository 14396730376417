import React from 'react'
import Header from '../RegistredAgentService/Header'
import Partners from '../Partners'
import ComplianceServices from './Complianc Service/ComplianceServices'
import ComplianceSection from './Compliance/ComplianceSection.js'
import FAQ from '../RegistredAgentService/Fact.js'
import ContactForm from './ContactForm.js'
import DocumentRetrievalService from './Complianc Service/DocumentRetrievalService.js'
import DocumentPic from "../../../Assets/Images/dcument retrival.png"
import RegisteredBanner from '../RegistredAgentService/RegisteredBanner.js'
import DocumentRetrivalSec from '../AllServices/DocumentRetrival/DocumentRetrivalSec.js'
import DocumentRetrivalThird from '../AllServices/DocumentRetrival/DocumentRetrivalThird.js'
import FaqCommonSection from '../CommonSection/FaqCommonSection.js'
import OurTrustedSection from '../CommonSection/OurTrustedSection.js'

const complianceData={
    "sectionTitle": "Why Choose Document Retrieval Services?",
    "sectionSubtitle": "Compliance Services are essential for your business.",
    "items": [
      {
        "title": "Legal Verification",
        "description": "Certified documents confirm your business’s legal status and help with transactions like mergers, loans, or partnerships."
      },
      {
        "title": "Timely Access",
        "description": "Quick retrieval of essential documents helps avoid delays in business processes that require official documentation."
      },
      {
        "title": "Compliance Support",
        "description": "Access to the right documents ensures you meet regulatory and filing requirements, keeping your business compliant."
      },
      {
        "title": "Secure and Reliable",
        "description": "Our services guarantee that your documents are safely stored and retrieved without risk of errors or misplacement."
      }
    ],
    "images": {
      "backgroundImage": DocumentPic,
      "iconImage": "https://cdn.builder.io/api/v1/image/assets/TEMP/2517a6129f7ab66094c10670002e3c726e87b73dba9f0efd07fceaf970443933?apiKey=136b58d08a644862944c0e5f22adbbe0&"
    }
  }

const DocumentRetrievalMain = () => {
    return (
        <div className='mt-24'>
           <RegisteredBanner />
           <OurTrustedSection />
           <DocumentRetrivalSec />
           <DocumentRetrivalThird />
           <FaqCommonSection/>
            <main className="flex flex-col items-center px-80 pt-16 pb-20 max-md:px-5">
                <div className="flex flex-col px-5 w-full max-w-[1240px] max-md:max-w-full">
                    {/* <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <Header />
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <Partners />
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <ComplianceSection complianceData={complianceData}/>
                    </div>
                    <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <DocumentRetrievalService />
                    </div> */}
                    {/* <div className="flex flex-col website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <FAQ />
                    </div> */}
                    <div className="flex flex-col px-8 website-font items-center bg-[linear-gradient(0deg,#FFF_0%,#FFF_100%,#FFF)]">
                        <p className='contact-form-haeding-main-text1'>Need help to <span className='contact-form-haeding-main-text2'>stay compliant?</span></p>
                        <ContactForm />
                    </div>
                </div>
            </main>
        </div>
    )
}

export default DocumentRetrievalMain