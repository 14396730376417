import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import authContext from "../common/authContext";

const Tour = () => {
  const { setSelectedFeature } = useContext(authContext);
  const navigate = useNavigate();
  const userData = localStorage.getItem("user");
 
  // Initialize features as an empty array for any logged-in user
  let features = [];
  let  roleName;
  // Check if userData is not null
  if (userData) {
      // Parse the JSON string into an object
      const userObject = JSON.parse(userData);
     
      // Access the roleName
      const roles = userObject.companyRoles; // Assuming companyRoles is an array
      let isAdmin = false; // Flag to check if the user is an admin
 
      if (roles && roles.length > 0) {
          // Check if the user has the required roles
          roles.forEach(role => {
              if (role.roles && role.roles.length > 0) {
                 roleName = role.roles[0].roleName; // Accessing the first roleName
                  console.log("role is", roleName); // Output the roleName
                 
                  // Set the isAdmin flag if the user is an admin or super admin
                  if (roleName === "SUPER_ADMINISTRATOR" || roleName === "ADMINISTRATOR") {
                      isAdmin = true;
                  }
              }
          });
      } else {
          console.log("No roles found");
      }
 
      // If the user is an admin, you can add features
      if (isAdmin) {
          features.push(
              { id: 1, name: "Dashboard", key: "dashboard", route: "/dashboard" },
              { id: 2, name: "Orders", key: "orders", route: "/order" },
              { id: 3, name: "Companies", key: "companydash", route: "/companydash" },
              { id: 4, name: "Users", key: "contactdash", route: "/contactdash" },
              { id: 5, name: "My Profile", key: "profile", route: "/profile" },
              { id: 6, name: "My Company", key: "company", route: "/company/self" },
 
          );
      }
      else if(!isAdmin && roleName=="EMPLOYEE"){
        features.push(
          { id: 1, name: "Dashboard", key: "employee_dashboard", route: "/dashboard" },
          { id: 2, name: "Orders", key: "employee_order", route: "/order" },
          { id: 3, name: "Companies", key: "emp_company", route: "/companydash" },
          { id: 4, name: "Users", key: "contactdash", route: "/contactdash" },
          { id: 5, name: "My Profile", key: "profile", route: "/profile" },
          { id: 6, name: "My Company", key: "company", route: "/company/self" },
          // emp_client
 
      );
      }
      else{
        // user
        features.push(
          { id: 1, name: "User Dashboard", key: "user_dashboard", route: "/dashboard" },
          // { id: 1, name: "Dashboard", key: "dashboard", route: "/dashboard" },
          { id: 2, name: "Orders", key: "user_order", route: "/order" },
          // { id: 3, name: "Companies", key: "user_company", route: "/companydash" },
          { id: 4, name: "My Profile", key: "user_myprofile", route: "/profile" },
          { id: 5, name: "My Company", key: "user_mycompany", route: "/company/self" },
 
      );
 
      }
 
      console.log("Updated features:", features); // Output the updated features
  } else {
      console.log("No user data found in localStorage");
  }
 
  // { id: 7, name: "Login Functionality", key: "login", route: "/" },
  // { id: 8, name: "Order Creation", key: "order", route: "/order" },
  // { id: 9, name: "Payment Process", key: "payment", route: "/payment" },
  const handleStartTour = (feature) => {
    console.log(feature);
    setSelectedFeature(feature);
    navigate(feature.route);
  };

  return (
    <div className="min-h-screen  bg-gray-50 p-8">
      {/* Header Section */}
      <div className="text-center mb-8 font-fam-for-all">
        <h1 className="text-4xl font-bold text-gray-800 mb-2">Take a Tour</h1>
        <p className="text-gray-600 text-lg">
          Explore the key features of our application and learn how to use them
          efficiently.
        </p>
      </div>

      {/* Features Table */}
      <div className="bg-white shadow rounded-lg p-6">
        <DataTable
          value={features}
          responsiveLayout="scroll"
          selectionMode="single"
          className="w-full font-fam-for-all"
        >
          <Column
            field="name"
            header="Feature Name"
            className="text-gray-700 text-lg"
          ></Column>
          <Column
            header="Action"
            body={(rowData) => (
              <button
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition-all"
                onClick={() => handleStartTour(rowData)} >
                Start Tour
              </button>
            )}
            className="text-center"
          ></Column>
        </DataTable>
      </div>
    </div>
  );
};

export default Tour;
