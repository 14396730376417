import { Dialog } from 'primereact/dialog';
import React from 'react';
import DelaWareCertificateOfFormation from '../utils/StatePdf/Delaware';
import { Button } from 'primereact/button';
import ArizonaCorp from '../utils/StatePdf/ArizonaCorp';
import ConnecticutCorp from '../utils/StatePdf/ConnecticutCorp';

const StatePdfComponent = ({ visible, setVisible, data, orderData }) => {
    console.log("order-data", data);

    const renderContent = () => {
        switch (data?.state?.stateFullDesc) {
            case "Delaware":
                return <DelaWareCertificateOfFormation orderData={orderData} />;
            case "Arizona":
                return <ArizonaCorp/>
            case "Connecticut":
                return <ConnecticutCorp/>        
            default:
                return <p className='mt-2 text-md leading-5 font-fam-for-all text-gray-700 w-[500px] text-wrap'>No Data Available</p>;
        }
    };

    return (
        <div>
            <Dialog visible={visible} onHide={() => setVisible(false)}>
                {renderContent()}
                <div className='flex w-full justify-content-center p-2'>
                    <Button
                        className="form-Btn-Label vstate-reverse-button font-fam-for-all text-center form-label mr-3"
                        label="Cancel"
                        onClick={() => setVisible(false)}
                    />
                </div>
            </Dialog>
        </div>
    );
};

export default StatePdfComponent;
