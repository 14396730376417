import React from 'react';
import { Card } from 'react-bootstrap';
import BlueButton from './BlueButton';
import '../CommonSection/CommonStyle/CommonStyle.css'

const CommonServiceCard = ({ title, image, description, buttonLabel, buttonLink }) => {
    return (
        <section className='main-card-class'>
            <Card>
                <Card.Img variant="top" src={image} className='card-imggg' />
                <Card.Body>
                    <Card.Title>{title}</Card.Title>
                    <Card.Text>
                        <div className='text-align-center mt-4'>
                            <p className='normal-common-txt'>{description}</p>
                        </div>
                    </Card.Text>
                    <div className='mt-4'>
                        <BlueButton label={buttonLabel} onclick={buttonLink} />
                    </div>
                </Card.Body>
            </Card>
        </section>
    );
};

export default CommonServiceCard;
