import React from 'react';
import './CommonStyle/CommonStyle.css';
import { Container, Row } from 'react-bootstrap';
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import { Autoplay } from "swiper/modules";


const OurTrustedSection = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    rtl: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      }
    ]
  };

  // Array of client images and alt texts
  const clientImages = [
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/25834f7741ed58a4fffcb24212b43bad4401a0a8ed68abbcaefd5cc689914323?apiKey=02362ed84d214b928bb0b669d3dcf7f8&", alt: "Partner 1" },
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0aeb22cd1a59f7d9a68de865176096e15ce93677af0bc8a0f0f9449dec71b180?apiKey=02362ed84d214b928bb0b669d3dcf7f8&", alt: "Partner 3" },
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/86abc7d1381841a552e71b8ed078870235232da1c2e46f82a2e08d5afce01a01?apiKey=02362ed84d214b928bb0b669d3dcf7f8&", alt: "Partner 4",},
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/0a26e66950ac2bbc6a32713c27553994998a18e39dddfb940198fca10e275c69?apiKey=02362ed84d214b928bb0b669d3dcf7f8&", alt: "Partner 5" },
    { src: "https://cdn.builder.io/api/v1/image/assets/TEMP/25834f7741ed58a4fffcb24212b43bad4401a0a8ed68abbcaefd5cc689914323?apiKey=02362ed84d214b928bb0b669d3dcf7f8&", alt: "Partner 1" },
  ];

  return (
    <section className='our-client'>
      <Container>
        <Row>
          <h2 className="self-center text-5xl font-medium tracking-tighter text-center text-blue-700 capitalize leading-[52.8px] max-md:max-w-full">
            <span className="font-web-color1">Our Trusted </span>
            <span className="font-web-color">Partners</span>
          </h2>
          <div className="mt-5">
            <Swiper
              slidesPerView={5}
              spaceBetween={30}
              autoplay={{
                delay: 2500,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: { slidesPerView: 2 },
                768: { slidesPerView: 3 },
                991: { slidesPerView: 5 },
                1440: { slidesPerView: 5 },
              }}
              modules={[Autoplay]}
              className="myswiper"
            >
              {clientImages.map((client, index) => (
                <SwiperSlide key={index}>
                  <img
                    src={process.env.PUBLIC_URL + client.src}
                    className="client-img"
                    alt={client.alt}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default OurTrustedSection;


