import logo from "./logo.svg";
import React, { useEffect, useState } from "react";
import "./App.css";
import "primeflex/primeflex.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
//core
import "primereact/resources/primereact.min.css";
//icons
import "primeicons/primeicons.css";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  HashRouter,
} from "react-router-dom";
import City from "./components/Product";
import LLC from "./components/AddComponents/LLC";
import DemoLLC from "./components/AddComponents/demoLLC";
import UserSignUp from "./components/UserSignUp";
import SignIn from "./components/SignIn";
import LandingPage from "./components/AddComponents/LandingPage";
import HomePage from "./components/HomePage";
import DataDashboard from "./components/Dashboard";
import Subscription from "./components/User";
import Contact from "./components/Contact";
import Customers from "./components/Customers";
import EditForm from "./components/EditComponents/EditForm";
import AddForm from "./components/AddComponents/AddForm";
import EditorTemplate from "./components/EditorTemplate";
import Invoice from "./components/Invoice";
import Chatbot from "./components/ChatBot";
import Profile from "./components/Profile";
import ProfileComp from "./components/ProfileComp";
import Client from "./components/Client";
import HomeLandingPage from "./components/HomeLandingPage";
import Layout from "./components/Website/Layout";
import MainContent from "./components/Website/MainContent";
import LayoutApp from "./components/LayoutApp";
import RolesAndAccess from "./components/RolesAndAccess";
import RolesAndPermission from "./components/RolesAndPermission";
import RolesMaster from "./components/Master/RolesMaster";
import RolesWithPermission from "./components/Master/RolesWithPermission";
import RolesWithPermissionChild from "./components/Master/RolesWithPermissionChild";
import authContext from "./common/authContext";
import ContactDashboard from "./components/ContactDashboard";
import ForgotPassword from "./components/ForgotPassword";
import ChangePassword from "./components/ChangePassword";
import CompanyDashboard from "./components/CompanyDashboard";
import extractPermissions from "./utils/extractPermission";
import AddOrderForm from "./components/AddComponents/AddOrderForm";
import OrderDashboard from "./components/OrderDashboard";
import AddOrderDemo from "./components/AddComponents/AddOrderDemo";
import ProductCatalog from "./utils/productCatalog";
import MasterDashboard from "./components/MasterDashboard";
import EmployeeDashboard from "./components/EmployeeDashboard";
import AffiliationPartner from "./components/AffiliationPartner";
import VendorDashboard from "./components/VendorDashBoard";
import VstatePartner from "./components/VstatePartner";
import ErrorBoundary from "./utils/ErrorBoundary";
import EditOrderForm from "./components/EditComponents/EditOrderForm";
import DashboardMain from "./components/Dashboard/Dashboard";
import AddServiceForm from "./components/AddComponents/AddOnServiceForm";
import MasterDashboardDemo from "./components/MasterDashBoardDemo";
import PaymentSuccess from "./components/Payment/PaymentSuccess";
import PaymentFailure from "./components/Payment/PaymentFailure";
import AddOrderFormService from "./components/AddComponents/AddOrderFormService";
import Dashboard from "./components/Dashboard/AdminDashboard/Dashboard";
import FormWithConfirmation from "./utils/PendingRequest";
import PaymentElement from "./utils/PaymentElements";
import WebsiteCompanyMain from "./components/Website/WebsiteComapny/WebsiteCompanyMain";
import RegistredAgentMain from "./components/Website/RegistredAgentService/RegistredAgentMain";
import ContactSection from "./components/Website/ContactSection/ContactSection";
import { AddOtpForm } from "./components/AddComponents/AddOtpForm";
import AccountManagerCompanyDashboard from "./components/AccountManagerCompanyDashboard";
import AccountManagerContactDashboard from "./components/AccountManagerContact";
import AccountManagerOrderDashboard from "./components/AccountManagerOrder";
import OrderDashboardMain from "./components/OrderDashboardMain";
import OrderDashboardMainDemo from "./components/OrderDashboardDemo2";
import ServiceContent from "./components/Website/ContentService/MainContent";
import CorporateFilingsMain from "./components/Website/ContentService/CorporateFilingsMain";
import CorporateSuplies from "./components/Website/ContentService/CorporateSuplies";
import DocumentRetrievalMain from "./components/Website/ContentService/DocumentRetrievalMain";
import SubscriptionServices from "./components/Website/ContentService/SubscriptionServiceMain";
import UccFilingsMain from "./components/Website/ContentService/UccFilingsMain";
import ScrollToTop from "./utils/ScrollToTop";
import PrivacySection from "./utils/PrivacyPolicy";
import Ribbon from "./utils/Ribbons";
import Tour from "./utils/TakeTourComponent";
import Joyride from "react-joyride";
import PendingRequest from "./components/PendingRequest";
import MasterServices from "./services/coreServices";
import AddBOIServiceForm from "./components/AddComponents/AddBOIServiceForm";
import AddEntityFormation from "./components/AddComponents/AddEntityFormation";
import EditEntityFormation from "./components/EditComponents/EditEntityFormation";
import PreviewDataComponent from "./utils/PreviewDataComponent";
import TransferOwnership from "./components/Company/TransferOwnership";
import MyCompanyDashboard from "./components/MyCompany";
import TourOne from "./components/TourGuideComponent/SecondStep/TourOne";
import TourSecond from "./components/TourGuideComponent/SecondStep/TourSecond";
import TourThird from "./components/TourGuideComponent/SecondStep/TourThird";
import TourFourth from "./components/TourGuideComponent/SecondStep/TourFourth";

// import ConnecticutLlc from './utils/StatePdf/ConnecticutLlc';
import AddDashboardEntityFormation from "./components/AddComponents/AddDashboardEntity";
import ConnecticutOne from "./utils/StatePdf/ConnecticutOne";
import ConnecticutCorp from "./utils/StatePdf/ConnecticutCorp";
import propertiesApp from "./utils/propertiesApp";
import TourFifth from "./components/TourGuideComponent/SecondStep/TourFifth";
import TourSixth from "./components/TourGuideComponent/SecondStep/TourSixth";
import AddNewEntityFormation from "./components/AddComponents/AddOrderForm";
import EditNewEntityFormation from "./components/EditComponents/EditNewEntityFormation";
import AddOnServiceForm from "./components/AddComponents/AddOnServiceForm";
import TourEight from './components/TourGuideComponent/SecondStep/TourEight';
import TourSeventh from './components/TourGuideComponent/SecondStep/TourSeventh';
import TourNine from './components/TourGuideComponent/SecondStep/TourNine';
import TourTen from './components/TourGuideComponent/SecondStep/TourTen';
import TourEleven from './components/TourGuideComponent/SecondStep/TourEleven';
import TourTwelve from './components/TourGuideComponent/SecondStep/TourTwelve';
import TourThirteen from './components/TourGuideComponent/SecondStep/TourThirteen';
import TourEighteen from './components/TourGuideComponent/SecondStep/TourEighteen';
import TourSeventeen from './components/TourGuideComponent/SecondStep/TourSeventeen';

function App() {
  const user = JSON.parse(localStorage.getItem("user"));
  const [isEmailExist, setIsEmailExist] = useState(false);
  const [pageRefreshMain, setPageRefreshMain] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [globalCompany, setGlobalCompany] = useState(null);

  const [isTabRefresh, setIsTabRefresh] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [editPayloadData, setEditPayloadData] = useState(null);

  const [addOnServiceData, setAddOnServiceData] = useState("");

  const [isNameRendered, setIsNameRendered] = useState(false);

  const [entityDash, setEntityDash] = useState(null);

  const [stateDash, setStateDash] = useState(null);

  const [paymentMethod, setPaymentMethod] = useState("Pay now");

  const [loginUserName, setLoginUserName] = useState("");

  const [orderTypeId, setOrderTypeId] = useState(null);

  const [paymentData, setPaymentData] = useState(null);

  const [paymentOrderData, setPaymentOrderData] = useState(null);

  const [signUpPayload, setSignUpPayload] = useState(null);

  const [redirectedData, setRedirectedData] = useState(null);

  const [isReviewDone, setIsReviewDone] = useState(true);

  const [paySessionId, setPaySessionId] = useState(null);

  const [accountManagerId, setAccountManagerId] = useState(null);

  const [addonServiceRedirectedData, setAddOnServiceRedirectedData] =
    useState(null);

  const [filterOrderStatusId, setFilterStatusId] = useState(null);

  const [fileBase64, setFileBase64] = useState(null);

  const [selectedFeature, setSelectedFeature] = useState(null);

  const [tourRun, setTourRun] = useState(false);

  const [tourSteps, setTourSteps] = useState([]);

  const [orderContinue, setOrderContinue] = useState(false);

  const [wizardScroll, setWizardScroll] = useState(false);

  const [closesHoliday, setClosesHoliday] = useState(null);

  const [messages, setMessages] = useState([]);

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (selectedFeature) {
      const stepsMap = {
        dashboard: [
          { target: ".dashbord1", content: <TourOne />, disableBeacon: true },
        ],
        orders: [
          { target: ".orders1", content: <TourSecond />, disableBeacon: true },
        ],
        companydash: [
          {
            target: ".companies1",
            content: <TourThird />,
            disableBeacon: true,
          },
        ],
        contactdash: [
          { target: ".users1", content: <TourFourth />, disableBeacon: true },
        ],
        profile: [
          { target: ".profile1", content: <TourFifth />, disableBeacon: true },
        ],
        company: [
          { target: ".company1", content: <TourSixth />, disableBeacon: true },
        ],
        login: [
          {
            target: ".login1",
            content: "You can start your business here",
            disableBeacon: true,
          },
          {
            target: ".login2",
            content: "Click here to login",
            disableBeacon: true,
          },
        ],
        order: [
          {
            target: ".order-step1",
            content: "Click on this create button",
            disableBeacon: true,
          },
          {
            target: ".order-step2",
            content: "Add to cart.",
            disableBeacon: true,
          },
        ],
        payment: [
          {
            target: ".payment-step1",
            content: "Enter payment details.",
            disableBeacon: true,
          },
          {
            target: ".payment-step2",
            content: "Confirm payment.",
            disableBeacon: true,
          },
        ],
        employee_dashboard: [
          {
            target: ".emp-step1",
            content: <TourSeventh />,
            disableBeacon: true,
          },
          // {target:".emp-step2", content:<TourEight/>, disableBeacon:true}
        ],
        employee_order: [
          { target: ".emp-step2", content: <TourEight />, disableBeacon: true },
        ],

        emp_company: [
          {
            target: ".emp_company",
            content: <TourSeventeen />,
            disableBeacon: true,
          },
        ],

        emp_client: [
          {
            target: ".emp_client",
            content: <TourEighteen />,
            disableBeacon: true,
          },
        ],

        user_company: [
          { target: ".user_comp", content: <TourNine />, disableBeacon: true },
        ],
        user_dashboard: [
          { target: ".user_dash", content: <TourTen />, disableBeacon: true },
        ],
        user_mycompany: [
          {
            target: ".user_mycompany",
            content: <TourEleven />,
            disableBeacon: true,
          },
        ],

        user_myprofile: [
          {
            target: ".user_myprofile",
            content: <TourTwelve />,
            disableBeacon: true,
          },
        ],
        user_order: [
          {
            target: ".user_order",
            content: <TourThirteen />,
            disableBeacon: true,
          },
        ],
      };
      setTourSteps(stepsMap[selectedFeature.key] || []);
      window.location.href= `${propertiesApp.tour_url}/#${selectedFeature.route}`
      // window.location.href = `http://localhost:3000/#${selectedFeature.route}`;

      setTourRun(true);
    }
  }, [selectedFeature]);

  useEffect(() => {
    MasterServices.getAllAnnouncement()
      .then((res) => {
        if (Array.isArray(res.data)) {
          const currentDate = new Date();
          const upcomingAnnouncements = res.data.filter((holiday) => {
            const holidayDate = new Date(holiday.announcementExpiryDate);
            const differenceInDays =
              (holidayDate - currentDate) / (1000 * 60 * 60 * 24);
            return (
              differenceInDays >= -holiday.daysBeforeAnnouncementExpiry &&
              differenceInDays >= 0 &&
              !holiday.isMute
            );
          });

          setMessages(
            upcomingAnnouncements.map(
              (announcement) => announcement.announcementDesc
            )
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const clearData = () => {
    setStateDash(null);
    setEntityDash(null);
    // setRedirectedData(null)
    setOrderTypeId(null);
    // setFilterStatusId(null)
    setPaymentMethod("Pay now");
    // setFileBase64(null)
    setAddOnServiceRedirectedData(null);
    setAddOnServiceData("");
    setEditPayloadData(null);
    setOrderContinue(false);
  };

  // inspect blocked

  // useEffect(() => {
  //   const handleContextMenu = (e) => {
  //     e.preventDefault();
  //   };

  //   const handleKeyDown = (e) => {
  //     if (
  //       (e.ctrlKey && e.shiftKey && e.code === "KeyI") ||
  //       (e.ctrlKey && e.code === "KeyU") ||
  //       e.code === "F12"
  //     ) {
  //       e.preventDefault();
  //     }
  //   };

  //   document.addEventListener("contextmenu", handleContextMenu);
  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("contextmenu", handleContextMenu);
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  useEffect(() => {
    if (user && globalCompany) {
      console.log(globalCompany);
      const userPermissions = extractPermissions(globalCompany);
      console.log(userPermissions);
      setPermissions(userPermissions);
    }
  }, [pageRefreshMain, globalCompany]);

  return (
    <div className="App">
      <authContext.Provider
        value={{
          isEmailExist: isEmailExist,
          setIsEmailExist: setIsEmailExist,
          permissions: permissions,
          setPermissions: setPermissions,
          pageRefreshMain: pageRefreshMain,
          setPageRefreshMain: setPageRefreshMain,
          globalCompany: globalCompany,
          setGlobalCompany: setGlobalCompany,
          isLoading: isLoading,
          setIsLoading: setIsLoading,
          isTabRefresh: isTabRefresh,
          setIsTabRefresh: setIsTabRefresh,
          editPayloadData: editPayloadData,
          setEditPayloadData: setEditPayloadData,
          addOnServiceData: addOnServiceData,
          setAddOnServiceData: setAddOnServiceData,
          isNameRendered: isNameRendered,
          setIsNameRendered: setIsNameRendered,
          stateDash: stateDash,
          setStateDash: setStateDash,
          entityDash: entityDash,
          setEntityDash: setEntityDash,
          paymentMethod: paymentMethod,
          setPaymentMethod: setPaymentMethod,
          loginUserName: loginUserName,
          setLoginUserName: setLoginUserName,
          orderTypeId: orderTypeId,
          setOrderTypeId: setOrderTypeId,
          paymentData: paymentData,
          setPaymentData: setPaymentData,
          setPaymentOrderData: setPaymentOrderData,
          paymentOrderData: paymentOrderData,
          signUpPayload: signUpPayload,
          setSignUpPayload: setSignUpPayload,
          redirectedData: redirectedData,
          setRedirectedData: setRedirectedData,
          isReviewDone: isReviewDone,
          setIsReviewDone: setIsReviewDone,
          paySessionId: paySessionId,
          setPaySessionId: setPaySessionId,
          accountManagerId: accountManagerId,
          setAccountManagerId: setAccountManagerId,
          addonServiceRedirectedData: addonServiceRedirectedData,
          setAddOnServiceRedirectedData: setAddOnServiceRedirectedData,
          filterOrderStatusId: filterOrderStatusId,
          setFilterStatusId: setFilterStatusId,
          clearData: clearData,
          fileBase64: fileBase64,
          setFileBase64: setFileBase64,
          selectedFeature: selectedFeature,
          setSelectedFeature: setSelectedFeature,
          orderContinue: orderContinue,
          setOrderContinue: setOrderContinue,
          wizardScroll: wizardScroll,
          setWizardScroll: setWizardScroll,
          showModal: showModal,
          setShowModal: setShowModal,
        }}
      >
        {messages.length > 0 && (
          <Ribbon messages={messages} autoHideDuration={5000} />
        )}
        {tourRun && (
          <Joyride
            steps={tourSteps}
            run={tourRun}
            continuous
            showProgress
            showSkipButton
            styles={{
              options: {
                arrowColor: "#fff",
                backgroundColor: "#ffff",
                textColor: "#333",
                overlayColor: "rgba(0, 0, 0, 0.5)",
                primaryColor: "#f04",
                spotlightShadow: "0 0 15px rgba(255, 255, 255, 0.5)",
                fontFamily: "Inter",
              },
              buttonNext: {
                backgroundColor: "#1570EF",
                color: "#fff",
                boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                border: "1px solid var(--Primary-700, #004EEC)",
                fontFamily: "Inter",
                display: "none",
              },
              buttonBack: {
                color: "#555",
                fontFamily: "Inter",
              },
              buttonSkip: {
                color: "#000",
                fontFamily: "Inter",
              },
            }}
            callback={(data) => {
              const { status } = data;
              if (status === "skipped") {
                setTourRun(false);
              }
              if (status === "finished") {
                setTourRun(false);
                // window.location.href = "http://localhost:3000/#/tour";
              }
            }}
          />
        )}

        <HashRouter basename="/">
          <ScrollToTop />
          <ErrorBoundary>
            <Routes>
              <Route path="/demo" element={<City />} />
              <Route path="/signup" element={<UserSignUp />} />
              <Route path="/signin" element={<SignIn />} />
              <Route path="/landing" element={<LandingPage />} />
              <Route path="/hom" element={<HomePage />} />
              <Route path="/forgotpwd" element={<ForgotPassword />} />
              <Route path="/user" element={<Subscription />} />
              <Route path="/changepwd" element={<ChangePassword />} />
              <Route path="/template" element={<EditorTemplate />} />
              <Route path="/chat" element={<Chatbot />} />
              <Route path="/partner" element={<VstatePartner />} />
              <Route path="/otp" element={<AddOtpForm />} />
              <Route path="/company" element={<HomeLandingPage />} />

              <Route path="/" element={<Layout />}>
                <Route path="/" element={<MainContent />} />
                <Route path="/webcompany" element={<WebsiteCompanyMain />} />
                <Route
                  path="/registeredcompany"
                  element={<RegistredAgentMain />}
                />
                <Route path="/webcontact" element={<ContactSection />} />
                <Route path="/compliance" element={<ServiceContent />} />
                <Route
                  path="/corporatefilings"
                  element={<CorporateFilingsMain />}
                />
                <Route
                  path="/corporatesuplies"
                  element={<CorporateSuplies />}
                />
                <Route
                  path="/documentretrieval"
                  element={<DocumentRetrievalMain />}
                />
                <Route
                  path="/subscription"
                  element={<SubscriptionServices />}
                />
                <Route path="/uccfilings" element={<UccFilingsMain />} />
                <Route path="/privacy-policy" element={<PrivacySection />} />
              </Route>

              <Route path="/" element={<LayoutApp />}>
                <Route path="/dashboard" element={<DashboardMain />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/client" element={<Client />} />
                <Route path="/profile" element={<ProfileComp />} />
                <Route path="/permission" element={<RolesAndPermission />} />
                <Route path="/roles" element={<RolesMaster />} />
                <Route
                  path="/roleswithpermission"
                  element={<RolesWithPermission />}
                />
                <Route
                  path="/roleswithpermissionchild"
                  element={<RolesWithPermissionChild />}
                />
                <Route path="/contactdash" element={<ContactDashboard />} />
                <Route path="/companydash" element={<CompanyDashboard />} />
                <Route path="/company/self" element={<MyCompanyDashboard />} />
                <Route
                  path="/company/transfer"
                  element={<TransferOwnership />}
                />
                <Route path="/llc" element={<DemoLLC />} />
                <Route path="/orderform" element={<AddEntityFormation />} />
                <Route
                  path="/orderform/new"
                  element={<AddNewEntityFormation />}
                />
                <Route
                  path="/dashboard/orderform"
                  element={<AddDashboardEntityFormation />}
                />
                <Route path="/edit/orderform" element={<EditOrderForm />} />
                <Route
                  path="/edit/entity"
                  element={<EditNewEntityFormation />}
                />
                <Route path="/order" element={<OrderDashboardMainDemo />} />
                <Route path="/orderdemo" element={<OrderDashboard />} />
                <Route path="/product" element={<ProductCatalog />} />
                <Route path="/admin" element={<MasterDashboardDemo />} />
                <Route path="/employee" element={<EmployeeDashboard />} />
                <Route path="/vendor" element={<VendorDashboard />} />
                <Route path="/dash" element={<DashboardMain />} />
                <Route path="/addonservice" element={<AddBOIServiceForm />} />
                <Route path="/service/addon" element={<AddOnServiceForm />} />
                <Route path="/orderformcgs" element={<AddOrderFormService />} />
                <Route path="/admindash" element={<Dashboard />} />
                <Route path="/pendingreq" element={<FormWithConfirmation />} />
                <Route path="/stripe" element={<PaymentElement />} />
                <Route path="/payment-success" element={<PaymentSuccess />} />
                <Route
                  path="/company/accountmanager"
                  element={<AccountManagerCompanyDashboard />}
                />
                <Route
                  path="/contact/accountmanager"
                  element={<AccountManagerContactDashboard />}
                />
                <Route
                  path="/order/accountmanager"
                  element={<AccountManagerOrderDashboard />}
                />
                <Route path="/pending" element={<PendingRequest />} />
                <Route path="/tour" element={<Tour />} />
                <Route path="/preview" element={<PreviewDataComponent />} />
              </Route>
            </Routes>
          </ErrorBoundary>
        </HashRouter>
      </authContext.Provider>
    </div>
  );
}

export default App;
