import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../CommonSection/CommonHeadingTitle";

const RegistrationService = () => {
  return (
    <section className="main-section-class">
      <Container>
        <CommonHeadingTitle heading={"Get the best "} />
        <CommonHeadingTitle blueheading={" company registration service "} />
        <Row className="justify-content-center">
          <Col lg={4} md={6} sm={12} xs={12}>
            <div className="common-card-main mt-5">
              <h2  className="step-color">Quick start your business</h2>
              <p className="normal-common-txt mt-4">Experience the fastest business registration service available. Fill out our form and our team will handle your company registration right away. Your business can be officially registered in a matter of days!</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <div className="common-card-main mt-5">
              <h2  className="step-color">We have got you covered!</h2>
              <p className="normal-common-txt mt-4">By choosing vState Filings to handle your company formation, you are making a strategic decision. We will protect you from potential losses. Our experts have registered thousands of businesses similar to yours across the US.</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <div className="common-card-main mt-5">
              <h2  className="step-color">Remote registration</h2>
              <p className="normal-common-txt mt-4">Enjoy the convenience of remote business registration with us. No need for office visits or lengthy phone waits, no matter where you are or what time it is.</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <div className="common-card-main mt-5">
              <h2  className="step-color">Free advisory sessions</h2>
              <p className="normal-common-txt mt-4">Explore our complimentary consultation services offered with our business formation packages. Our representatives are here to assist you, addressing all your concerns and helping you navigate the process seamlessly.</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <div className="common-card-main mt-5">
              <h2  className="step-color">Save money</h2>
              <p className="normal-common-txt mt-4">We provide the most efficient and cost-effective solution for business formation. Our streamlined process ensures simplicity and speed. Explore it firsthand.</p>
            </div>
          </Col>
          <Col lg={4} md={6} sm={12} xs={12}>
            <div className="common-card-main mt-5">
              <h2  className="step-color">Personalized dashboard</h2>
              <p className="normal-common-txt mt-4">Your dashboard is designed for convenience. Access all your documents, register new companies, choose additional services, or cancel current ones—all from one modern and well-organized platform.</p>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RegistrationService;
