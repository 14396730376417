import React, { useContext, useEffect, useState } from 'react'
import OrderServices from '../services/orderServices';
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import authContext from '../common/authContext';
import { loadStripe } from '@stripe/stripe-js';

const PaymentComponent = ({ closeProgresbar3, setCloseProgresbar3, data }) => {
    const { clearData, permissions, loginUserName, globalCompany, filterOrderStatusId, setFilterStatusId, isTabRefresh, setIsTabRefresh, editPayloadData, setEditPayloadData, redirectedData, setRedirectedData } = useContext(authContext);

    const [stripeData, setStripeData] = useState(null)
    const stripePromise = loadStripe("pk_test_51PyTdiKhsNfb7YuLO8XP7SHE3FUn3rspK3sI7OTbOIulKp9XdM7WtU4gqjxiKVDVBldIKIEBvL8PLjdKkW1h8f1100bw75rZPd");

    console.log(data)
    useEffect(() => {
        if (data) {
            OrderServices.getDataById(`/order/api/orderDetails/getById/${data.id}`)
                .then((res) => {
                    console.log(res.data)
                    if (res.data && res.data !== "") {
                        setStripeData(res.data)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [data])

    const stripePay = async (item, orderData) => {

        try {

            const stripe = await stripePromise;

            const data = {

                product: item
            }

            console.log(item)

            const productPaydata = item.map((val) => {
                return {
                    name: val.productListing.displayName,
                    unitAmount: Math.round(val.unitPrice * 100),
                    quantity: 1
                };
            });

            const paytemp = {
                "lineItems": productPaydata
            }

            // const response = await axios.post(`$/checkout`, data);
            setIsTabRefresh(true)
            OrderServices.paymentOrder(paytemp)
                .then((response) => {
                    console.log(response)
                    setIsTabRefresh(false)
                    localStorage.setItem("sessionId", response.data.sessionId)
                    // setPaySessionId(res.data.sessionId)

                    // const sessionId = response.data.id;
                    // console.log(sessionId)

                    const localData = {
                        data: orderData,
                        res: response.data,
                        orderLineItem: item,
                        updatedBy: loginUserName
                    }
                    localStorage.setItem("payment", JSON.stringify(localData))
                    setTimeout(() => {
                        window.location.href = response.data.checkoutUrl;
                    }, 1000); // Small timeout to ensure data is saved

                    // const { error } = await stripe.redirectToCheckout({
                    //     sessionId: sessionId
                    // });
                    // if (error) {
                    //     console.error("Stripe Checkout Error:", error);
                    // }
                })
                .catch((err) => {
                    console.log(err)
                    setIsTabRefresh(false)
                })


        } catch (err) {
            console.error("Error during checkout process:", err);
        }
    }
    const handlePaymentDialog = (data) => {
        console.log(data)
        setStripeData(data)
        setCloseProgresbar3(true)
    }

    const handlePayment = () => {
        if (stripeData) {
            console.log(stripeData)
            stripePay(stripeData.orderLineItem, stripeData)
        }
    }
    return (
        <div>
            {
                closeProgresbar3 && (
                    <Dialog className="payment-dialog rounded-lg" visible={closeProgresbar3} onHide={() => setCloseProgresbar3(false)}>
                        <section className="flex rounded-lg overflow-hidden flex-wrap gap-6 items-start p-3 bg-white rounded-xl shadow-xl max-md:px-5">
                            <div className="payment-icon-confirm">
                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="24" viewBox="0 0 14 24" fill="none">
                                    <path d="M7 1V23M12 5H4.5C3.57174 5 2.6815 5.36875 2.02513 6.02513C1.36875 6.6815 1 7.57174 1 8.5C1 9.42826 1.36875 10.3185 2.02513 10.9749C2.6815 11.6313 3.57174 12 4.5 12H9.5C10.4283 12 11.3185 12.3687 11.9749 13.0251C12.6313 13.6815 13 14.5717 13 15.5C13 16.4283 12.6313 17.3185 11.9749 17.9749C11.3185 18.6313 10.4283 19 9.5 19H1" stroke="#039855" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>
                            </div>
                            <div className="flex flex-col flex-1 shrink basis-0 min-w-[240px] max-md:max-w-full">
                                <header className="flex flex-col w-full max-md:max-w-full">
                                    <h2 className="text-lg mb-0 font-fam-for-all font-medium leading-loose text-red-900 max-md:max-w-full">
                                        Pay Now
                                    </h2>
                                    <p className="mt-2 text-md leading-5 text-gray-700 w-[500px] font-fam-for-all text-wrap">
                                        You selected the <strong>Pay Now</strong> option. Please complete your payment to finalize your order.</p>
                                </header>
                                <div className="flex gap-3 justify-content-end items-center mt-3 w-full text-base font-medium max-md:max-w-full">
                                    <div className="flex justify-content-end gap-3 items-start self-stretch my-auto min-w-[240px]">
                                        <Button className="vstate-reverse-button" label="Cancel" onClick={() => setCloseProgresbar3(false)} />
                                        <Button className="vstate-button" label="Proceed to payment" onClick={handlePayment} />

                                    </div>
                                </div>
                            </div>
                        </section>
                    </Dialog>
                )
            }
        </div>
    )
}

export default PaymentComponent