import { Avatar } from '@mui/material';
import { Button } from 'primereact/button';
import React, { useContext, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';
import MasterServices from '../../services/coreServices';
import ContactService from '../../services/ContactService';
import authContext from '../../common/authContext';

const UploadImage = ({ setImageVisible, data }) => {
  const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading,fileBase64, setFileBase64 } = useContext(authContext);

  const [visible, setVisible] = useState(true);
  const [visible1, setVisible1] = useState(true);
  const [useCamera, setUseCamera] = useState(false);
  const [fileData, setFileData] = useState(null)
  const [username, setUserName] = useState("");
  const [avatarImage, setAvatarImage] = useState(null);
  const webcamRef = useRef(null);

  const getInitials = (name) => {
    const nameArray = name.trim().split(" ");
    if (nameArray.length >= 2) {
      const firstNameInitial = nameArray[0].charAt(0).toUpperCase();
      const lastNameInitial = nameArray[1].charAt(0).toUpperCase();
      return firstNameInitial + lastNameInitial;
    } else {
      const initial = nameArray[0].charAt(0).toUpperCase();
      return initial + initial;
    }
  };

  useEffect(() => {
    if (data) {
      if (data.contactDetails !== undefined) {
        setUserName(`${data.contactDetails.firstName} ${data.contactDetails.lastName}`);
        if (fileBase64) {
          setAvatarImage(`data:image/jpeg;base64,${fileBase64}`);  
        }
      }
    }

  }, [data,fileBase64]);

  console.log(avatarImage)

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileData(file)
      const reader = new FileReader();
      reader.onloadend = () => {
        setAvatarImage(reader.result);
        setVisible1(false);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleClick = () => {
    document.getElementById('fileInput').click();
  };

  const handleUseCamera = () => {
    setUseCamera(true);
    setVisible(false);
  };

  const dataURLtoBlob = (dataURL) => {
    const byteString = atob(dataURL.split(',')[1]);
    const mimeString = dataURL.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  const handleCapture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setFileData(dataURLtoBlob(imageSrc));
    setAvatarImage(imageSrc);
    setUseCamera(false);
    setVisible1(false);
  };

  const handleClick1 = () => {
    setVisible(false)
  }

  const handleCancelCamera = () => {
    setUseCamera(false);
    setVisible(true);
  };


  const handleSave = () => {
    // Save photo logic here

    const formdata = new FormData();
    if (fileData) {
      formdata.append("file", fileData);
    }
    setIsLoading(true)
    MasterServices.saveFile("AvatarImagePath", formdata)
      .then((res) => {
        console.log(res)
        if (data) {
          const actualdata1 = {
            "id": data.contactDetails.id,
            "salutation": data.contactDetails.salutation,
            "jobTitle": "Java Developer",
            "contactSourceId": null,
            "recordTypeId": null,
            "avatarImgPath": res.data,
            "phone": data.contactDetails.mobileNo,
            "fax": data.contactDetails.fax,
            "statusId": 1
          }
          ContactService.updateContact(actualdata1, data.contactDetails.id)
            .then((res) => {
              console.log(res.data)
              setImageVisible(false)
              setIsLoading(false)
              window.location.reload(false)
            })
            .catch((err) => {
              console.log(err)
              setIsLoading(false)
            })
        }

      })
      .catch((err) => {
        console.log(err)
        setIsLoading(false)
      })
  };

  return (
    <>
      <div className='view-profile-container'>
        <div className='view-profile-header-main'>
          <div className='view-profile-header-content-main'>
            <div className='view-profile-header-content-text-main'>
              <p className='view-profile-header-content-text1 m-0'>Profile photo</p>
              <p className='view-profile-header-content-text2'>Update or change your profile picture here.</p>
            </div>
            <div className='view-profile-header-content-close-main'>
              <button onClick={() => setImageVisible(false)}><i className='pi pi-times'></i></button>
            </div>
          </div>
        </div>
        <div className='card-devider'></div>
        <div>
          {useCamera ? (
            <>
              {/* <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                className='custom-webcam'
              /> */}
              <div className="webcam-wrapper" style={{ position: 'relative', display: 'inline-block' }}>
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  screenshotFormat="image/jpeg"
                  className="custom-webcam"
                />
                <button
                  className="pi pi-camera"
                  onClick={handleCapture}
                  style={{
                    position: 'absolute',
                    top: '90%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    fontSize: '2rem',
                    color: 'white',
                    opacity: 0.7,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',  
                    border: '2px solid white',              
                    borderRadius: '50%',                    
                    padding: '1rem',                      
                    cursor: 'pointer',
                  }}
                />
              </div>
              {/* <div className='flex justify-content-center'>
                <Button
                  onClick={handleCapture}
                  className="mt-2 vstate-button font-fam-for-all"
                  style={{ height: "40px" }}
                >
                  Capture Photo
                </Button>
                <Button
                  onClick={handleCancelCamera}
                  className="mt-2 vstate-reverse-button font-fam-for-all"
                  style={{ height: "40px", marginLeft: "10px" }}
                >
                  Cancel
                </Button>
              </div> */}

            </>
          ) : (
            <Avatar
              alt="Profile Picture"
              src={avatarImage || ""}
              className='custom-avatar'
              sx={{ width: 124, height: 124 }}
            >
              {!avatarImage && getInitials(username)}
            </Avatar>
          )}
        </div>
        <div className='view-profile-footer-container'>
          <div className='card-devider'></div>
          {visible ? (
            <div className='view-profile-footer-main'>
              {/* <button className='view-profile-footer-content-main1' onClick={handleClick1}>
                <div className='view-profile-footer-content-main1-div1'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M17 3.00006C17.2626 2.73741 17.5744 2.52907 17.9176 2.38693C18.2608 2.24479 18.6286 2.17163 19 2.17163C19.3714 2.17163 19.7392 2.24479 20.0824 2.38693C20.4256 2.52907 20.7374 2.73741 21 3.00006C21.2626 3.2627 21.471 3.57451 21.6131 3.91767C21.7553 4.26083 21.8284 4.62862 21.8284 5.00006C21.8284 5.37149 21.7553 5.73929 21.6131 6.08245C21.471 6.42561 21.2626 6.73741 21 7.00006L7.5 20.5001L2 22.0001L3.5 16.5001L17 3.00006Z" stroke="#D0D5DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <div className='sidebar-items-text'>
                  Edit
                </div>
              </button> */}
              <div className='view-profile-footer-content-main2'>
                <button className='view-profile-footer-content-main2-div1' onClick={handleClick1}>
                  <div className='view-profile-footer-content-main1-div2'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                      <path d="M23 19C23 19.5304 22.7893 20.0391 22.4142 20.4142C22.0391 20.7893 21.5304 21 21 21H3C2.46957 21 1.96086 20.7893 1.58579 20.4142C1.21071 20.0391 1 19.5304 1 19V8C1 7.46957 1.21071 6.96086 1.58579 6.58579C1.96086 6.21071 2.46957 6 3 6H7L9 3H15L17 6H21C21.5304 6 22.0391 6.21071 22.4142 6.58579C22.7893 6.96086 23 7.46957 23 8V19Z" stroke="#D0D5DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                      <path d="M12 17C14.2091 17 16 15.2091 16 13C16 10.7909 14.2091 9 12 9C9.79086 9 8 10.7909 8 13C8 15.2091 9.79086 17 12 17Z" stroke="#D0D5DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                  </div>
                  <div className=' sidebar-items-text'>
                    Add photo
                  </div>
                </button>
              </div>
              {/* <div className='view-profile-footer-content-main3'>
                <div className='view-profile-footer-content-main3-div1'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M3 6H5M5 6H21M5 6V20C5 20.5304 5.21071 21.0391 5.58579 21.4142C5.96086 21.7893 6.46957 22 7 22H17C17.5304 22 18.0391 21.7893 18.4142 21.4142C18.7893 21.0391 19 20.5304 19 20V6H5ZM8 6V4C8 3.46957 8.21071 2.96086 8.58579 2.58579C8.96086 2.21071 9.46957 2 10 2H14C14.5304 2 15.0391 2.21071 15.4142 2.58579C15.7893 2.96086 16 3.46957 16 4V6M10 11V17M14 11V17" stroke="#D0D5DD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </div>
                <div className='view-profile-footer-content-main3-text1'>
                  Delete
                </div>
              </div> */}
            </div>
          ) : (
            <div className='view-profile-footer-container1'>
              <div className='view-profile-footer-container1-main'>
                {visible1 ? (
                  <>
                    {!useCamera &&<Button className="mt-2 vstate-reverse-button font-fam-for-all text-center mr-2" style={{ height: "40px" }} onClick={handleUseCamera}>Use camera</Button>}
                    {!useCamera && <Button className="mt-2 vstate-button font-fam-for-all text-center mr-3" style={{ height: "40px" }} onClick={handleClick}>Upload photo</Button>}
                  </>
                ) : (
                  <>
                    <Button className="mt-2 vstate-reverse-button font-fam-for-all text-center mr-2" style={{ height: "40px" }} onClick={() => setImageVisible(false)}>Cancel</Button>
                    <Button className="mt-2 vstate-button font-fam-for-all text-center mr-3" style={{ height: "40px" }} onClick={handleSave}>Save Photo</Button>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
        <input
          type="file"
          id="fileInput"
          style={{ display: 'none' }}
          accept="image/*"
          onChange={handleFileChange}
        />
      </div>
    </>
  );
}

export default UploadImage;
