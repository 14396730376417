import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";

const services = [
  {
    title: "Legal Compliance",
    description:
      "Corporate filings ensure your business remains compliant with regulations in all states or countries where you operate.",
  },
  {
    title: "Operational Flexibility",
    description:
      "Filings such as amendments or conversions allow your business to evolve as it grows, ensuring you meet current and future needs.",
  },
  {
    title: "Avoid Disruptions",
    description:
      "Timely and accurate filings prevent business disruptions due to non-compliance or incorrect documentation.",
  },
  {
    title: "Streamlined Process",
    description:
      "Our services simplify the filing process, reducing paperwork and helping you avoid errors or delays.",
  },
];

const CorporateFillingSec = () => {
  return (
    <section className="main-section-class">
      <div className="entity-formation">
        <Container>
          <CommonHeadingTitle heading={"Why should your business have a"} />
          <CommonHeadingTitle blueheading={"registered agent"} />
          <Row className="mt-5">
            <Col lg={6} md={6} sm={12} xs={12}>
              <Row>
                {services.map((service, index) => (
                  <Col lg={12} md={12} sm={12} xs={12} key={index}>
                    <div className="main-class-div">
                      <h4 className="medium-level-heading">{service.title}</h4>
                      <div className="grey-border mt-3 mb-3"></div>
                      <p className="normal-common-txt">{service.description}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <img
                loading="lazy"
                className="user-img-buisness"
                src={
                  process.env.PUBLIC_URL +
                  "https://cdn.builder.io/api/v1/image/assets/TEMP/3d25827044e772ced8f120d27b9829699eb72a2cf1f453dd209561109fc47155?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                }
              />
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default CorporateFillingSec;
