import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useContext, useEffect, useState } from 'react'
import OrderServices from '../../services/orderServices'
import MasterServices from '../../services/coreServices'
import authContext from '../../common/authContext'
import { useNavigate } from 'react-router-dom'
import { formatISODate } from '../../utils/dateUtils'
import { TabPanel, TabView } from 'primereact/tabview'

const CompanySubscriptions = ({ data }) => {

    const { setFeedbackData, permissions, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh, redirectedData, setRedirectedData } = useContext(authContext);


    const [products, setProducts] = useState([

    ])

    const [selectedProduct, setSelectedProduct] = useState(null)

    const navigate = useNavigate()




    useEffect(() => {
        if (data) {
            OrderServices.getClientSubscriptionByCompanyId(data.id)
                .then((res) => {
                    console.log(res)
                    if (Array.isArray(res.data)) {
                        console.log(res.data)
                        const subscriptionData= res.data.filter((val)=>val.isSubscription === true)
                        setProducts(subscriptionData)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    }, [data]);





    return (
        <div>
            <div className='grid p-4'>
                <div className='col-12'>
                    <TabView>

                        <TabPanel header="Your current subscriptions">

                            <div className="font-fam-for-all">

                                <div className='card my-3 hover-card slide-in1'>
                                    <div className=''>

                                        <div className='wizard-card-radio-container gap-0 font-fam-for-all'>
                                            <div className='wizard-card-radio-main'>
                                                {
                                                  products.length>0 ?  products.map((val) => (

                                                        <button className='wizard-card-checkbox-container hover:shadow-lg transform hover:scale-105 transition-transform duration-300'>
                                                            <div className='wizard-card-checkbox-main'>
                                                                <div className='wizard-card-checkbox-text-main'>
                                                                    <div className='wizard-card-checkbox-text-sub-main align-items-center'>
                                                                        <div className='w-full grid'>
                                                                            <div className='col-11'>
                                                                                <div className='wizard-card-checkbox-text1 font-fam-for-all text-start'>
                                                                                    <span className='font-semibold'></span>{val.productName}
                                                                                </div>
                                                                                <div className='srch-cand-text pt-2 text-start'>
                                                                                    <span className='font-semibold'>State :</span>{val.stateName}
                                                                                </div>
                                                                                <div className='srch-cand-text pt-2 text-start'>
                                                                                    <span className='font-semibold'>Order Date :</span>{formatISODate(val.orderDate)}
                                                                                </div>
                                                                                <div className='srch-cand-text pt-2 text-start'>
                                                                                    <span className='font-semibold'>Expiry Date :</span>{formatISODate(val.expiryDate)}
                                                                                </div>
                                                                            </div>

                                                                            <div className='wizard-card-checkbox-text2 col-1 flex align-items-center'>
                                                                                {/* ${val.productListing.unitPrice} */}
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className='wizard-card-checkbox-action-container flex align-items-center'>
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                                                            <path d="M7.5 15L12.5 10L7.5 5" stroke="#004EEC" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    )): <p className='font-fam-for-all'>No subscription found</p>
                                                }


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TabPanel>
                    </TabView>

                </div>

            </div>
        </div>
    )
}

export default CompanySubscriptions;