import React from "react";
import Modal from "react-bootstrap/Modal";
import CommonTourBlue from "./CommonTourComponent/CommonTourBlue";
import CommonTourModal from "./CommonTourModal";
import MyProfileTourModal from "./MyProfileTourModal";

const TourOneModal = (props) => {
  const [modalsecondShow, setModalSecondShow] = React.useState(false);

  const handleStartClick = () => {
    setModalSecondShow(true); 
    props.onHide();         
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <section className="main-class-tour">
            <div>
              <h4 className="tour-heading">Let’s Begin Tour!</h4>
              <p className="tour-text-holder">
                Click "Start" to start the tour or "Skip" if you’d like to
                explore on your own. You can always restart the tour from the
                help section.
              </p>
            </div>
            <div>
              <CommonTourBlue
                label={"Start"}
                onclick={handleStartClick}
              />
              <div className="mt-3">
                <section className="main-class-white-btn">
                  <button className="white-btnnn" onClick={props.onHide}>
                    Skip
                  </button>
                </section>
              </div>
            </div>
          </section>
        </Modal.Body>
      </Modal>
      <MyProfileTourModal
        show={modalsecondShow}
        onHide={() => setModalSecondShow(false)}
      />
    </div>
  );
};

export default TourOneModal;
