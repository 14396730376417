import html2canvas from "html2canvas";
import { Button } from "primereact/button";
import { useRef } from "react";
import { PDFDocument } from "pdf-lib";


const DelaWareCertificateOfFormation = (
  { orderData  }
) => {
  const componentRef = useRef();

  if (!orderData) {
    return <div>No order data available.</div>;
  }

  const {
    orderTitle = '',
    llcName = '',
    orderDate = '',
    billingInfo = {},
    clientName = ''
} = orderData;

const billingAddress = [
    billingInfo.city,
    billingInfo.state,
    billingInfo.zip,
]
    .filter(Boolean)
    .join(', ');


  // const downloadPDF = () => {
  //     const input = componentRef.current;
  //     html2canvas(input, { scale: 3 }).then((canvas) => {
  //         const imgData = canvas.toDataURL('image/png');
  //         const pdf = new jsPDF('p', 'mm', 'a4');

  //         // Calculate width and height to maintain aspect ratio
  //         const pdfWidth = pdf.internal.pageSize.getWidth();
  //         const canvasWidth = canvas.width;
  //         const canvasHeight = canvas.height;
  //         const pdfHeight = (canvasHeight * pdfWidth) / canvasWidth;

  //         // Add image to PDF
  //         pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
  //         pdf.save(`Delaware.pdf`);
  //     });
  // };

  const downloadPDF = async () => {
    const input = document.getElementById("content-to-pdf"); // Main content container

    const sections = Array.from(input.querySelectorAll(".section")); // Get all sections with class 'section'

    // Define A4 page size (in points)
    // const pageWidth = 595.28; // default
    const pageWidth = 595.28; // A4 width in points

    const pageHeight = 841.89; // A4 height in points

    // Create a new PDF document
    const pdfDoc = await PDFDocument.create();

    const scale = 2; // Scale for better resolution
    const pageMargin = 20; // Page margin

    // Function to render content of a section to a page
    const renderSection = async (section, startY) => {
      // Capture the section's content using html2canvas
      const canvas = await html2canvas(section, {
        scale: scale,
        y: startY,
        height: pageHeight * scale + 800,
      });

      const imgData = canvas.toDataURL("image/png");
      const img = await pdfDoc.embedPng(imgData);

      const imgWidth = img.width * (pageWidth / canvas.width) - 30; // Scale image to fit the page width
      const imgHeight = img.height * (pageHeight / canvas.height); // Scale image to fit the page height

      // Add a new page to the PDF
      const page = pdfDoc.addPage([pageWidth, pageHeight]);

      // Draw the image onto the page
      page.drawImage(img, {
        x: pageMargin,
        y: pageHeight - imgHeight - pageMargin, // Y position from the top of the page
        width: imgWidth,
        height: imgHeight,
      });
    };

    // Render each section on a new page
    for (let i = 0; i < sections.length; i++) {
      await renderSection(sections[i], 0);
    }

    // Serialize the document to bytes
    const pdfBytes = await pdfDoc.save();

    // Trigger the download of the PDF
    const link = document.createElement("a");
    link.href = URL.createObjectURL(
      new Blob([pdfBytes], { type: "application/pdf" })
    );
    link.download = "generated-document.pdf";
    link.click();
  };

  return (
    <>
      <div
        id="content-to-pdf"
        style={{
          backgroundColor: "#fff",
          display: "flex",
          flexDirection: "column",
          fontFamily: "Inter, sans-serif",
          // maxWidth: '1200px',
        }}
        ref={componentRef}
      >
        <div
          className="section"
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          {/* Header Section */}
          <div
            style={{
              display: "flex",
              width: "100%",
              alignItems: "start",
              color: "#101828",
              fontWeight: 600,
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                alignItems: "center",
                gap: "11px",
                justifyContent: "center",
                flex: 1,
                padding: "80px 120px",
              }}
            >
              <div
                style={{
                  alignSelf: "stretch",
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "start",
                  flex: 1,
                  margin: "auto 0",
                }}
              >
                <div
                  style={{
                    fontSize: "24px",
                    lineHeight: "normal",
                  }}
                >
                  CERTIFICATE OF FORMATION
                  <br />
                  OF
                </div>
                <div
                  style={{
                    fontSize: "28px",
                    lineHeight: 1,
                    marginTop: "24px",
                  }}
                >
                  {orderTitle}
                </div>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div
            style={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              justifyContent: "center",
              padding: "16px 120px 48px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  fontWeight: 500,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "40px 64px",
                    justifyContent: "start",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      color: "#101828",
                      whiteSpace: "nowrap",
                      lineHeight: 2,
                      width: "80px",
                    }}
                  >
                    First
                  </div>
                  <div
                    style={{
                      display: "flex",
                      minWidth: "240px",
                      // alignItems: 'start',
                      gap: "6px",
                      fontSize: "16px",
                      color: "#101828",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      flex: 1,
                      margin: "auto 0",
                    }}
                  >
                    <div>The name of the Limited Liability Company is</div>
                    <div
                      style={{
                        alignItems: "center",
                        borderBottom: "1px solid #d0d5dd",
                        background: "#fff",
                        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                        display: "flex",
                        minWidth: "240px",
                        gap: "8px",
                        fontSize: "14px",
                        color: "#667085",
                        fontWeight: 400,
                        lineHeight: "24px",
                        justifyContent: "start",
                        padding: "0 14px",
                        flex: 1,
                      }}
                    >
                      {orderTitle}
                    </div>
                    <div>.</div>
                  </div>
                </div>

                {/* Address Section */}
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: "40px 64px",
                    justifyContent: "start",
                  }}
                >
                  <div
                    style={{
                      fontSize: "18px",
                      color: "#101828",
                      whiteSpace: "nowrap",
                      lineHeight: 2,
                      width: "80px",
                    }}
                  >
                    Second
                  </div>
                  <div
                    style={{
                      display: "flex",
                      minWidth: "240px",
                      // alignItems: 'start',
                      gap: "6px",
                      fontSize: "16px",
                      color: "#101828",
                      justifyContent: "start",
                      flexWrap: "wrap",
                      flex: 1,
                      margin: "auto 0",
                    }}
                  >
                    <div>
                      The address of its registered office in the state of
                      Delaware is
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        borderBottom: "1px solid #d0d5dd",
                        background: "#fff",
                        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                        display: "flex",
                        minWidth: "240px",
                        gap: "8px",
                        fontSize: "14px",
                        color: "#667085",
                        fontWeight: 400,
                        lineHeight: "24px",
                        justifyContent: "start",
                        padding: "0 14px",
                        flex: 1,
                      }}
                    >
                      {billingAddress || "N/A"}
                    </div>
                    <div>.</div>
                  </div>
                </div>

                {/* Witness Section */}
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    gap: "5px",
                    lineHeight: "24px",
                  }}
                >
                  <div>
                    <strong>IN WITNESS WHEREOF</strong>, the undersigned has
                    executed this Certificate of Formation as of the date
                  </div>

                  <div
                    style={{
                      alignItems: "center",
                      borderBottom: "1px solid #d0d5dd",
                      background: "#fff",
                      boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
                      display: "flex",
                      gap: "8px",
                      fontSize: "14px",
                      color: "#667085",
                      fontWeight: 400,
                      lineHeight: "24px",
                      justifyContent: "start",
                      padding: "0 14px",
                      flex: 1,
                    }}
                  >
                    {orderDate}
                  </div>
                </div>

                {/* Signature Section */}
                <div
                  style={{
                    display: "flex",
                    marginTop: "20px",
                    flexDirection: "column",
                    justifyContent: "end",
                    padding: "24px 0",
                    maxWidth: "600px",
                  }}
                >
                  <div className="flex">
                    <div>By: /S/</div>
                    <div
                      style={{
                        width: "50%",
                        borderBottom: "1px solid #d0d5dd",
                        padding: "0 14px",
                        textAlign: "center",
                      }}
                    >
                      {clientName}
                    </div>
                  </div>
                  <div className="flex">
                    <div>Name:</div>
                    <div
                      style={{
                        width: "50%",
                        borderBottom: "1px solid #d0d5dd",
                        padding: "0 14px",
                        textAlign: "center",
                      }}
                    >
                      {clientName}
                    </div>
                    <div>,Authorized Person</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          style={{ background: "#e4e7ec", minHeight: "1px", width: "100%" }}
        />
      </div>
      <div className="flex w-full justify-content-center p-2">
        <Button
          className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3"
          label="Download PDF"
          icon="pi pi-download"
          onClick={downloadPDF}
        ></Button>
      </div>
    </>
  );
};

export default DelaWareCertificateOfFormation;
