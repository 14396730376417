import { Button } from 'primereact/button'
import { DataTable } from 'primereact/datatable'
import { InputText } from 'primereact/inputtext'
import React, { useContext, useEffect, useRef, useState } from 'react'
import authContext from '../../common/authContext'
import { Skeleton } from 'primereact/skeleton'
import { Column } from 'primereact/column'
import { FilterMatchMode } from 'primereact/api'
import RequestCompanyOwnership from './AddCompanyComponets/RequestCompanyOwnership'
import companyMasterService from '../../services/companyMasterService'
import { formatISODate } from '../../utils/dateUtils'
import MasterServices from '../../services/coreServices'
import { Toast } from 'primereact/toast'

const TransferOwnership = () => {
    const { globalCompany, clearData, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh, redirectedData, setRedirectedData } = useContext(authContext);

    const [superAdminId, setSuperAdminId] = useState(null)

    const [superAdminMainId, setSuperAdminMainId] = useState(null)

    const [selectedProduct, setSelectedProduct] = useState(null)

    const [products, setProducts] = useState([])

    const [data, setData] = useState(null)

    const [visible, setVisible] = useState(false)

    const user = JSON.parse(localStorage.getItem('user'));

    const [allStatus, setAllStatus] = useState([])

    const toast = useRef(null);


    useEffect(() => {
        MasterServices.getAllStatus()
            .then((res) => {
                if (res.data !== "") {
                    setAllStatus(res.data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }, [])


    const getStatus = (id) => {
        if (allStatus.length > 0) {
            const temp = allStatus.find((val) => val.id === id)
            if (temp) {
                return temp.statusName;
            }
            else {
                return null;
            }

        }
        else {
            return null;
        }
    }


    const onRowSelect = (rowData) => {
        setData(rowData.data)
    }

    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        id: { value: null, matchMode: FilterMatchMode.IN },
        companyName: { value: null, matchMode: FilterMatchMode.EQUALS },
        lastName: { value: null, matchMode: FilterMatchMode.EQUALS },
        mobileNo: { value: null, matchMode: FilterMatchMode.EQUALS },
        emailId: { value: null, matchMode: FilterMatchMode.EQUALS },

    });

    const [showSkeleton, setShowSkeleton] = useState(false)

    function checkIdsExist(arr, idsToCheck) {
        // Check if any of the idsToCheck are found in arr
        const foundIds = idsToCheck.filter(idToCheck => arr.some(item => item.id === idToCheck));
        return foundIds.length > 0 ? foundIds : null;
    }

    useEffect(() => {

        if (globalCompany !== null) {
            if (globalCompany.roles.length > 0) {
                // Check for both 1 and 4
                const ids = checkIdsExist(globalCompany.roles, [1, 4]);
                const superId = checkIdsExist(globalCompany.roles, [1]);
                console.log(ids);
                if (ids) {
                    setSuperAdminId(ids); // Set the array of matched IDs
                }
                if (!ids || ids.length === 0) {

                    setSuperAdminId(null)
                }
                if (superId) {
                    setSuperAdminMainId(superId)
                }
                else {
                    setSuperAdminMainId(null)
                }


                if (ids) {
                    companyMasterService.getAllCompanyTransfer()
                        .then((res) => {
                            console.log(res)
                            if (Array.isArray(res.data)) {
                                setProducts(res.data)
                                setShowSkeleton(true)
                            }
                            else {
                                setShowSkeleton(true)
                            }

                        })
                        .catch((err) => {
                            console.log(err)
                            setShowSkeleton(true)
                        })
                }
                else {
                    companyMasterService.getAllCompanyById(globalCompany?.companyDetails?.id)
                        .then((response) => {
                            console.log(response)
                            if (response.data !== "") {
                                const data = response.data
                                companyMasterService.getAllCompanyTransferByUserId(data?.adminContactId?.id)
                                    .then((res) => {
                                        console.log(res)
                                        if (Array.isArray(res.data)) {
                                            const filteredProduct = res.data.filter(
                                                (val) =>
                                                    val.statusId !== 25 || 
                                                    (val.statusId === 25 && val.requestedBy === data.adminContactId.id)
                                            );
                                            console.log(filteredProduct)
                                            if (filteredProduct && Array.isArray(filteredProduct)) {
                                                setProducts(filteredProduct) 
                                            }
                                        }
                                        setShowSkeleton(true)
                                    })
                                    .catch((err) => {
                                        console.log(err)
                                        setShowSkeleton(true)
                                    })
                            }

                        })
                        .catch((err) => {
                            console.log(err)
                            setShowSkeleton(true)
                        })
                }
            }


        }

    }, [globalCompany, isTabRefresh]);


    const orderdateBody = (rowdata) => (
        <div className='webapp-sm-size'>
            {rowdata.requestDate ? formatISODate(rowdata.requestDate) : ""}
        </div>
    )

    const items = Array.from({ length: 5 }, (v, i) => i);

    const statusBody = (rowdata) => {
        return (
            <div className='badge-container'>
                <div className='badge-main grid' style={{ background: '#ECFDF3' }}>
                    <div className="col-12 flex align-items-center p-0">

                        <span className='badge-label ml-1' style={{ color: '#027A48' }}>{getStatus(rowdata.statusId)}</span>
                    </div>

                </div>
            </div>
        )
    }


    const requestByBody = (rowdata) => {
        console.log(rowdata)
        return <div className='webapp-sm-size'>
            {`${rowdata?.transferFrom?.adminContactId?.firstName} ${rowdata?.transferFrom?.adminContactId?.lastName}`}
        </div>
    }

    const updateStatusCancel = (id) => {
        setIsTabRefresh(true)
        companyMasterService.updateTranferWithCancel(id)
            .then((res) => {
                console.log(res)
                setIsTabRefresh(false)
                if (toast.current !== undefined) {
                    toast.current.show({
                        severity: 'success', summary: 'company transfer cancelled', life: 3000

                    })
                }
            })
            .catch((err) => {
                console.log(err)
                setIsTabRefresh(false)
            })
    }

    const updateStatusApproved = (id) => {
        setIsTabRefresh(true)
        companyMasterService.updateTranferWithApproved(id)
            .then((res) => {
                console.log(res)
                setIsTabRefresh(false)
                if (toast.current !== undefined) {
                    toast.current.show({
                        severity: 'success', summary: 'company transfer approved', life: 3000

                    })
                }
            })
            .catch((err) => {
                console.log(err)
                setIsTabRefresh(false)
            })
    }

    const updateStatusRejected = (id) => {
        setIsTabRefresh(true)
        companyMasterService.updateTranferWithRejected(id)
            .then((res) => {
                console.log(res)
                setIsTabRefresh(false)
                if (toast.current !== undefined) {
                    toast.current.show({
                        severity: 'success', summary: 'company transfer rejected', life: 3000

                    })
                }
            })
            .catch((err) => {
                console.log(err)
                setIsTabRefresh(false)
            })
    }

    const updateStatusCompleted = (id) => {
        setIsTabRefresh(true)
        companyMasterService.updateTranferWithCompleted(id)
            .then((res) => {
                console.log(res)
                setIsTabRefresh(false)
                if (toast.current !== undefined) {
                    toast.current.show({
                        severity: 'success', summary: 'company transfer completed', life: 3000

                    })
                }
            })
            .catch((err) => {
                console.log(err)
                setIsTabRefresh(false)
            })
    }



    const iconBodyTemplate = (rowdata) => {
        console.log(rowdata)
        const isRaisedByUser = rowdata.transferFrom?.adminContactId?.emailId === user?.username
        console.log(isRaisedByUser)
        switch (rowdata?.statusId) {
            case 21:
                if (superAdminId) {
                    return (
                        <span
                            className="status-icon approved text-gray-600"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Pending"
                        >
                            Approval Pending
                        </span>
                    );
                }
                else {
                    if (isRaisedByUser) {
                        return (
                            <button
                                className="pi pi-times image-btn-icon"
                                data-toggle="tooltip"
                                data-placement="bottom"
                                title="Cancel"
                                onClick={() => updateStatusCancel(rowdata?.id)}
                            ></button>
                        );
                    } else {
                        return (
                            <>
                                <button
                                    className="pi pi-check image-btn-icon"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Accept"
                                    onClick={() => updateStatusApproved(rowdata?.id)}
                                ></button>
                                <button
                                    className="pi pi-times image-btn-icon"
                                    data-toggle="tooltip"
                                    data-placement="bottom"
                                    title="Reject"
                                    onClick={() => updateStatusRejected(rowdata?.id)}
                                ></button>
                            </>
                        );
                    }
                }


            case 22:
                if (superAdminId) {
                    return (
                        <button
                            className="pi pi-check image-btn-icon"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Migrate Company"
                            onClick={() => updateStatusCompleted(rowdata?.id)}
                        ></button>
                    );
                }
                else {
                    return (
                        <span
                            className="status-icon approved text-blue-600"
                            data-toggle="tooltip"
                            data-placement="bottom"
                            title="Approved"
                        >
                            Approved
                        </span>
                    );
                }


            case 23:
                return (
                    <span
                        className="status-icon rejected text-red-600"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Rejected"
                    >
                        Rejected
                    </span>
                );

            case 24:
                return (
                    <span
                        className="status-icon completed text-green-600"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Completed"
                    >
                        Completed
                    </span>
                );

            case 25:
                return (
                    <span
                        className="status-icon cancelled text-orange-600"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancelled"
                    >
                        Cancelled
                    </span>
                );

            default:
                return null;
        }
    };


    const childCompanyBody = (rowdata) => {
        console.log(rowdata);
        if (Array.isArray(rowdata.details)) {
            return rowdata.details
                .map((val) => val?.companyToTransfer?.companyName)
                .filter(Boolean)
                .join(', ');
        }
        return '';
    };




    return (

        <div className='grid webapp-content-container'>
            <Toast ref={toast} />


            <div className='col-12 '>

                <div className='card search-cand-card2'>

                    <div className='col-12 grid font-fam-for-all py-3 px-3'>
                        <div className='col-7' style={{ paddingLeft: '20px' }}>
                            <p className='search-cand-header mb-0'>Transfer Ownership</p>
                            <p className='search-cand-sub-header mb-0'>Track all requests for transferring ownership between entities.</p>
                        </div>
                        <div className='col-5'>
                            <div className="flex align-items-center justify-content-end gap-2 mt-3">
                                {!(superAdminId && superAdminMainId) && <Button className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Request New Transfer' onClick={() => setVisible(true)} ></Button>}
                            </div>


                        </div>
                    </div>



                    <div className='card-devider'>

                    </div>
                    <div className='col-12' style={{ height: 'fit-content', padding: "20px 20px 0px 30px" }}>
                        <div className='col-12 grid'>
                            <div className="flex flex-wrap align-items-center justify-content-start gap-3" style={{ width: "1300px" }}>
                                <span className="p-input-icon-left" >
                                    <i className="pi pi-search" />
                                    <InputText placeholder="Search" className='vstate-input-field-fixed'
                                        // value={filteredInput}
                                        // onKeyDown={(event) => {
                                        //     if (event.key === 'Enter') {
                                        //         handleSearch()
                                        //     }
                                        // }}
                                        // onChange={(e) => {
                                        //     setFilteredInput(e.target.value)
                                        //     if (e.target.value === "") {
                                        //         setProducts(tempProduct)

                                        //     }
                                        // }} 
                                        style={{ borderRadius: "8px", }} />
                                </span>

                                <span>
                                    <Button
                                        type="button"
                                        // icon="pi pi-plus"
                                        style={{ fontSize: "14px" }}
                                        className=" vstate-button font-fam-for-all text-center"
                                    // onClick={handleFilter}
                                    >Filter</Button>
                                </span>
                                <span>
                                    <Button
                                        type="button"
                                        // icon="pi pi-plus"
                                        style={{ fontSize: "14px" }}
                                        // onClick={handleClearFilter}
                                        className="  font-fam-for-all text-center vstate-reverse-button"
                                    >Clear Filter</Button>
                                </span>
                            </div>
                        </div>
                    </div>


                    <div style={{ padding: '30px' }}>

                        <div className='grid' >
                            <div className='col-12 pt-0 '>
                                <div className="">
                                    <div className='vstate-card'>

                                        {showSkeleton ? <DataTable value={products} rows={25} rowsPerPageOptions={[5, 10, 25, 50]} inline paginator emptyMessage='No Data Found' filters={filters}
                                            globalFilterFields={['companyName', 'lastName', 'mobileNo', 'emailId']} selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)}
                                            onRowSelect={onRowSelect} metaKeySelection={false}>
                                            <Column header="Transfer Date" body={orderdateBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                                            <Column field='transferFrom.companyName' header="Company Owner" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                                            <Column field='transferTo.companyName' header="New Owner" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                                            <Column header="Transfered Company" body={childCompanyBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                                            <Column header="Requested By" body={requestByBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                                            <Column field='requestedStatus' header="Requested Status" body={statusBody} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                                            {/* <Column field='approveOrRejectedBy' header="Approved/Rejected By" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column> */}
                                            <Column field='requestMessage' header="Comments" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                                            <Column header="Action" body={iconBodyTemplate} className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                                        </DataTable> : <DataTable value={items} rows={5} rowsPerPageOptions={[5, 10, 25, 50]} inline paginator emptyMessage='No Candidate Found'
                                            selectionMode="single" selection={selectedProduct} onSelectionChange={(e) => setSelectedProduct(e.value)}
                                            onRowSelect={onRowSelect} metaKeySelection={false}>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                            <Column body={<Skeleton />} className='datatable-body-icon'></Column>
                                        </DataTable>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RequestCompanyOwnership visible={visible} setVisible={setVisible} />
        </div>
    )
}

export default TransferOwnership