import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import BlueButton from "../CommonSection/BlueButton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const RegisteredBanner = () => {
  return (
    <section className="main-section-class">
      <Container>
        <Row>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div className="mt-5">
              <h3 className="home-banner-heading">
                Get the most reliable <br />
                <span className="big-blue-text">registered agent services</span>
                <br /> for your business
              </h3>
              <div className="mt-5">
                <BlueButton
                  label="Signup now"
                  icon={<FontAwesomeIcon icon={faArrowRightLong} />}
                />
              </div>
              <div className="d-flex mt-5">
                <img
                  src={process.env.PUBLIC_URL + "/Images/user.png"}
                  className="user-img mt-2"
                />
                <p className="home-banner-sub-tile-subscriber ms-3">
                  32k Satisfied <br /> Customers
                </p>
              </div>
            </div>
          </Col>
          <Col lg={6} md={6} sm={12} xs={12}>
            <div >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "https://cdn.builder.io/api/v1/image/assets/TEMP/f6faeccce27196208810871449c72c2f75db818a98ae2cfba17dc4a64f077abc?placeholderIfAbsent=true&apiKey=136b58d08a644862944c0e5f22adbbe0"
                }
                className="banner-img mt-5"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default RegisteredBanner;
