import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import CommonHeadingTitle from "../../CommonSection/CommonHeadingTitle";
import ServiceCommonCard from "../../CommonSection/ServiceCommonCard";
import BlueButton from "../../CommonSection/BlueButton";

const SubscriptionThird = () => {
  const services = [
    {
      title: "Registered Agent",
      description:
        "A service that provides a designated individual or entity to receive legal documents on behalf of a business. This service ensures that the business is compliant with state regulations.",
    },
    {
      title: "Annual Report",
      description:
        "A subscription service that manages the filing of annual reports required by the state to maintain business compliance. It ensures timely submissions to avoid penalties.",
    },
    {
      title: "Independent Manager",
      description:
        "A service that provides an independent manager for LLCs to assist with decision-making and compliance requirements. This can be beneficial for liability protection and operational efficiency.",
    },
    {
      title: "Independent Director",
      description:
        "Similar to an independent manager, this service provides an external director to enhance corporate governance and compliance. It adds an objective viewpoint to the board.",
    },
    {
      title: "Biennial Report",
      description:
        "A service that handles the filing of biennial reports required by some states, ensuring compliance and providing updated business information to state authorities.",
    },
  ];

  return (
    <section>
      <Container>
        <CommonHeadingTitle blueheading={"Subscriptions Services"} />
        <div className="text-center mt-5 mb-5">
          <h4 className="subheading">
            Subscription services, including registered agent services, annual
            reports, and independent managers or directors, provide ongoing
            support to ensure your business remains compliant and operational
            without disruption.
          </h4>
        </div>
        <Row className="justify-content-center">
          {services.map((service, index) => (
            <Col key={index} lg={4} md={4} sm={6} xs={12}>
              <ServiceCommonCard
                title={service.title}
                description={service.description}
              />
            </Col>
          ))}
        </Row>
        <div className="text-center mt-5 mb-5">
          <BlueButton label={"Get Quote"} />
        </div>
      </Container>
    </section>
  );
};

export default SubscriptionThird;
