import React, { useContext, useEffect, useState } from "react";
import ServiceCard from "./ServiceCard";
import { TabPanel, TabView } from "primereact/tabview";
import Video1 from "../../Assets/Video/dashboard.mp4"
import { Dropdown } from "primereact/dropdown";
import AddressService from "../../services/addressService";
import MasterServices from "../../services/coreServices";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import authContext from "../../common/authContext";

function ServiceSection() {

  const { setEntityDash, setStateDash, globalCompany, setGlobalCompany, isLoading, setIsLoading, isTabRefresh, setIsTabRefresh, orderTypeId, setOrderTypeId, addonServiceRedirectedData, setAddOnServiceRedirectedData } = useContext(authContext);

  const services = [
    {
      title: "Corporate Filings",
      description: "Access corporate kits and necessary publications.",
      features: [
        {
          "itemName": "Foreign Qualification",
          "path": "",
          "isFirst": false,
          "orderType": null,
          description1: "Access corporate kits and necessary publications.",
          "description": "When your company plans to do business in another state, you must register there as a foreign entity. This service manages the registration process, ensuring that your business meets all requirements to legally operate in other states."
        },
        {
          "itemName": "Limited Liability Partnership (LLP)",
          "path": "",
          "isFirst": false,
          "orderType": null,
          description1: "Access corporate kits and necessary publications.",
          "description": "An LLP offers liability protection for partners while allowing them to participate in the management of the business. This filing service helps establish the LLP structure and ensures compliance with state regulations."
        },
        {
          "itemName": "Limited Partnership (LP)",
          "path": "",
          "isFirst": false,
          "orderType": null,
          description1: "Access corporate kits and necessary publications.",
          "description": "In a Limited Partnership, there is at least one general partner who manages the business and is personally liable, and one or more limited partners who have liability protection but no management authority. This service assists in setting up an LP."
        },
        {
          "itemName": "Non-Profit",
          "path": "",
          "isFirst": false,
          "orderType": null,
          description1: "Access corporate kits and necessary publications.",
          "description": "Form a non-profit organization for charitable, religious, educational, or social purposes. This service helps guide you through the incorporation process and ensures compliance with applicable state and IRS regulations."
        },
        {
          "itemName": "501c3",
          "path": "",
          "isFirst": false,
          "orderType": null,
          description1: "Access corporate kits and necessary publications.",
          "description": "Obtain tax-exempt status for your non-profit organization by filing for 501(c)(3) status with the IRS. This service helps you complete the necessary forms and ensures compliance with federal requirements."
        },
        {
          "itemName": "Dissolution",
          "path": "",
          "isFirst": false,
          "orderType": null,
          description1: "Access corporate kits and necessary publications.",
          "description": "When it’s time to close your business, dissolution is a legal process that officially ends your company’s existence. This service assists in filing the necessary paperwork and fulfilling any legal obligations."
        },
        {
          "itemName": "Amendment",
          "path": "certificate-of-amendment",
          "isFirst": true,
          "orderType": 8,
          description1: "Access corporate kits and necessary publications.",
          "description": "If your company undergoes changes like a name change, adding new business activities, or changing its structure, this service helps you file the appropriate amendment with the state."
        },
        {
          "itemName": "S-Corp Election",
          "path": "s-corp-election",
          "isFirst": true,
          "orderType": 23,
          description1: "Access corporate kits and necessary publications.",
          "description": "This service helps your corporation elect to be taxed as an S-Corporation, offering the benefits of pass-through taxation, which avoids double taxation on both the corporate and individual levels."
        },
        {
          "itemName": "Conversion",
          "path": "",
          "isFirst": false,
          "orderType": null,
          description1: "Access corporate kits and necessary publications.",
          "description": "When you want to change your business entity type (e.g., from an LLC to a corporation), this service manages the conversion process, ensuring that your new entity complies with state and federal regulations."
        },
        {
          "itemName": "Merger",
          "path": "",
          "isFirst": false,
          "orderType": null,
          description1: "Access corporate kits and necessary publications.",
          "description": "This service facilitates the process of merging two companies into one, handling the legal documentation and filings to complete the merger in compliance with state laws"
        },
        {
          "itemName": "Reinstatement",
          "path": "",
          "isFirst": false,
          "orderType": null,
          description1: "Access corporate kits and necessary publications.",
          "description": "If your business has been dissolved or its status revoked, reinstatement services can bring it back into good standing with the state by filing the necessary documents and resolving any penalties."
        },

      ]
    },
    {
      title: "Compliance",
      description: "Stay up-to-date with all required business filings and regulations.",
      features: [
        {
          "itemName": "Beneficial Ownership (BOI)",
          "path": "beneficial-ownership-information-report",
          "isFirst": true,
          "orderType": 18,
          description1: "Stay up-to-date with all required business filings and regulations.",
          "description": "This service ensures your business complies with federal or state regulations by filing reports that disclose who has significant ownership or control over the company, promoting transparency and preventing fraud."
        },
        {
          "itemName": "Annual Report",
          "path": "annual-report",
          "isFirst": true,
          "orderType": 6,
          description1: "Stay up-to-date with all required business filings and regulations.",
          "description": "An Annual Report provides updated information about your business to the state, including changes in directors, officers, and address. It’s essential to keep your company in good standing and compliant with state laws."
        },
        {
          "itemName": "Reinstatement",
          "path": "",
          "isFirst": true,
          "orderType": null,
          description1: "Stay up-to-date with all required business filings and regulations.",
          "description": "If your business has fallen out of good standing or been administratively dissolved, reinstatement services will help bring your company back into compliance by filing the necessary documents and paying any outstanding fees."
        },
        {
          "itemName": "Biennial Report",
          "path": "biennial-report",
          "isFirst": true,
          "orderType": 7,
          description1: "Stay up-to-date with all required business filings and regulations.",
          "description": "Similar to an Annual Report, but filed every two years, a Biennial Report ensures that your business remains in compliance with state regulations by submitting updated company information."
        },
        {
          "itemName": "LLC Publication",
          "path": "llc-publication",
          "isFirst": true,
          "orderType": 4,
          description1: "Stay up-to-date with all required business filings and regulations.",
          "description": "Some states require LLCs to publish a notice of their formation in designated newspapers. This service manages the entire process, ensuring compliance with state laws and avoiding potential penalties."
        },
        {
          "itemName": "Liquor License Publication",
          "path": "liquor-license-publishing",
          "isFirst": true,
          "orderType": 14,
          description1: "Stay up-to-date with all required business filings and regulations.",
          "description": "If you're applying for a liquor license, you may be required to publish a notice in a local newspaper. This service handles the publication process, making sure you meet all local requirements."
        },
        {
          "itemName": "Foundation Publication",
          "path": "",
          "isFirst": true,
          "orderType": null,
          description1: "Stay up-to-date with all required business filings and regulations.",
          "description": "Certain states require that foundations publish a notice of their formation. This service ensures compliance with state publication requirements."
        },

        {
          "itemName": "Sidewalk Cafe Publication",
          "path": "",
          "isFirst": true,
          "orderType": null,
          description1: "Stay up-to-date with all required business filings and regulations.",
          "description": "For businesses seeking permits for sidewalk cafés, publishing a notice may be a legal requirement. This service takes care of the publication process as part of the permit application."
        },
        {
          "itemName": "DBA Publication",
          "path": "dba-certificate-of-assumed-name-form",
          "isFirst": true,
          "orderType": 10,
          description1: "Stay up-to-date with all required business filings and regulations.",
          "description": "When operating under a Doing Business As (DBA) name, some states require you to publish a notice. This service ensures your DBA name is properly registered and published"
        },
        {
          "itemName": "EIN",
          "path": "ein-product-form",
          "isFirst": true,
          "orderType": 22,
          description1: "Stay up-to-date with all required business filings and regulations.",
          "description": "When operating under a Doing Business As (DBA) name, some states require you to publish a notice. This service ensures your DBA name is properly registered and published"
        },
       


      ]
    },
    
    {
      title: "Subscriptions",
      description: "Secure ongoing legal representation and document management services.",
      features: [
        {
          "itemName": "Registered Agent",
          "path": "reg-agent-product",
          "isFirst": true,
          "orderType": 24,
          description1: "Secure ongoing legal representation and document management services.",
          "description": "A registered agent receives legal documents, such as lawsuits or subpoenas, on behalf of your business and ensures you meet state compliance requirements."
        },
        {
          "itemName": "Annual Report",
          "path": "annual-report",
          "isFirst": true,
          "orderType": 6,
          description1: "Secure ongoing legal representation and document management services.",
          "description": "This service ensures that your annual reports are filed on time each year, keeping your business in good standing with the state."
        },
        {
          "itemName": "Independent Manager",
          "path": "independent-manager",
          "isFirst": true,
          "orderType": 13,
          description1: "Secure ongoing legal representation and document management services.",
          "description": "Certain LLCs may require an independent manager to meet legal obligations. This service provides qualified individuals to serve in that role."
        },
        {
          "itemName": "Independent Director",
          "path": "",
          "isFirst": true,
          "orderType": null,
          description1: "Secure ongoing legal representation and document management services.",
          "description": "This service helps you appoint an independent director, as required by law for certain companies, ensuring the company has impartial oversight."
        },
        {
          "itemName": "Biennial Report",
          "path": "biennial-report",
          "isFirst": true,
          "orderType": 7,
          description1: "Secure ongoing legal representation and document management services.",
          "description": "Similar to an annual report, but filed every two years, this service manages the timely filing of biennial reports to keep your company compliant."
        },

      ]
    },
    {
      title: "UCC Filings",
      description: "File and manage UCC secured transactions.",
      features: [
        {
          "itemName": "UCC Filing",
          "path": "ucc-filing",
          "isFirst": true,
          "orderType": 15,
          description1: "File and manage UCC secured transactions.",
          "description": "File a UCC-1 form to publicly declare a creditor’s interest in a debtor’s personal or business assets, securing a legal claim to those assets in case of default."
        },

        {
          "itemName": "UCC Amendment",
          "path": "ucc-amendment",
          "isFirst": true,
          "orderType": 17,
          description1: "File and manage UCC secured transactions.",
          "description": "Make updates to existing UCC filings, such as changing the details of the collateral or parties involved."
        },
        {
          "itemName": "UCC Search",
          "path": "ucc-search",
          "isFirst": true,
          "orderType": 16,
          description1: "File and manage UCC secured transactions.",
          "description": "Conduct a search to verify any existing UCC filings on a business or individual, helping assess whether assets are encumbered by liens."
        },
        {
          "itemName": "Litigation Search",
          "path": "litigation-search",
          "isFirst": true,
          "orderType": 5,
          description1: "File and manage UCC secured transactions.",
          "description": "This service searches public records to uncover any ongoing or past litigation involving a business, ensuring you have a clear understanding of its legal standing."
        },
      ]
    },
    {
      title: "Document Retrieval",
      description: "Order certified copies of business records and legal documents.",
      features: [
        {
          "itemName": "Certificate of Good Standing",
          "path": "cgs",
          "isFirst": false,
          "orderType": 2,
          description1: "Order certified copies of business records and legal documents.",
          "description": "A document issued by the state that certifies your company is in compliance with all regulations and has paid any necessary fees. It’s often required for business dealings like securing loans or entering contracts."
        },
        {
          "itemName": "Formation Documents",
          "orderType": 12,
          "description": "",
          "path": "",
          "isFirst": true,
          "orderType": null,
          description1: "Order certified copies of business records and legal documents.",
          "description": "Retrieve official copies of your company's original formation documents, such as articles of incorporation or organization, as proof of your company’s legal existence."
        },
        {
          "itemName": "Amendment Documents",
          "path": "certificate-of-amendment",
          "isFirst": true,
          "orderType": 8,
          description1: "Order certified copies of business records and legal documents.",
          "description": "This service provides copies of any amendments filed to your company’s original formation documents, reflecting changes to its structure or name."
        },
        {
          "itemName": "Certified Copies",
          "orderType": 12,
          "description": "",
          "path": "copie",
          "isFirst": true,
          description1: "Order certified copies of business records and legal documents.",
          "description": ""
        },

        {
          "itemName": "Certificate of Change",
          "path": "certificate-of-change",
          "isFirst": true,
          "orderType": 9,
          description1: "Order certified copies of business records and legal documents.",
          "description": ""
        },

        {
          "itemName": "Certificate of Dissolution",
          "path": "certificate-of-dissolution",
          "isFirst": true,
          "orderType": 11,
          description1: "Order certified copies of business records and legal documents.",
          "description": ""
        },

      ]
    },
    {
      title: "Corporate Supplies",
      description: "Manage and order essential corporate supplies.",
      features: [
        {
          "itemName": "Corporate Kit",
          "path": "kit",
          "isFirst": false,
          "orderType": 3,
          description1: "Manage and order essential corporate supplies.",
          "description": "A corporate kit contains essential items for maintaining corporate records, including a binder, stock certificates, minutes forms, and a company seal."
        },
        {
          "itemName": "Corporate Seal",
          "path": "",
          "isFirst": false,
          "orderType": null,
          description1: "Manage and order essential corporate supplies.",
          "description": "A customized embosser that authenticates official company documents. It imprints your company’s name and details to provide a formal stamp of approval on important paperwork."
        },

        {
          "itemName": "Stock/Membership Certificates",
          "path": "",
          "isFirst": false,
          "orderType": null,
          description1: "Manage and order essential corporate supplies.",
          "description": "Provide shareholders or LLC members with official certificates to document their ownership stake in the company."
        },

      ]
    },

  ];


  const [entityTypes, setEntityTypes] = useState([])

  const [enityName, setEntityName] = useState(null)

  const [allState, setAllState] = useState([])

  const [state, setState] = useState(null)

  const navigate = useNavigate()

  useEffect(() => {
    MasterServices.getAll("/core/api/entityTypeMaster/getAll")
      .then((res) => {
        console.log(res.data)
        if (res.data !== "") {
          setEntityTypes(res.data)
        }
      })
      .catch((err) => {
        console.log(err)
      })

    AddressService.getAllStates()
      .then((res) => {
        console.log(res)
        if (res.data !== "") {
          setAllState(res.data)
        }

      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  const handleNavigate = () => {
    setStateDash(state)
    setEntityDash(enityName)
    navigate("/dashboard/orderform")

  }


  return (
    <div className="flex flex-col px-4 w-full max-md:px-5 max-md:max-w-full">
      <div className="flex gap-6 items-start  w-full max-md:max-w-full">
        <div className="flex overflow-hidden flex-wrap flex-1 shrink gap-5 justify-center p-4 w-full  rounded-lg shadow-md basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full" style={{ background: "#F0F5FF" }}>

          <video src={Video1} autoPlay muted className="min-w-[240px] w-[270px]"></video>
          {/* <img loading="lazy" src={"https://cdn.builder.io/api/v1/image/assets/TEMP/8762e5e6aa680b464ea4fa921fcaa9959f7adfd6025dc742c5e6ead79e346f8f?apiKey=136b58d08a644862944c0e5f22adbbe0&"} alt="" className="object-contain shrink-0 aspect-[1.24] min-w-[240px] w-[270px]" /> */}

          <div className="flex flex-col flex-1 shrink my-auto basis-0 min-w-[240px] max-md:max-w-full">
            <div className="flex flex-col w-full max-md:max-w-full">
              <div className="flex flex-col w-full max-md:max-w-full">
                <h2 className="text-2xl font-medium tracking-tight leading-loose text-gray-900 max-md:max-w-full mb-0">
                  Form a Company Within Minutes!
                </h2>
                <p className="text-base text-gray-500 max-md:max-w-full mb-0">One-stop formation and filing service for all types of businesses.</p>
              </div>
              <div className="flex grid flex-wrap items-start mt-3 w-full max-md:max-w-full">

                <div className="flex col-12 col-md-12 col-lg-12 col-xl-6 gap-4 items-center">
                  <span className="self-stretch my-auto text-lg font-black leading-none text-center text-blue-700 w-[18px]">✓</span>
                  <span className="self-stretch my-auto text-sm font-medium leading-6 text-gray-900">Corporation</span>
                </div>

                <div className="flex col-12 col-md-12 col-lg-12 col-xl-6 gap-4 items-center">
                  <span className="self-stretch my-auto text-lg font-black leading-none text-center text-blue-700 w-[18px]">✓</span>
                  <span className="self-stretch my-auto text-sm font-medium leading-6 text-gray-900">Limited Liability Company</span>
                </div>


                <div className="flex col-12 col-md-12 col-lg-12 col-xl-6 gap-4 items-center">
                  <span className="self-stretch my-auto text-lg font-black leading-none text-center text-blue-700 w-[18px]">✓</span>
                  <span className="self-stretch my-auto text-sm font-medium leading-6 text-gray-900">Professional Corporation</span>
                </div>
                <div className="flex col-12 col-md-12 col-lg-12 col-xl-6 gap-4 items-center">
                  <span className="self-stretch my-auto text-lg font-black leading-none text-center text-blue-700 w-[18px]">✓</span>
                  <span className="self-stretch my-auto text-sm font-medium leading-6 text-gray-900">Professional Limited Liability Company</span>
                </div>
              </div>

              <div className="flex flex-column justify-content-center mt-3">
                <div className="input-fields-main ">
                  <div className="grid w-full justify-content-start">
                    <div className="col-12 col-md-12 col-lg-12 col-xl-4">
                      <div className=" justify-content-center dialog-form-field-space w-full">
                        <div className="field">
                          <label
                            htmlFor=""
                            className="form-label font-fam-for-all"
                          >
                            Select Entity Type
                            <span className="form-field-mandatory">
                              *
                            </span>
                          </label>
                          <span className="p-float-label ">

                            <Dropdown
                              value={enityName}
                              options={entityTypes}
                              onChange={(e) => setEntityName(e.value)}
                              optionLabel="entityShortName"
                              placeholder="Select Entity Type"
                              style={{
                                width: "100%",
                                height: "47px",
                              }}

                              filter
                              className="dialog-form-input-field-wizard"
                              required
                            />

                          </span>
                        </div>

                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 col-xl-4 ">
                      <div className=" justify-content-center dialog-form-field-space w-full">
                        <div className="field">
                          <label
                            htmlFor=""
                            className="form-label font-fam-for-all"
                          >
                            State of Formation{" "}
                            <span className="form-field-mandatory">
                              *
                            </span>
                          </label>
                          <span className="p-float-label ">

                            <Dropdown
                              value={state}
                              options={allState}
                              onChange={(e) => {

                                setState(e.value);
                              }}
                              optionLabel="stateFullDesc"
                              style={{
                                width: "100%",
                                height: "47px",
                              }}
                              placeholder="Select State"
                              filter
                              className="dialog-form-input-field-wizard"
                              required
                            />

                          </span>

                        </div>

                      </div>
                    </div>
                    <div className="col-12 col-md-12 col-lg-12 col-xl-4 align-items-center flex mt-4 pt-3">
                      <Button className=" form-Btn-Label vstate-button font-fam-for-all text-center  form-label mr-3" label='Register Now' onClick={() => handleNavigate()}  ></Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className=" mt-4 overflow-hidden flex-wrap flex-1 shrink gap-5 justify-center p-4 w-full  rounded-lg shadow-md basis-0 min-w-[240px] max-md:px-5 max-md:max-w-full" style={{ background: "#F0F5FF" }}>
        <TabView className="bg-blue-50">
          {services.map((service, index) => (
            <TabPanel header={service.title}>
              <ServiceCard key={index} {...service} />
            </TabPanel>
          ))}
        </TabView>
      </div>


    </div>
  );
}

export default ServiceSection;