import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import React, { useEffect, useState } from 'react'
import MasterServices from '../../services/coreServices'

const Documents = ({ data }) => {

    const [products, setProducts] = useState([

    ])

    console.log(data)

    useEffect(() => {
        if (data) {
            if (Array.isArray(data.orderDetails.documentsId)) {
                if (data.orderDetails.documentsId.length > 0) {
                    setProducts(data.orderDetails.documentsId)
                }
            }
        }
    }, [data])

    function downloadPDFFromBase64(base64String, filename) {
    
        const byteCharacters = atob(base64String);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'application/file' });

        
        const url = URL.createObjectURL(blob);

    
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;

        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    const handleFileDownload=(rowdata)=>{
        if (rowdata.documentPath) {
            MasterServices.getFile(rowdata.documentPath)
            .then((res)=>{
                console.log(res)
                downloadPDFFromBase64(res.data,rowdata.documentName)

            })
            .catch((err)=>{
                console.log(err)
            })
        }
    }

    // Action column template
    const iconBodyTemplate = (rowData) => {
        console.log(rowData)
        return (
            <>
                <button className="datatable-highlight-color" onClick={()=>handleFileDownload(rowData)}>
                    <span><i className='pi pi-download mr-1'></i></span>Download
                </button>
            </>
        );
    };
    return (
        <div>
            <DataTable value={products} scrollable
                dataKey='name' paginator rows={10} rowsPerPageOptions={[5, 10, 25, 50]}
                className='font-fam-for-all datatable-sm'

                globalFilterFields={["djjddjjd"]}>


                <Column field='documentName' header="Name" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                {/* <Column field='type' header="Type" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column> */}
                {/* <Column field='createdBy' header="Uploaded By" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>
                <Column field='creationDate' header="Date Modified" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column> */}
                <Column body={iconBodyTemplate} header="Action" className='font-fam-for-all datatable-lg-col webapp-sm-size'></Column>


            </DataTable>
        </div>
    )
}

export default Documents